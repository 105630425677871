import React from "react";
import { useAuth } from "../../contexts/AuthContext";
import Header from "./components/header";
import "./leftPanel3.css";
import DrawOptions from "./components/drawOptions";
import EraserOptions from "./components/eraserOptions";
import FilterOptions from "./components/filterOptions";
import SharpenOptions from "./components/sharpenOptions";
import ColorOptions from "./components/colorOptions";
import SaturationOptions from "./components/saturationOptions";
import VibranceOptions from "./components/vibranceOptions";
import HueOptions from "./components/hueOptions";
import PixelateOptions from "./components/pixelateOptions";
import BlurOptions from "./components/blurOptions";
import EmbossOptions from "./components/embossOptions";
import BlendColorOptions from "./components/blendColorOptions";
import BlendImageOptions from "./components/blendImageOptions";
import TextColorsOptions from "./components/textColorsOptions";
import FontsOptions from "./components/fontsOptions";
import TextAlignmentsOptions from "./components/textAlignmentsOptions";
import LineHeightOptions from "./components/lineHeightOptions";
import CharSpacingOptions from "./components/CharSpacingOptions";
import TextStylesOptions from "./components/textStylesOptions";
import WhiteNBlack from "./components/whiteNBlackOptions";
import AlternateOptions from "./components/alternateOptions";
import BrightnessOptions from "./components/brightnessOptions";
import RemoveImgBg from "./components/removeImgBg";
import { useSelector } from "react-redux";
import { colorConstants } from "../../colors";
const { LEFT_PANEL3_BG_COLOR } = colorConstants;

const LeftPanel3 = ({ save, saveBack }) => {
  const { currentUser } = useAuth();
  const _home = useSelector((state) => state.home);
  const setActiveStyle = (styleName, value, object) => {
    object = object || _home.currentCanvas.getActiveObject();
    if (!object) return;

    if (object.setSelectionStyles && object.isEditing) {
      var style = {};
      style[styleName] = value;
      object.setSelectionStyles(style);
      object.setCoords();
    } else {
      object.set(styleName, value);
    }

    object.setCoords();
    _home.currentCanvas.requestRenderAll();
    if (currentUser) {
      if (_home.face === "front") {
        save();
      } else {
        saveBack();
      }
    }
  };

  const getActiveStyle = (styleName, object) => {
    object = object || _home.currentCanvas.getActiveObject();
    if (!object) return "";

    return object.getSelectionStyles && object.isEditing
      ? object.getSelectionStyles()[styleName] || ""
      : object[styleName] || "";
  };
  return (
    <div
      style={
        !_home.lP3
          ? { display: "none", background: LEFT_PANEL3_BG_COLOR }
          : { background: LEFT_PANEL3_BG_COLOR }
      }
      className="panel6-main-wrapper"
    >
      <Header
        lP2SelectedOption={_home.lP2SelectedOption}
        mousecursor={_home.mousecursor}
        mousecursor1={_home.mousecursor1}
      />
      <div className="lp3-options">
        {_home.lP2SelectedOption === "Draw" ? (
          <DrawOptions
            mousecursor={_home.mousecursor}
            mousecursor1={_home.mousecursor1}
          />
        ) : null}
        {_home.lP2SelectedOption === "leftPanel2.CropandErase" ? (
          <EraserOptions mousecursor={_home.mousecursor} />
        ) : null}
        {_home.lP2SelectedOption === "leftPanel2.Filters" ? (
          <FilterOptions save={save} saveBack={saveBack} />
        ) : null}
        {/* {lP2SelectedOption === "Remove Color" ? <RemoveColorOptions /> : null} */}
        {_home.lP2SelectedOption === "Sharpen" ? (
          <SharpenOptions save={save} saveBack={saveBack} />
        ) : null}
        {_home.lP2SelectedOption === "leftPanel2.Colorize" ? (
          <ColorOptions save={save} saveBack={saveBack} />
        ) : null}
        {_home.lP2SelectedOption === "Saturation" ? (
          <SaturationOptions save={save} saveBack={saveBack} />
        ) : null}
        {_home.lP2SelectedOption === "Vibrance" ? (
          <VibranceOptions save={save} saveBack={saveBack} />
        ) : null}
        {_home.lP2SelectedOption === "Hue" ? (
          <HueOptions save={save} saveBack={saveBack} />
        ) : null}
        {_home.lP2SelectedOption === "leftPanel2.Alternate" ? (
          <AlternateOptions save={save} saveBack={saveBack} />
        ) : null}
        {_home.lP2SelectedOption === "Pixelate" ? (
          <PixelateOptions save={save} saveBack={saveBack} />
        ) : null}
        {_home.lP2SelectedOption === "Blur" ? (
          <BlurOptions save={save} saveBack={saveBack} />
        ) : null}
        {_home.lP2SelectedOption === "Emboss" ? (
          <EmbossOptions save={save} saveBack={saveBack} />
        ) : null}
        {_home.lP2SelectedOption === "leftPanel2.BlendColor" ? (
          <BlendColorOptions save={save} saveBack={saveBack} />
        ) : null}
        {_home.lP2SelectedOption === "Blend Image" ? (
          <BlendImageOptions />
        ) : null}
        {_home.lP2SelectedOption === "TextOptions.TextColors" ? (
          <TextColorsOptions save={save} saveBack={saveBack} />
        ) : null}
        {_home.lP2SelectedOption === "TextOptions.Fonts" ? (
          <FontsOptions save={save} saveBack={saveBack} />
        ) : null}
        {_home.lP2SelectedOption === "Text Alignments" ? (
          <TextAlignmentsOptions save={save} saveBack={saveBack} />
        ) : null}
        {_home.lP2SelectedOption === "Line Height" ? (
          <LineHeightOptions setActiveStyle={setActiveStyle} />
        ) : null}
        {_home.lP2SelectedOption === "Char Spacing" ? (
          <CharSpacingOptions setActiveStyle={setActiveStyle} />
        ) : null}
        {_home.lP2SelectedOption === "Text Styles" ? (
          <TextStylesOptions
            setActiveStyle={setActiveStyle}
            getActiveStyle={getActiveStyle}
          />
        ) : null}
        {_home.lP2SelectedOption === "leftPanel2.Black&White" ? (
          <WhiteNBlack
            setActiveStyle={setActiveStyle}
            getActiveStyle={getActiveStyle}
          />
        ) : null}
        {_home.lP2SelectedOption === "leftPanel2.Brightness" ? (
          <BrightnessOptions save={save} saveBack={saveBack} />
        ) : null}
        {_home.lP2SelectedOption === "Remove Image BG" ? <RemoveImgBg /> : null}
      </div>
    </div>
  );
};

export default LeftPanel3;
