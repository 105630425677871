import React, { useEffect, useState } from "react";
import "./productItem.css";
import { calcCf } from "../../../../../../util";
import { useDispatch } from "react-redux";
import { cartConstants } from "../../../../../../redux/constants";
import ProductHoverColors from "./components/productHoverColors";
const { SELECT_PRODUCT } = cartConstants;

const ProductItem = ({
  setProductsPopupOpen,
  product,
  index,
  setSelectedProduct,
  // setShowDetail,
}) => {
  const dispatch = useDispatch();
  const [newProduct, setNewProduct] = useState();
  const [showColor, setShowColor] = useState(false);

  const calculateMinMaxPrice = () => {
    let min = parseFloat(product.variants[0].subVariants[0].price);
    let max = parseFloat(product.variants[0].subVariants[0].price);
    for (let i = 0; i < product.variants.length; i++) {
      for (let j = 0; j < product.variants[i].subVariants.length; j++) {
        if (parseFloat(product.variants[i].subVariants[j].price) < min) {
          min = parseFloat(product.variants[i].subVariants[j].price);
        }
        if (parseFloat(product.variants[i].subVariants[j].price) > max) {
          max = parseFloat(product.variants[i].subVariants[j].price);
        }
      }
    }
    if (min === max) {
      return min;
    } else {
      return min + " - " + max;
    }
  };

  useEffect(() => {
    if (product) {
      let newItem = product;
      const w = parseInt(product.productImage.split(/[ ;]+/)[0]);
      const h = parseInt(product.productImage.split(/[ ;]+/)[1]);
      const productImage = {
        width: w,
        height: h,
        url: product.productImage.split(/[ ;]+/)[2],
      };
      newItem = { ...newItem, productImage: productImage };

      let newArray = [...product.variants];
      for (let i = 0; i < product.variants.length; i++) {
        if (product.variants[i].imageURL) {
          const w = parseInt(
            product.variants[i].imageURL.url.split(/[ ;]+/)[0]
          );
          const h = parseInt(
            product.variants[i].imageURL.url.split(/[ ;]+/)[1]
          );
          const imageURL = {
            width: w,
            height: h,
            url: product.variants[i].imageURL.url.split(/[ ;]+/)[2],
          };
          newArray[i] = { ...newArray[i], imageURL: imageURL };
        } else {
          newArray[i] = { ...newArray[i], imageURL: null };
        }
      }
      newItem = { ...newItem, variants: newArray };
      setNewProduct(newItem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);
  // const handleProductSelect = () => {
  //   // setShowDetail(true);
  //   dispatch({
  //     type: SELECT_PRODUCT,
  //     payload: product,
  //   });
  //   setSelectedProduct(newProduct);
  // };
  const handleProductSelect1 = () => {
    dispatch({
      type: SELECT_PRODUCT,
      payload: product,
    });
    setSelectedProduct(newProduct);
  };
  return (
    <div
      key={index}
      className="product-item"
      onMouseEnter={() => setShowColor(true)}
      onMouseLeave={() => setShowColor(false)}
    >
      <div className="prod-image">
        <img
          src={newProduct?.productImage.url}
          width={
            newProduct?.productImage.width /
            calcCf(
              newProduct?.productImage.width,
              newProduct?.productImage.height,
              200
            )
              ? newProduct?.productImage.width /
                calcCf(
                  newProduct?.productImage.width,
                  newProduct?.productImage.height,
                  200
                )
              : ""
          }
          height={
            newProduct?.productImage.height /
            calcCf(
              newProduct?.productImage.width,
              newProduct?.productImage.height,
              200
            )
              ? newProduct?.productImage.height /
                calcCf(
                  newProduct?.productImage.width,
                  newProduct?.productImage.height,
                  200
                )
              : ""
          }
          alt="product"
        />
      </div>
      <div className="prod-text-box">
        <h4>{newProduct?.name}</h4>
        <h5>
          <span>{calculateMinMaxPrice()}</span>
        </h5>
        <i className="ri-more-fill"></i>
      </div>
      {showColor ? (
        <div className="product-overlay">
          <ProductHoverColors
            product={product}
            handleProductSelect1={handleProductSelect1}
            setProductsPopupOpen={setProductsPopupOpen}
          />
        </div>
      ) : null}
    </div>
  );
};

export default ProductItem;
