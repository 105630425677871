/* eslint-disable no-unused-vars */
import { Tooltip, withStyles } from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userCanvasesConstants } from "../../../../../../redux/constants";
import "./canvasButton.css";
import { useTranslation } from "react-i18next";
const { SELECTED_CANVAS } = userCanvasesConstants;

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const CanvasButton = ({
  index,
  canvas,
  handleRemoveCanvaus,
  handleEditCanvausName,
  handleUpdateCurrentCanvas,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const _home = useSelector((state) => state.home);
  const loadCanvas = () => {
    dispatch({
      type: SELECTED_CANVAS,
      payload: canvas.canvasName,
    });
    _home.currentCanvas.clear();
    _home.currentCanvas.loadFromJSON(canvas.canvas, function () {
      _home.currentCanvas.renderAll();
    });
  };
  return (
    <div className="canvasButton" key={index}>
      <p onClick={() => loadCanvas()}>{canvas.canvasName}</p>
      <div className="groupIcons-box">
        <div className="groupIcon" onClick={() => loadCanvas()}>
          <LightTooltip title={t("CanvasButton.LoadCanvas")} placement="top">
            <i className="ri-tv-2-line"></i>
          </LightTooltip>
        </div>
        <div className="groupIcon" onClick={handleUpdateCurrentCanvas}>
          <LightTooltip
            title={t("CanvasButton.UpdatewithCurrentCanvas")}
            placement="top"
          >
            <i className="ri-save-line"></i>
          </LightTooltip>
        </div>
        <div className="groupIcon" onClick={handleEditCanvausName}>
          <LightTooltip
            title={t("CanvasButton.EditCanvasName")}
            placement="top"
          >
            <i className="ri-text"></i>
          </LightTooltip>
        </div>
        <div className="groupIcon" onClick={handleRemoveCanvaus}>
          <LightTooltip title={t("CanvasButton.DeleteCanvas")} placement="top">
            <i className="ri-delete-bin-line"></i>
          </LightTooltip>
        </div>
      </div>
    </div>
  );
};

export default CanvasButton;
