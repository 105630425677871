/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./productHoverColors.css";
import {
  cartConstants,
  homeConstants,
} from "../../../../../../../../redux/constants";
import { useDispatch, useSelector } from "react-redux";
const { SELECT_COLOR } = cartConstants;
const { R, G, B } = homeConstants;

const ProductHoverColors = ({
  product,
  handleProductSelect1,
  setProductsPopupOpen,
}) => {
  const dispatch = useDispatch();
  const _home = useSelector((state) => state.home);
  const _cart = useSelector((state) => state.cart);
  const [colors, setColors] = useState([]);
  const subVarientsChecking = (subVariants) => {
    for (let i = 0; i < subVariants.length; i++) {
      if (subVariants[i].inStock) {
        return true;
      }
    }
    return false;
  };
  const getUniqueColors = () => {
    let newArray = [];
    for (let i = 0; i < product.variants.length; i++) {
      console.log(
        "product.variants[i]",
        subVarientsChecking(product.variants[i].subVariants)
      );
      if (
        newArray.indexOf(product.variants[i].color) === -1 &&
        subVarientsChecking(product.variants[i].subVariants)
      )
        newArray.push(product.variants[i].color);
    }
    setColors(newArray);
  };
  useEffect(() => {
    getUniqueColors();
  }, []);
  const handleCanvasBgColorsChange = (r, g, b) => {
    _home.currentCanvas?.setBackgroundColor(
      `rgb(${r},${g},${b})`,
      _home.currentCanvas.renderAll.bind(_home.currentCanvas)
    );
    _home.frontCanvas?.setBackgroundColor(
      `rgb(${r},${g},${b})`,
      _home.frontCanvas.renderAll.bind(_home.frontCanvas)
    );
    _home.backCanvas?.setBackgroundColor(
      `rgb(${r},${g},${b})`,
      _home.backCanvas.renderAll.bind(_home.backCanvas)
    );
    dispatch({
      type: R,
      payload: r,
    });
    dispatch({
      type: G,
      payload: g,
    });
    dispatch({
      type: B,
      payload: b,
    });
  };
  const getRfromHex = (color) => {
    var bigint = parseInt(color.replace("#", ""), 16);
    var r = (bigint >> 16) & 255;
    return r;
  };
  const getGfromHex = (color) => {
    var bigint = parseInt(color.replace("#", ""), 16);
    var g = (bigint >> 8) & 255;
    return g;
  };
  const getBfromHex = (color) => {
    var bigint = parseInt(color.replace("#", ""), 16);
    var b = bigint & 255;
    return b;
  };
  const handleColorSelect = (c) => {
    handleProductSelect1();
    handleCanvasBgColorsChange(getRfromHex(c), getGfromHex(c), getBfromHex(c));
    dispatch({
      type: SELECT_COLOR,
      payload: c,
    });
    // canvas width change
    _home.frontCanvas.setDimensions({
      width: _cart.selectedProduct.canvases[0]?.width / _home.maxZoom,
      height: _cart.selectedProduct.canvases[0]?.height / _home.maxZoom,
    });
    _home.frontCanvas.requestRenderAll();
    _home.backCanvas.setDimensions({
      width: _cart.selectedProduct.canvases[1]?.width / _home.maxZoom,
      height: _cart.selectedProduct.canvases[1]?.height / _home.maxZoom,
    });
    _home.backCanvas.requestRenderAll();
    setProductsPopupOpen(false);
  };

  return (
    <div className="prodDetailColors">
      <ul className="prodDetail_colors">
        {colors.map((c, i) => (
          <div
            key={i}
            className="prodDetail_color"
            style={{ background: c }}
            onClick={() => handleColorSelect(c)}
          ></div>
        ))}
      </ul>
    </div>
  );
};

export default ProductHoverColors;
