/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
// import { httpsCallable } from "firebase/functions";
// import { functions } from "../../../../firebase";
import { useStripe } from "@stripe/react-stripe-js";
import "./paymentStatus.css";

const PaymentStatus = ({ clientSecret, createOrder }) => {
  const [message, setMessage] = useState(null);
  const stripe = useStripe();

  // const createNewOrder = () => {
  //   try {
  //     const createPayedOrder = httpsCallable(functions, "dbOrdersOnCall-createPayedOrder");
  //     createPayedOrder().then((result) => {
  //       // code here
  //     });
  //   } catch (e) {
  //     console.error("Error removing Image: ", e);
  //   }
  // };

  useEffect(() => {
    if (!stripe) {
      return;
    }

    // Retrieve the "payment_intent_client_secret" query parameter appended to
    // your return_url by Stripe.js
    // const clientSecret = new URLSearchParams(window.location.search).get(
    //   "payment_intent_client_secret"
    // );

    // Retrieve the PaymentIntent
    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      // Inspect the PaymentIntent `status` to indicate the status of the payment
      // to your customer.
      //
      // Some payment methods will [immediately succeed or fail][0] upon
      // confirmation, while others will first enter a `processing` state.
      //
      // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage(paymentIntent.status);
          createOrder();
          break;

        case "processing":
          setMessage(
            "Payment processing. We'll update you when payment is received."
          );
          break;

        case "requires_payment_method":
          // Redirect your user back to your payment page to attempt collecting
          // payment again
          setMessage("Payment failed. Please try another payment method.");
          break;

        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);
  return (
    <div className="paymentStatus">
      <h1>{message}</h1>
      <h1>{clientSecret}</h1>
    </div>
  );
};

export default PaymentStatus;

// http://localhost:3000/checkout?payment_intent=pi_3KPY96LDQ1zZD1vm1S1AjYQt&payment_intent_client_secret=pi_3KPY96LDQ1zZD1vm1S1AjYQt_secret_9tScOwevJOcoVBvjWPRCGXFL4&redirect_status=succeeded
