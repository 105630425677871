/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
import { useAuth } from "../../contexts/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(18),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  notchedOutline: {
    borderWidth: "1px",
    borderColor: "#1CCAFF !important",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  floatingLabelFocusStyle: {
    color: "#1CCAFF !important",
  },
  multilineColor: {
    color: "#F9FAFB",
  },
}));

const Signin = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login, authError } = useAuth();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (currentUser) {
      navigate("/");
    }
  }, [currentUser]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      await login(email, password);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const backToHome = () => {
    navigate("/");
  };

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Typography
          style={{ color: "#F9FAFB", display: "flex", alignItems: "center" }}
          variant="h4"
          gutterBottom
        >
          <i
            className="backarrow fal fa-long-arrow-left"
            onClick={() => backToHome()}
          ></i>
          &nbsp;{t("Signin.SignintoJandiprint")}
        </Typography>
        {authError ? (
          <>
            <Alert
              style={{ width: "100%", marginTop: "10px" }}
              severity="error"
            >
              {authError}
            </Alert>
          </>
        ) : null}
        <form className={classes.form} onSubmit={handleSubmit} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                  },
                  className: classes.multilineColor,
                }}
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                label={t("Signin.EmailAddress")}
                name="email"
                onChange={(e) => setEmail(e.target.value.replace(/ /g, ""))}
                autoComplete="email"
                autoFocus
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                onChange={(e) => setPassword(e.target.value)}
                required
                fullWidth
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                  },
                  className: classes.multilineColor,
                }}
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                name="password"
                label={t("Signin.Password")}
                type="password"
                id="password"
                autoComplete="current-password"
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            style={{ background: "#1890FF", color: "#fff" }}
            className={classes.submit}
            disabled={loading}
          >
            {t("Signin.LogIn")}
          </Button>
          <Grid container justifyContent="space-between">
            <Grid item>
              <p className="color-white" style={{ color: "#F9FAFB" }}>
                <Link to="/forget-password" style={{ color: "#1CCAFF" }}>
                  {t("Signin.ForgetPassword")}
                </Link>
              </p>
            </Grid>
            <Grid item>
              <p className="color-white" style={{ color: "#F9FAFB" }}>
                {t("Signin.Needanaccount")}&nbsp;&nbsp;
                <Link to="/signup" style={{ color: "#1CCAFF" }}>
                  {t("Signin.SignUp")}
                </Link>
              </p>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
};

export default Signin;
