/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-sequences */
import React, { useEffect } from "react";
import { fabric } from "fabric";
import { Checkbox, makeStyles, Paper } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { homeConstants } from "../../../../redux/constants";
import IconButton from "../../../iconButton";
import { useTranslation } from "react-i18next";
const { DRAWING } = homeConstants;

const useStyles = makeStyles({
  paper: {
    backgroundColor: "#212B36",
    padding: "10px",
    marginBottom: "10px",
    color: "white",
    textAlign: "left",
  },
});

const EraserOptions = ({ mousecursor }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const _home = useSelector((state) => state.home);
  const handleErase = (checked) => {
    if (checked) {
      _home.currentCanvas.freeDrawingBrush = new fabric.EraserBrush(
        _home.currentCanvas
      );
      _home.currentCanvas.freeDrawingCursor = "pointer";
      _home.currentCanvas.freeDrawingBrush.width = 10;
      _home.currentCanvas.isDrawingMode = true;
      _home.currentCanvas.requestRenderAll();
      _home.currentCanvas.remove(mousecursor);
      dispatch({
        type: DRAWING,
        payload: true,
      });
    } else {
      dispatch({
        type: DRAWING,
        payload: false,
      });
      _home.currentCanvas.isDrawingMode = false;
      _home.currentCanvas.requestRenderAll();
    }
  };

  let h;
  const handleCropImage = () => {
    var e = _home.currentCanvas.getActiveObject();
    e.angle = 0;
    if (e && e.get("type") === "image") {
      var i = new fabric.Rect({
          id: "crop-rect",
          top: e.top,
          left: e.left,
          width: e.getScaledWidth(),
          height: e.getScaledHeight(),
          stroke: "rgb(42, 67, 101)",
          strokeWidth: 2,
          strokeDashArray: [5, 5],
          fill: "rgba(255, 255, 255, 1)",
          globalCompositeOperation: "overlay",
        }),
        a = new fabric.Rect({
          id: "overlay-rect",
          top: e.top,
          left: e.left,
          width: e.getScaledWidth(),
          height: e.getScaledHeight(),
          selectable: !1,
          selection: !1,
          fill: "rgba(0, 0, 0, 0.5)",
        });
      if ((i.setControlVisible("mtr", !1), e.cropX)) {
        var s = e.cropX,
          o = e.cropY,
          c = e.width,
          l = e.height;
        e.set({
          cropX: null,
          cropY: null,
          left: e.left - s * e.scaleX,
          top: e.top - o * e.scaleY,
          width: e._originalElement.naturalWidth,
          height: e._originalElement.naturalHeight,
        });
        e.dirty = !0;
        i.set({
          left: e.left + s * e.scaleX,
          top: e.top + o * e.scaleY,
          width: c * e.scaleX,
          height: l * e.scaleY,
        });
        i.dirty = !0;
        a.set({
          left: e.left,
          top: e.top,
          width: e.width * e.scaleX,
          height: e.height * e.scaleY,
        });
        a.dirty = !0;
      }
      _home.currentCanvas.add(a);
      _home.currentCanvas.add(i);
      _home.currentCanvas.discardActiveObject();
      _home.currentCanvas.setActiveObject(i);
      _home.currentCanvas.renderAll();
      i.on("moving", function () {
        // eslint-disable-next-line no-unused-expressions
        (i.top < e.top || i.left < e.left) &&
          ((i.left = i.left < e.left ? e.left : i.left),
          (i.top = i.top < e.top ? e.top : i.top)),
          (i.top + i.getScaledHeight() > e.top + e.getScaledHeight() ||
            i.left + i.getScaledWidth() > e.left + e.getScaledWidth()) &&
            ((i.top =
              i.top + i.getScaledHeight() > e.top + e.getScaledHeight()
                ? e.top + e.getScaledHeight() - i.getScaledHeight()
                : i.top),
            (i.left =
              i.left + i.getScaledWidth() > e.left + e.getScaledWidth()
                ? e.left + e.getScaledWidth() - i.getScaledWidth()
                : i.left));
      });
      i.on("deselected", function () {
        h();
      });
      // if (currentUser) {
      //   if (_home.face === "front") {
      //     save();
      //   } else {
      //     saveBack();
      //   }
      // }
    }

    h = (r, n) => {
      var s = (i.left - e.left) / e.scaleX,
        o = (i.top - e.top) / e.scaleY,
        c = (i.width * i.scaleX) / e.scaleX,
        l = (i.height * i.scaleY) / e.scaleY;
      e.set("cropX", s);
      e.set("cropY", o);
      e.set("width", c);
      e.set("height", l);
      _home.currentCanvas.remove(i);
      _home.currentCanvas.remove(a);
      e.set({
        top: e.top + o * e.scaleY,
        left: e.left + s * e.scaleX,
      });
    };
  };

  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        h();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);
  return (
    <div className="p-10">
      <Paper className={classes.paper}>
        {t("eraserOptions.Eraser")}:
        <Checkbox
          style={{ color: "#1CCAFF" }}
          checked={_home.drawing}
          color="primary"
          onClick={(e) => handleErase(e.target.checked)}
          inputProps={{ "aria-label": "secondary checkbox" }}
        />
      </Paper>
      <IconButton
        text={t("eraserOptions.CropImage")}
        onClick={() => handleCropImage()}
      >
        <i className="ri-crop-line"></i>
      </IconButton>
    </div>
  );
};

export default EraserOptions;
