/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./prodDetailColors.css";
import {
  cartConstants,
  homeConstants,
} from "../../../../../../../../redux/constants";
import { useDispatch, useSelector } from "react-redux";
const { SELECT_COLOR } = cartConstants;
const { R, G, B } = homeConstants;

const ProdDetailColors = () => {
  const dispatch = useDispatch();
  const _home = useSelector((state) => state.home);
  const _cart = useSelector((state) => state.cart);
  const [colors, setColors] = useState([]);
  const getUniqueColors = () => {
    let newArray = [];
    for (let i = 0; i < _cart.selectedProduct.variants.length; i++) {
      if (newArray.indexOf(_cart.selectedProduct.variants[i].color) === -1)
        newArray.push(_cart.selectedProduct.variants[i].color);
    }
    setColors(newArray);
  };
  useEffect(() => {
    getUniqueColors();
    if (_home.frontCanvas && _home.backCanvas) {
      _home.frontCanvas.setDimensions({
        width: _cart.selectedProduct.canvases[0]?.width / _home.maxZoom,
        height: _cart.selectedProduct.canvases[0]?.height / _home.maxZoom,
      });
      _home.frontCanvas.requestRenderAll();
      _home.backCanvas.setDimensions({
        width: _cart.selectedProduct.canvases[1]?.width / _home.maxZoom,
        height: _cart.selectedProduct.canvases[1]?.height / _home.maxZoom,
      });
      _home.backCanvas.requestRenderAll();
    }
  }, [_cart.selectedProduct]);
  const handleCanvasBgColorsChange = (r, g, b) => {
    _home.currentCanvas.setBackgroundColor(
      `rgb(${r},${g},${b})`,
      _home.currentCanvas.renderAll.bind(_home.currentCanvas)
    );
    _home.frontCanvas.setBackgroundColor(
      `rgb(${r},${g},${b})`,
      _home.frontCanvas.renderAll.bind(_home.frontCanvas)
    );
    _home.backCanvas.setBackgroundColor(
      `rgb(${r},${g},${b})`,
      _home.backCanvas.renderAll.bind(_home.backCanvas)
    );
    dispatch({
      type: R,
      payload: r,
    });
    dispatch({
      type: G,
      payload: g,
    });
    dispatch({
      type: B,
      payload: b,
    });
  };
  const getRfromHex = (color) => {
    var bigint = parseInt(color.replace("#", ""), 16);
    var r = (bigint >> 16) & 255;
    return r;
  };

  const getGfromHex = (color) => {
    var bigint = parseInt(color.replace("#", ""), 16);
    var g = (bigint >> 8) & 255;
    return g;
  };

  const getBfromHex = (color) => {
    var bigint = parseInt(color.replace("#", ""), 16);
    var b = bigint & 255;
    return b;
  };
  const handleColorSelect = (c) => {
    handleCanvasBgColorsChange(getRfromHex(c), getGfromHex(c), getBfromHex(c));
    dispatch({
      type: SELECT_COLOR,
      payload: c,
    });
  };
  return (
    <div className="prodDetailColors">
      <div className="colorTitle">Colours</div>
      <ul className="colors">
        {colors.map((c, i) => (
          <div
            key={i}
            className="color"
            style={{ background: c }}
            onClick={() => handleColorSelect(c)}
          ></div>
        ))}
        {_cart.selectedProduct.name === "Dummy Product"
          ? _cart.dumyColors.map((c, i) => (
              <div
                key={i}
                className="color"
                style={{ background: c }}
                onClick={() => handleColorSelect(c)}
              ></div>
            ))
          : null}
      </ul>
    </div>
  );
};

export default ProdDetailColors;
