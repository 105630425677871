import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./studioDropdown.css";
import { productOptionsConstants } from "../../../../../../redux/constants";
import { colorConstants } from "../../../../../../colors";
import { useTranslation } from "react-i18next";
const { PRODUCT_POPUP_Items_BG } = colorConstants;
const { SELECTED_STUDIO } = productOptionsConstants;

const StudioDropdown = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const _productOptions = useSelector((state) => state.productOptions);
  const [openDropdown, setOpenDropdown] = useState(false);
  const handleDropdownOpening = () => {
    setOpenDropdown((prev) => !prev);
  };

  const handleStudioSelect = (s) => {
    dispatch({
      type: SELECTED_STUDIO,
      payload: s,
    });
    setOpenDropdown(false);
  };

  return (
    <div
      className="studio-dropdown"
      style={{ background: PRODUCT_POPUP_Items_BG, display: "inline-block" }}
    >
      <h4 onClick={() => handleDropdownOpening()}>
        {_productOptions.selectedStudio?.companyName
          ? _productOptions.selectedStudio?.companyName
          : "Select Studio"}
      </h4>
      <i className="ri-arrow-drop-down-fill"></i>
      {openDropdown ? (
        <ul style={{ background: PRODUCT_POPUP_Items_BG }}>
          <li
            style={{
              fontSize: "18px",
              color: "#ccc",
              background: PRODUCT_POPUP_Items_BG,
            }}
            onClick={() => setOpenDropdown(false)}
          >
            {" "}
            {t("StudioDropdown.SelectStudio")}{" "}
          </li>
          {_productOptions.studios.map((item, i) =>
            item.inSale ? (
              <li
                style={{ background: PRODUCT_POPUP_Items_BG }}
                onClick={() => handleStudioSelect(item)}
              >
                {item.companyName}
              </li>
            ) : null
          )}
        </ul>
      ) : null}
    </div>
  );
};

export default StudioDropdown;
