import { Checkbox } from "@material-ui/core";
import React from "react";

const CustomCheckBox = ({ lable, checked, onClick }) => {
  return (
    <div className="mt-10">
      <Checkbox
        checked={checked}
        style={{
          color: "#1CCAFF",
          padding: "0px",
          marginRight: "10px",
        }}
        color="primary"
        onClick={onClick}
        inputProps={{ "aria-label": "secondary checkbox" }}
      />
      {lable}
    </div>
  );
};

export default CustomCheckBox;
