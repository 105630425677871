/* eslint-disable no-unused-vars */
import { makeStyles, Paper } from "@material-ui/core";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../../../firebase";
import { useAuth } from "../../../../contexts/AuthContext";
import React, { useState } from "react";
import UploadImage from "../uploadImage";
import { getInfo, calcCf } from "../../../../util";

const useStyles = makeStyles((theme) => ({
  paper: {
    height: 200,
    width: 200,
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#212B36",
    alignItems: "center",
  },
  control: {
    padding: theme.spacing(2),
  },
}));

const ProductImage = ({ product, setProduct }) => {
  const classes = useStyles();
  const { currentUser } = useAuth();
  const imageInputRef = React.useRef();
  const [progress, setProgress] = useState(0);

  const handleProductImage = (productImage) => {
    setProduct({ ...product, productImage: productImage });
  };

  //handle the image uploading process
  const handleUploadClick = async (e) => {
    if (e.target.files.length < 1) return;
    const file = e.target.files[0];
    let dimensions = await getInfo(file);
    // change file name
    const myNewFile = new File([file], new Date().getTime() + file.name, {
      type: file.type,
    });
    const sotrageRef = ref(
      storage,
      `images/${currentUser.uid}/products/${myNewFile.name}`
    );
    const uploadTask = uploadBytesResumable(sotrageRef, myNewFile);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(prog);
      },
      (error) => console.log(error),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
          const nImg = {
            width: dimensions.width,
            height: dimensions.height,
            url: downloadURL,
          };
          handleProductImage(nImg);
        });
      }
    );
  };
  //handle the image uploading process
  return (
    <>
      <h3 className="fw-300 mt-10">Image:</h3>
      <UploadImage
        handleUploadClick={handleUploadClick}
        imageInputRef={imageInputRef}
      />
      {product.productImage ? (
        <div className="mt-10">
          <Paper className={classes.paper}>
            <img
              width={
                product.productImage.width /
                calcCf(
                  product.productImage.width,
                  product.productImage.height,
                  200
                )
              }
              height={
                product.productImage.height /
                calcCf(
                  product.productImage.width,
                  product.productImage.height,
                  200
                )
              }
              src={product.productImage.url}
              alt="productImage"
            />
          </Paper>
        </div>
      ) : null}
    </>
  );
};

export default ProductImage;
