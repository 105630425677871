import { Button, makeStyles, Modal } from "@material-ui/core";
import React, { useState } from "react";
import { useAuth } from "../../../../../../contexts/AuthContext";
import "./statusInOrderDetail.css";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    background: "#637381",
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const StatusInOrderDetail = ({ status, handleUpdateStatus }) => {
  const { userClaims } = useAuth();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [modalStyle] = useState(getModalStyle);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleUpdateOrderStatus = (status) => {
    setOpen(false);
    handleUpdateStatus(status);
  };
  return (
    <div className="statusInOrderDetail">
      <h2 className="fw-300">UPDATE STATUS:</h2>
      <div className="statusBtns">
        {userClaims?.studio ? (
          <>
            <Button
              variant="contained"
              component="label"
              style={{
                borderColor: "#2e313f",
                color: "#fff",
                backgroundColor: "#1890FF",
                width: "100px",
              }}
              onClick={() => handleUpdateOrderStatus("Initial")}
            >
              Initial
            </Button>
            &nbsp;&nbsp;
            <Button
              variant="contained"
              component="label"
              style={{
                borderColor: "#2e313f",
                color: "#fff",
                backgroundColor: "#FFC107",
                width: "100px",
              }}
              onClick={() => handleUpdateOrderStatus("Printing")}
            >
              Printing
            </Button>
            &nbsp;&nbsp;
            <Button
              variant="contained"
              component="label"
              style={{
                borderColor: "#2e313f",
                color: "#fff",
                backgroundColor: "#54D62C",
                width: "100px",
              }}
              onClick={() => handleUpdateOrderStatus("Delivery")}
            >
              Delivery
            </Button>
            &nbsp;&nbsp;
            <Button
              variant="contained"
              component="label"
              style={{
                borderColor: "#2e313f",
                color: "#fff",
                backgroundColor: "#FF4842",
                width: "100px",
              }}
              onClick={() => handleOpen()}
            >
              cancel
            </Button>
          </>
        ) : null}
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <h3 className="fw-300 mt-10 mb-10 canv-lable">
            Are you Sure you want to cancel this order?
          </h3>
          <div className="mt-20">
            <Button
              variant="contained"
              component="label"
              style={{
                marginBottom: "10px",
                borderColor: "#2e313f",
                color: "#fff",
                backgroundColor: "#1890FF",
                width: "100px",
              }}
              onClick={() => handleUpdateOrderStatus("Cancel")}
            >
              Yes
            </Button>
            &nbsp; &nbsp; &nbsp;
            <Button
              variant="contained"
              component="label"
              style={{
                marginBottom: "10px",
                borderColor: "#2e313f",
                color: "#161C24",
                backgroundColor: "#FFC107",
                width: "100px",
              }}
              onClick={() => handleClose()}
            >
              No
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default StatusInOrderDetail;
