import React from "react";
import IconBox from "./components/iconBox";
import moment from "moment";
import momentTZ from "moment-timezone";
import "./orderDetailTopBoxes.css";

const OrderDetailTopBoxes = ({ selectedOrder }) => {
  const totalPrice = () => {
    let total = 0;
    for (let i = 0; i < selectedOrder.products.length; i++) {
      total = total + selectedOrder.products[i].price;
    }
    return Math.round((total + selectedOrder.shipment.price) * 100) / 100;
  };
  const orderDate = () => {
    const d = new Date(selectedOrder.createdAt);
    const year = moment(momentTZ(d).tz("Europe/Berlin")).format("YYYY");
    const month = moment(momentTZ(d).tz("Europe/Berlin")).format("MM");
    const day = moment(momentTZ(d).tz("Europe/Berlin")).format("DD");
    return year + "-" + month + "-" + day;
  };

  const calculateQuantityOfProducts = () => {
    let total = 0;
    for (let i = 0; i < selectedOrder.products.length; i++) {
      total = total + parseInt(selectedOrder.products[i].quantity);
    }
    return total;
  };

  return (
    <div className="orderDetailTopBoxes">
      <div className="dateBox">
        <IconBox bgColor="#1939B7" icon="ri-calendar-2-line" />
        <div className="textbox">
          <h3>DATE</h3>
          <p>{orderDate()}</p>
        </div>
      </div>
      <div className="dateBox">
        <IconBox bgColor="#54D62C" icon="ri-money-euro-box-line" />
        <div className="textbox">
          <h3>TOTAL</h3>
          <p>{totalPrice()}</p>
        </div>
      </div>
      <div className="dateBox">
        <IconBox bgColor="#5BE584" icon="ri-shopping-cart-2-line" />
        <div className="textbox">
          <h3>QUANTITY</h3>
          <p>{calculateQuantityOfProducts()}</p>
        </div>
      </div>
      <div className="dateBox">
        <IconBox bgColor="#FFC107" icon="ri-line-chart-line" />
        <div className="textbox">
          <h3>STATUS</h3>
          <p>{selectedOrder.status}</p>
        </div>
      </div>
    </div>
  );
};

export default OrderDetailTopBoxes;
