import React from "react";
// import SizeTable from "./components/sizeTable";
// import sizeimg1 from "../../assets/images/sizeimg1.jpg";
// import sizeimg2 from "../../assets/images/sizeimg2.jpg";
// import sizeimg3 from "../../assets/images/sizeimg3.jpg";
import { Markup } from "interweave";
import "./sizeClarificationPopup.css";

const SizeClarificationPopup = ({ open, selectedProduct }) => {
  return (
    <ul
      style={!open ? { display: "none" } : { color: "#000" }}
      className="clarification-popup"
    >
      {selectedProduct.description2 === "" ? (
        <Markup content={selectedProduct.description1} />
      ) : (
        <>
          <div className="desc-left">
            <Markup content={selectedProduct.description1} />
          </div>
          <div className="desc-right">
            <Markup content={selectedProduct.description2} />
          </div>
        </>
      )}
    </ul>
  );
};

export default SizeClarificationPopup;
