import React, { useEffect, useState } from "react";
import { fabric } from "fabric";
import { Checkbox, makeStyles, Paper } from "@material-ui/core";
import { useTranslation } from "react-i18next";
// import CustomCheckBox from "../../../customCheckBox";
import CustomSlider from "../../../customSlider";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
  paper: {
    backgroundColor: "#212B36",
    padding: "10px",
    marginBottom: "10px",
    color: "white",
    textAlign: "left",
  },
});

const BrightnessOptions = ({ save, saveBack }) => {
  const { t } = useTranslation();
  const _home = useSelector((state) => state.home);
  const classes = useStyles();
  // filters start
  const [brightnessApl, setBrightnessApl] = useState(false);
  // filters end
  const [brightness, setBrightness] = useState(0.1);

  const applyFilter = (index, filter) => {
    var obj = _home.currentCanvas.getActiveObject();
    if (obj) {
      obj.filters[index] = filter;
      obj.applyFilters();
      _home.currentCanvas.requestRenderAll();
    }
    if (_home.face === "front") {
      save();
    } else {
      saveBack();
    }
  };

  const applyFilterValue = (index, prop, value) => {
    var obj = _home.currentCanvas.getActiveObject();
    if (obj.filters[index]) {
      obj.filters[index][prop] = value;
      obj.applyFilters();
      _home.currentCanvas.requestRenderAll();
    }
    if (_home.face === "front") {
      save();
    } else {
      saveBack();
    }
  };

  const handleBrightness = (event, value) => {
    setBrightness(value);
    var obj = _home.currentCanvas.getActiveObject();
    if (obj) {
      applyFilterValue(5, "brightness", parseFloat(value));
    }
  };

  const applyBrightness = (check) => {
    setBrightnessApl(check);
    applyFilter(
      5,
      check &&
        new fabric.Image.filters.Brightness({
          brightness: parseFloat(brightness),
        })
    );
  };

  useEffect(() => {
    if (_home.currentCanvas) {
      _home.currentCanvas.on({
        "selection:updated": HandleElement,
        "selection:created": HandleElement,
      });
    }
  }, [_home.currentCanvas]);
  function HandleElement(obj) {
    if (obj.target.filters) {
      // BrightnessApl
      if (obj.target.filters[5]) {
        setBrightnessApl(true);
      } else {
        setBrightnessApl(false);
      }
    } else {
      setBrightnessApl(false);
    }
  }

  return (
    <div className="edit-options">
      <Paper className={classes.paper}>
        {t("BrightnessOptions.Brightness")}
        <Checkbox
          style={{ color: "#1CCAFF" }}
          color="primary"
          checked={brightnessApl}
          onClick={(e) => applyBrightness(e.target.checked)}
          inputProps={{ "aria-label": "secondary checkbox" }}
        />
        {/* <CustomCheckBox onClick={applyBrightness} /> */}
        <CustomSlider
          title={t("BrightnessOptions.Value")}
          value={brightness}
          min={-1}
          max={1}
          step={0.003921}
          handleChange={handleBrightness}
        />
      </Paper>
    </div>
  );
};

export default BrightnessOptions;
