import React, { useState } from "react";
import { Checkbox, Grid, Slider } from "@material-ui/core";
import { fabric } from "fabric";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const PixelateOptions = ({ save, saveBack }) => {
  const { t } = useTranslation();
  const _home = useSelector((state) => state.home);
  const [pixelate, setPixelate] = useState(1);
  const applyFilter = (index, filter) => {
    var obj = _home.currentCanvas.getActiveObject();
    if (obj) {
      obj.filters[index] = filter;
      obj.applyFilters();
      _home.currentCanvas.requestRenderAll();
    }
    if (_home.face === "front") {
      save();
    } else {
      saveBack();
    }
  };

  const applyFilterValue = (index, prop, value) => {
    var obj = _home.currentCanvas.getActiveObject();
    if (obj.filters[index]) {
      obj.filters[index][prop] = value;
      obj.applyFilters();
      _home.currentCanvas.requestRenderAll();
    }
    if (_home.face === "front") {
      save();
    } else {
      saveBack();
    }
  };

  const applyPixelate = (check) => {
    applyFilter(
      10,
      check &&
        new fabric.Image.filters.Pixelate({
          blocksize: parseInt(pixelate, 10),
        })
    );
  };

  const handleValueChange = (event, newValue) => {
    setPixelate(newValue);
    applyFilterValue(10, "blocksize", parseInt(newValue, 10));
  };
  return (
    <div>
      {t("AlternateOptions.Pixelate")}
      <Checkbox
        color="primary"
        onClick={(e) => applyPixelate(e.target.checked)}
        inputProps={{ "aria-label": "secondary checkbox" }}
      />
      <Grid container spacing={2} alignItems="center">
        <Grid item xs>
          <Slider
            step={1}
            min={1}
            max={10}
            value={typeof pixelate === "number" ? pixelate : 0}
            onChange={handleValueChange}
            aria-labelledby="input-slider"
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default PixelateOptions;
