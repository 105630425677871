import { combineReducers } from "redux";
import { cartReducer } from "./cartReducer";
import { orderReducer } from "./orderReducer";
import { homeReducer } from "./homeReducer";
import { filterReducer } from "./filterReducer";
import { userCanvasesReducer } from "./userCanvasesReducer";
import { productOptionsReducer } from "./productOptionsReducer";

const CombinedReducers = combineReducers({
  cart: cartReducer,
  order: orderReducer,
  home: homeReducer,
  filter: filterReducer,
  userCanvases: userCanvasesReducer,
  productOptions: productOptionsReducer,
});

export default CombinedReducers;
