import React, { useEffect } from "react";
import { fabric } from "fabric";
import Option from "./components/option";
import OptionsList from "./components/optionsList";
import { useAuth } from "../../contexts/AuthContext";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../firebase";
import "./leftPanel1.css";
import { useDispatch, useSelector } from "react-redux";
import { homeConstants, userCanvasesConstants } from "../../redux/constants";
import { Tooltip, withStyles } from "@material-ui/core";
import { colorConstants } from "../../colors";
import { useTranslation } from "react-i18next";
const { RP_OPEN, DRAWING, LP1_SELECTED_OPTION, LP2, LP3 } = homeConstants;
const { USER_CANVASES } = userCanvasesConstants;
const {
  LEFT_PANEL1_BG_COLOR,
  LEFT_PANEL1_ICON_HOVER,
  LEFT_PANEL1_ICON_NOT_HOVER,
} = colorConstants;

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const LeftPanel1 = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { currentUser } = useAuth();
  const _home = useSelector((state) => state.home);
  const handlePanel2OptionSelect = (option) => {
    // if clicked the same option
    if (option === _home.lP1SelectedOption) {
      dispatch({
        type: LP2,
        payload: !_home.lP2,
      });
      dispatch({
        type: LP3,
        payload: false,
      });
      dispatch({
        type: LP1_SELECTED_OPTION,
        payload: "",
      });
      // setLP1SelectedOption("");
    }
    // if clicked the not same option (another button in P2)
    else {
      dispatch({
        type: LP2,
        payload: true,
      });
      dispatch({
        type: LP3,
        payload: false,
      });
      dispatch({
        type: LP1_SELECTED_OPTION,
        payload: option,
      });
      // setHoverOnUL(false);
    }
    dispatch({
      type: RP_OPEN,
      payload: false,
    });
    _home.currentCanvas.isDrawingMode = false;
    dispatch({
      type: DRAWING,
      payload: false,
    });
    _home.currentCanvas.remove(_home.mousecursor);
    _home.currentCanvas.requestRenderAll();
    if (fabric.isWebglSupported()) {
      fabric.textureSize = 2048;
    }
  };
  const getAllUserCanvases = () => {
    console.log('getAllUserCanvases')
    if (currentUser) {
      try {
        const getCanvases = httpsCallable(
          functions,
          "dbCanvasesOnCall-getCanvases"
        );
        getCanvases().then((result) => {
          console.log("Canvases", result);
          dispatch({
            type: USER_CANVASES,
            payload: result.data?.canvases,
          });
        });
      } catch (e) {
        console.error("Error adding document: ", e);
      }
    }
  };
  useEffect(() => {
    getAllUserCanvases();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div
      style={{ background: LEFT_PANEL1_BG_COLOR }}
      className="leftPanel1-main-wrapper"
    >
      {/* Option List is holding the all the panel2 options */}
      <OptionsList
      // setHoverOnUL={setHoverOnUL}
      >
        <Option
          // hoverOnUL={hoverOnUL}
          // setHoverOnUL={setHoverOnUL}
          handlePanel2OptionSelect={handlePanel2OptionSelect}
          optionText="leftPanel1.Colors"
          selected={_home.lP1SelectedOption === "leftPanel1.Colors"}
        >
          <i
            className="ri-palette-line"
            style={
              _home.lP1SelectedOption === "leftPanel1.Colors"
                ? { color: LEFT_PANEL1_ICON_HOVER }
                : { color: LEFT_PANEL1_ICON_NOT_HOVER }
            }
          ></i>
        </Option>
        <Option
          // hoverOnUL={hoverOnUL}
          // setHoverOnUL={setHoverOnUL}
          handlePanel2OptionSelect={handlePanel2OptionSelect}
          optionText="leftPanel1.ImageManager"
          selected={_home.lP1SelectedOption === "leftPanel1.ImageManager"}
        >
          <i
            className="ri-image-line"
            style={
              _home.lP1SelectedOption === "leftPanel1.ImageManager"
                ? { color: LEFT_PANEL1_ICON_HOVER }
                : { color: LEFT_PANEL1_ICON_NOT_HOVER }
            }
          ></i>
        </Option>
        <Option
          // hoverOnUL={hoverOnUL}
          // setHoverOnUL={setHoverOnUL}
          handlePanel2OptionSelect={handlePanel2OptionSelect}
          optionText="leftPanel1.Edit"
          selected={_home.lP1SelectedOption === "leftPanel1.Edit"}
        >
          <i
            style={
              _home.lP1SelectedOption === "leftPanel1.Edit"
                ? { color: LEFT_PANEL1_ICON_HOVER }
                : { color: LEFT_PANEL1_ICON_NOT_HOVER }
            }
            className="fal fa-sliders-v"
          ></i>
        </Option>
        <Option
          // hoverOnUL={hoverOnUL}
          // setHoverOnUL={setHoverOnUL}
          handlePanel2OptionSelect={handlePanel2OptionSelect}
          optionText="leftPanel1.Draw"
          selected={_home.lP1SelectedOption === "leftPanel1.Draw"}
        >
          <i
            className="ri-mark-pen-fill"
            style={
              _home.lP1SelectedOption === "leftPanel1.Draw"
                ? { color: LEFT_PANEL1_ICON_HOVER }
                : { color: LEFT_PANEL1_ICON_NOT_HOVER }
            }
          ></i>
        </Option>
        <Option
          handlePanel2OptionSelect={handlePanel2OptionSelect}
          optionText="leftPanel1.Text"
          selected={_home.lP1SelectedOption === "leftPanel1.Text"}
        >
          <i
            className="ri-text"
            style={
              _home.lP1SelectedOption === "leftPanel1.Text"
                ? { color: LEFT_PANEL1_ICON_HOVER }
                : { color: LEFT_PANEL1_ICON_NOT_HOVER }
            }
          ></i>
        </Option>
        <Option
          handlePanel2OptionSelect={handlePanel2OptionSelect}
          optionText="leftPanel1.YourCanvases"
          selected={_home.lP1SelectedOption === "leftPanel1.YourCanvases"}
        >
          <LightTooltip title={t("leftPanel1.saveCurrentCanvas")}>
            <i
              className="ri-tv-2-line"
              style={
                _home.lP1SelectedOption === "leftPanel1.YourCanvases"
                  ? { color: LEFT_PANEL1_ICON_HOVER }
                  : { color: LEFT_PANEL1_ICON_NOT_HOVER }
              }
            ></i>
          </LightTooltip>
        </Option>
      </OptionsList>
    </div>
  );
};

export default LeftPanel1;
