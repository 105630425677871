import React from "react";
import { useAuth } from "../../../../contexts/AuthContext";
import { fabric } from "fabric";
import { useSelector } from "react-redux";
import GreyScaleFilter from "./components/greyScaleFilter";
import InvertFilter from "./components/invertFilter";
import SepiaFilter from "./components/sepiaFilter";
import SharpenFilter from "./components/sharpenFilter";
import EmbossFilter from "./components/embossFilter";
import BlackWhiteFilter from "./components/blackWhiteFilter";
import BrownieFilter from "./components/brownieFilter";
import VintageFilter from "./components/vintageFilter";
import KodachromeFilter from "./components/kodachromeFilter";
import TechnicolorFilter from "./components/technicolorFilter";
import PolaroidFilter from "./components/polaroidFilter";
const FilterOptions = ({ save, saveBack }) => {
  const { currentUser } = useAuth();
  const _home = useSelector((state) => state.home);
  const applyFilter = (index, filter) => {
    var objs = _home.currentCanvas.getActiveObjects();
    if (objs.length > 0) {
      for (let i = 0; i < objs.length; i++) {
        if (objs[i] && objs[i].type === "image") {
          objs[i].filters[index] = filter;
          objs[i].applyFilters();
          _home.currentCanvas.requestRenderAll();
        }
      }
    }
    if (currentUser) {
      if (_home.face === "front") {
        save();
      } else {
        saveBack();
      }
    }
  };
  return (
    <div className="pl-10">
      <ul className="lph ofys">
        <GreyScaleFilter
          applyFilter={applyFilter}
          fabric={fabric}
          save={save}
          saveBack={saveBack}
        />
        <InvertFilter applyFilter={applyFilter} fabric={fabric} />
        <SepiaFilter applyFilter={applyFilter} fabric={fabric} />
        <SharpenFilter applyFilter={applyFilter} fabric={fabric} />
        <EmbossFilter applyFilter={applyFilter} fabric={fabric} />
        <BlackWhiteFilter applyFilter={applyFilter} fabric={fabric} />
        <BrownieFilter applyFilter={applyFilter} fabric={fabric} />
        <VintageFilter applyFilter={applyFilter} fabric={fabric} />
        <KodachromeFilter applyFilter={applyFilter} fabric={fabric} />
        <TechnicolorFilter applyFilter={applyFilter} fabric={fabric} />
        <PolaroidFilter applyFilter={applyFilter} fabric={fabric} />
      </ul>
    </div>
  );
};
export default FilterOptions;
