//import app from '../firebaseConfig'
import { initializeApp } from "firebase/app";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";
import { getFunctions } from "firebase/functions";
import { getStorage, ref, deleteObject } from "firebase/storage";
import { getFirestore, collection, addDoc, getDocs } from "firebase/firestore";

const firebaseConfig = {
  // apiKey: "AIzaSyArpkCQJgwObk9RjUlmuP2a5jRX_8Hufns",
  // authDomain: "jandiprintdev.firebaseapp.com",
  // projectId: "jandiprintdev",
  // storageBucket: "jandiprintdev.appspot.com",
  // messagingSenderId: "577984363720",
  // appId: "1:577984363720:web:d017d6f1fa9fe5fd55f9fd",
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth();
export const createUserWith_Email_Password = createUserWithEmailAndPassword;
export const signIn_WithEmailAndPassword = signInWithEmailAndPassword;
export const onAuth_StateChanged = onAuthStateChanged;
export const sign_Out = signOut;
export const functions = getFunctions(app, "europe-west3");
export const storage = getStorage(app);
export const myref = ref;
export const my_deleteObject = deleteObject;
export const db = getFirestore();
export const dbcollection = collection;
export const dbaddDoc = addDoc;
export const dbgetDocs = getDocs;
export default app;
