/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import { toast } from "react-toastify";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(18),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  notchedOutline: {
    borderWidth: "1px",
    borderColor: "#1CCAFF !important",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  floatingLabelFocusStyle: {
    color: "#1CCAFF !important",
  },
  multilineColor: {
    color: "#F9FAFB",
  },
}));
toast.configure();
const ForgetPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [errorText, setErrorText] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (currentUser) {
      navigate("/");
    }
  }, [currentUser]);

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email === "") {
      setErrorText(t("ForgetPassword.EnterValidEmail"));
    } else if (!validateEmail(email)) {
      setErrorText(t("ForgetPassword.EnterValidEmail"));
    } else {
      const auth = getAuth();
      sendPasswordResetEmail(auth, email)
        .then(() => {
          toast(t("ForgetPassword.CheckEmail"), {
            type: "success",
            position: toast.POSITION.BOTTOM_CENTER,
          });
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          setErrorText(errorMessage);
        });
    }
    setLoading(false);
  };

  const backToHome = () => {
    navigate("/");
    window.location.reload();
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography
          style={{ color: "#F9FAFB", display: "flex", alignItems: "center" }}
          variant="h4"
          gutterBottom
        >
          <i
            className="backarrow fal fa-long-arrow-left"
            onClick={() => backToHome()}
          ></i>
          &nbsp;Reset Password
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                  },
                  className: classes.multilineColor,
                }}
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                label="Email Address"
                name="email"
                onChange={(e) => setEmail(e.target.value)}
                autoComplete="email"
                autoFocus
              />
              {errorText ? (
                <p style={{ color: "red", marginTop: "10px" }}>{errorText}</p>
              ) : null}
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            style={{ background: "#1890FF", color: "#fff" }}
            className={classes.submit}
            disabled={loading}
          >
            Reset
          </Button>
        </form>
      </div>
    </Container>
  );
};

export default ForgetPassword;
