import {
  Checkbox,
  makeStyles,
  Paper,
  Tooltip,
  withStyles,
} from "@material-ui/core";
import React, { useState } from "react";
import CanvasInput from "../addCanvas/canvasInput";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: "10px",
    color: "#fff",
    backgroundColor: "#212B36",
  },
  paper1: {
    height: 200,
    width: 200,
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#212B36",
    alignItems: "center",
  },
  control: {
    padding: theme.spacing(2),
  },
}));

const AddVariant = ({
  index,
  childSKU,
  inStock,
  name,
  price,
  size,
  quantityInStock,
  handleSubVariantChange,
  handleVariantInStockChange,
  handleVarientPlusButton,
  handleVarientCloneButton,
  handleVarientMinusButton,
  handleSetStockQuantityInfinity,
}) => {
  const classes = useStyles();
  const [minimize, setMinimize] = useState(true);
  return (
    <div className="mt-10 mr-10" key={index}>
      <Paper className={classes.paper}>
        {minimize ? (
          <h3 className="fw-300 df aic">
            {index + 1}&nbsp; &nbsp;name:{name}&nbsp; &nbsp; price:
            {price ? ` €${price}` : ""}&nbsp;
            <span>
              Quantity: {quantityInStock ? quantityInStock : "inifinity"}
            </span>
            &nbsp;
            <LightTooltip title="Delete" placement="top">
              <i
                className="backarrow fal fa-trash-alt"
                onClick={handleVarientMinusButton}
              ></i>
            </LightTooltip>
            &nbsp; &nbsp;
            <LightTooltip title="Clone" placement="top">
              <i
                className="backarrow fal fa-clone"
                onClick={handleVarientCloneButton}
              ></i>
            </LightTooltip>
            &nbsp; &nbsp;
            <LightTooltip title="Add New" placement="top">
              <i
                className="backarrow fal fa-plus"
                onClick={handleVarientPlusButton}
              ></i>
            </LightTooltip>
            &nbsp;&nbsp;
            <LightTooltip title="Maximize" placement="top">
              <i
                className="backarrow fal fa-window-maximize"
                onClick={() => setMinimize(false)}
              ></i>
            </LightTooltip>
          </h3>
        ) : (
          <div className="mt-10 mb-10">
            <span>Child SKU:</span>
            &nbsp; &nbsp;
            <CanvasInput
              type="text"
              value={childSKU}
              name="childSKU"
              onChange={handleSubVariantChange}
              placeholder="Child SKU"
              width="150px"
            />
            &nbsp; &nbsp;
            <span>Name:</span>
            &nbsp; &nbsp;
            <CanvasInput
              type="text"
              value={name}
              name="name"
              onChange={handleSubVariantChange}
              placeholder="Name"
              width="150px"
            />
            &nbsp; &nbsp;
            <span>Price:</span>
            &nbsp; &nbsp;
            <CanvasInput
              type="number"
              value={price}
              name="price"
              onChange={handleSubVariantChange}
              placeholder="Price"
              width="150px"
            />
            <div className="mt-10">
              <span>Size:</span>
              &nbsp; &nbsp;
              <CanvasInput
                type="text"
                value={size}
                name="size"
                onChange={handleSubVariantChange}
                placeholder="Size"
                width="150px"
              />
              &nbsp; &nbsp;
              <span>Quantity In Stock:</span>
              &nbsp; &nbsp;
              <CanvasInput
                type="number"
                value={quantityInStock}
                name="quantityInStock"
                onChange={handleSubVariantChange}
                placeholder="Infinity"
                width="150px"
              />
              &nbsp; &nbsp;
              <button
                className="cup"
                onClick={() => handleSetStockQuantityInfinity()}
              >
                Infinity
              </button>
              &nbsp; &nbsp;
              <span>InStock:</span>
              <Checkbox
                checked={inStock}
                style={{ color: "#1CCAFF" }}
                color="primary"
                onClick={(e) => handleVariantInStockChange(e.target.checked)}
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
            </div>
            <div className="mt-10">
              {/* <UploadImage
                handleUploadClick={handleVarientUploadClick}
                imageInputRef={imageInputRef}
              />
              {imageURL ? (
                <div className="mt-10">
                  <Paper className={classes.paper1}>
                    <img
                      width={
                        imageURL.width /
                        calcCf(imageURL.width, imageURL.height, 200)
                      }
                      height={
                        imageURL.height /
                        calcCf(imageURL.width, imageURL.height, 200)
                      }
                      src={imageURL.url}
                      alt="productImage"
                    />
                  </Paper>
                </div>
              ) : null} */}
              <div className="mt-10">
                <LightTooltip title="Add New" placement="top">
                  <i
                    className="backarrow fal fa-plus"
                    onClick={handleVarientPlusButton}
                  ></i>
                </LightTooltip>
                &nbsp; &nbsp;
                <LightTooltip title="Delete" placement="top">
                  <i
                    className="backarrow fal fa-trash-alt"
                    onClick={handleVarientMinusButton}
                  ></i>
                </LightTooltip>
                &nbsp; &nbsp;
                <LightTooltip title="Clone" placement="top">
                  <i
                    className="backarrow fal fa-clone"
                    onClick={handleVarientCloneButton}
                  ></i>
                </LightTooltip>
                &nbsp; &nbsp;
                <LightTooltip title="Minimize" placement="top">
                  <i
                    className="backarrow fal fa-window-maximize"
                    onClick={() => setMinimize(true)}
                  ></i>
                </LightTooltip>
              </div>
            </div>
          </div>
        )}
      </Paper>
    </div>
  );
};

export default AddVariant;
