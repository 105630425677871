/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const TextColorsOptions = ({ save, saveBack }) => {
  const { t } = useTranslation();
  const _home = useSelector((state) => state.home);
  const [fillApl, setFillApl] = useState("#000000");
  const [strokeApl, setStrokeApl] = useState("#000000");
  const [backgroundColorApl, setBackgroundColorApl] = useState("#ffffff");
  const [textBackgroundColorApl, setTextBackgroundColorApl] =
    useState("#ffffff");

  const setActiveStyle = (styleName, value, object) => {
    object = object || _home.currentCanvas.getActiveObject();
    if (!object) return;

    if (object.setSelectionStyles && object.isEditing) {
      var style = {};
      style[styleName] = value;
      object.setSelectionStyles(style);
      object.setCoords();
    } else {
      object.set(styleName, value);
    }
    object.setCoords();
    _home.currentCanvas.requestRenderAll();
    if (_home.face === "front") {
      save();
    } else {
      saveBack();
    }
  };

  const setActiveProp = (name, value) => {
    var object = _home.currentCanvas.getActiveObject();
    if (!object) return;
    object.set(name, value).setCoords();
    _home.currentCanvas.renderAll();
    if (_home.face === "front") {
      save();
    } else {
      saveBack();
    }
  };

  const setFill = (value) => {
    setFillApl(value);
    setActiveStyle("fill", value);
  };

  const setStroke = (value) => {
    setStrokeApl(value);
    setActiveStyle("stroke", value);
  };

  const setBgColor = (value) => {
    setBackgroundColorApl(value);
    setActiveProp("backgroundColor", value);
  };

  const setTextBgColor = (value) => {
    setTextBackgroundColorApl(value);
    setActiveProp("textBackgroundColor", value);
  };

  return (
    <div className="p-10">
      <div className="mt-10 df aic">
        {t("TextColorsOptions.FillColor")}: &nbsp;&nbsp;
        <input
          type="color"
          style={{
            width: "40px",
            cursor: "pointer",
            border: "none",
            background: "transparent",
          }}
          value={fillApl}
          onChange={(e) => setFill(e.target.value)}
          className="btn-object-action"
        ></input>
      </div>
      <br />
      <div className="mt-10 df aic">
        {t("TextColorsOptions.Stroke")}: &nbsp;&nbsp;
        <input
          type="color"
          style={{
            width: "40px",
            cursor: "pointer",
            border: "none",
            background: "transparent",
          }}
          value={strokeApl}
          onChange={(e) => setStroke(e.target.value)}
          className="btn-object-action"
        ></input>
      </div>
      <br />
      <div className="mt-10 df aic">
        {t("TextColorsOptions.Background")}: &nbsp;&nbsp;
        <input
          type="color"
          style={{
            width: "40px",
            cursor: "pointer",
            border: "none",
            background: "transparent",
          }}
          value={backgroundColorApl}
          onChange={(e) => setBgColor(e.target.value)}
          className="btn-object-action"
        ></input>
      </div>
      <br />
      {t("TextColorsOptions.BackgroundTextColor")}:
      <div className="mt-10 df aic">
        <input
          type="color"
          style={{
            width: "40px",
            cursor: "pointer",
            border: "none",
            background: "transparent",
          }}
          defaultValue={textBackgroundColorApl}
          onChange={(e) => setTextBgColor(e.target.value)}
          className="btn-object-action"
        ></input>
      </div>
    </div>
  );
};

export default TextColorsOptions;
