import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
// import SizeTable from "./components/sizeTable";
// import sizeimg1 from "../../assets/images/sizeimg1.jpg";
// import sizeimg2 from "../../assets/images/sizeimg2.jpg";
// import sizeimg3 from "../../assets/images/sizeimg3.jpg";
import { Markup } from "interweave";
import "./legalTextsPopup.css";

const LegalTextsPopup = ({ open, setLegalTextsOpen }) => {
  const { t } = useTranslation();
  const [secondOpen, setSecondOpen] = useState(false);
  const [thirdOpen, setThirdOpen] = useState(false);
  const [fourthOpen, setFourthOpen] = useState(false);
  const _productOptions = useSelector((state) => state.productOptions);
  const handleSecondOpening = () => {
    setSecondOpen((prev) => !prev);
    setThirdOpen(false);
    setFourthOpen(false);
  };
  const handleThirdOpening = () => {
    setSecondOpen(false);
    setThirdOpen((prev) => !prev);
    setFourthOpen(false);
  };
  const handleFourthOpening = () => {
    setSecondOpen(false);
    setThirdOpen(false);
    setFourthOpen((prev) => !prev);
  };
  return (
    <ul
      style={!open ? { display: "none" } : { color: "#fff" }}
      className="legalTextsPopup-popup"
    >
      {_productOptions.selectedStudio?.legal ? (
        <>
          <h3 className="fw-300 mb-10">{t("LegalTexts.CompanyDetails")}</h3>
          <i
            className="ri-close-line product-popup-close-icon"
            onClick={() => setLegalTextsOpen(false)}
          ></i>
          <Markup
            content={_productOptions.selectedStudio?.legal.companyDetails}
          />

          <br />
          <h3
            className="df aic cup fw-300 mb-10"
            onClick={() => handleSecondOpening()}
          >
            {t("LegalTexts.TermsConditions")} &nbsp;
            {secondOpen ? (
              <i className="ri-arrow-up-s-line"></i>
            ) : (
              <i className="ri-arrow-down-s-line"></i>
            )}
          </h3>
          <ul
            className="close-open-legle-text"
            style={!secondOpen ? { display: "none" } : null}
          >
            <Markup
              content={_productOptions.selectedStudio?.legal.termsConditions}
            />
          </ul>
          <br />
          <h3
            className="df aic cup fw-300 mb-10"
            onClick={() => handleThirdOpening()}
          >
            {t("LegalTexts.Revocation")} &nbsp;
            {thirdOpen ? (
              <i className="ri-arrow-up-s-line"></i>
            ) : (
              <i className="ri-arrow-down-s-line"></i>
            )}
          </h3>
          <ul
            className="close-open-legle-text"
            style={!thirdOpen ? { display: "none" } : null}
          >
            <Markup
              content={_productOptions.selectedStudio?.legal.revocation}
            />
          </ul>
          <br />
          <h3
            className="df aic cup fw-300 mb-10"
            onClick={() => handleFourthOpening()}
          >
            {t("LegalTexts.DataProtection")} &nbsp;
            {fourthOpen ? (
              <i className="ri-arrow-up-s-line"></i>
            ) : (
              <i className="ri-arrow-down-s-line"></i>
            )}
          </h3>
          <ul
            className="close-open-legle-text"
            style={!fourthOpen ? { display: "none" } : null}
          >
            <Markup
              content={_productOptions.selectedStudio?.legal.dataProtection}
            />
          </ul>
          <br />
          <br />
        </>
      ) : null}
    </ul>
  );
};

export default LegalTextsPopup;
