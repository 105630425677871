import React, { useState } from "react";
import { Grid, Slider, Typography } from "@material-ui/core";

const LineHeightOptions = ({ setActiveStyle }) => {
  const [lineHeight, setLineHeight] = useState(1.5);

  const handleLineHeight = (event, newValue) => {
    setLineHeight(newValue);
    setActiveStyle("lineHeight", parseFloat(newValue, 10));
  };
  return (
    <div>
      <Typography id="input-slider" gutterBottom>
        Line Height:
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs>
          <Slider
            min={0}
            max={10}
            step={0.1}
            value={typeof lineHeight === "number" ? lineHeight : 0}
            onChange={handleLineHeight}
            aria-labelledby="input-slider"
            style={{ width: "90%" }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default LineHeightOptions;
