import { cartConstants } from "../constants";
const {
  SELECT_PRODUCT,
  SELECT_COLOR,
  SELECT_SIZE,
  CHANGE_QUANTITY,
  REMOVE_CART_ITEM,
  ADD_TO_CART,
  SET_CART,
  ADD_TO_CART_LOADING,
  SELECTED_STUDIO,
  CART_QUANTITY_CHANGE_LOADING,
  DELETE_CART_ITEM_LOADING,
} = cartConstants;
const initialState = {
  // add
  selectedColor: "",
  selectedSize: "",
  selectedProduct: {
    name: "Dummy Product",
    sku: "",
    productImage: null,
    description: "",
    threeDModel: "Female Basic T-Shirt",
    canvases: [
      {
        width: "3484",
        height: "4327",
      },
      {
        width: "3484",
        height: "4327",
      },
    ],
    shipments: [],
    variants: [],
  },
  addToCartLoading: false,
  cart: [],
  selectedStudio: null,
  dumyColors: [
    "#000000",
    "#ffffff",
    "#fe4a49",
    "#2ab7ca",
    "#fed766",
    "#011f4b",
    "#005b96",
    "#b3cde0",
    "#251e3e",
    "#451e3e",
    "#851e3e",
    "#0e9aa7",
    "#fe8a71",
    "#2a4d69",
    "#35a79c",
    "#ee4035",
    "#36454f",
    "#4b3832",
  ],
  cartOuantityChangeLoading: false,
  deleteCartItemLoading: false,
};

export const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    // Select Product
    case SELECT_PRODUCT:
      return {
        ...state,
        selectedProduct: action.payload,
      };
    // add to cart loading
    case ADD_TO_CART_LOADING:
      return {
        ...state,
        addToCartLoading: action.payload,
      };
    // selected studio
    case SELECTED_STUDIO:
      return {
        ...state,
        selectedStudio: action.payload,
      };
    // Selected color
    case SELECT_COLOR:
      return {
        ...state,
        selectedColor: action.payload,
      };
    // change quantity
    case CHANGE_QUANTITY:
      return {
        ...state,
        cart: state.cart.map((item, i) => {
          if (i === action.payload.i) item = action.payload.newItem;
          return item;
        }),
      };
    // remove cart item
    case REMOVE_CART_ITEM:
      return {
        ...state,
        cart: state.cart.filter((item, i) => i !== action.payload.i),
      };
    // Selected Size
    case SELECT_SIZE:
      return {
        ...state,
        selectedSize: action.payload,
      };
    // Add to cart
    case ADD_TO_CART:
      return {
        ...state,
        cart: state.cart.concat(action.payload),
      };
    // set cart
    case SET_CART:
      return {
        ...state,
        cart: action.payload,
      };
    case CART_QUANTITY_CHANGE_LOADING:
      return {
        ...state,
        cartOuantityChangeLoading: action.payload,
      };
    case DELETE_CART_ITEM_LOADING:
      return {
        ...state,
        deleteCartItemLoading: action.payload,
      };
    default:
      return state;
  }
};
