/* eslint-disable no-loop-func */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { CircularProgress, Grid, makeStyles, Paper } from "@material-ui/core";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../../firebase";
import UploadImage from "../../../../components/uploadImage";
import { fabric } from "fabric";
import "./imageManager.css";
import { useAuth } from "../../../../contexts/AuthContext";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  ref,
  getDownloadURL,
  uploadBytesResumable,
  getStorage,
  deleteObject,
} from "firebase/storage";
import { storage } from "../../../../firebase";
import { getInfo, calcCf } from "../../../../util";

import { homeConstants } from "../../../../redux/constants";
import IconButton from "../../../iconButton";
import { useNavigate } from "react-router-dom";
const { UPLOADED_IMAGES } = homeConstants;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 100,
    width: 100,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#212B36",
    display: "flex",
  },
  control: {
    padding: theme.spacing(2),
  },
}));

const ImageManager = ({ save, saveBack }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const _home = useSelector((state) => state.home);
  const { currentUser, dbUserData } = useAuth();
  const imageInputRef = React.useRef();
  const [spacing, setSpacing] = useState(2);
  const [imageManagerLoading, setImageManagerLoading] = useState(false);

  const imgAdded = (file) => {
    var imgObj = new Image();
    imgObj.crossOrigin = "Anonymous";
    imgObj.src = file;
    imgObj.onload = function () {
      var image = new fabric.Image(imgObj);
      let scale = _home.currentZoom / _home.maxZoom;
      image.set({
        scaleX: scale,
        scaleY: scale,
      });
      _home.currentCanvas.add(image);
      _home.currentCanvas.requestRenderAll();
    };
    if (_home.face === "front" || _home.face === "front") {
      save();
    } else {
      saveBack();
    }
  };

  const removeImage = async (rmImg) => {
    let newArray = [];
    for (let i = 0; i < _home.uploadedImages.length; i++) {
      if (
        rmImg.width === _home.uploadedImages[i].width &&
        rmImg.height === _home.uploadedImages[i].height &&
        rmImg.image === _home.uploadedImages[i].image
      ) {
      } else {
        newArray.push(_home.uploadedImages[i]);
      }
    }
    dispatch({
      type: UPLOADED_IMAGES,
      payload: newArray,
    });
    const storage = getStorage();
    // Create a reference to the file to delete
    const desertRef = ref(storage, rmImg.image);

    // Delete the file
    deleteObject(desertRef)
      .then(() => {
        // File deleted successfully
      })
      .catch((error) => {
        // Uh-oh, an error occurred!
      });
    try {
      const removePicture = httpsCallable(
        functions,
        "dbUsersOnCall-removePicture"
      );
      removePicture({
        width: rmImg.width,
        height: rmImg.height,
        image: rmImg.image,
      }).then((result) => {
        // code here
      });
    } catch (e) {
      console.error("Error removing Image: ", e);
    }
  };
  const handleUploadClick = async (e) => {
    if (e.target.files.length < 1) return;
    let imgArray = [];
    if (currentUser) {
      setImageManagerLoading(true);
      for (let i = 0; i < e.target.files.length; i++) {
        let dimensions = await getInfo(e.target.files[i]);
        const myNewFile = new File(
          [e.target.files[i]],
          new Date().getTime() + e.target.files[i].name,
          {
            type: e.target.files[i].type,
          }
        );
        const sotrageRef = ref(
          storage,
          `images/${currentUser.uid}/${myNewFile.name}`
        );
        const uploadTask = uploadBytesResumable(sotrageRef, myNewFile);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const prog = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
          },
          (error) => console.log(error),
          async () => {
            let downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            try {
              const addPicture = httpsCallable(
                functions,
                "dbUsersOnCall-addPicture"
              );
              addPicture({
                width: dimensions.width,
                heigth: dimensions.height,
                image: downloadURL,
              }).then((result) => {
                imgArray.push({
                  width: dimensions.width,
                  height: dimensions.height,
                  image: downloadURL,
                });
                if (i === e.target.files.length - 1) {
                  setTimeout(() => {
                    dispatch({
                      type: UPLOADED_IMAGES,
                      payload: [..._home.uploadedImages, ...imgArray],
                    });
                    setImageManagerLoading(false);
                  }, 6000);
                }
              });
            } catch (e) {
              console.error("Error adding document: ", e);
            }
          }
        );
      }
    } else {
      for (let i = 0; i < e.target.files.length; i++) {
        let dimensions1 = await getInfo(e.target.files[i]);
        let objectUrl = URL.createObjectURL(e.target.files[i]);
        imgArray.push({
          width: dimensions1.width,
          height: dimensions1.height,
          image: objectUrl,
        });
      }
      dispatch({
        type: UPLOADED_IMAGES,
        payload: [..._home.uploadedImages, ...imgArray],
      });
    }
  };
  const showImagesPreview = () => {
    if (_home.uploadedImages.length > 0) {
      return _home.uploadedImages.map((item, i) => (
        <Grid style={{ position: "relative" }} key={i} item>
          {currentUser ? (
            <i
              style={{
                color: "#FF4842",
                cursor: "pointer",
                position: "absolute",
                top: "-5px",
                right: "-5px",
              }}
              className="fal fa-minus-circle"
              onClick={() => removeImage(item)}
            ></i>
          ) : null}
          <Paper className={classes.paper} onClick={() => imgAdded(item.image)}>
            <img
              src={item.image}
              width={item.width / calcCf(item.width, item.height, 100)}
              height={item.height / calcCf(item.width, item.height, 100)}
              alt="testing"
              className="gridImg"
            />
          </Paper>
        </Grid>
      ));
    }
  };
  const handleLogin = () => {
    navigate("/signin");
  };
  return (
    <ul className="edit-options over-flowy-scroll">
      {currentUser ? (
        _home.uploadedImages.length < 10 ? (
          <UploadImage
            handleUploadClick={handleUploadClick}
            imageInputRef={imageInputRef}
          />
        ) : (
          <p className="mt-10">{t("imageManager.Only10allowed")}</p>
        )
      ) : (
        <UploadImage
          handleUploadClick={handleUploadClick}
          imageInputRef={imageInputRef}
        />
      )}
      <br />
      <div style={!imageManagerLoading ? { display: "none" } : null}>
        <p className="mb-10">{t("uploadImage.FetchingImages")}</p>
        <CircularProgress />
      </div>
      {!currentUser && _home.uploadedImages.length > 0 ? (
        <div className="mb-10">
          <p style={{ color: "red", marginBottom: "10px", textAlign: "left" }}>
            <span
              style={{
                border: "1px solid red",
                width: "20px",
                display: "inline-block",
                textAlign: "center",
              }}
            >
              !
            </span>{" "}
            {t("imageManager.Imageswillnotbeavailableafterrefresh")}
          </p>
          <IconButton
            text={t("YourCanvases.Login")}
            onClick={() => handleLogin()}
          >
            <i className="ri-user-line"></i>
          </IconButton>
        </div>
      ) : null}
      <Grid item xs={12}>
        <Grid
          container
          justifyContent="center"
          spacing={spacing}
          className="image-grid-container"
        >
          {showImagesPreview()}
        </Grid>
      </Grid>
    </ul>
  );
};

export default ImageManager;
