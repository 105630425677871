/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import UploadImage from "../../../uploadImage";
import axios from "axios";
import "./removeImgBg.css";
import { Grid, makeStyles, Paper } from "@material-ui/core";
import CustomButton from "../../../leftPanel2/components/customButton";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 100,
    width: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#2e313f",
  },
  control: {
    padding: theme.spacing(2),
  },
}));

const RemoveImgBg = () => {
  const classes = useStyles();
  const imageInputRef = React.useRef();
  const [spacing, setSpacing] = useState(2);
  const [file, setFile] = useState(null);

  // helper to get dimensions of an image
  const imageDimensions = (file) =>
    new Promise((resolve, reject) => {
      const img = new Image();

      // the following handler will fire after the successful loading of the image
      img.onload = () => {
        const { naturalWidth: width, naturalHeight: height } = img;
        resolve({ width, height });
      };

      // and this handler will fire if there was an error with the image (like if it's not really an image or a corrupted one)
      img.onerror = () => {
        reject("There was some problem with the image.");
      };

      img.src = URL.createObjectURL(file);
    });

  // here's how to use the helper
  const getInfo = async (file) => {
    try {
      const dimensions = await imageDimensions(file);
      return dimensions;
    } catch (error) {
      console.error(error);
    }
  };

  //handle the image uploading process
  const handleUploadClick = async (e) => {
    if (e.target.files.length < 1) return;
    //   setFile(e.target.files[0]);
    let dimensions = await getInfo(e.target.files[0]);
    let cf = 0;
    if (dimensions.width > dimensions.height) {
      cf = dimensions.width / 100;
    } else {
      cf = dimensions.height / 100;
    }
    let objectUrl = URL.createObjectURL(e.target.files[0]);
    setFile({
      fileObject: e.target.files[0],
      preview: objectUrl,
      width: parseInt(dimensions.width / cf),
      height: parseInt(dimensions.height / cf),
    });
  };

  const removeBg = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "JWT fefege...",
    };

    let formData = new FormData();
    const ref_size = 500;
    const key = "*HY!cE!AY8EY@W16*RtfG21y34";

    const url = "http://dimi-nas.synology.me:8080/";
    const data = { "ref-size": ref_size, greyscale: "Ye", key: key };
    // const file = {
    //   file: file.fileObject,
    //   data: data,
    // };
    formData.append("file", file.fileObject);
    formData.append("ref-size", ref_size);
    formData.append("greyscale", "ye");
    formData.append("key", key);
    // try {
    var res = await axios.post(url, formData, {
      headers: headers,
    });
    // } catch (error) {
    //   console.log(error);
    // }
  };

  const showImagesPreview = () => {
    if (file) {
      return (
        <Grid item>
          <Paper
            className={classes.paper}
            // onClick={() => imgAdded(item.preview)}
          >
            <img
              src={file.preview}
              width={file.width}
              height={file.height}
              alt="testing"
              className="gridImg"
            />
          </Paper>
        </Grid>
      );
    }
  };
  return (
    <div className="removeImgBg-main-wrapper">
      <UploadImage
        handleUploadClick={handleUploadClick}
        imageInputRef={imageInputRef}
      />
      <br />
      <Grid item xs={12}>
        <Grid
          container
          justifyContent="center"
          spacing={spacing}
          className="image-grid-container"
        >
          {showImagesPreview()}
        </Grid>
      </Grid>
      <br />
      {file ? <CustomButton text="Remove" onClick={() => removeBg()} /> : null}
    </div>
  );
};

export default RemoveImgBg;
