import React from "react";

const Option = ({
  children,
  handlePanel2OptionSelect,
  optionText,
  selected,
}) => {
  return (
    <li>
      <span
        className="leftPanel1-btn"
        onClick={() => handlePanel2OptionSelect(optionText)}
      >
        {children}
        {/* &nbsp;
        <span
          style={!hoverOnUL ? { display: "none" } : null}
          className="leftPanel1-btn-text"
        >
          {optionText}
        </span> */}
      </span>
    </li>
  );
};

export default Option;
