import { Tooltip, withStyles } from "@material-ui/core";
import React from "react";
import "./addCanvas.css";
import CanvasInput from "./canvasInput";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const AddCanvas = ({
  index,
  width,
  height,
  handleCanvasChange,
  handleCanvasPlusButton,
  handleCanvasMinusButton,
}) => {
  return (
    <div className="mt-10" key={index}>
      {/* <span>Width:</span> */}
      &nbsp; &nbsp; &nbsp; &nbsp;
      <CanvasInput
        width="120px"
        index={1000}
        type="number"
        value={width}
        name="width"
        onChange={handleCanvasChange}
        placeholder="Canvas width"
      />
      &nbsp; &nbsp;
      {/* <span>Height:</span> */}
      &nbsp; &nbsp;
      <CanvasInput
        width="120px"
        index={2000}
        type="number"
        value={height}
        name="height"
        onChange={handleCanvasChange}
        placeholder="Canvas height"
      />
      &nbsp; &nbsp;
      <LightTooltip title="Add New Canvas" placement="top">
        <i
          className="backarrow fal fa-plus"
          onClick={handleCanvasPlusButton}
        ></i>
      </LightTooltip>
      &nbsp; &nbsp;
      <LightTooltip title="Delete" placement="top">
        <i
          className="backarrow fal fa-minus"
          onClick={handleCanvasMinusButton}
        ></i>
      </LightTooltip>
    </div>
  );
};

export default AddCanvas;
