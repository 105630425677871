import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { filterConstants } from "../../../../../redux/constants";
import CustomCheckBox from "../../../../customCheckBox";
import { useTranslation } from "react-i18next";
const { BLACK_WHITE } = filterConstants;

const BlackWhiteFilter = ({ applyFilter, fabric }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const _filter = useSelector((state) => state.filter);
  const applyBlackWhite = (check) => {
    dispatch({
      type: BLACK_WHITE,
      payload: check,
    });
    applyFilter(19, check && new fabric.Image.filters.BlackWhite());
  };
  return (
    <div>
      <CustomCheckBox
        lable={t("BlackWhiteFilter.BlackWhite")}
        checked={_filter.blackWhite}
        onClick={(e) => applyBlackWhite(e.target.checked)}
      />
    </div>
  );
};

export default BlackWhiteFilter;
