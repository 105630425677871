import React from "react";
import { Button } from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { useTranslation } from "react-i18next";
// import { useAuth } from "../../contexts/AuthContext";

const UploadImage = ({ handleUploadClick, imageInputRef }) => {
  const { t } = useTranslation();
  // const { currentUser } = useAuth();
  return (
    <div style={{ textAlign: "center" }}>
      <Button
        variant="contained"
        component="label"
        className="iconButton"
        style={{
          marginTop: "0px",
          borderColor: "#2e313f",
          color: "#fff",
          backgroundColor: "#212B36",
          width: "100%",
        }}
        startIcon={<CloudUploadIcon />}
      >
        {t("uploadImage.Upload")}
        <input
          accept="image/*"
          name="img"
          type="file"
          multiple
          onChange={handleUploadClick}
          ref={imageInputRef}
          hidden
        />
      </Button>
    </div>
  );
};

export default UploadImage;
