import React, { useEffect, useState } from "react";
import { Checkbox, makeStyles, Paper } from "@material-ui/core";
import { fabric } from "fabric";
// import CustomCheckBox from "../../../customCheckBox";
import CustomSlider from "../../../customSlider";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  paper: {
    backgroundColor: "#212B36",
    padding: "10px",
    marginBottom: "10px",
    color: "white",
  },
});

const ColorOptions = ({ save, saveBack }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const _home = useSelector((state) => state.home);
  // filters start
  const [gamma, setGamma] = useState(false);
  const [saturationApl, setSaturationApl] = useState(false);
  const [hueApl, setHueApl] = useState(false);
  // filters end
  const [red, setRed] = useState(1);
  const [green, setGreen] = useState(1);
  const [blue, setBlue] = useState(1);
  const [saturation, setSaturation] = useState(0);
  const [hue, setHue] = useState(0);
  const [contrastApl, setContrastApl] = useState(false);
  const [contrast, setContrast] = useState(0);

  const applyFilter = (index, filter) => {
    var obj = _home.currentCanvas.getActiveObject();
    if (obj) {
      obj.filters[index] = filter;
      obj.applyFilters();
      _home.currentCanvas.requestRenderAll();
    }
    if (_home.face === "front") {
      save();
    } else {
      saveBack();
    }
  };

  const getFilter = (index) => {
    var obj = _home.currentCanvas.getActiveObject();
    return obj.filters[index];
  };

  const applyFilterValue = (index, prop, value) => {
    var obj = _home.currentCanvas.getActiveObject();
    if (obj.filters[index]) {
      obj.filters[index][prop] = value;
      obj.applyFilters();
      _home.currentCanvas.requestRenderAll();
    }
    if (_home.face === "front") {
      save();
    } else {
      saveBack();
    }
  };

  const applyGamma = (check) => {
    setGamma(check);
    applyFilter(
      17,
      check &&
        new fabric.Image.filters.Gamma({
          gamma: [red, green, blue],
        })
    );
  };

  const handleRedChange = (event, newValue) => {
    setRed(newValue);
    var current = getFilter(17).gamma;
    current[0] = parseFloat(newValue);
    applyFilterValue(17, "gamma", current);
  };

  const handleGreenChange = (event, newValue) => {
    setGreen(newValue);
    var current = getFilter(17).gamma;
    current[1] = parseFloat(newValue);
    applyFilterValue(17, "gamma", current);
  };

  const handleBlueChange = (event, newValue) => {
    setBlue(newValue);
    var current = getFilter(17).gamma;
    current[2] = parseFloat(newValue);
    applyFilterValue(17, "gamma", current);
  };

  const applySaturation = (check) => {
    setSaturationApl(check);
    applyFilter(
      7,
      check &&
        new fabric.Image.filters.Saturation({
          saturation: parseFloat(saturation),
        })
    );
  };

  const handleSaturationValueChange = (event, newValue) => {
    setSaturation(newValue);
    applyFilterValue(7, "saturation", parseFloat(newValue));
  };

  const applyHue = (check) => {
    setHueApl(check);
    applyFilter(
      21,
      check &&
        new fabric.Image.filters.HueRotation({
          rotation: parseFloat(hue),
        })
    );
  };

  const handleHueValueChange = (event, newValue) => {
    setHue(newValue);
    applyFilterValue(21, "rotation", parseFloat(newValue));
  };

  const applyContrast = (check) => {
    setContrastApl(check);
    applyFilter(
      6,
      check &&
        new fabric.Image.filters.Contrast({
          contrast: parseFloat(contrast),
        })
    );
  };

  const handleContrastChange = (event, newValue) => {
    setContrast(newValue);
    applyFilterValue(6, "contrast", parseFloat(newValue));
  };

  useEffect(() => {
    if (_home.currentCanvas) {
      _home.currentCanvas.on({
        "selection:updated": HandleElement,
        "selection:created": HandleElement,
      });
    }
  }, [_home.currentCanvas]);
  function HandleElement(obj) {
    if (obj.target.filters) {
      // Gamma
      if (obj.target.filters[17]) {
        setGamma(true);
      } else {
        setGamma(false);
      }
      // SaturationApl
      if (obj.target.filters[7]) {
        setSaturationApl(true);
      } else {
        setSaturationApl(false);
      }
      // HueApl
      if (obj.target.filters[21]) {
        setHueApl(true);
      } else {
        setHueApl(false);
      }
    } else {
      setGamma(false);
      setSaturationApl(false);
      setHueApl(false);
    }
  }

  return (
    <ul className="edit-options over-flowy-scroll">
      <Paper className={classes.paper}>
        {t("ColorOptions.Gamma")}
        <Checkbox
          style={{ color: "#1CCAFF" }}
          color="primary"
          checked={gamma}
          onClick={(e) => applyGamma(e.target.checked)}
          inputProps={{ "aria-label": "secondary checkbox" }}
        />
        {/* <CustomCheckBox onClick={applyGamma} /> */}
        <br />
        <CustomSlider
          title={t("ColorOptions.Red")}
          value={red}
          min={0.2}
          max={2.2}
          step={0.003921}
          handleChange={handleRedChange}
        />
        <br />
        <CustomSlider
          title={t("ColorOptions.Green")}
          value={green}
          min={0.2}
          max={2.2}
          step={0.003921}
          handleChange={handleGreenChange}
        />
        <br />
        <CustomSlider
          title={t("ColorOptions.Blue")}
          value={blue}
          min={0.2}
          max={2.2}
          step={0.003921}
          handleChange={handleBlueChange}
        />
      </Paper>
      <Paper className={classes.paper}>
        {t("ColorOptions.Saturation")}
        <Checkbox
          style={{ color: "#1CCAFF" }}
          color="primary"
          checked={saturationApl}
          onClick={(e) => applySaturation(e.target.checked)}
          inputProps={{ "aria-label": "secondary checkbox" }}
        />
        {/* <CustomCheckBox onClick={applySaturation} /> */}
        <CustomSlider
          title={t("ColorOptions.Value")}
          value={saturation}
          min={-1}
          max={1}
          step={0.003921}
          handleChange={handleSaturationValueChange}
        />
      </Paper>
      <Paper className={classes.paper}>
        {t("ColorOptions.Hue")}
        <Checkbox
          style={{ color: "#1CCAFF" }}
          color="primary"
          checked={hueApl}
          onClick={(e) => applyHue(e.target.checked)}
          inputProps={{ "aria-label": "secondary checkbox" }}
        />
        {/* <CustomCheckBox onClick={applyHue} /> */}
        {/* <Grid container spacing={2} alignItems="center">
          <Grid item xs>
            <Slider
              step={0.01}
              min={0}
              max={1}
              value={typeof hue === "number" ? hue : 0}
              onChange={handleHueValueChange}
              aria-labelledby="input-slider"
            />
          </Grid>
        </Grid> */}
        <CustomSlider
          title={t("ColorOptions.Value")}
          value={hue}
          min={-2}
          max={2}
          step={0.002}
          handleChange={handleHueValueChange}
        />
      </Paper>

      <Paper className={classes.paper}>
        {t("ColorOptions.Contrast")}
        <Checkbox
          style={{ color: "#1CCAFF" }}
          color="primary"
          checked={contrastApl}
          onClick={(e) => applyContrast(e.target.checked)}
          inputProps={{ "aria-label": "secondary checkbox" }}
        />
        <CustomSlider
          title={t("ColorOptions.Value")}
          value={contrast}
          min={-1}
          max={1}
          step={0.003921}
          handleChange={handleContrastChange}
        />
      </Paper>
    </ul>
  );
};

export default ColorOptions;
