import React from "react";
import ProductDetailUpper from "./components/productDetailUpper";
import { Markup } from "interweave";
import "./productDetail.css";

const ProductDetail = ({
  setShowDetail,
  selectedProduct,
  setProductsPopupOpen,
}) => {
  return (
    <div className="productDetail">
      {/* productDetailUpper */}
      <ProductDetailUpper
        setShowDetail={setShowDetail}
        selectedProduct={selectedProduct}
        setProductsPopupOpen={setProductsPopupOpen}
      />
      {/* productDetaiolLower */}
      <ul className="productDetaiolLower">
        {selectedProduct.description2 === "" ? (
          <Markup content={selectedProduct.description1} />
        ) : (
          <>
            <div className="desc-left">
              <Markup content={selectedProduct.description1} />
            </div>
            <div className="desc-right">
              <Markup content={selectedProduct.description2} />
            </div>
          </>
        )}
      </ul>
    </div>
  );
};

export default ProductDetail;
