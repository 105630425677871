const panel_color = "#212B36";

export const colorConstants = {
  MIDDLE_PANEL_BG_COLOR: "#161C24",
  LEFT_PANEL1_BG_COLOR: panel_color,
  LEFT_PANEL2_BG_COLOR: panel_color,
  LEFT_PANEL3_BG_COLOR: panel_color,
  RIGHT_PANEL_BG_COLOR: panel_color,
  FOOTER_PANEL_BG_COLOR: panel_color,
  HEADER_APP_BG_COLOR: panel_color,
  ELEMENT_COLOR: "#1CCAFF",
  LEFT_PANEL1_ICON_HOVER: "#74CAFF",
  LEFT_PANEL1_ICON_NOT_HOVER: "#ffffff",
  PRODUCT_POPUP_BACKGROUND: "#161c24",
  PRODUCT_POPUP_Items_BG: "#212B36",
  BARCHART_BAR_FILL_COLOR: "#8884d8",
  LINECHART_LINE_FILL_COLOR: "#82ca9d",
  PRODUCT_DETAIL_GREEN_LABLES: "#00a86",
};
