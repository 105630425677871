/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../firebase";
import "./dashboard.css";
import logo from "../../assets/images/jandilogo.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Menu } from "@material-ui/icons";
import DashboardSidebar from "./dashboardSidebar";
import DashboardContentArea from "./dashboardContentArea";
import { useAuth } from "../../contexts/AuthContext";
import { makeStyles, Paper } from "@material-ui/core";
import {
  orderConstants,
  cartConstants,
  homeConstants,
} from "../../redux/constants";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
const {
  STUDIO_ORDERS,
  STUDIO_ORDERS_LOADING,
  ORDERS_LOADING,
  ORDERS,
  MY_ORDERS_LOADING,
  MY_ORDERS,
  PRODUCTS_LOADING,
  PRODUCTS,
  ALL_ORDERS,
} = orderConstants;
const { SET_CART } = cartConstants;
const { COME_FROM_DASHBOARD } = homeConstants;

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: "#212B36",
    position: "absolute",
    right: "10px",
    top: "50px",
    width: "150px",
    textAlign: "left",
    borderRadius: "5px",
  },
}));

const UserDashboard = () => {
  const { t } = useTranslation();
  let { tab } = useParams();
  const dispatch = useDispatch();
  const { currentUser, logout, userClaims } = useAuth();
  const [showlanguageDropdown, setShowlanguageDropdown] = useState(false);
  const classes = useStyles();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("");
  const [showloginDropdown, setShowloginDropdown] = useState(false);
  useEffect(() => {
    switch (tab) {
      case "profile":
        setActiveTab("DashboardSidebar.Profile");
        break;
      case "financials":
        setActiveTab("DashboardSidebar.Financials");
        break;
      case "pictures":
        setActiveTab("DashboardSidebar.Pictures");
        break;
      case "my-orders":
        setActiveTab("DashboardSidebar.MyOrders");
        break;
      case "all-orders":
        setActiveTab("DashboardSidebar.AllOrders");
        break;
      case "products":
        setActiveTab("DashboardSidebar.Products");
        break;
      case "shipments":
        setActiveTab("DashboardSidebar.Shipments");
        break;
      case "legal-texts":
        setActiveTab("DashboardSidebar.Legaltexts");
        break;
      case "orders":
        setActiveTab("DashboardSidebar.Orders");
        break;
      case "user-management":
        setActiveTab("DashboardSidebar.UserManagement");
        break;
      case "users":
        setActiveTab("DashboardSidebar.Users");
        break;
      default:
        setActiveTab("DashboardSidebar.Profile");
        navigate("/dashboard/profile");
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  const handleLanguageChange = (lang) => {
    i18next.changeLanguage(lang);
  };

  useEffect(() => {
    if (userClaims?.user) {
      dispatch({
        type: MY_ORDERS_LOADING,
        payload: true,
      });
      const getAllOrdersOfBuyer = httpsCallable(
        functions,
        "dbOrdersOnCall-getAllOrdersOfBuyer"
      );
      getAllOrdersOfBuyer({ buyerID: currentUser.uid }).then((result) => {
        dispatch({
          type: MY_ORDERS,
          payload: result.data,
        });
        dispatch({
          type: MY_ORDERS_LOADING,
          payload: false,
        });
      });
    }
    if (userClaims?.admin) {
      dispatch({
        type: ORDERS_LOADING,
        payload: true,
      });
      const getAllOrders = httpsCallable(
        functions,
        "dbOrdersOnCall-getAllOrders"
      );
      getAllOrders().then((result) => {
        dispatch({
          type: ALL_ORDERS,
          payload: result.data,
        });
        dispatch({
          type: ORDERS_LOADING,
          payload: false,
        });
      });
    }

    if (userClaims?.studio) {
      dispatch({
        type: STUDIO_ORDERS_LOADING,
        payload: true,
      });
      dispatch({
        type: ORDERS_LOADING,
        payload: true,
      });
      const getAllOrdersOfSeller = httpsCallable(
        functions,
        "dbOrdersOnCall-getAllOrdersOfSeller"
      );
      getAllOrdersOfSeller({ sellerID: currentUser.uid }).then((result) => {
        dispatch({
          type: STUDIO_ORDERS,
          payload: result.data,
        });
        dispatch({
          type: ORDERS,
          payload: result.data,
        });
        dispatch({
          type: ORDERS_LOADING,
          payload: false,
        });
        dispatch({
          type: STUDIO_ORDERS_LOADING,
          payload: false,
        });
      });

      try {
        dispatch({
          type: PRODUCTS_LOADING,
          payload: true,
        });
        const getAllProducts = httpsCallable(
          functions,
          "dbProductsOnCall-getAllProducts"
        );
        getAllProducts({ sellerID: currentUser.uid }).then((result) => {
          dispatch({
            type: PRODUCTS,
            payload: result.data,
          });
          dispatch({
            type: PRODUCTS_LOADING,
            payload: false,
          });
        });
      } catch (e) {
        console.error("Error removing Image: ", e);
        // setProductsLoading(false);
        dispatch({
          type: PRODUCTS_LOADING,
          payload: false,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userClaims]);

  const handleLogout = async () => {
    dispatch({
      type: SET_CART,
      payload: [],
    });
    try {
      await logout();
      setShowloginDropdown(false);
    } catch (error) {
      alert("Failed to Log out ");
    }
  };
  const handleHumburgerOpening = () => {
    setShowloginDropdown(!showloginDropdown);
  };
  const handleNavigate = (to) => {
    dispatch({
      type: COME_FROM_DASHBOARD,
      payload: true,
    });
    navigate(to);
    setShowloginDropdown(false);
  };

  const handleLanguageDropdownOpening = () => {
    setShowlanguageDropdown(!showlanguageDropdown);
  };
  const goToHome = () => {
    dispatch({
      type: COME_FROM_DASHBOARD,
      payload: true,
    });
    navigate("/");
  };
  return (
    <div className="dashboard">
      <div className="dashboard-header df aic jcsb pl-20 pr-20">
        <a
          className="df aic"
          style={{ color: "#fff" }}
          href="https://jandiprint.de"
          target="_blank"
        >
          {/* Jandiprint */}
          <img className="logo-img" src={logo} alt="jandiprint.de" />
        </a>
        <h1 className="df jcc aic">
          <i
            className="backarrow fal fa-long-arrow-left"
            onClick={() => goToHome()}
          ></i>
          &nbsp; &nbsp;
          {/* {activeTab} */}
          {t(activeTab)}
        </h1>
        <div className="df aic">
          <button
            onClick={() => handleLanguageDropdownOpening()}
            className="header-icon-btn"
          >
            <i className="ri-translate"></i>
            {/* <i className="fas fa-bars"></i> */}
          </button>
          &nbsp;
          <button
            onClick={() => handleHumburgerOpening()}
            className="header-icon-btn"
          >
            <Menu />
            {/* <i className="fas fa-bars"></i> */}
          </button>
        </div>
      </div>
      <div className="dashboard-body">
        <DashboardSidebar activeTab={activeTab} setActiveTab={setActiveTab} />
        <DashboardContentArea
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      </div>
      <div
        className="login-dropdown-wrap"
        style={!showlanguageDropdown ? { display: "none" } : null}
        onClick={() => setShowlanguageDropdown(false)}
      >
        <Paper
          className={classes.paper}
          style={!showlanguageDropdown ? { display: "none" } : null}
        >
          <ul className="login-dropdown color-white">
            <>
              <li onClick={() => handleLanguageChange("en")}>English</li>
              <li onClick={() => handleLanguageChange("de")}>Deutsch</li>
            </>
          </ul>
        </Paper>
      </div>
      <div
        className="login-dropdown-wrap"
        style={!showloginDropdown ? { display: "none" } : null}
        onClick={() => setShowloginDropdown(false)}
      >
        <Paper
          className={classes.paper}
          style={!showloginDropdown ? { display: "none" } : null}
        >
          <ul className="login-dropdown">
            {currentUser ? (
              <>
                <li onClick={() => handleNavigate("/")}>
                  <Link to="/">
                    <i className="fal fa-home color-white"></i>
                    &nbsp; {t("UserDashboard.Home")}
                  </Link>
                </li>
                <li
                  onClick={() => handleLogout()}
                  style={{ border: "none" }}
                  className="logout-li"
                >
                  <i className="fal fa-sign-out color-white"></i>
                  &nbsp; {t("UserDashboard.SignOut")}
                </li>
              </>
            ) : null}
          </ul>
        </Paper>
      </div>
    </div>
  );
};

export default UserDashboard;
