import React from "react";

const OptionsList = ({ children }) => {
  return (
    <div
      className="my-options-list"
      // onMouseEnter={() => setHoverOnUL(true)}
      // onMouseLeave={() => setHoverOnUL(false)}
    >
      {children}
    </div>
  );
};

export default OptionsList;
