import React, { useContext, useEffect, useState } from "react";
import { httpsCallable } from "firebase/functions";
import { toast } from "react-toastify";
import { functions } from "../firebase";
import { useNavigate } from "react-router-dom";
import { sendEmailVerification } from "firebase/auth";
import { useTranslation } from "react-i18next";
import {
  auth,
  createUserWith_Email_Password,
  signIn_WithEmailAndPassword,
  onAuth_StateChanged,
  sign_Out,
} from "../firebase";
import { useDispatch, useSelector } from "react-redux";
import {
  homeConstants,
  productOptionsConstants,
  cartConstants,
} from "../redux/constants";
const { UPLOADED_IMAGES } = homeConstants;
const { SET_CART } = cartConstants;
// eslint-disable-next-line no-unused-vars
const { POPUP_PRODUCTS, STUDIOS, SELECTED_STUDIO, PRODUCTS_LOADING } =
  productOptionsConstants;

const AuthContext = React.createContext();
toast.configure();
export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const _productOptions = useSelector((state) => state.productOptions);
  const [currentUser, setCurrentUser] = useState();
  const [userDataLoading, setUserDataLoading] = useState(false);
  const [dbUserData, setDbUserData] = useState();
  const [userClaims, setUserClaims] = useState(null);
  const [loading, setLoading] = useState(true);
  const [authError, setAuthError] = useState("");

  useEffect(() => {
    onAuth_StateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        // const uid = user.uid;
        setUserDataLoading(true);
        const getUserData = httpsCallable(
          functions,
          "dbUsersOnCall-getUserData"
        );
        getUserData({ userID: user.uid }).then((result) => {
          setDbUserData(result.data);
          setUserDataLoading(false);
        });
        setCurrentUser(user);
        const getClaim = httpsCallable(functions, "authOnCall-getClaim");
        getClaim({ email: user.email }).then((result) => {
          setUserClaims(result.data);
        });
      } else {
        // User is signed out
      }
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // getUserCartData
    if (currentUser) {
      try {
        const getUserCartData = httpsCallable(
          functions,
          "dbUsersOnCall-getUserCartData"
        );
        getUserCartData().then((result) => {
          console.log("dbUsersOnCall-getUserCartData", result);
          dispatch({
            type: SET_CART,
            payload: result.data,
          });
        });
      } catch (e) {
        console.error("Error adding document: ", e);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  useEffect(() => {
    if (dbUserData) {
      let tempArr = [];
      if (dbUserData.pictures) {
        for (let i = 0; i < dbUserData.pictures.length; i++) {
          const w = parseInt(dbUserData.pictures[i].split(/[ ;]+/)[0]);
          const h = parseInt(dbUserData.pictures[i].split(/[ ;]+/)[1]);
          let cf = 0;
          if (w > h) {
            cf = w / 100;
          } else {
            cf = h / 100;
          }
          const nImg = {
            w: parseInt(w / cf),
            h: parseInt(h / cf),
            width: w,
            height: h,
            image: dbUserData.pictures[i].split(/[ ;]+/)[2],
          };
          tempArr.push(nImg);
        }
        dispatch({
          type: UPLOADED_IMAGES,
          payload: tempArr,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dbUserData]);

  const logedInUserCountry = () => {
    if (dbUserData.shippingAddressFlag) {
      return dbUserData.shippingAddress.country;
    } else {
      return dbUserData.billingAddress.country;
    }
  };

  useEffect(() => {
    // getAllStudios
    try {
      // setStudiosLoading(true);
      dispatch({
        type: PRODUCTS_LOADING,
        payload: true,
      });
      const getAllStudios = httpsCallable(
        functions,
        "dbUsersOnCall-getAllStudios"
      );
      getAllStudios().then((result) => {
        let newArr = [];
        for (let i = 0; i < result.data.length; i++) {
          for (let j = 0; j < result.data[i].shipments.length; j++) {
            if (result.data[i].shipments[j].country === logedInUserCountry()) {
              newArr.push(result.data[i]);
            }
          }
        }
        dispatch({
          type: STUDIOS,
          payload: newArr,
        });
        dispatch({
          type: SELECTED_STUDIO,
          payload: newArr[0],
        });
        console.log("newArr", newArr);
        // setStudiosLoading(false);
        dispatch({
          type: PRODUCTS_LOADING,
          payload: true,
        });
      });
    } catch (e) {
      console.error("Error adding document: ", e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dbUserData]);

  useEffect(() => {
    if (_productOptions.selectedStudio) {
      try {
        // setProductsLoading(true);
        dispatch({
          type: PRODUCTS_LOADING,
          payload: true,
        });
        const getAllProducts = httpsCallable(
          functions,
          "dbProductsOnCall-getAllProducts"
        );
        getAllProducts({
          sellerID: _productOptions.selectedStudio.userID,
        }).then((result) => {
          // code here
          // setProducts(result.data);
          dispatch({
            type: POPUP_PRODUCTS,
            payload: result.data,
          });
          // setProductsLoading(false);
          dispatch({
            type: PRODUCTS_LOADING,
            payload: false,
          });
        });
      } catch (e) {
        console.error("Error removing Image: ", e);
        // setProductsLoading(false);
        dispatch({
          type: PRODUCTS_LOADING,
          payload: false,
        });
      }
      dispatch({
        type: SELECTED_STUDIO,
        payload: _productOptions.selectedStudio,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_productOptions.selectedStudio]);

  const signup = async (email, password) => {
    createUserWith_Email_Password(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        setCurrentUser(user);
        sendEmailVerification(user).then(() => {
          // Email verification sent!
          toast(t("AuthProvider.Pleasecheckyouremail"), {
            type: "success",
            position: toast.POSITION.BOTTOM_CENTER,
          });
        });
        navigate("/");
      })
      .catch((error) => {
        const errorCode = error.code;
        // const errorMessage = error.message;
        if (errorCode === "auth/email-already-in-use") {
          setAuthError(t("AuthProvider.Accountwiththisemailalreadyexist"));
        }
      });
  };

  const login = async (email, password) => {
    setAuthError("");
    await signIn_WithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        setCurrentUser(user);
        navigate("/");
      })
      .catch((error) => {
        setAuthError(t("AuthProvider.Failedtologin"));
      });
  };

  const logout = () => {
    sign_Out(auth)
      .then(() => {
        // Sign-out successful.
        setCurrentUser(null);
        navigate("/");
        // window.location.reload(false);
      })
      .catch((error) => {
        // An error happened.
        console.log(error);
      });
  };

  const value = {
    currentUser,
    dbUserData,
    userDataLoading,
    setDbUserData,
    userClaims,
    signup,
    login,
    logout,
    authError,
  };
  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
