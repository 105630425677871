import { productOptionsConstants } from "../constants";
const { POPUP_PRODUCTS, STUDIOS, SELECTED_STUDIO, PRODUCTS_LOADING } =
  productOptionsConstants;
const initialState = {
  studios: [],
  products: [],
  selectedStudio: null,
  productsLoading: false,
};

export const productOptionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case POPUP_PRODUCTS:
      return {
        ...state,
        products: action.payload,
      };
    case STUDIOS:
      return {
        ...state,
        studios: action.payload,
      };
    case SELECTED_STUDIO:
      return {
        ...state,
        selectedStudio: action.payload,
      };
    case PRODUCTS_LOADING:
      return {
        ...state,
        productsLoading: action.payload,
      };
    default:
      return state;
  }
};
