import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  // Legend,
  ResponsiveContainer,
} from "recharts";
import { colorConstants } from "../../../../../colors";
const { LINECHART_LINE_FILL_COLOR } = colorConstants;
const MyLineChart = ({ data, XAxisDataKey, lineDataKey }) => {
  return (
    <div>
      <ResponsiveContainer width="100%" aspect={4}>
        <LineChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey={XAxisDataKey}
            tick={{ stroke: "white", strokeWidth: 1 }}
          />
          <YAxis tick={{ stroke: "white", strokeWidth: 1 }} />
          <Tooltip />
          {/* <Legend /> */}
          <Line
            type="monotone"
            dataKey={lineDataKey}
            stroke={LINECHART_LINE_FILL_COLOR}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};
export default MyLineChart;
