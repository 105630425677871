export const cartConstants = {
  // add
  SELECT_PRODUCT: "SELECT_PRODUCT",
  SELECT_COLOR: "SELECT_COLOR",
  SELECT_SIZE: "SELECT_SIZE",
  CHANGE_QUANTITY: "CHANGE_QUANTITY",
  REMOVE_CART_ITEM: "REMOVE_CART_ITEM",
  ADD_TO_CART: "ADD_TO_CART",
  SET_CART: "SET_CART",
  ADD_TO_CART_LOADING: "ADD_TO_CART_LOADING",
  SELECTED_STUDIO: "SELECTED_STUDIO",
  CART_QUANTITY_CHANGE_LOADING: "CART_QUANTITY_CHANGE_LOADING",
  DELETE_CART_ITEM_LOADING: "DELETE_CART_ITEM_LOADING",
};

export const orderConstants = {
  // add
  STUDIO_ORDERS: "STUDIO_ORDERS",
  STUDIO_ORDERS_LOADING: "STUDIO_ORDERS_LOADING",
  ORDERS: "ORDERS",
  ALL_ORDERS: "ALL_ORDERS",
  ORDERS_LOADING: "ORDERS_LOADING",
  MY_ORDERS: "MY_ORDERS",
  MY_ORDERS_LOADING: "MY_ORDERS_LOADING",
  PRODUCTS: "PRODUCTS",
  PRODUCTS_LOADING: "PRODUCTS_LOADING",
  PRODUCT_DELETE_LOADING: "PRODUCT_DELETE_LOADING",
};

export const homeConstants = {
  // add
  FACE: "FACE",
  CURRENT_CANVAS: "CURRENT_CANVAS",
  FRONT_CANVAS: "FRONT_CANVAS",
  BACK_CANVAS: "BACK_CANVAS",
  MAX_ZOOM: "MAX_ZOOM",
  CURRENT_ZOOM: "CURRENT_ZOOM",
  BRUSH_WIDTH: "BRUSH_WIDTH",
  RP_OPEN: "RP_OPEN",
  DRAWING: "DRAWING",
  LP1_SELECTED_OPTION: "LP1_SELECTED_OPTION",
  LP2_SELECTED_OPTION: "LP2_SELECTED_OPTION",
  LP2: "LP2",
  LP3: "LP3",
  UPLOADED_IMAGES: "UPLOADED_IMAGES",
  R: "R",
  G: "G",
  B: "B",
  COME_FROM_DASHBOARD: "COME_FROM_DASHBOARD",
  FIRST_COME_ONE_SITE: "FIRST_COME_ONE_SITE",
};
export const filterConstants = {
  GREY_SCALE: "GREY_SCALE",
  MODE: "MODE",
  INVERT: "INVERT",
  SEPIA: "SEPIA",
  SHARPEN: "SHARPEN",
  EMBOSS: "EMBOSS",
  BLACK_WHITE: "BLACK_WHITE",
  BROWNIE: "BROWNIE",
  VINTAGE: "VINTAGE",
  KODACHROME: "KODACHROME",
  TECHNICOLOR: "TECHNICOLOR",
  POLAROID: "POLAROID",
};
export const userCanvasesConstants = {
  USER_CANVASES: "USER_CANVASES",
  SELECTED_CANVAS: "SELECTED_CANVAS",
};
export const productOptionsConstants = {
  POPUP_PRODUCTS: "POPUP_PRODUCTS",
  STUDIOS: "STUDIOS",
  SELECTED_STUDIO: "SELECTED_STUDIO",
  PRODUCTS_LOADING: "PRODUCTS_LOADING",
};
