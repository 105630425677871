import React, { useEffect, useState } from "react";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../../firebase";
import { toast } from "react-toastify";
import "../products.css";
import Header from "./header";
import BelowActionButtons from "./belowActionButtons";
import ShowCanvases from "./showCanvases";
// import ShowShipments from "./showShipments";
// import ShowVariants from "./showVariants";
import ProductImage from "./productImage";
import CanvasPlusButton from "./canvasPlusButton";
// import ShipmentPlusButton from "./shipmentPlusButton";
import VariantPlusButton from "./variantPlusButton";
import Name from "./name";
import Sku from "./sku";
import Description from "./description";
import ThreeDModles from "./threeDModles";
import Loader from "../../../../components/loader";
import { Checkbox, Grid } from "@material-ui/core";
import MainVarientCard from "./mainVarientCard";
import SelectedVarient from "./selectedVarient";
import { orderConstants } from "../../../../redux/constants";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
const { PRODUCTS } = orderConstants;

toast.configure();
const AddNewProduct = ({ setNewProduct, editing, setEditing, editingItem }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const _order = useSelector((state) => state.order);
  const [selectedVarient, setSelectedVarient] = useState(null);
  const [selectedVarientIndex, setSelectedVarientIndex] = useState(null);
  const [product, setProduct] = useState({
    name: "",
    sku: "",
    productImage: null,
    description1: editing ? editingItem.description1 : "",
    description2: editing ? editingItem.description2 : "",
    threeDModel: "No 3D Model",
    inSale: false,
    canvases: [],
    // shipments: [],
    variants: [],
  });
  const [productSaveLoading, setProductSaveLoading] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [threeDModles, setThreeDModles] = useState([
    "No 3D Model",
    "Female Basic T-Shirt",
    "Male Basic T-Shirt",
  ]);
  useEffect(() => {
    if (editing) {
      let newItem = editingItem;
      const w = parseInt(editingItem.productImage.split(/[ ;]+/)[0]);
      const h = parseInt(editingItem.productImage.split(/[ ;]+/)[1]);
      const productImage = {
        width: w,
        height: h,
        url: editingItem.productImage.split(/[ ;]+/)[2],
      };
      newItem = { ...newItem, productImage: productImage };

      let newArray = [...editingItem.variants];
      for (let i = 0; i < editingItem.variants.length; i++) {
        if (editingItem.variants[i].imageURL) {
          const w = parseInt(
            editingItem.variants[i].imageURL.url.split(/[ ;]+/)[0]
          );
          const h = parseInt(
            editingItem.variants[i].imageURL.url.split(/[ ;]+/)[1]
          );
          const imageURL = {
            width: w,
            height: h,
            url: editingItem.variants[i].imageURL.url.split(/[ ;]+/)[2],
          };
          newArray[i] = { ...newArray[i], imageURL: imageURL };
        } else {
          newArray[i] = { ...newArray[i], imageURL: null };
        }
      }
      newItem = { ...newItem, variants: newArray };
      setProduct(newItem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editing]);
  const handleProductSubmit = () => {
    try {
      setProductSaveLoading(true);
      const createProduct = httpsCallable(
        functions,
        "dbProductsOnCall-createProduct"
      );
      createProduct(product).then((result) => {
        console.log("result", result.data);
        dispatch({
          type: PRODUCTS,
          payload: [result.data, ..._order.products],
          // payload: [{ productID: result.data }, ..._order.products],
        });
        setProductSaveLoading(false);
        setNewProduct(false);
      });
    } catch (e) {
      console.error("Error adding document: ", e);
      setProductSaveLoading(false);
    }
  };
  const handleProductUpdate = () => {
    // updateProductData
    try {
      setProductSaveLoading(true);
      const updateProductData = httpsCallable(
        functions,
        "dbProductsOnCall-updateProductData"
      );
      updateProductData(product).then((result) => {
        console.log(result);
        setProductSaveLoading(false);
      });
    } catch (e) {
      console.error("Error adding document: ", e);
      setProductSaveLoading(false);
    }
  };
  const handleDescription1Change = (e, editor) => {
    setProduct({ ...product, description1: editor.getData() });
  };
  const handleDescription2Change = (e, editor) => {
    setProduct({ ...product, description2: editor.getData() });
  };
  const handleInsaleSelect = (checked) => {
    if (checked) {
      if (!product.productImage) {
        toast(t("AddNewProduct.MissingProductImage"), {
          type: "error",
          position: toast.POSITION.BOTTOM_CENTER,
        });
      } else if (product.name === "") {
        toast(t("AddNewProduct.MissingProductName"), {
          type: "error",
          position: toast.POSITION.BOTTOM_CENTER,
        });
      } else if (product.sku === "") {
        toast(t("AddNewProduct.MissingProductSku"), {
          type: "error",
          position: toast.POSITION.BOTTOM_CENTER,
        });
      } else if (product.canvases.length < 1) {
        toast(t("AddNewProduct.MissingProductCanvas"), {
          type: "error",
          position: toast.POSITION.BOTTOM_CENTER,
        });
      } else if (product.canvases[0].width === "") {
        toast(t("AddNewProduct.MissingProductCanvas1Width"), {
          type: "error",
          position: toast.POSITION.BOTTOM_CENTER,
        });
      } else if (product.canvases[0].height === "") {
        toast(t("AddNewProduct.MissingProductCanvas1Height"), {
          type: "error",
          position: toast.POSITION.BOTTOM_CENTER,
        });
      } else if (product.canvases[1] && product.canvases[1].width === "") {
        toast(t("AddNewProduct.MissingProductCanvas2Width"), {
          type: "error",
          position: toast.POSITION.BOTTOM_CENTER,
        });
      } else if (product.canvases[1] && product.canvases[1].height === "") {
        toast(t("AddNewProduct.MissingProductCanvas2Height"), {
          type: "error",
          position: toast.POSITION.BOTTOM_CENTER,
        });
      } else if (product.variants.length < 1) {
        toast(t("AddNewProduct.MissingProductVariant"), {
          type: "error",
          position: toast.POSITION.BOTTOM_CENTER,
        });
      } else {
        setProduct({ ...product, inSale: checked });
      }
    } else {
      setProduct({ ...product, inSale: checked });
    }
  };
  return (
    <ul className="newProduct-scroll">
      {selectedVarient ? (
        <SelectedVarient
          product={product}
          setProduct={setProduct}
          setSelectedVarient={setSelectedVarient}
          i={selectedVarientIndex}
        />
      ) : (
        <>
          <Header
            setNewProduct={setNewProduct}
            editing={editing}
            setEditing={setEditing}
          />
          <Grid container>
            <Grid item xs={12} sm={3}>
              <ProductImage product={product} setProduct={setProduct} />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Name product={product} setProduct={setProduct} />
              <Sku product={product} setProduct={setProduct} />
              <ThreeDModles
                threeDModles={threeDModles}
                product={product}
                setProduct={setProduct}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CanvasPlusButton product={product} setProduct={setProduct} />
              <ShowCanvases product={product} setProduct={setProduct} />
              <div className=" pl-20 df aic">
                <Checkbox
                  checked={product.inSale}
                  style={{ color: "#1CCAFF" }}
                  color="primary"
                  onClick={(e) => handleInsaleSelect(e.target.checked)}
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
                <span>InSale</span>
              </div>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <Description
                lable="Description1"
                data={product.description1}
                onChange={handleDescription1Change}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Description
                lable="Description2"
                data={product.description2}
                onChange={handleDescription2Change}
              />
            </Grid>
          </Grid>
          <VariantPlusButton product={product} setProduct={setProduct} />
          <Grid container className="mt-20">
            {product.variants.map((variant, i) => (
              <Grid item xs={12} sm={2} style={{ padding: "10px" }} key={i}>
                <MainVarientCard
                  i={i}
                  product={product}
                  setProduct={setProduct}
                  setSelectedVarient={setSelectedVarient}
                  setSelectedVarientIndex={setSelectedVarientIndex}
                />
              </Grid>
            ))}
          </Grid>

          {/* <ShowVariants product={product} setProduct={setProduct} /> */}
          <BelowActionButtons
            editing={editing}
            setEditing={setEditing}
            handleProductUpdate={handleProductUpdate}
            handleProductSubmit={handleProductSubmit}
          />
          {productSaveLoading ? <Loader /> : null}
        </>
      )}
    </ul>
  );
};
export default AddNewProduct;
