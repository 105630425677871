import React from "react";
import { Checkbox } from "@material-ui/core";
import { fabric } from "fabric";
import { useSelector } from "react-redux";

const EmbossOptions = ({ save, saveBack }) => {
  const _home = useSelector((state) => state.home);

  const applyFilter = (index, filter) => {
    var obj = _home.currentCanvas.getActiveObject();
    if (obj) {
      obj.filters[index] = filter;
      obj.applyFilters();
      _home.currentCanvas.requestRenderAll();
    }
    if (_home.face === "front") {
      save();
    } else {
      saveBack();
    }
  };

  const applyEmboss = (check) => {
    applyFilter(
      8,
      check &&
        new fabric.Image.filters.Convolute({
          //matrix: [1, 1, 1, 1, 0.7, -1, -1, -1, -1],
          matrix: [-2, -1, 0, -1, 1, 1, 0, 1, 2],
        })
    );
  };
  return (
    <div>
      Emboss:
      <Checkbox
        color="primary"
        onClick={(e) => applyEmboss(e.target.checked)}
        inputProps={{ "aria-label": "secondary checkbox" }}
      />
    </div>
  );
};

export default EmbossOptions;
