import { Checkbox } from "@material-ui/core";
import React from "react";

const CheckBoxWithLabel = ({ checked, onClick, label }) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Checkbox
        style={{ color: "#1CCAFF", padding: "0" }}
        color="primary"
        checked={checked}
        onClick={(e) => onClick(e.target.checked)}
        inputProps={{ "aria-label": "secondary checkbox" }}
      />
      &nbsp;&nbsp;
      <span>{label}</span>
    </div>
  );
};

export default CheckBoxWithLabel;
