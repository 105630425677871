import React from "react";
import { useAuth } from "../../contexts/AuthContext";
import "./dashboard.css";
import { Navigate } from "react-router-dom";
import UserDashboard from "./userDashboard";

const Dashboard = () => {
  const { currentUser } = useAuth();
  return currentUser ? <UserDashboard /> : <Navigate to="/" />;
};

export default Dashboard;
