import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { Button, CircularProgress } from "@material-ui/core";

const columns = [
  { id: "email", label: "EMAIL", minWidth: 170 },
  { id: "city", label: "CITY", minWidth: 100 },
  {
    id: "details",
    label: "",
    minWidth: 170,
    align: "right",
  },
];

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
    backgroundColor: "#161C24",
  },
});

const CustomTable = ({ users, setDetail, setUserData }) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleDetailClick = (user) => {
    setUserData(user);
    setDetail(true);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    backgroundColor: "#212B36",
                    color: "#F9FAFB",
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {users
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, i) => {
                return (
                  <TableRow key={i} hover role="checkbox" tabIndex={-1}>
                    <TableCell style={{ color: "#fff" }}>{row.email}</TableCell>
                    <TableCell style={{ color: "#fff" }}>
                      {row?.address?.city}
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        variant="contained"
                        component="label"
                        style={{
                          marginBottom: "10px",
                          borderColor: "#2e313f",
                          color: "#fff",
                          backgroundColor: "#1890FF",
                          width: "150px",
                        }}
                        onClick={() => handleDetailClick(row)}
                      >
                        Detail
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        {users.length === 0 ? (
          <div style={{ textAlign: "center", margin: "20px" }}>
            <CircularProgress style={{ color: "#1CCAFF" }} />
          </div>
        ) : null}
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={users.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        style={{ backgroundColor: "#212B36", color: "#F9FAFB" }}
      />
    </Paper>
  );
};

export default CustomTable;
