import { Button } from "@material-ui/core";
import React from "react";
import CartItem from "../../cartItem";
import { useTranslation } from "react-i18next";
import "./singleStudioOrder.css";
import { useAuth } from "../../../../contexts/AuthContext";

const SingleStudioOrder = ({ studio, handleStripe }) => {
  const { t } = useTranslation();
  const { dbUserData } = useAuth();
  const showCartItems = () =>
    studio.cartItems.length > 0
      ? studio.cartItems.map((item, index) => (
          <CartItem item={item} index={index} key={index} />
        ))
      : null;

  console.log("studio", studio);

  const getShippingPrice = () => {
    if (dbUserData.shippingAddressFlag) {
      for (let i = 0; i < studio.shipments.length; i++) {
        if (
          dbUserData.shippingAddress.country === studio.shipments[i].country
        ) {
          return studio.shipments[i].price;
        }
      }
    } else {
      for (let i = 0; i < studio.shipments.length; i++) {
        if (dbUserData.billingAddress.country === studio.shipments[i].country) {
          return studio.shipments[i].price;
        }
      }
    }
    return 0.0;
  };

  const getDeliveryTime = () => {
    if (dbUserData.shippingAddressFlag) {
      for (let i = 0; i < studio.shipments.length; i++) {
        if (
          dbUserData.shippingAddress.country === studio.shipments[i].country
        ) {
          return studio.shipments[i].deliveryTime;
        }
      }
    } else {
      for (let i = 0; i < studio.shipments.length; i++) {
        if (dbUserData.billingAddress.country === studio.shipments[i].country) {
          return studio.shipments[i].deliveryTime;
        }
      }
    }
    return 0.0;
  };

  return (
    <>
      {studio.cartItems.length > 0 ? (
        <div className="singleStudioOrder df">
          <div className="singleorderItems w75p">{showCartItems()}</div>
          <div className="orderSumry">
            <p className="singleOrder-studio-text">
              <span>{t("SingleStudioOrder.Studio")}</span> {studio.studio}
            </p>
            <p className="singleOrder-studio-text">
              <span>{t("SingleStudioOrder.TotalPrice")}</span> €{" "}
              {Math.round(studio.totalPrice * 100) / 100}
            </p>
            <p className="singleOrder-studio-text">
              <span>{t("SingleStudioOrder.ShipingCost")}</span> €{" "}
              {getShippingPrice()}
            </p>
            <p className="singleOrder-studio-text">
              <span>{t("SingleStudioOrder.DeliveryTime")}</span>{" "}
              {getDeliveryTime()}
            </p>
            <p className="singleOrder-studio-text">
              <span>{t("SingleStudioOrder.Sum")}</span>€
              {Math.round((getShippingPrice() + studio.totalPrice) * 100) / 100}
            </p>

            <Button
              variant="contained"
              component="label"
              style={{
                marginTop: "10px",
                borderColor: "#2e313f",
                color: "#fff",
                backgroundColor: "#1890FF",
                width: "100%",
              }}
              onClick={() => handleStripe(studio.cartItems)}
            >
              {t("SingleStudioOrder.PayOrder")}
            </Button>
            {/* <div className="buyer-addresses">
              <p>Buyer Address:</p>
              <p>
                <span>country:&nbsp;</span>
                {dbUserData?.shippingAddressFlag
                  ? dbUserData?.shippingAddress.country
                  : dbUserData?.billingAddress.country}
              </p>
              <p>
                <span>street:&nbsp;</span>
                {dbUserData?.shippingAddressFlag
                  ? dbUserData?.shippingAddress.street
                  : dbUserData?.billingAddress.street}
              </p>
              <p>
                <span>houseNo:&nbsp;</span>
                {dbUserData?.shippingAddressFlag
                  ? dbUserData?.shippingAddress.houseNo
                  : dbUserData?.billingAddress.houseNo}
              </p>
              <p>
                <span>adrLine1:&nbsp;</span>
                {dbUserData?.shippingAddressFlag
                  ? dbUserData?.shippingAddress.adrLine1
                  : dbUserData?.billingAddress.adrLine1}
              </p>
              <p>
                <span>city:&nbsp;</span>
                {dbUserData?.shippingAddressFlag
                  ? dbUserData?.shippingAddress.city
                  : dbUserData?.billingAddress.city}
              </p>
              <p>
                <span>zipCode:&nbsp;</span>
                {dbUserData?.shippingAddressFlag
                  ? dbUserData?.shippingAddress.zipCode
                  : dbUserData?.billingAddress.zipCode}
              </p>
            </div> */}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default SingleStudioOrder;
