import React, { useEffect, useState } from "react";
import {
  Delete,
  FlipToFront,
  FlipToBack,
  FileCopy,
  GridOn,
  GridOff,
  CloudDownload,
  RestorePage,
  TabUnselected,
  ClearAll,
  ArrowUpward,
  ArrowDownward,
  Undo,
  Redo,
} from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
// import { useAuth } from "../../contexts/AuthContext";
import "./footerPanel.css";
import { useDispatch, useSelector } from "react-redux";
import { homeConstants } from "../../redux/constants";
import { colorConstants } from "../../colors";
import { withStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
const { FACE, CURRENT_CANVAS, CURRENT_ZOOM, DRAWING } = homeConstants;
const { FOOTER_PANEL_BG_COLOR } = colorConstants;

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const Pnael4 = ({ copy, paste, clipboard }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const _home = useSelector((state) => state.home);
  const _cart = useSelector((state) => state.cart);
  // const { currentUser } = useAuth();
  const [changeOriginalWidth, setChangeOriginalWidth] = useState(true);
  const deleteSelectedObj = () => {
    _home.currentCanvas.remove(_home.currentCanvas.getActiveObject());
  };

  useEffect(() => {
    if (_home.currentCanvas && changeOriginalWidth) {
      setChangeOriginalWidth(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_home.currentCanvas]);

  const handleCanvousFaceTogle = (cf) => {
    _home.currentCanvas.isDrawingMode = false;
    _home.currentCanvas.discardActiveObject().renderAll();
    dispatch({
      type: DRAWING,
      payload: false,
    });
    if (cf === "front") {
      dispatch({
        type: CURRENT_CANVAS,
        payload: _home.frontCanvas,
      });
      _home.backCanvas.remove(_home.mousecursor1);
      dispatch({
        type: FACE,
        payload: "front",
      });
      _home.backCanvas.selection = false;
      _home.backCanvas.forEachObject(function (o) {
        o.selectable = false;
      });
      _home.frontCanvas.selection = true;
      _home.frontCanvas.forEachObject(function (o) {
        o.selectable = true;
      });
    } else {
      dispatch({
        type: CURRENT_CANVAS,
        payload: _home.backCanvas,
      });
      _home.frontCanvas.remove(_home.mousecursor);
      dispatch({
        type: FACE,
        payload: "back",
      });
      _home.backCanvas.selection = true;
      _home.backCanvas.forEachObject(function (o) {
        o.selectable = true;
      });
      _home.frontCanvas.selection = false;
      _home.frontCanvas.forEachObject(function (o) {
        o.selectable = false;
      });
    }
    dispatch({
      type: CURRENT_ZOOM,
      payload: 1,
    });
  };

  const handleBringForward = () => {
    var obj = _home.currentCanvas.getActiveObject();
    _home.currentCanvas.bringForward(obj);
  };

  const handleSendBackWords = () => {
    var obj = _home.currentCanvas.getActiveObject();
    _home.currentCanvas.sendBackwards(obj);
  };

  const group = () => {
    if (!_home.currentCanvas.getActiveObject()) {
      return;
    }
    if (_home.currentCanvas.getActiveObject().type !== "activeSelection") {
      return;
    }
    _home.currentCanvas.getActiveObject().toGroup();
    _home.currentCanvas.requestRenderAll();
  };
  const ungroup = () => {
    if (!_home.currentCanvas.getActiveObject()) {
      return;
    }
    if (_home.currentCanvas.getActiveObject().type !== "group") {
      return;
    }
    _home.currentCanvas.getActiveObject().toActiveSelection();
    _home.currentCanvas.requestRenderAll();
    _home.currentCanvas.discardActiveObject().renderAll();
  };
  const handleResetZoom = () => {
    _home.currentCanvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
  };
  const handleCanvasClear = () => {
    _home.currentCanvas.clear();
    _home.currentCanvas.setBackgroundColor(
      `rgb(${_home.r},${_home.g},${_home.b})`,
      _home.currentCanvas.renderAll.bind(_home.currentCanvas)
    );
  };
  const handleUnselectElements = () => {
    _home.currentCanvas.discardActiveObject().renderAll();
  };
  const getCanvasAtResoution = (canvas, newWidth) => {
    if (canvas.width !== newWidth) {
      var scaleMultiplier = newWidth / canvas.width;
      var objects = canvas.getObjects();
      for (var i in objects) {
        objects[i].scaleX = objects[i].scaleX * scaleMultiplier;
        objects[i].scaleY = objects[i].scaleY * scaleMultiplier;
        objects[i].left = objects[i].left * scaleMultiplier;
        objects[i].top = objects[i].top * scaleMultiplier;
        objects[i].setCoords();
      }
      // setBrushWidth(brushWidth * scaleMultiplier);
      // canvas.freeDrawingBrush.width = brushWidth * scaleMultiplier;
      canvas.setWidth(canvas.getWidth() * scaleMultiplier);
      canvas.setHeight(canvas.getHeight() * scaleMultiplier);
      canvas.renderAll();
      canvas.calcOffset();
    }
  };
  const handlezoom = (zoom) => {
    if (zoom) {
      let original_width;
      if (_home.face === "front") {
        original_width = parseInt(_cart.selectedProduct.canvases[0].width);
        getCanvasAtResoution(_home.currentCanvas, original_width);
      } else {
        original_width = parseInt(_cart.selectedProduct.canvases[1].width);
        getCanvasAtResoution(_home.currentCanvas, original_width);
      }
    } else {
      let original_width;
      if (_home.face === "front") {
        original_width =
          parseInt(_cart.selectedProduct.canvases[0].width) / _home.maxZoom;
        getCanvasAtResoution(_home.currentCanvas, original_width);
      } else {
        original_width =
          parseInt(_cart.selectedProduct.canvases[1].width) / _home.maxZoom;
        getCanvasAtResoution(_home.currentCanvas, original_width);
      }
    }
  };
  var dataURLtoBlob = function (dataurl) {
    var parts = dataurl.split(","),
      mime = parts[0].match(/:(.*?);/)[1];
    if (parts[0].indexOf("base64") !== -1) {
      var bstr = atob(parts[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new Blob([u8arr], { type: mime });
    } else {
      var raw = decodeURIComponent(parts[1]);
      return new Blob([raw], { type: mime });
    }
  };
  var download = function (filename, dataUrl) {
    var element = document.createElement("a");
    var dataBlob = dataURLtoBlob(dataUrl);
    element.setAttribute("href", URL.createObjectURL(dataBlob));
    element.setAttribute("download", filename);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    var clickHandler;
    element.addEventListener(
      "click",
      (clickHandler = function () {
        // ..and to wait a frame
        requestAnimationFrame(function () {
          URL.revokeObjectURL(element.href);
        });
        element.removeAttribute("href");
        element.removeEventListener("click", clickHandler);
      })
    );
    document.body.removeChild(element);
  };
  const handleCanvasDownloadBtn = () => {
    _home.currentCanvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
    handlezoom(true);
    var dataURL = _home.currentCanvas.toDataURL({
      format: "png",
      multiplier: 0,
    });
    download(`${_home.face}.png`, dataURL);
    handlezoom(false);
  };

  return (
    <div
      className="footerPanel-main-wrapper"
      style={{ background: FOOTER_PANEL_BG_COLOR }}
    >
      <div>
        <LightTooltip title={t("footerPanel.Delete")}>
          <Delete className="cup" onClick={() => deleteSelectedObj()} />
        </LightTooltip>
        &nbsp; &nbsp; &nbsp;
        <LightTooltip title={t("footerPanel.FrontCanvas")}>
          <FlipToFront
            style={
              _home.face === "front"
                ? { backgroundColor: "#212B36" }
                : { color: "white" }
            }
            className="cup"
            onClick={() => handleCanvousFaceTogle("front")}
          />
        </LightTooltip>
        &nbsp; &nbsp; &nbsp;
        <LightTooltip
          title={t("footerPanel.BackCanvas")}
          style={{
            backgroundColor: "#212B36",
          }}
        >
          <FlipToBack
            style={
              _home.face === "back"
                ? { backgroundColor: "#212B36" }
                : { color: "white" }
            }
            className="cup"
            onClick={() => handleCanvousFaceTogle("back")}
          />
        </LightTooltip>
        &nbsp; &nbsp; &nbsp;
        <LightTooltip title={t("footerPanel.Copy")}>
          <FileCopy className="cup" onClick={() => copy()} />
          {/* <i className="backarrow fal fa-copy" onClick={() => copy()}></i> */}
        </LightTooltip>
        &nbsp; &nbsp; &nbsp;
        <LightTooltip title={t("footerPanel.Paste")}>
          <FileCopy
            style={
              clipboard ? { backgroundColor: "#353948" } : { color: "white" }
            }
            className="cup"
            onClick={() => paste()}
          />
        </LightTooltip>
        &nbsp; &nbsp; &nbsp;
        <LightTooltip title={t("footerPanel.GroupObjects")}>
          <GridOn className="cup" onClick={() => group()} />
        </LightTooltip>
        &nbsp; &nbsp; &nbsp;
        <LightTooltip title={t("footerPanel.UngroupObjects")}>
          <GridOff className="cup" onClick={() => ungroup()} />
        </LightTooltip>
        &nbsp; &nbsp;
        <LightTooltip title={t("footerPanel.ResetZoom")}>
          <RestorePage className="cup" onClick={() => handleResetZoom()} />
        </LightTooltip>
        &nbsp; &nbsp;
        <LightTooltip title={t("footerPanel.UnselectElements")}>
          <TabUnselected
            className="cup"
            onClick={() => handleUnselectElements()}
          />
        </LightTooltip>
        &nbsp; &nbsp;
        <LightTooltip title={t("footerPanel.ClearCanvas")}>
          <ClearAll onClick={() => handleCanvasClear()} className="cup" />
        </LightTooltip>
        &nbsp; &nbsp;
        <LightTooltip title={t("footerPanel.DownloadCurrentCanvas")}>
          <CloudDownload
            style={{ color: "white" }}
            className="cup"
            onClick={() => handleCanvasDownloadBtn()}
          />
        </LightTooltip>
      </div>
      <div>
        <LightTooltip title={t("footerPanel.BringObjectsForward")}>
          <ArrowUpward className="cup" onClick={() => handleBringForward()} />
        </LightTooltip>
        &nbsp; &nbsp;
        <LightTooltip title={t("footerPanel.SendObjectsBackWord")}>
          <ArrowDownward
            className="cup"
            onClick={() => handleSendBackWords()}
          />
        </LightTooltip>
        &nbsp; &nbsp;
        <>
          {_home.face === "front" ? (
            <>
              <button
                style={{ background: "transparent" }}
                className="outline-border-none cup"
                id="undo"
                disabled
              >
                <LightTooltip title={t("footerPanel.Undo")}>
                  <Undo style={{ color: "#fff" }} />
                </LightTooltip>
              </button>
              &nbsp; &nbsp;
              <button
                style={{ background: "transparent" }}
                className="outline-border-none cup"
                id="redo"
                disabled
              >
                <LightTooltip title={t("footerPanel.Redo")}>
                  <Redo style={{ color: "#fff" }} />
                  {/* <i style={{ color: "grey" }} className="fal fa-redo"></i> */}
                </LightTooltip>
              </button>
            </>
          ) : null}
          {_home.face === "back" ? (
            <>
              <button
                style={{ background: "transparent" }}
                className="cup outline-border-none"
                id="undoback"
                disabled
              >
                <LightTooltip title={t("footerPanel.Undo")}>
                  <Undo style={{ color: "#fff" }} />
                  {/* <i style={{ color: "grey" }} className="fal fa-undo"></i> */}
                </LightTooltip>
              </button>
              &nbsp; &nbsp;
              <button
                style={{ background: "transparent" }}
                className="cup outline-border-none"
                id="redoback"
                disabled
              >
                <LightTooltip title={t("footerPanel.Redo")}>
                  <Redo style={{ color: "#fff" }} />
                </LightTooltip>
              </button>
            </>
          ) : null}
        </>
      </div>
    </div>
  );
};

export default Pnael4;
