import React from "react";
import "./categoriesSidebar.css";
import { colorConstants } from "../../../../../../colors";
const { PRODUCT_POPUP_Items_BG } = colorConstants;

const CategoriesSidebar = () => {
  return (
    <div
      className="categories-sidebar"
      style={{ background: PRODUCT_POPUP_Items_BG }}
    >
      categories-sidebar
    </div>
  );
};

export default CategoriesSidebar;
