import { Button } from "@material-ui/core";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../../../firebase";
import CountrySelector from "../../../../../components/countrySelector";
import { useAuth } from "../../../../../contexts/AuthContext";
import CanvasInput from "../../../products/addCanvas/canvasInput";
import { useTranslation } from "react-i18next";
import InnerLoader from "../../../../../components/innerLoader";

toast.configure();
const AddNewShipment = ({
  editing,
  setNewShipment,
  setEditing,
  shipmentsGloble,
  shipment,
  setShipment,
}) => {
  const { t } = useTranslation();
  const { dbUserData, setDbUserData } = useAuth();
  const [addShipmentLoading, setAddShipmentLoading] = useState(false);

  const handleBackPress = () => {
    setNewShipment(false);
    setEditing(false);
  };
  const handleCancel = () => {
    setShipment({ country: "", price: 0, currency: "EUR" });
    setNewShipment(false);
    setEditing(false);
  };
  const handleField = (e) => {
    if (e.target.name === "price") {
      if (parseFloat(e.target.value) >= 0) {
        setShipment({
          ...shipment,
          [e.target.name]: parseFloat(e.target.value),
        });
      } else {
        setShipment({ ...shipment, [e.target.name]: 0 });
      }
    } else {
      if (!editing) {
        setShipment({ ...shipment, [e.target.name]: e.target.value });
      }
    }
  };

  const handleDeliveryTime = (e) => {
    setShipment({ ...shipment, [e.target.name]: e.target.value });
  };
  const handleShipmentAdd = () => {
    let flag = true;
    for (let i = 0; i < shipmentsGloble.length; i++) {
      // country
      if (shipment.country === shipmentsGloble[i].country) {
        flag = false;
      }
    }
    if (flag) {
      if (shipment.country === "") {
        toast(t("Shipments.SelectCountry"), {
          type: "error",
          position: toast.POSITION.BOTTOM_CENTER,
        });
      } else {
        setAddShipmentLoading(true);
        if (dbUserData?.shipments) {
          setDbUserData({
            ...dbUserData,
            shipments: [...dbUserData.shipments, shipment],
          });
          try {
            const updateAllShipments = httpsCallable(
              functions,
              "dbUsersOnCall-updateAllShipments"
            );
            updateAllShipments({
              shipments: [...dbUserData.shipments, shipment],
            }).then((result) => {
              //
              setAddShipmentLoading(false);
              setNewShipment(false);
              setShipment({ country: "", price: 0, currency: "EUR" });
            });
          } catch (e) {
            console.error("Error adding document: ", e);
          }
        } else {
          setDbUserData({
            ...dbUserData,
            shipments: [shipment],
          });
          try {
            const updateAllShipments = httpsCallable(
              functions,
              "dbUsersOnCall-updateAllShipments"
            );
            updateAllShipments({
              shipments: [shipment],
            }).then((result) => {
              //
              setAddShipmentLoading(false);
              setNewShipment(false);
              setShipment({ country: "", price: 0, currency: "EUR" });
            });
          } catch (e) {
            console.error("Error adding document: ", e);
          }
        }
      }
    } else {
      toast(t("Shipments.ShipmentExists"), {
        type: "error",
        position: toast.POSITION.BOTTOM_CENTER,
      });
      console.log("shipment", shipment);
    }
  };

  const handleShipmentUpdate = () => {
    setAddShipmentLoading(true);
    if (dbUserData?.shipments) {
      try {
        const updateAllShipments = httpsCallable(
          functions,
          "dbUsersOnCall-updateAllShipments"
        );
        let newArr = [];
        for (let i = 0; i < dbUserData.shipments.length; i++) {
          if (dbUserData.shipments[i].country !== shipment.country) {
            newArr.push(dbUserData.shipments[i]);
          } else {
            newArr.push(shipment);
          }
        }
        setDbUserData({
          ...dbUserData,
          shipments: newArr,
        });
        updateAllShipments({
          shipments: newArr,
        }).then((result) => {
          //
          setAddShipmentLoading(false);
          setNewShipment(false);
          setEditing(false);
          setShipment({ country: "", price: 0, currency: "EUR" });
        });
      } catch (e) {
        console.error("Error adding document: ", e);
      }
    } else {
      setDbUserData({
        ...dbUserData,
        shipments: [shipment],
      });
      try {
        const updateAllShipments = httpsCallable(
          functions,
          "dbUsersOnCall-updateAllShipments"
        );
        updateAllShipments({
          shipments: [shipment],
        }).then((result) => {
          //
          setAddShipmentLoading(false);
          setNewShipment(false);
          setShipment({ country: "", price: 0, currency: "EUR" });
        });
      } catch (e) {
        console.error("Error adding document: ", e);
      }
    }
  };

  return (
    <div className="addNewShipment">
      <h1>
        <i
          className="backarrow fal fa-long-arrow-left"
          onClick={() => handleBackPress()}
        ></i>
        &nbsp;&nbsp;
        {editing
          ? t("AddNewShipment.EditShipment")
          : t("AddNewShipment.NewShipment")}
      </h1>
      <h3 className="fw-300 mt-10 mb-10">{t("AddNewShipment.COUNTRY")}:</h3>
      <div style={{ width: "250px" }}>
        <CountrySelector value={shipment.country} onChange={handleField} />
      </div>
      <h3 className="fw-300 mt-10 mb-10">{t("AddNewShipment.PRICE")}:</h3>
      <CanvasInput
        name="price"
        type="number"
        value={shipment.price}
        onChange={handleField}
        placeholder={t("AddNewShipment.PRICE")}
      />
      <h3 className="fw-300 mt-10 mb-10">
        {t("AddNewShipment.DELIVERYTIME")}:
      </h3>
      <CanvasInput
        name="deliveryTime"
        type="text"
        value={shipment.deliveryTime}
        onChange={handleDeliveryTime}
        placeholder={t("AddNewShipment.DELIVERYTIME")}
      />
      <h3 className="fw-300 mt-10 mb-10">{t("AddNewShipment.CURRENCY")}:</h3>
      <CanvasInput
        name="price"
        type="text"
        value={shipment.currency}
        onChange={handleField}
        placeholder={t("AddNewShipment.CURRENCY")}
        readOnly={true}
      />
      <div className="mt-30">
        {editing ? (
          <>
            <Button
              variant="contained"
              component="label"
              style={{
                marginBottom: "10px",
                borderColor: "#2e313f",
                color: "#fff",
                backgroundColor: "#1890FF",
                width: "250px",
              }}
              onClick={() => handleShipmentUpdate()}
            >
              {t("AddNewShipment.Update")}
            </Button>
            &nbsp; &nbsp; &nbsp;
            <Button
              variant="contained"
              component="label"
              style={{
                marginBottom: "10px",
                borderColor: "#2e313f",
                color: "#161C24",
                backgroundColor: "#FFC107",
                width: "250px",
              }}
              onClick={() => handleCancel()}
            >
              {t("AddNewShipment.Cancel")}
            </Button>
          </>
        ) : (
          <Button
            variant="contained"
            component="label"
            style={{
              marginBottom: "10px",
              borderColor: "#2e313f",
              color: "#fff",
              backgroundColor: "#1890FF",
              width: "250px",
            }}
            onClick={() => handleShipmentAdd()}
          >
            {t("AddNewShipment.Submit")}
          </Button>
        )}
      </div>
      {addShipmentLoading ? <InnerLoader /> : null}
    </div>
  );
};

export default AddNewShipment;
