import React from "react";
import { Button } from "@material-ui/core";

const BelowActionButtons = ({
  editing,
  setEditing,
  handleProductUpdate,
  handleProductSubmit,
}) => {
  return (
    <div className="mt-10">
      {editing ? (
        <>
          <Button
            variant="contained"
            component="label"
            style={{
              marginBottom: "10px",
              borderColor: "#2e313f",
              color: "#fff",
              backgroundColor: "#1890FF",
              width: "250px",
            }}
            onClick={() => handleProductUpdate()}
          >
            Update
          </Button>
          &nbsp; &nbsp; &nbsp;
          <Button
            variant="contained"
            component="label"
            style={{
              marginBottom: "10px",
              borderColor: "#2e313f",
              color: "#161C24",
              backgroundColor: "#FFC107",
              width: "250px",
            }}
            onClick={() => setEditing(false)}
          >
            Cancel
          </Button>
        </>
      ) : (
        <Button
          variant="contained"
          component="label"
          style={{
            marginBottom: "10px",
            borderColor: "#2e313f",
            color: "#fff",
            backgroundColor: "#1890FF",
            width: "250px",
          }}
          onClick={() => handleProductSubmit()}
        >
          Submit
        </Button>
      )}
    </div>
  );
};

export default BelowActionButtons;
