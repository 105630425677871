/* eslint-disable no-unused-vars */
import { Typography } from "@material-ui/core";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import CustomSelect from "../customSelect";
import { useTranslation } from "react-i18next";

const TextAlignmentsOptions = ({ save, saveBack }) => {
  const { t } = useTranslation();
  const _home = useSelector((state) => state.home);
  const [textAlign, setTextAlign] = useState([
    t("TextAlignment.Left"),
    t("TextAlignment.Center"),
    t("TextAlignment.Right"),
    t("TextAlignment.Justify"),
    t("TextAlignment.Justify-left"),
    t("TextAlignment.Justify-right"),
    t("TextAlignment.Justify-center"),
  ]);

  const setActiveProp = (name, value) => {
    var object = _home.currentCanvas.getActiveObject();
    if (!object) return;
    object.set(name, value).setCoords();
    _home.currentCanvas.renderAll();
    if (_home.face === "front") {
      save();
    } else {
      saveBack();
    }
  };

  const changeTextAlign = (ta) => {
    setActiveProp("textAlign", ta.toLowerCase());
  };
  return (
    <div>
      <Typography id="input-slider" gutterBottom>
        Text Align:
      </Typography>
      <CustomSelect options={textAlign} onChange={changeTextAlign} />
    </div>
  );
};

export default TextAlignmentsOptions;
