import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { filterConstants } from "../../../../../redux/constants";
import CustomCheckBox from "../../../../customCheckBox";
import { useTranslation } from "react-i18next";
const { TECHNICOLOR } = filterConstants;

const TechnicolorFilter = ({ applyFilter, fabric }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const _filter = useSelector((state) => state.filter);
  const applyTechnicolor = (check) => {
    dispatch({
      type: TECHNICOLOR,
      payload: check,
    });
    applyFilter(14, check && new fabric.Image.filters.Technicolor());
  };
  return (
    <div>
      <CustomCheckBox
        lable={t("TechnicolorFilter.Technicolor")}
        checked={_filter.technicolor}
        onClick={(e) => applyTechnicolor(e.target.checked)}
      />
    </div>
  );
};

export default TechnicolorFilter;
