import { orderConstants } from "../constants";
const {
  STUDIO_ORDERS,
  STUDIO_ORDERS_LOADING,
  ORDERS,
  ORDERS_LOADING,
  MY_ORDERS,
  MY_ORDERS_LOADING,
  PRODUCTS,
  PRODUCTS_LOADING,
  PRODUCT_DELETE_LOADING,
  ALL_ORDERS,
} = orderConstants;
const initialState = {
  // add
  studioOrdersLoading: false,
  studioOrders: [],
  ordersLoading: false,
  orders: [],
  allOrders: [],
  myOrdersLoading: false,
  myOrders: [],
  productsLoading: false,
  products: [],
  productDeletLoading: false,
};

export const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case STUDIO_ORDERS_LOADING:
      return {
        ...state,
        studioOrdersLoading: action.payload,
      };
    case STUDIO_ORDERS:
      return {
        ...state,
        studioOrders: action.payload,
      };
    case ORDERS_LOADING:
      return {
        ...state,
        ordersLoading: action.payload,
      };
    case ORDERS:
      return {
        ...state,
        orders: action.payload,
      };
    case MY_ORDERS_LOADING:
      return {
        ...state,
        myOrdersLoading: action.payload,
      };
    case MY_ORDERS:
      return {
        ...state,
        myOrders: action.payload,
      };
    case PRODUCTS_LOADING:
      return {
        ...state,
        productsLoading: action.payload,
      };
    case PRODUCTS:
      return {
        ...state,
        products: action.payload,
      };
    case PRODUCT_DELETE_LOADING:
      return {
        ...state,
        productDeletLoading: action.payload,
      };
    case ALL_ORDERS:
      return {
        ...state,
        allOrders: action.payload,
      };
    default:
      return state;
  }
};
