/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import SimpleInput from "../simpleInput";
import OrderTable from "./myOrdersTable";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../contexts/AuthContext";
import OrderDetail from "./orderDetail";
import "./orders.css";
import { useSelector } from "react-redux";
import SearchByStatus from "../orders/components/searchByStatus";

const MyOrders = () => {
  const { t } = useTranslation();
  const _order = useSelector((state) => state.order);
  const [searchOrderIdText, setSearchOrderIdText] = useState("");
  const [searchStatusText, setSearchStatusText] = useState("");
  const [ordersGloble, setOrdersGloble] = useState([]);
  const [orders, setOrders] = useState([]);
  const [selectedOrderIndex, setSelectedOrderIndex] = useState(null);
  const [ordersLoading, setOrdersLoading] = useState(false);
  const [showOrderDetail, setShowOrderDetail] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [orderFilter, setOrderFilter] = useState({
    orderId: "",
    status: "",
    city: "",
    userId: "",
  });

  useEffect(() => {
    setOrders(
      _order.myOrders.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      )
    );
    setOrdersGloble(
      _order.myOrders.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      )
    );
  }, [_order.myOrders]);

  const searchByOrderId = (searchText) => {
    setSearchOrderIdText(searchText);
    if (searchText === "") {
      setOrders(ordersGloble);
    } else {
      setOrders(
        orders.filter((x) =>
          x?.orderID?.toLowerCase().includes(searchText.toLowerCase())
        )
      );
    }
  };
  const searchByStatus = (searchText) => {
    setSearchStatusText(searchText);
    if (searchText === "") {
      setOrders(ordersGloble);
    } else {
      setOrders(
        ordersGloble.filter((x) =>
          x?.status?.toLowerCase().includes(searchText.toLowerCase())
        )
      );
    }
  };
  return showOrderDetail ? (
    <OrderDetail
      selectedOrder={selectedOrder}
      setShowOrderDetail={setShowOrderDetail}
      selectedOrderIndex={selectedOrderIndex}
      setSelectedOrder={setSelectedOrder}
    />
  ) : (
    <div className="orders">
      <h1>{t("MyOrders.OrderList")}</h1>
      <div className="filters-wrapper">
        <SimpleInput
          name="orderId"
          value={searchOrderIdText}
          placeholder={t("MyOrders.SearchByOrderID")}
          onChange={(e) => searchByOrderId(e.target.value)}
        />
        &nbsp; &nbsp;
        {/* <SimpleInput
          name="status"
          value={searchStatusText}
          placeholder={t("MyOrders.SearchByStatus")}
          onChange={(e) => searchByStatus(e.target.value)}
        /> */}
        <SearchByStatus
          name="status"
          value={searchStatusText}
          placeholder={t("Orders.SearchByStatus")}
          onChange={searchByStatus}
        />
      </div>
      <br />
      {/* table */}
      <OrderTable
        orders={orders}
        setShowOrderDetail={setShowOrderDetail}
        setSelectedOrder={setSelectedOrder}
        setSelectedOrderIndex={setSelectedOrderIndex}
      />
    </div>
  );
};

export default MyOrders;
