/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useAuth } from "../../../../contexts/AuthContext";
import { fabric } from "fabric";
import CustomButton from "../customButton";
import { Button, makeStyles, Paper, Typography } from "@material-ui/core";
import CustomSelect from "../../../leftPanel3/components/customSelect";
import { useSelector } from "react-redux";
import IconButton from "../../../iconButton";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  paper: {
    backgroundColor: "#212B36",
    marginBottom: "10px",
    color: "white",
    textAlign: "left",
  },
});

const TextOptions = ({ save, handlelP2OptionSelect, saveBack }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const _home = useSelector((state) => state.home);
  const { currentUser } = useAuth();
  const [bold, setBold] = useState("normal");
  const [italic, setItalic] = useState("normal");
  const [underline, setUnderline] = useState(false);
  const [overline, setOverline] = useState(false);
  const [linethrough, setLinethrough] = useState(false);

  const [textAlign, setTextAlign] = useState([
    t("TextAlignment.Left"),
    t("TextAlignment.Center"),
    t("TextAlignment.Right"),
    t("TextAlignment.Justify"),
    t("TextAlignment.Justify-left"),
    t("TextAlignment.Justify-right"),
    t("TextAlignment.Justify-center"),
  ]);

  const setActiveStyle = (styleName, value, object) => {
    object = object || _home.currentCanvas.getActiveObject();
    if (!object) return;

    if (object.setSelectionStyles && object.isEditing) {
      var style = {};
      style[styleName] = value;
      object.setSelectionStyles(style);
      object.setCoords();
    } else {
      object.set(styleName, value);
    }
    object.setCoords();
    _home.currentCanvas.requestRenderAll();
    if (currentUser) {
      if (_home.face === "front") {
        save();
      } else {
        saveBack();
      }
    }
  };

  const setActiveProp = (name, value) => {
    var object = _home.currentCanvas.getActiveObject();
    if (!object) return;
    object.set(name, value).setCoords();
    _home.currentCanvas.renderAll();
    if (_home.face === "front") {
      save();
    } else {
      saveBack();
    }
  };

  const changeTextAlign = (ta) => {
    setActiveProp("textAlign", ta.toLowerCase());
  };

  const addIText = () => {
    var text = "Put in your text here";
    var textSample = new fabric.IText(text, {
      left: 50,
      top: 50,
      fontFamily: "arial",
      fill: "#000000",
      stroke: "#000000",
      fontSize: 38,
      backgroundColor: "#ffffff00",
      textBackgroundColor: "#ffffff00",
      scaleX: 0.5,
      scaleY: 0.5,
      originX: "left",
      hasRotatingPoint: true,
      centerTransform: true,
    });
    _home.currentCanvas.add(textSample);
    _home.currentCanvas.requestRenderAll();
    if (currentUser) {
      if (_home.face === "front") {
        save();
      } else {
        saveBack();
      }
    }
  };

  // New UnderLine Code Start
  const addHandler = (fn, eventName) => {
    var el = this;
    let obj;
    if ((obj = _home.currentCanvas.getActiveObject())) {
      fn.call(el, obj);
      _home.currentCanvas.renderAll();
    }
  };

  function setStyle(object, styleName, isSettingApplied) {
    var style = {};
    if (styleName === "fontWeight" || styleName === "fontStyle") {
      style[styleName] = isSettingApplied;
      object.setSelectionStyles(style);
    } else {
      style[styleName] = !isSettingApplied;
      object.setSelectionStyles(style);
    }
  }

  // Get the style
  function dtGetStyle(object, styleName) {
    return object[styleName];
  }

  // Set the style
  function dtSetStyle(object, styleName, value) {
    if (styleName === "fontWeight" || styleName === "fontStyle") {
      object[styleName] = value;
      object.set({ dirty: true });
      _home.currentCanvas.renderAll();
    } else {
      object[styleName] = !value;
      object.set({ dirty: true });
      _home.currentCanvas.renderAll();
    }
  }

  const handleTextStyle = (obj, setting) => {
    if (obj.get("type") === "i-text") {
      if (obj.getSelectionStyles().length > 0) {
        // single characters selected
        if (setting === "fontWeight") {
          var isSettingApplied =
            obj.getSelectionStyles()[0][setting] && obj.isEditing
              ? true
              : false;
          setStyle(obj, setting, isSettingApplied ? "normal" : "bold");
        } else if (setting === "fontStyle") {
          var isSettingApplied5 =
            obj.getSelectionStyles()[0][setting] && obj.isEditing
              ? true
              : false;
          setStyle(obj, setting, isSettingApplied5 ? "normal" : "italic");
        } else {
          var isSettingApplied1 =
            obj.getSelectionStyles()[0][setting] && obj.isEditing
              ? true
              : false;
          setStyle(obj, setting, isSettingApplied1);
        }
      } else {
        // full element selected
        let nA = [];
        Object.entries(obj.styles).forEach(([key, value]) => {
          Object.entries(value).forEach(([key, value]) => {
            Object.entries(value).forEach(([k, value]) => {
              if (k !== setting) {
                if (k === "fontWeight") {
                  nA[key] = { fontWeight: value };
                } else if (k === "fontStyle") {
                  nA[key] = { fontStyle: value };
                } else if (k === "underline") {
                  nA[key] = { underline: value };
                } else if (k === "overline") {
                  nA[key] = { overline: value };
                } else if (k === "linethrough") {
                  nA[key] = { linethrough: value };
                }
              }
            });
          });
        });
        let styleObj = Object.assign({}, nA);
        obj.styles = { 0: styleObj };
        if (setting === "fontWeight") {
          var isSettingApplied2 = dtGetStyle(obj, setting);
          let fb;
          if (isSettingApplied2 === "normal") {
            fb = false;
          } else {
            fb = true;
          }
          dtSetStyle(obj, setting, fb ? "normal" : "bold");
          setBold(fb ? "normal" : "bold");
        } else if (setting === "fontStyle") {
          var isSettingApplied3 = dtGetStyle(obj, setting);
          let fi;
          if (isSettingApplied3 === "normal") {
            fi = false;
          } else {
            fi = true;
          }
          dtSetStyle(obj, setting, fi ? "normal" : "italic");
          setItalic(fi ? "normal" : "italic");
        } else {
          var isSettingApplied4 = dtGetStyle(obj, setting) ? true : false;
          dtSetStyle(obj, setting, isSettingApplied4 ? true : false);
          if (setting === "underline") {
            setUnderline(isSettingApplied4 ? false : true);
          } else if (setting === "overline") {
            setOverline(isSettingApplied4 ? false : true);
          } else if (setting === "linethrough") {
            setLinethrough(isSettingApplied4 ? false : true);
          }
        }
      }
    }
    if (currentUser) {
      if (_home.face === "front") {
        save();
      } else {
        saveBack();
      }
    }
  };
  // New UnderLine Code End

  useEffect(() => {
    if (_home.currentCanvas) {
      _home.currentCanvas.on({
        "selection:updated": HandleElement,
        "selection:created": HandleElement,
      });
      _home.currentCanvas.on("before:selection:cleared", function () {
        var clearedObject;
        if (typeof _home.currentCanvas.getActiveObject() !== "undefined") {
          clearedObject = _home.currentCanvas.getActiveObject();
          console.log('clearedObject.get("type")', clearedObject.get("type"));
          console.log("clearedObject.text", clearedObject.text);
          if (clearedObject.get("type") === "i-text") {
            if (clearedObject.text === "") {
              _home.currentCanvas.remove(clearedObject);
            }
          }
        }
      });
    }
  }, [_home.currentCanvas]);

  function HandleElement(obj) {
    // bold
    if (obj.target["fontWeight"] === "bold") {
      setBold("bold");
    } else {
      setBold("normal");
    }
    // italic
    if (obj.target["fontStyle"] === "italic") {
      setItalic("italic");
    } else {
      setItalic("normal");
    }
    // underline
    if (obj.target["underline"]) {
      setUnderline(true);
    } else {
      setUnderline(false);
    }
    // overline
    if (obj.target["overline"]) {
      setOverline(true);
    } else {
      setOverline(false);
    }
    // linethrough
    if (obj.target["linethrough"]) {
      setLinethrough(true);
    } else {
      setLinethrough(false);
    }
    // console.log(obj.target["underline"]);
  }

  return (
    <div className="edit-options">
      <IconButton text={t("TextOptions.AddText")} onClick={() => addIText()}>
        <i className="ri-file-text-line"></i>
      </IconButton>
      <IconButton
        text={t("TextOptions.TextColors")}
        onClick={() => handlelP2OptionSelect("TextOptions.TextColors")}
      >
        <i className="ri-paint-brush-line"></i>
      </IconButton>
      <IconButton
        text={t("TextOptions.Fonts")}
        onClick={() => handlelP2OptionSelect("TextOptions.Fonts")}
      >
        <i className="ri-font-size-2"></i>
      </IconButton>
      <Typography id="input-slider" style={{ textAlign: "left" }} gutterBottom>
        {t("TextOptions.TextAlign")}:
      </Typography>
      <CustomSelect mb="10px" options={textAlign} onChange={changeTextAlign} />
      <Paper className={classes.paper}>
        <Button
          style={{
            border: "1px solid #353948",
            backgroundColor: bold === "bold" ? "#353948" : "",
            color: "white",
            fontWeight: "bold",
            margin: "5px",
          }}
          onClick={() =>
            addHandler(function (obj) {
              handleTextStyle(obj, "fontWeight");
            })
          }
        >
          {t("TextOptions.Bold")}
        </Button>
        <Button
          style={{
            border: "1px solid #353948",
            color: "white",
            backgroundColor: italic === "italic" ? "#353948" : "",
            fontStyle: "italic",
            margin: "5px",
          }}
          onClick={() =>
            addHandler(function (obj) {
              handleTextStyle(obj, "fontStyle");
            })
          }
        >
          {t("TextOptions.Italic")}
        </Button>
        <Button
          style={{
            border: "1px solid #353948",
            color: "white",
            backgroundColor: underline ? "#353948" : "",
            textDecoration: "underline",
            margin: "5px",
          }}
          onClick={() =>
            addHandler(function (obj) {
              handleTextStyle(obj, "underline");
            })
          }
        >
          {t("TextOptions.Underline")}
        </Button>
        <Button
          style={{
            border: "1px solid #353948",
            color: "white",
            backgroundColor: overline ? "#353948" : "",
            textDecoration: "overline",
            margin: "5px",
          }}
          onClick={() =>
            addHandler(function (obj) {
              handleTextStyle(obj, "overline");
            })
          }
        >
          {t("TextOptions.Overline")}
        </Button>
        <Button
          style={{
            border: "1px solid #353948",
            color: "white",
            backgroundColor: linethrough ? "#353948" : "",
            textDecoration: "line-through",
            margin: "5px",
          }}
          onClick={() =>
            addHandler(function (obj) {
              handleTextStyle(obj, "linethrough");
            })
          }
        >
          {t("TextOptions.Line")}
        </Button>
      </Paper>
      <div style={{ textAlign: "left" }}></div>
    </div>
  );
};

export default TextOptions;
