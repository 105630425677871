import React from "react";
import { calcCf } from "../../../../../util";
import "./mainVarientCard.css";

const MainVarientCard = ({
  i,
  product,
  setProduct,
  setSelectedVarient,
  setSelectedVarientIndex,
}) => {
  const handleVarientCloneButton = () => {
    var newArray = [...product.variants];
    let n = { ...newArray[i], id: new Date().getTime() };
    newArray.splice(i + 1, 0, n);
    setProduct({ ...product, variants: newArray });
  };
  const handleVarientPlusButton = () => {
    var newArray = [...product.variants];
    newArray.splice(i + 1, 0, {
      id: new Date().getTime(),
      imageURL: null,
      color: "#000000",
      name: "",
      // main sub varient
      subVariants: [
        {
          id: new Date().getTime() + 1,
          childSKU: "",
          currency: "Eur",
          inStock: true,
          name: "",
          price: 0,
          size: "",
          quantityInStock: "",
        },
      ],
    });
    setProduct({ ...product, variants: newArray });
  };
  const handleVarientDeleteButton = (index) => {
    let newArray = product.variants.filter((c, i) => i !== index);
    setProduct({ ...product, variants: newArray });
  };
  const handleVariantSelect = () => {
    setSelectedVarient(true);
    setSelectedVarientIndex(i);
  };

  return (
    <div className="mainVarientCard">
      <p className="mainVarient-name">
        {product.variants[i].name ? product.variants[i].name : "Name"}{" "}
      </p>
      <div className="varient-image">
        <img
          src={
            product.variants[i].imageURL
              ? product.variants[i].imageURL.url
              : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRXLUSQTsvEWvFsaGTxqLq9s8CwrzjJHOtUkA&usqp=CAU"
          }
          width={
            product.variants[i].imageURL
              ? product.variants[i].imageURL.width /
                calcCf(
                  product.variants[i].imageURL.width,
                  product.variants[i].imageURL.height,
                  100
                )
              : null
          }
          height={
            product.variants[i].imageURL
              ? product.variants[i].imageURL.height /
                calcCf(
                  product.variants[i].imageURL.width,
                  product.variants[i].imageURL.height,
                  100
                )
              : null
          }
          alt="varimg"
          onClick={() => handleVariantSelect()}
        />
      </div>
      <div className="mainVarient-color">
        <div
          className="varient-color"
          style={{ background: product.variants[i].color }}
        ></div>
        <div>
          <i
            className="ri-file-add-line"
            onClick={() => handleVarientPlusButton()}
          ></i>
          &nbsp;
          <i
            className="ri-file-copy-line"
            onClick={() => handleVarientCloneButton()}
          ></i>
          &nbsp;
          <i
            className="ri-pencil-line"
            onClick={() => handleVariantSelect()}
          ></i>
          &nbsp;
          <i
            className="ri-delete-bin-line"
            onClick={() => handleVarientDeleteButton(i)}
          ></i>
        </div>
      </div>
    </div>
  );
};

export default MainVarientCard;
