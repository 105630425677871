import React from "react";
import { useTranslation } from "react-i18next";
import "./customSelect.css";

const CustomSelect = ({ mb, options, onChange }) => {
  const { t } = useTranslation();
  return (
    <div style={mb ? { marginBottom: mb } : null}>
      <select
        className="customSelect"
        defaultValue={options[0]}
        onChange={(e) => onChange(e.target.value)}
      >
        <option disabled value={t("TextOptions.Select")}>
          {t("TextOptions.Select")}
        </option>
        {options.map((option, i) => (
          <option style={{ fontFamily: option }} key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};

export default CustomSelect;
