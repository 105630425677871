import React, { useEffect, useState } from "react";
import { fabric } from "fabric";
import { Checkbox, makeStyles, Paper } from "@material-ui/core";
// import CustomCheckBox from "../../../customCheckBox";
import CustomSlider from "../../../customSlider";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  paper: {
    backgroundColor: "#212B36",
    padding: "10px",
    marginBottom: "10px",
    color: "white",
  },
});

const AlternateOptions = ({ save, saveBack }) => {
  const { t } = useTranslation();
  const _home = useSelector((state) => state.home);
  const classes = useStyles();
  // filters start
  const [hueApl, setHueApl] = useState(false);
  const [blurApl, setBlurApl] = useState(false);
  const [pixelateApl, setPixelateApl] = useState(false);
  // filters end
  const [noise, setNoise] = useState(100);
  const [pixelate, setPixelate] = useState(4);
  const [blur, setBlur] = useState(0.1);

  const applyFilter = (index, filter) => {
    var obj = _home.currentCanvas.getActiveObject();
    if (obj) {
      obj.filters[index] = filter;
      obj.applyFilters();
      _home.currentCanvas.requestRenderAll();
    }
    if (_home.face === "front") {
      save();
    } else {
      saveBack();
    }
  };

  const applyFilterValue = (index, prop, value) => {
    var obj = _home.currentCanvas.getActiveObject();
    if (obj.filters[index]) {
      obj.filters[index][prop] = value;
      obj.applyFilters();
      _home.currentCanvas.requestRenderAll();
    }
    if (_home.face === "front") {
      save();
    } else {
      saveBack();
    }
  };

  const applyHue = (check) => {
    setHueApl(check);
    applyFilter(
      9,
      check &&
        new fabric.Image.filters.Noise({
          noise: parseInt(noise, 10),
        })
    );
  };

  const handleNoiseValueChange = (event, newValue) => {
    if (newValue < 0) {
      setNoise(0);
    } else if (newValue > 1000) {
      setNoise(1000);
    } else {
      setNoise(newValue);
    }
    applyFilterValue(9, "noise", parseInt(newValue, 10));
  };

  const applyPixelate = (check) => {
    setPixelateApl(check);
    applyFilter(
      10,
      check &&
        new fabric.Image.filters.Pixelate({
          blocksize: parseInt(pixelate, 10),
        })
    );
  };
  const handlePixelateValueChange = (event, newValue) => {
    setPixelate(newValue);
    applyFilterValue(10, "blocksize", parseInt(newValue, 10));
  };

  const applyBlur = (check) => {
    setBlurApl(check);
    applyFilter(
      11,
      check &&
        new fabric.Image.filters.Blur({
          value: parseFloat(blur),
        })
    );
  };

  const handleBlurValueChange = (event, newValue) => {
    setBlur(newValue);
    applyFilterValue(11, "blur", parseFloat(newValue, 10));
  };

  useEffect(() => {
    if (_home.currentCanvas) {
      _home.currentCanvas.on({
        "selection:updated": HandleElement,
        "selection:created": HandleElement,
      });
    }
  }, [_home.currentCanvas]);
  function HandleElement(obj) {
    if (obj.target.filters) {
      // HueApl
      if (obj.target.filters[9]) {
        setHueApl(true);
      } else {
        setHueApl(false);
      }
      // BlurApl
      if (obj.target.filters[11]) {
        setBlurApl(true);
      } else {
        setBlurApl(false);
      }
      // PixelateApl
      if (obj.target.filters[10]) {
        setPixelateApl(true);
      } else {
        setPixelateApl(false);
      }
    } else {
      setHueApl(false);
      setBlurApl(false);
      setPixelateApl(false);
    }
  }

  return (
    <div className="edit-options">
      <Paper className={classes.paper}>
        {t("AlternateOptions.Noise")}
        <Checkbox
          style={{ color: "#1CCAFF" }}
          color="primary"
          checked={hueApl}
          onClick={(e) => applyHue(e.target.checked)}
          inputProps={{ "aria-label": "secondary checkbox" }}
        />
        {/* <CustomCheckBox onClick={applyHue} /> */}
        <CustomSlider
          title={t("AlternateOptions.Value")}
          value={noise}
          min={0}
          max={1000}
          step={1}
          handleChange={handleNoiseValueChange}
        />
      </Paper>
      <Paper className={classes.paper}>
        {t("AlternateOptions.Pixelate")}
        <Checkbox
          style={{ color: "#1CCAFF" }}
          color="primary"
          checked={pixelateApl}
          onClick={(e) => applyPixelate(e.target.checked)}
          inputProps={{ "aria-label": "secondary checkbox" }}
        />
        {/* <CustomCheckBox onClick={applyPixelate} /> */}
        <CustomSlider
          title={t("AlternateOptions.Value")}
          value={pixelate}
          min={2}
          max={20}
          step={1}
          handleChange={handlePixelateValueChange}
        />
      </Paper>
      <Paper className={classes.paper}>
        {t("AlternateOptions.Blur")}
        <Checkbox
          style={{ color: "#1CCAFF" }}
          color="primary"
          checked={blurApl}
          onClick={(e) => applyBlur(e.target.checked)}
          inputProps={{ "aria-label": "secondary checkbox" }}
        />
        {/* <CustomCheckBox onClick={applyBlur} /> */}
        <CustomSlider
          title={t("AlternateOptions.Value")}
          value={blur}
          min={0}
          max={1}
          step={0.01}
          handleChange={handleBlurValueChange}
        />
      </Paper>
    </div>
  );
};

export default AlternateOptions;
