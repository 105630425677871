import React, { useEffect, useState } from "react";
import { fabric } from "fabric";
import Header from "../../components/header";
import MidleArea from "../../components/midleArea";
import LeftPanel1 from "../../components/leftPanel1";
import LeftPanel2 from "../../components/leftPanel2";
import RightPanel from "../../components/rightPanel";
// import FooterPanel from "../../components/footerPanel";
import "./home.css";
import $ from "jquery";
import LeftPanel3 from "../../components/leftPanel3";
import { useAuth } from "../../contexts/AuthContext";
import { useSelector, useDispatch } from "react-redux";
import { homeConstants } from "../../redux/constants";
import { Button, makeStyles, Modal } from "@material-ui/core";
import { useTranslation } from "react-i18next";
const { FIRST_COME_ONE_SITE } = homeConstants;

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    background: "#637381",
  };
}
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const Home = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  const _home = useSelector((state) => state.home);
  const { currentUser } = useAuth();
  const [clipboard, setClipboard] = useState(null);
  const copy = () => {
    if (_home.currentCanvas.getActiveObject()) {
      _home.currentCanvas.getActiveObject().clone(function (cloned) {
        setClipboard(cloned);
      });
    }
  };
  const paste = () => {
    if (clipboard) {
      // clone again, so you can do multiple copies.
      clipboard.clone(function (clonedObj) {
        _home.currentCanvas.discardActiveObject();
        clonedObj.set({
          left: clonedObj.left + 10,
          top: clonedObj.top + 10,
          evented: true,
        });
        if (clonedObj.type === "activeSelection") {
          // active selection needs a reference to the currentCanvas.
          clonedObj.currentCanvas = _home.currentCanvas;
          clonedObj.forEachObject(function (obj) {
            _home.currentCanvas.add(obj);
          });
          // this should solve the unselectability
          clonedObj.setCoords();
        } else {
          _home.currentCanvas.add(clonedObj);
        }
        // _clipboard.top += 10;
        // _clipboard.left += 10;
        clipboard.set({
          left: clipboard.left + 10,
          top: clipboard.top + 10,
        });
        _home.currentCanvas.setActiveObject(clonedObj);
      });
    }
  };
  // undo / redo start
  var state;
  var stateBack;
  var undo = [];
  var undoBack = [];
  var redo = [];
  var redoBack = [];
  if (!state) {
    var statelocal = localStorage.getItem("state");
    state = JSON.parse(statelocal);
    var undolocal = localStorage.getItem("undo");
    undo = JSON.parse(undolocal);
    var redolocal = localStorage.getItem("redo");
    redo = JSON.parse(redolocal);
  }
  if (!stateBack) {
    stateBack = JSON.parse(localStorage.getItem("stateBack"));
    undoBack = JSON.parse(localStorage.getItem("undoBack"));
    redoBack = JSON.parse(localStorage.getItem("redoBack"));
  }
  function save() {
    redo = [];
    localStorage.setItem("redo", JSON.stringify(redo));
    $("#redo").prop("disabled", true);
    if (state) {
      if (undo?.length < 5) {
        if (undo.indexOf(state) === -1) undo.push(state);
        let undolocal = JSON.parse(localStorage.getItem("undo"));
        if (undolocal.indexOf(state) === -1) undolocal.push(state);
        localStorage.setItem("undo", JSON.stringify(undolocal));
        $("#undo").prop("disabled", false);
      } else {
        let newArray = undo.filter((item, i) => i !== 0);
        if (newArray.indexOf(state) === -1) newArray.push(state);
        undo = newArray;
        let tempUndolocal = JSON.parse(localStorage.getItem("undo"));
        let undolocal = tempUndolocal.filter((item, i) => i !== 0);
        if (undolocal.indexOf(state) === -1) undolocal.push(state);
        localStorage.setItem("undo", JSON.stringify(undolocal));
      }
    }
    state = JSON.stringify(_home.frontCanvas);
    localStorage.setItem("state", JSON.stringify(state));
    if (state !== '{"version":"4.6.0","objects":[],"background":"white"}') {
      if (state !== '{"version":"4.6.0","objects":[]}') {
        localStorage.setItem("RFrontState", JSON.stringify(state));
      }
    }
  }
  function saveBack() {
    redoBack = [];
    localStorage.setItem("redoBack", JSON.stringify(redoBack));
    $("#redoback").prop("disabled", true);
    if (stateBack) {
      if (undoBack.length < 5) {
        if (undoBack.indexOf(stateBack) === -1) {
          undoBack.push(stateBack);
        }
        localStorage.setItem("undoBack", JSON.stringify(undoBack));
        $("#undoback").prop("disabled", false);
      } else {
        let newArray = undoBack.filter((item, i) => i !== 0);
        if (newArray.indexOf(stateBack) === -1) {
          newArray.push(stateBack);
        }
        undoBack = newArray;
        let undolocal = JSON.parse(localStorage.getItem("undoBack")).filter(
          (item, i) => i !== 0
        );
        if (undolocal.indexOf(stateBack) === -1) {
          undolocal.push(stateBack);
        }
        localStorage.setItem("undoBack", JSON.stringify(undolocal));
      }
    }
    stateBack = JSON.stringify(_home.backCanvas);
    localStorage.setItem("stateBack", JSON.stringify(stateBack));
    if (stateBack !== '{"version":"4.6.0","objects":[],"background":"white"}') {
      localStorage.setItem("RBackState", JSON.stringify(stateBack));
    }
  }
  function undoReplay(undo, redo, buttonsOn, buttonsOff) {
    redo.push(state);
    localStorage.setItem("redo", JSON.stringify(redo));
    state = undo.pop();
    localStorage.setItem("state", JSON.stringify(state));
    var on = $(buttonsOn);
    var off = $(buttonsOff);
    on.prop("disabled", true);
    off.prop("disabled", true);
    if (state) {
      _home.frontCanvas.clear();
      _home.frontCanvas.loadFromJSON(state, function () {
        _home.frontCanvas.renderAll();
        on.prop("disabled", false);
        if (undo.length) {
          off.prop("disabled", false);
        }
      });
    }
  }
  function undoReplayBack(undo, redo, buttonsOn, buttonsOff) {
    redo.push(stateBack);
    localStorage.setItem("redoBack", JSON.stringify(redo));
    stateBack = undo.pop();
    localStorage.setItem("stateBack", JSON.stringify(stateBack));
    var on = $(buttonsOn);
    var off = $(buttonsOff);
    on.prop("disabled", true);
    off.prop("disabled", true);
    if (stateBack) {
      _home.backCanvas.clear();
      _home.backCanvas.loadFromJSON(stateBack, function () {
        _home.backCanvas.renderAll();
        on.prop("disabled", false);
        if (undo.length) {
          off.prop("disabled", false);
        }
      });
    }
  }
  function redoReplay(redo, undo, buttonsOn, buttonsOff) {
    undo.push(state);
    localStorage.setItem("undo", JSON.stringify(undo));
    state = redo.pop();
    localStorage.setItem("state", JSON.stringify(state));
    var on = $(buttonsOn);
    var off = $(buttonsOff);
    on.prop("disabled", true);
    off.prop("disabled", true);
    if (state) {
      _home.frontCanvas.clear();
      _home.frontCanvas.loadFromJSON(state, function () {
        _home.frontCanvas.renderAll();
        on.prop("disabled", false);
        if (redo.length) {
          off.prop("disabled", false);
        }
      });
    }
  }
  function redoReplayBack(redo, undo, buttonsOn, buttonsOff) {
    undo.push(stateBack);
    localStorage.setItem("undoBack", JSON.stringify(undo));
    stateBack = redo.pop();
    localStorage.setItem("stateBack", JSON.stringify(stateBack));
    var on = $(buttonsOn);
    var off = $(buttonsOff);
    on.prop("disabled", true);
    off.prop("disabled", true);
    if (stateBack) {
      _home.backCanvas.clear();
      _home.backCanvas.loadFromJSON(stateBack, function () {
        _home.backCanvas.renderAll();
        on.prop("disabled", false);
        if (redo.length) {
          off.prop("disabled", false);
        }
      });
    }
  }
  if (_home.frontCanvas && currentUser) {
    $(function () {
      save();
      _home.frontCanvas.on("object:modified", function () {
        save();
      });
      $("#undo").click(function () {
        undoReplay(undo, redo, "#redo", this);
      });
      $("#redo").click(function () {
        redoReplay(redo, undo, "#undo", this);
      });
    });
  }
  if (_home.backCanvas && currentUser) {
    $(function () {
      saveBack();
      _home.backCanvas.on("object:modified", function () {
        saveBack();
      });
      $("#undoback").click(function () {
        undoReplayBack(undoBack, redoBack, "#redoback", this);
      });
      $("#redoback").click(function () {
        redoReplayBack(redoBack, undoBack, "#undoback", this);
      });
    });
  }
  useEffect(() => {
    // fabric WebGL initianization. TextureSize is amount of pixel in IMG length or width for filtering
    if (fabric.isWebglSupported() && fabric.maxTextureSize >= 8192) {
      fabric.textureSize = 4096;
      console.log("WebGL set to 4096");
      fabric.filterBackend = fabric.initFilterBackend();
    } else if (fabric.isWebglSupported() && fabric.maxTextureSize >= 16384) {
      fabric.textureSize = 8192;
      console.log("WebGL set to 8192");
      fabric.filterBackend = fabric.initFilterBackend();
    }
    localStorage.removeItem("state");
    localStorage.removeItem("undo");
    localStorage.removeItem("redo");
    localStorage.removeItem("stateBack");
    localStorage.removeItem("undoBack");
    localStorage.removeItem("redoBack");
    if (currentUser) {
      // front
      localStorage.setItem("state", null);
      const emptyArray = [];
      localStorage.setItem("undo", JSON.stringify(emptyArray));
      localStorage.setItem("redo", JSON.stringify(emptyArray));
      // back
      localStorage.setItem("stateBack", null);
      const emptyArrayBack = [];
      localStorage.setItem("undoBack", JSON.stringify(emptyArrayBack));
      localStorage.setItem("redoBack", JSON.stringify(emptyArrayBack));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // undo / redo end
  useEffect(() => {
    if (_home.currentCanvas) {
      // eslint-disable-next-line no-restricted-globals
      document.addEventListener("keyup", ({ key } = event) => {
        if (_home.face === "front") {
          save();
        }
        if (_home.face) {
          saveBack();
        }
        if (key === "Delete") {
          if (_home.currentCanvas) {
            const objs = _home.currentCanvas.getActiveObjects();
            if (objs.length > 0) {
              for (let i = 0; i < objs.length; i++) {
                if (!objs[i].isEditing) {
                  _home.currentCanvas.remove(objs[i]);
                }
              }
              _home.currentCanvas.discardActiveObject().renderAll();
            }
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_home.currentCanvas]);
  const handleHandleReloadCanvas = () => {
    var RFrontState = JSON.parse(localStorage.getItem("RFrontState"));
    var RBackState = JSON.parse(localStorage.getItem("RBackState"));
    if (RFrontState) {
      _home.frontCanvas?.clear();
      _home.frontCanvas?.loadFromJSON(RFrontState, function () {
        _home.frontCanvas?.renderAll();
      });
    }
    if (RBackState) {
      _home.backCanvas?.clear();
      _home.backCanvas?.loadFromJSON(RBackState, function () {
        _home.backCanvas?.renderAll();
      });
    }
    dispatch({
      type: FIRST_COME_ONE_SITE,
      payload: false,
    });
  };
  const handleCloseReloadCanvasPopup = () => {
    localStorage.removeItem("RFrontState");
    localStorage.removeItem("RBackState");
    dispatch({
      type: FIRST_COME_ONE_SITE,
      payload: false,
    });
  };
  return (
    <div className="home-main-wrapper">
      <>
        <Header />
        <LeftPanel1 />
        <div className="main-body-wrapper">
          <LeftPanel2 save={save} saveBack={saveBack} />
          <LeftPanel3 save={save} saveBack={saveBack} />
          <div>
            <MidleArea copy={copy} paste={paste} clipboard={clipboard} />
          </div>
          <RightPanel />
        </div>
      </>
      <Modal
        open={_home.firstComeOnSite}
        onClose={() => {}}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <h3 className="fw-300 mt-10 mb-10 canv-lable">
            {t("Home.ReloadState")}
          </h3>
          <div className="mt-20">
            <Button
              variant="contained"
              component="label"
              style={{
                marginBottom: "10px",
                borderColor: "#2e313f",
                color: "#fff",
                backgroundColor: "#1890FF",
                width: "100px",
              }}
              onClick={() => handleHandleReloadCanvas()}
            >
              Yes
            </Button>
            &nbsp; &nbsp; &nbsp;
            <Button
              variant="contained"
              component="label"
              style={{
                marginBottom: "10px",
                borderColor: "#2e313f",
                color: "#161C24",
                backgroundColor: "#FFC107",
                width: "100px",
              }}
              onClick={() => handleCloseReloadCanvasPopup()}
            >
              NO
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default Home;
