import React from "react";
import { useTranslation } from "react-i18next";
import "./panel3Header.css";

const Panel3Header = ({ lP1SelectedOption }) => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="panel3Header-main-wrapper">
        <h4>{t(lP1SelectedOption)}</h4>
      </div>
    </div>
  );
};

export default Panel3Header;
