import React from "react";
import "./iconButton.css";

const IconButton = ({ children, text, onClick, active }) => {
  return (
    <div
      className="iconButton"
      onClick={onClick}
      style={
        active ? { background: "#161c24", border: "1px solid #c4cdd5" } : null
      }
    >
      {/* <i className="ri-mark-pen-line iconButton-symbol"></i> */}
      {children ? <>{children}&nbsp;&nbsp;</> : null}
      {text}
    </div>
  );
};

export default IconButton;
