import React from "react";
import AddCanvas from "../addCanvas";

const ShowCanvases = ({ product, setProduct }) => {
  const handleCanvasChange = (e, i) => {
    let newArray = [...product.canvases];
    newArray[i] = { ...newArray[i], [e.target.name]: e.target.value };
    setProduct({ ...product, canvases: newArray });
  };
  const handleCanvasPlusButton = (i) => {
    if (product.canvases.length < 2) {
      var newArray = [...product.canvases];
      newArray.splice(i + 1, 0, {
        width: "",
        height: "",
      });
      setProduct({ ...product, canvases: newArray });
    }
  };
  const handleCanvasMinusButton = (index) => {
    let newArray = product.canvases.filter((c, i) => i !== index);
    setProduct({ ...product, canvases: newArray });
  };
  return product.canvases?.length > 0
    ? product.canvases.map((c, i) => (
        <AddCanvas
          key={i}
          index={i}
          width={c.width}
          height={c.height}
          handleCanvasChange={(e) => handleCanvasChange(e, i)}
          handleCanvasPlusButton={() => handleCanvasPlusButton(i)}
          handleCanvasMinusButton={() => handleCanvasMinusButton(i)}
        />
      ))
    : null;
};

export default ShowCanvases;
