import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../../../firebase";
import Tooltip from "@material-ui/core/Tooltip";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { useTranslation } from "react-i18next";
import { CircularProgress, withStyles } from "@material-ui/core";
import { useAuth } from "../../../../../contexts/AuthContext";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
    backgroundColor: "#161C24",
  },
});

const ShipmentTable = ({
  shipments,
  shipmentsLoading,
  setEditing,
  setEditingItem,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { dbUserData, setDbUserData } = useAuth();

  const columns = [
    { id: "country", label: t("ShipmentTable.COUNTRY"), minWidth: 170 },
    { id: "price", label: t("ShipmentTable.PRICE"), minWidth: 100 },
    { id: "currency", label: t("ShipmentTable.CURRENCY"), minWidth: 100 },
    {
      id: "details",
      label: "",
      minWidth: 170,
      align: "right",
    },
  ];

  const handleDetailClick = (item) => {
    setEditing(true);
    setEditingItem(item);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleDeleteShipment = (index) => {
    setDbUserData({
      ...dbUserData,
      shipments: [...shipments.filter((s, i) => i !== index)],
    });
    try {
      const updateAllShipments = httpsCallable(functions, "dbUsersOnCall-updateAllShipments");
      updateAllShipments({
        shipments: [...shipments.filter((s, i) => i !== index)],
      }).then((result) => {
        //
      });
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column, i) => (
                <TableCell
                  key={i}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    backgroundColor: "#212B36",
                    color: "#F9FAFB",
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {shipments
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, i) => {
                return (
                  <TableRow key={i} hover role="checkbox" tabIndex={-1}>
                    <TableCell style={{ color: "#fff" }}>
                      {row.country}
                    </TableCell>
                    <TableCell style={{ color: "#fff" }}>{row.price}</TableCell>
                    <TableCell style={{ color: "#fff" }}>
                      {row.currency}
                    </TableCell>
                    <TableCell align="right">
                      <LightTooltip title="Edit" placement="top">
                        <i
                          style={{ color: "#B78103", fontSize: "20px" }}
                          className="cup fal fa-edit"
                          onClick={() => handleDetailClick(row)}
                        ></i>
                      </LightTooltip>
                      &nbsp; &nbsp;
                      <LightTooltip title="Delete" placement="top">
                        <i
                          style={{ color: "#FF4842", fontSize: "20px" }}
                          className="cup fal fa-trash-alt"
                          onClick={() => handleDeleteShipment(i)}
                        ></i>
                      </LightTooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        {shipmentsLoading ? (
          <div style={{ textAlign: "center", margin: "20px" }}>
            <CircularProgress style={{ color: "#1CCAFF" }} />
          </div>
        ) : null}
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={[].length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        style={{ backgroundColor: "#212B36", color: "#F9FAFB" }}
      />
    </Paper>
  );
};

export default ShipmentTable;
