/* eslint-disable react-hooks/exhaustive-deps */
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../../../../contexts/AuthContext";
import { filterConstants } from "../../../../../redux/constants";
import { useTranslation } from "react-i18next";
import CustomCheckBox from "../../../../customCheckBox";
const {
  GREY_SCALE,
  MODE,
  INVERT,
  SEPIA,
  SHARPEN,
  EMBOSS,
  BLACK_WHITE,
  BROWNIE,
  VINTAGE,
  KODACHROME,
  TECHNICOLOR,
  POLAROID,
} = filterConstants;

const GreyScaleFilter = ({ applyFilter, fabric, save, saveBack }) => {
  const { t } = useTranslation();
  const { currentUser } = useAuth();
  const dispatch = useDispatch();
  const _filter = useSelector((state) => state.filter);
  const _home = useSelector((state) => state.home);
  const applyFilterValue = (index, prop, value) => {
    var obj = _home.currentCanvas.getActiveObject();
    if (obj.filters[index]) {
      obj.filters[index][prop] = value;
      dispatch({
        type: MODE,
        payload: value,
      });
      obj.applyFilters();
      _home.currentCanvas.requestRenderAll();
    }
    if (currentUser) {
      if (_home.face === "front") {
        save();
      } else {
        saveBack();
      }
    }
  };
  const applyGreyScale = (check) => {
    dispatch({
      type: GREY_SCALE,
      payload: check,
    });
    if (check) {
      dispatch({
        type: MODE,
        payload: "average",
      });
    }
    applyFilter(0, check && new fabric.Image.filters.Grayscale());
  };
  useEffect(() => {
    if (_home.currentCanvas) {
      _home.currentCanvas.on({
        "selection:updated": HandleElement,
        "selection:created": HandleElement,
      });
      _home.currentCanvas.on("selection:cleared", function () {
        dispatch({
          type: GREY_SCALE,
          payload: false,
        });
        dispatch({
          type: INVERT,
          payload: false,
        });
        dispatch({
          type: SEPIA,
          payload: false,
        });
        dispatch({
          type: SHARPEN,
          payload: false,
        });
        dispatch({
          type: EMBOSS,
          payload: false,
        });
        dispatch({
          type: BLACK_WHITE,
          payload: false,
        });
        dispatch({
          type: BROWNIE,
          payload: false,
        });
        dispatch({
          type: VINTAGE,
          payload: false,
        });
        dispatch({
          type: KODACHROME,
          payload: false,
        });
        dispatch({
          type: TECHNICOLOR,
          payload: false,
        });
        dispatch({
          type: POLAROID,
          payload: false,
        });
      });
    }
  }, [_home.currentCanvas]);
  function HandleElement(obj) {
    if (obj.target.filters) {
      if (obj.target.filters[0]) {
        dispatch({
          type: GREY_SCALE,
          payload: true,
        });
        dispatch({
          type: MODE,
          payload: obj.target.filters[0]["mode"],
        });
      } else {
        dispatch({
          type: GREY_SCALE,
          payload: false,
        });
      }
      // Invert
      if (obj.target.filters[1]) {
        dispatch({
          type: INVERT,
          payload: true,
        });
      } else {
        dispatch({
          type: INVERT,
          payload: false,
        });
      }
      //Sepia
      if (obj.target.filters[3]) {
        dispatch({
          type: SEPIA,
          payload: true,
        });
      } else {
        dispatch({
          type: SEPIA,
          payload: false,
        });
      }
      //Sharpen
      if (obj.target.filters[12]) {
        dispatch({
          type: SHARPEN,
          payload: true,
        });
      } else {
        dispatch({
          type: SHARPEN,
          payload: false,
        });
      }
      //Emboss
      if (obj.target.filters[8]) {
        dispatch({
          type: EMBOSS,
          payload: true,
        });
      } else {
        dispatch({
          type: EMBOSS,
          payload: false,
        });
      }
      //BlackWhite
      if (obj.target.filters[19]) {
        dispatch({
          type: BLACK_WHITE,
          payload: true,
        });
      } else {
        dispatch({
          type: BLACK_WHITE,
          payload: false,
        });
      }
      //Brownie
      if (obj.target.filters[4]) {
        dispatch({
          type: BROWNIE,
          payload: true,
        });
      } else {
        dispatch({
          type: BROWNIE,
          payload: false,
        });
      }
      //Vintage
      if (obj.target.filters[9]) {
        dispatch({
          type: VINTAGE,
          payload: true,
        });
      } else {
        dispatch({
          type: VINTAGE,
          payload: false,
        });
      }
      //Kodachrome
      if (obj.target.filters[18]) {
        dispatch({
          type: KODACHROME,
          payload: true,
        });
      } else {
        dispatch({
          type: KODACHROME,
          payload: false,
        });
      }
      //Technicolor
      if (obj.target.filters[14]) {
        dispatch({
          type: TECHNICOLOR,
          payload: true,
        });
      } else {
        dispatch({
          type: TECHNICOLOR,
          payload: false,
        });
      }
      //Polaroid
      if (obj.target.filters[15]) {
        dispatch({
          type: POLAROID,
          payload: true,
        });
      } else {
        dispatch({
          type: POLAROID,
          payload: false,
        });
      }
    }
  }
  return (
    <div>
      <CustomCheckBox
        lable={t("GreyScaleFilter.Grayscale")}
        checked={_filter.greyScale}
        onClick={(e) => applyGreyScale(e.target.checked)}
      />
      <FormControl component="fieldset">
        <RadioGroup
          row
          aria-label="position"
          value={_filter.mode}
          name="position"
          onChange={(e) => applyFilterValue(0, "mode", e.target.value)}
        >
          <FormControlLabel
            value="average"
            control={<Radio style={{ color: "#1CCAFF" }} color="primary" />}
            label={t("GreyScaleFilter.Avg")}
            labelPlacement="end"
          />
          <FormControlLabel
            value="luminosity"
            control={<Radio style={{ color: "#1CCAFF" }} color="primary" />}
            label={t("GreyScaleFilter.Lum")}
            labelPlacement="end"
          />
          <FormControlLabel
            value="lightness"
            control={<Radio style={{ color: "#1CCAFF" }} color="primary" />}
            label={t("GreyScaleFilter.Light")}
            labelPlacement="end"
          />
        </RadioGroup>
      </FormControl>
    </div>
  );
};

export default GreyScaleFilter;
