/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import SimpleInput from "../simpleInput";
import OrderTable from "./orderTable";
import OrderDetail from "./orderDetail";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";
import moment from "moment";
import momentTZ from "moment-timezone";
import { useSelector } from "react-redux";
import ProductSearch from "./components/productSearch";
import SearchByStatus from "./components/searchByStatus";

const Orders = () => {
  const { t } = useTranslation();
  const _order = useSelector((state) => state.order);
  const [searchOrderIdText, setSearchOrderIdText] = useState("");
  const [searchByProductText, setSearchByProductText] = useState("");
  const [searchStatusText, setSearchStatusText] = useState("");
  const [ordersGloble, setOrdersGloble] = useState([]);
  const [orders, setOrders] = useState([]);
  const [showOrderDetail, setShowOrderDetail] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [csvArray, setCsvArray] = useState([]);
  const [selectedOrderIndex, setSelectedOrderIndex] = useState(null);

  useEffect(() => {
    setOrders(
      _order.orders.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      )
    );
    setOrdersGloble(
      _order.orders.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      )
    );
  }, [_order.orders]);

  const searchByOrderId = (searchText) => {
    setSearchOrderIdText(searchText);
    if (searchText === "") {
      setOrders(ordersGloble);
    } else {
      setOrders(
        orders.filter((x) =>
          x?.orderID?.toLowerCase().includes(searchText.toLowerCase())
        )
      );
    }
  };
  const searchByProduct = (searchText) => {
    setSearchByProductText(searchText);
    if (searchText === "") {
      setOrders(ordersGloble);
    }
  };
  const applyProductSearch = () => {
    let newArray = [];
    for (let i = 0; i < ordersGloble.length; i++) {
      for (let j = 0; j < ordersGloble[i].products.length; j++) {
        if (
          ordersGloble[i].products[j].name
            .toLowerCase()
            .includes(searchByProductText.toLowerCase())
        ) {
          newArray.push(ordersGloble[i]);
        }
      }
    }
    setOrders(newArray);
  };
  const searchByStatus = (searchText) => {
    setSearchStatusText(searchText);
    if (searchText === "") {
      setOrders(ordersGloble);
    } else {
      setOrders(
        ordersGloble.filter((x) =>
          x?.status?.toLowerCase().includes(searchText.toLowerCase())
        )
      );
    }
  };

  const headers = [
    // Time Information
    { label: "Created At", key: "createdAt" },
    { label: "Year", key: "year" },
    { label: "Month", key: "month" },
    { label: "Day", key: "day" },
    { label: "Time", key: "time" },
    // Order Information
    { label: "Order ID", key: "orderID" },
    // Buyer Information
    { label: "Buyer ID", key: "buyerID" },
    { label: "Buyer Mail", key: "buyerMail" },
    { label: "Buyer FirstName", key: "buyerFirstName" },
    { label: "Buyer LastName", key: "buyerLastName" },
    { label: "Buyer CompanyName", key: "buyerCompanyName" },
    { label: "Buyer Phone Number", key: "buyerPhone" },
    // Seller Information
    { label: "Seller ID", key: "sellerID" },
    { label: "Seller Mail", key: "sellerMail" },
    { label: "Seller FirstName", key: "sellerFirstName" },
    { label: "Seller LastName", key: "sellerLastName" },
    { label: "Seller CompanyName", key: "sellerCompanyName" },
    { label: "Seller Phone Number", key: "sellerPhone" },
    // shipment
    { label: "Shipment ShippingCompany", key: "shipment.shippingCompany" },
    { label: "Shipment Status", key: "shipment.status" },
    { label: "Shipment TrackingID", key: "shipment.trackingID" },
    { label: "Shipment TrackingURL", key: "shipment.trackingURL" },
    // product
    { label: "Back DownloadURL", key: "backDownloadURL" },
    { label: "Product Name", key: "productName" },
    { label: "Variant Name", key: "variantName" },
    { label: "Sub Variant Name", key: "subVariantName" },
    { label: "Child SKU", key: "childSKU" },
    { label: "Color", key: "color" },
    { label: "Front DownloadURL", key: "frontDownloadURL" },
    { label: "Price", key: "price" },
    { label: "Product ID", key: "productID" },
    { label: "Size", key: "size" },
    { label: "Sku", key: "sku" },
    { label: "Quantity", key: "quantity" },
    // Buyer Shipping Address Flag
    { label: "Buyer Shipping Address Flag", key: "buyerShippingAddressFlag" },
    // Buyer Billing Address
    { label: "Buyer Billing City", key: "buyerBillingAddress.city" },
    { label: "Buyer Billing Country", key: "buyerBillingAddress.country" },
    { label: "Buyer Billing HouseNo", key: "buyerBillingAddress.houseNo" },
    {
      label: "Buyer Billing AddressLine1",
      key: "buyerBillingAddress.adrLine1",
    },
    { label: "Buyer Billing Street", key: "buyerBillingAddress.street" },
    { label: "Buyer Billing ZipCode", key: "buyerBillingAddress.zipCode" },
    // Buyer Shipping Address
    { label: "Buyer Shipping City", key: "buyerShippingAddress.city" },
    { label: "Buyer Shipping Country", key: "buyerShippingAddress.country" },
    { label: "Buyer Shipping HouseNo", key: "buyerShippingAddress.houseNo" },
    {
      label: "Buyer Shipping AddressLine1",
      key: "buyerShippingAddress.adrLine1",
    },
    { label: "Buyer Shipping Street", key: "buyerShippingAddress.street" },
    { label: "Buyer Shipping ZipCode", key: "buyerShippingAddress.zipCode" },
    // Seller Shipping Address Flag
    { label: "Seller Shipping Address Flag", key: "sellerShippingAddressFlag" },
    // Seller Billing Address
    { label: "Seller Billing City", key: "sellerBillingAddress.city" },
    { label: "Seller Billing Country", key: "sellerBillingAddress.country" },
    { label: "Seller Billing HouseNo", key: "sellerBillingAddress.houseNo" },
    {
      label: "Seller Billing AddressLine1",
      key: "sellerBillingAddress.adrLine1",
    },
    { label: "Seller Billing Street", key: "sellerBillingAddress.street" },
    { label: "Seller Billing ZipCode", key: "sellerBillingAddress.zipCode" },
    // Seller Shipping Address
    { label: "Seller Shipping City", key: "sellerShippingAddress.city" },
    { label: "Seller Shipping Country", key: "sellerShippingAddress.country" },
    { label: "Seller Shipping HouseNo", key: "sellerShippingAddress.houseNo" },
    {
      label: "Seller Shipping AddressLine1",
      key: "sellerShippingAddress.adrLine1",
    },
    { label: "Seller Shipping Street", key: "sellerShippingAddress.street" },
    { label: "Seller Shipping ZipCode", key: "sellerShippingAddress.zipCode" },
  ];

  const getCsvArray = (orders) => {
    let tempCsvArray = [];
    console.log("orders in AllOrders", orders);
    for (let i = 0; i < orders.length; i++) {
      const d = new Date(orders[i].createdAt);
      let sameData = {
        //addressFrom: orders[i].addressFrom,
        // Buyer
        buyerBillingAddress: orders[i].buyerBillingAddress,
        buyerShippingAddressFlag: orders[i].buyerShippingAddressFlag,
        buyerShippingAddress: orders[i].buyerShippingAddress,
        buyerID: orders[i].buyerID,
        buyerMail: orders[i].buyerMail,
        buyerFirstName: orders[i].buyerFirstName,
        buyerLastName: orders[i].buyerLastName,
        buyerCompanyName: orders[i].buyerCompanyName,
        buyerPhone: orders[i].buyerPhone,
        // Seller
        sellerBillingAddress: orders[i].sellerBillingAddress,
        sellerShippingAddressFlag: orders[i].sellerShippingAddressFlag,
        sellerShippingAddress: orders[i].sellerShippingAddress,
        sellerID: orders[i].sellerID,
        sellerMail: orders[i].sellerMail,
        sellerFirstName: orders[i].sellerFirstName,
        sellerLastName: orders[i].sellerLastName,
        sellerCompanyName: orders[i].sellerCompanyName,
        sellerPhone: orders[i].sellerPhone,
        // Overall
        orderID: orders[i].orderID,
        // sellerID: orders[i].sellerID,
        // sellerMail: orders[i].sellerMail,
        shipment: orders[i].shipment,
        createdAt: moment(momentTZ(d).tz("Europe/Berlin")).format(
          "MMMM Do YYYY, HH:mm:ss"
        ),
        day: moment(momentTZ(d).tz("Europe/Berlin")).format("DD"),
        month: moment(momentTZ(d).tz("Europe/Berlin")).format("MMMM"),
        year: moment(momentTZ(d).tz("Europe/Berlin")).format("YYYY"),
        time: moment(momentTZ(d).tz("Europe/Berlin")).format("HH:mm:ss"),
      };
      for (let j = 0; j < orders[i].products.length; j++) {
        let mergeData = {
          ...sameData,
          backDownloadURL: orders[i].products[j].backDownloadURL,
          childSKU: orders[i].products[j].childSKU,
          color: orders[i].products[j].color,
          frontDownloadURL: orders[i].products[j].frontDownloadURL,
          productName: orders[i].products[j].productName,
          variantName: orders[i].products[j].mainVariantName,
          subVariantName: orders[i].products[j].subVariantName,
          price: orders[i].products[j].price,
          productID: orders[i].products[j].productID,
          quantity: orders[i].products[j].quantity,
          size: orders[i].products[j].size,
          sku: orders[i].products[j].sku,
        };
        tempCsvArray.push(mergeData);
      }
    }
    setCsvArray(tempCsvArray);
  };

  useEffect(() => {
    if (orders.length > 0) {
      getCsvArray(orders);
    }
  }, [orders]);

  return showOrderDetail ? (
    <OrderDetail
      selectedOrderIndex={selectedOrderIndex}
      selectedOrder={selectedOrder}
      setSelectedOrder={setSelectedOrder}
      setShowOrderDetail={setShowOrderDetail}
    />
  ) : (
    <div className="orders">
      <h1>{t("Orders.OrderList")}</h1>
      <div className="filters-wrapper">
        <SimpleInput
          name="orderId"
          value={searchOrderIdText}
          placeholder={t("Orders.SearchByOrderID")}
          onChange={(e) => searchByOrderId(e.target.value)}
        />
        &nbsp; &nbsp;
        {/* <SimpleInput
          name="status"
          value={searchStatusText}
          placeholder={t("Orders.SearchByStatus")}
          onChange={(e) => searchByStatus(e.target.value)}
        /> */}
        <SearchByStatus
          name="status"
          value={searchStatusText}
          placeholder={t("Orders.SearchByStatus")}
          onChange={searchByStatus}
        />
        &nbsp; &nbsp;
        {/* <SimpleInput
          name="city"
          value={searchByProductText}
          placeholder={t("Orders.SearchByProduct")}
          onChange={(e) => searchByProduct(e.target.value)}
        /> */}
        <ProductSearch
          name="city"
          value={searchByProductText}
          placeholder={t("Orders.SearchByProduct")}
          onChange={(e) => searchByProduct(e.target.value)}
          applyProductSearch={applyProductSearch}
        />
        {/* &nbsp; &nbsp;
        <SimpleInput
          name="userId"
          value={searchBuyerEmailText}
          placeholder={t("Orders.SearchByBuyerEmail")}
          onChange={(e) => searchByBuyerEmail(e.target.value)}
        /> */}
        <div className="mt-10">
          <CSVLink data={csvArray} headers={headers} separator={";"}>
            {t("Orders.Downloadascsv")}
          </CSVLink>
        </div>
      </div>
      <br />
      {/* table */}
      <OrderTable
        orders={orders}
        setSelectedOrderIndex={setSelectedOrderIndex}
        setShowOrderDetail={setShowOrderDetail}
        setSelectedOrder={setSelectedOrder}
      />
    </div>
  );
};

export default Orders;
