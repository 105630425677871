import React, { useState } from "react";
import "./searchByStatus.css";

const SearchByStatus = ({ name, value, placeholder, onChange }) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const handleDropdownOpening = () => {
    setOpenDropdown((prev) => !prev);
  };
  const handleSelect = (v) => {
    onChange(v);
    setOpenDropdown(false);
  };
  return (
    <div className="studio-dropdown1">
      <h4 onClick={() => handleDropdownOpening()}>
        {value ? value : placeholder}
      </h4>
      <i className="ri-arrow-drop-down-fill"></i>
      {openDropdown ? (
        <ul>
          <li onClick={() => handleSelect("")}>{placeholder}</li>
          <li onClick={() => handleSelect("Initial")}>Initial</li>
          <li onClick={() => handleSelect("Printing")}>Printing</li>
          <li onClick={() => handleSelect("Delivery")}>Delivery</li>
          <li onClick={() => handleSelect("Cancel")}>Cancel</li>
        </ul>
      ) : null}
    </div>
  );
};

export default SearchByStatus;
