/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../firebase";
import { Button } from "@material-ui/core";
import SimpleInput from "../simpleInput";
import UserTable from "./userTable";
import UpdateUser from "./updateUser";

const Users = () => {
  const [searchEmailText, setSearchEmailText] = useState("");
  const [searchCityText, setSearchCityText] = useState("");
  const [usersGloble, setUsersGloble] = useState([]);
  const [users, setUsers] = useState([]);
  const [detail, setDetail] = useState(false);
  const [userData, setUserData] = useState();

  useEffect(() => {
    const getAllUsers = httpsCallable(functions, "dbUsersOnCall-getAllUsers");
    getAllUsers().then((result) => {
      setUsers(result.data);
      setUsersGloble(result.data);
    });
  }, []);

  const searchByEmail = (searchText) => {
    setSearchEmailText(searchText);
    if (searchText === "") {
      setUsers(usersGloble);
    } else {
      setUsers(
        users.filter((x) =>
          x.email.toLowerCase().includes(searchText.toLowerCase())
        )
      );
    }
  };

  const searchByCity = (searchText) => {
    setSearchCityText(searchText);
    if (searchText === "") {
      setUsers(usersGloble);
    } else {
      setUsers(
        users.filter((x) =>
          x?.address.city?.toLowerCase().includes(searchText.toLowerCase())
        )
      );
    }
  };

  return (
    <div className="orders">
      {detail ? (
        <UpdateUser
          userData={userData}
          setUserData={setUserData}
          setDetail={setDetail}
          usersGloble={usersGloble}
          setUsers={setUsers}
          setUsersGloble={setUsersGloble}
        />
      ) : (
        <>
          <h1>User List</h1>
          <div className="filters-wrapper df aic mb-20">
            <SimpleInput
              name="email"
              value={searchEmailText}
              placeholder="Search By Email"
              onChange={(e) => searchByEmail(e.target.value)}
            />
            &nbsp; &nbsp;
            <SimpleInput
              name="city"
              value={searchCityText}
              placeholder="Search By City"
              onChange={(e) => searchByCity(e.target.value)}
            />
            &nbsp; &nbsp;
            {/* <Button
              variant="contained"
              component="label"
              style={{
                borderColor: "#2e313f",
                color: "#fff",
                backgroundColor: "#00A86B",
                width: "150px",
              }}
              onClick={() => applyFilter()}
            >
              Filter
            </Button> */}
          </div>
          {/* table */}
          <UserTable
            users={users}
            setUsers={setUsers}
            setDetail={setDetail}
            setUserData={setUserData}
          />
        </>
      )}
    </div>
  );
};

export default Users;
