/* eslint-disable react/jsx-no-target-blank */
import { makeStyles, Paper, Tooltip } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../firebase";
import { useTranslation } from "react-i18next";
import React from "react";
import { cartConstants } from "../../../redux/constants";
const {
  CHANGE_QUANTITY,
  REMOVE_CART_ITEM,
  CART_QUANTITY_CHANGE_LOADING,
  DELETE_CART_ITEM_LOADING,
} = cartConstants;

const useStyles = makeStyles((theme) => ({
  paper: {
    height: 60,
    width: "100%",
    alignItems: "center",
    backgroundColor: "#212B36",
    display: "flex",
    marginTop: "10px",
    padding: "10px",
    color: "white",
    borderRadius: 10,
  },
}));

const CartItem = ({ item, index }) => {
  console.log("item!!!", item);
  console.log("index!!!", index);
  const { t } = useTranslation();
  const _cart = useSelector((state) => state.cart);
  const classes = useStyles();
  const dispatch = useDispatch();
  const handleQuantityChange = (v) => {
    dispatch({
      type: CART_QUANTITY_CHANGE_LOADING,
      payload: true,
    });
    if (parseInt(v) > 0) {
      let updatedCartItem = {
        studioID: item.studioID,
        studio: item.studio,
        productID: item.productID,
        addressFrom: item.addressFrom,
        billingAddress: item.billingAddress,
        shippingAddress: item.shippingAddress,
        mainVariantID: item.mainVariantID,
        mainVariantName: item.mainVariantName,
        subVariantID: item.subVariantID,
        subVariantName: item.subVariantName,
        productName: item.productName,
        sku: item.sku,
        color: item.color,
        size: item.size,
        childSKU: item.childSKU,
        quantity: parseInt(v),
        price: item.unitPrice * parseInt(v),
        unitPrice: item.unitPrice,
        frontDownloadURL: item.frontDownloadURL,
        backDownloadURL: item.backDownloadURL,
      };
      let newArray = [..._cart.cart];
      newArray[index] = updatedCartItem;
      dispatch({
        type: CHANGE_QUANTITY,
        payload: {
          i: index,
          newItem: updatedCartItem,
        },
      });
      try {
        const updateUserCartQuantity = httpsCallable(
          functions,
          "dbUsersOnCall-updateUserCartQuantity"
        );
        updateUserCartQuantity({ cart: newArray }).then((result) => {
          dispatch({
            type: CART_QUANTITY_CHANGE_LOADING,
            payload: false,
          });
        });
      } catch (e) {
        console.error("Error adding document: ", e);
      }
    } else {
      dispatch({
        type: CART_QUANTITY_CHANGE_LOADING,
        payload: false,
      });
    }
  };

  const handleRemoveCartItem = () => {
    dispatch({
      type: DELETE_CART_ITEM_LOADING,
      payload: true,
    });
    let newArray = _cart.cart.filter((c, i) => i !== index);
    dispatch({
      type: REMOVE_CART_ITEM,
      payload: {
        i: index,
      },
    });
    try {
      const updateUserCart = httpsCallable(
        functions,
        "dbUsersOnCall-updateUserCart"
      );
      updateUserCart({ cart: newArray }).then((result) => {
        dispatch({
          type: DELETE_CART_ITEM_LOADING,
          payload: false,
        });
      });
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  console.log("item", item.previewImageURL);

  return (
    <div className="card-item" key={index}>
      <Paper className={classes.paper}>
        <h2 className="fw-300 fz-16">
          <span style={{ color: "#c0c5ce", fontWeight: 500 }}>
            {t("CartItem.Name")}
          </span>{" "}
          {item.subVariantName}
        </h2>
        &nbsp; &nbsp;
        <img width="50px" src={item.previewImageURL} alt="preview" />
        &nbsp; &nbsp;
        <h2 className="fw-300 fz-16">
          <span style={{ color: "#c0c5ce", fontWeight: 500 }}>
            {t("CartItem.Color")}
          </span>{" "}
        </h2>
        &nbsp; &nbsp;
        <span
          style={{
            width: "40px",
            height: "35px",
            display: "inline-block",
            backgroundColor: item.color,
          }}
        />
        &nbsp; &nbsp;
        <h2 className="fw-300 fz-16">
          <span style={{ color: "#c0c5ce", fontWeight: 500 }}>
            {" "}
            {t("CartItem.Size")}
          </span>{" "}
          {item.size}
        </h2>
        &nbsp; &nbsp;
        <h2 className="fw-300 fz-16">
          <span style={{ color: "#c0c5ce", fontWeight: 500 }}>
            {t("CartItem.ChildSKU")}
          </span>{" "}
          {item.childSKU}
        </h2>
        &nbsp; &nbsp;
        <h2 className="fw-300 fz-16">
          <span style={{ color: "#c0c5ce", fontWeight: 500 }}>
            {t("CartItem.Quantity")}
          </span>
        </h2>
        &nbsp; &nbsp;
        <input
          className="custom-input"
          style={{ border: `1px solid #1ccaff`, width: "50px" }}
          type="number"
          value={item.quantity}
          onChange={(e) => handleQuantityChange(e.target.value)}
        />
        &nbsp; &nbsp;
        <h2 className="fw-300 fz-16">
          <span style={{ color: "#c0c5ce" }}>{t("CartItem.Price")}</span> €{" "}
          {Math.round(item.price * 100) / 100}
        </h2>
        &nbsp; &nbsp;
        <button className="preview-btn">
          <a href={item.frontDownloadURL} target="_blank">
            {t("CartItem.FrontPreview")}
          </a>
        </button>
        &nbsp; &nbsp;
        <button className="preview-btn">
          <a href={item.backDownloadURL} target="_blank">
            {t("CartItem.BackPreview")}
          </a>
        </button>
        &nbsp; &nbsp;
        <Tooltip title="Delete">
          <i
            style={{ color: "white" }}
            className="backarrow fal fa-trash-alt"
            onClick={() => handleRemoveCartItem()}
          ></i>
        </Tooltip>
      </Paper>
    </div>
  );
};

export default CartItem;
