/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CartItem from "./cartItem";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../firebase";
import { useSelector } from "react-redux";
import "./checkout.css";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { Button } from "@material-ui/core";
import StripeCheckout from "react-stripe-checkout";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./checkoutForm";
import SingleStudioOrder from "./components/singleStudioOrder";
import PaymentStatus from "./components/paymentStatus";
import { useTranslation } from "react-i18next";
import Loader from "../../components/loader";

const CheckOut = () => {
  const { t } = useTranslation();
  let { payment_intent_client_secret, redirect_status } = useParams();
  const { currentUser, dbUserData } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState(null);
  const _cart = useSelector((state) => state.cart);
  const [cartItems, setCartItems] = useState([]);
  const _productOptions = useSelector((state) => state.productOptions);

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
  // let stripePromise;
  // const getStripe = () => {
  //   if (!stripePromise) {
  //     stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
  //   }
  //   return stripePromise;
  // }

  const handleSeperateDefStudioOrders = async () => {
    let cartItems = [];
    let stuProducts = [];
    for (let i = 0; i < _productOptions.studios.length; i++) {
      let stuCartItems = [];
      let totalPrice = 0;
      for (let j = 0; j < _cart.cart?.length; j++) {
        if (_productOptions.studios[i].userID === _cart.cart[j].studioID) {
          try {
            const getAllProducts = httpsCallable(
              functions,
              "dbProductsOnCall-getAllProducts"
            );
            const res = await getAllProducts({
              sellerID: _productOptions.studios[i].userID,
            });
            console.log("res.data", res.data);
            stuProducts.push({
              seller: _productOptions.studios[i].userID,
              products: res.data,
            });
            // .then((result) => {
            // dispatch({
            //   type: POPUP_PRODUCTS,
            //   payload: result.data,
            // });
            // });
          } catch (e) {
            console.error("Error removing Image: ", e);
          }

          stuCartItems.push(_cart.cart[j]);
          totalPrice = totalPrice + parseFloat(_cart.cart[j].price);
        }
      }

      // cart items with variant IDs
      for (let n = 0; n < stuCartItems.length; n++) {
        // after filtering will remain only one object in the array
        // that's way its fStudioProducts[0]
        const fStudioProducts = stuProducts.filter(
          (item) => item.seller === stuCartItems[n].studioID
        );
        console.log("fStudioProducts", fStudioProducts);
        // products
        for (let m = 0; m < fStudioProducts[0].products.length; m++) {
          // product main variants
          for (
            let l = 0;
            l < fStudioProducts[0].products[m].variants.length;
            l++
          ) {
            // product sub varians
            for (
              let k = 0;
              k < fStudioProducts[0].products[m].variants[l].subVariants.length;
              k++
            ) {
              if (
                stuCartItems[n].subVariantID ===
                fStudioProducts[0].products[m].variants[l].subVariants[k].id
              ) {
                // get data
                stuCartItems[n].color =
                  fStudioProducts[0].products[m].variants[l].color;
                stuCartItems[n].previewImageURL =
                  fStudioProducts[0].products[m].variants[l].imageURL.url.split(
                    /[ ;]+/
                  )[2];
                stuCartItems[n].mainVarianName =
                  fStudioProducts[0].products[m].variants[l].name;
                stuCartItems[n].childSKU =
                  fStudioProducts[0].products[m].variants[l].subVariants[
                    k
                  ].childSKU;
                stuCartItems[n].currency =
                  fStudioProducts[0].products[m].variants[l].subVariants[
                    k
                  ].currency;
                stuCartItems[n].inStock =
                  fStudioProducts[0].products[m].variants[l].subVariants[
                    k
                  ].inStock;
                stuCartItems[n].subVariantName =
                  fStudioProducts[0].products[m].variants[l].subVariants[
                    k
                  ].name;
                stuCartItems[n].price =
                  fStudioProducts[0].products[m].variants[l].subVariants[
                    k
                  ].price;
                stuCartItems[n].quantityInStock =
                  fStudioProducts[0].products[m].variants[l].subVariants[
                    k
                  ].quantityInStock;
                stuCartItems[n].size =
                  fStudioProducts[0].products[m].variants[l].subVariants[
                    k
                  ].size;
              }
            }
          }
        }
      }

      cartItems.push({
        studio: _productOptions.studios[i].companyName,
        shipments: _productOptions.studios[i].shipments,
        totalPrice: totalPrice,
        cartItems: stuCartItems,
      });
    }
    console.log("CartItems in CheckOut", cartItems);
    setCartItems(cartItems);
  };

  useEffect(() => {
    setCartItems([]);
    handleSeperateDefStudioOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_cart.cart, _productOptions.studios]);

  const clientSecret = new URLSearchParams(window.location.search).get(
    "payment_intent_client_secret"
  );
  const redirect_status1 = new URLSearchParams(window.location.search).get(
    "redirect_status"
  );
  const payment_intent = new URLSearchParams(window.location.search).get(
    "payment_intent"
  );

  const handleStripe = (cartItems) => {
    console.log("studioID", cartItems[0].studioID);
    localStorage.setItem("studioID", cartItems[0].studioID);
    try {
      setLoading(true);
      const createStripePayment = httpsCallable(
        functions,
        "dbUsersOnCall-createStripePayment"
      );
      createStripePayment({ cartItems: cartItems }).then((client_secret) => {
        console.log("Payment Return", client_secret);
        setOptions({ clientSecret: client_secret.data });
        setLoading(false);
      });
    } catch (e) {
      console.error("Error in Stripe payment: ", e);
    }
  };

  const createOrder = () => {
    setLoading(true);
    let nOrder = {
      studioID: localStorage.getItem("studioID"),
    };
    // createOrder
    try {
      console.log("Sending create paid order", nOrder);
      const createPayedOrder = httpsCallable(
        functions,
        "dbOrdersOnCall-createOrder"
      );
      createPayedOrder(nOrder).then((result) => {
        // code here
        console.log("createPayedOrder", result);
        setLoading(false);
        localStorage.removeItem("studioID");
      });
    } catch (e) {
      console.error("Error removing Image: ", e);
    }
  };

  console.log("cartItems", cartItems);

  return (
    <div className="checkout">
      <h1 className="checkout-heading df jcc aic">
        <i
          className="backarrow fal fa-long-arrow-left"
          onClick={() => navigate("/")}
        ></i>
        &nbsp; &nbsp; {t("CheckOut.Checkout")}
      </h1>
      <>
        {cartItems.length > 0
          ? cartItems.map((studio, i) => (
              <SingleStudioOrder
                key={i}
                studio={studio}
                handleStripe={handleStripe}
              />
            ))
          : null}
      </>
      {_cart.cart?.length === 0 ? (
        <h1 className="empty-cart-msg">{t("CheckOut.CartisEmpty")}</h1>
      ) : (
        <div className="tac mt-20">
          {options ? (
            <Elements stripe={stripePromise} options={options}>
              <CheckoutForm />
              {console.log("clientSecret", clientSecret)}
            </Elements>
          ) : null}
          <Elements stripe={stripePromise} options={options}>
            {clientSecret ? (
              <PaymentStatus
                clientSecret={clientSecret}
                createOrder={createOrder}
              />
            ) : null}
          </Elements>
        </div>
      )}
      {_cart.cartOuantityChangeLoading ? <Loader /> : null}
      {_cart.deleteCartItemLoading ? <Loader /> : null}
      {loading ? <Loader /> : null}
    </div>
  );
};

export default CheckOut;

// http://localhost:3000/checkout?
// payment_intent = pi_3KPXgsLDQ1zZD1vm0rNcrNJr
// payment_intent_client_secret = pi_3KPXgsLDQ1zZD1vm0rNcrNJr_secret_7EfTeYVv5f0SKA2Mmqv4325ya &
// redirect_status = succeeded
