import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { filterConstants } from "../../../../../redux/constants";
import CustomCheckBox from "../../../../customCheckBox";
import { useTranslation } from "react-i18next";
const { INVERT } = filterConstants;

const InvertFilter = ({ applyFilter, fabric }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const _filter = useSelector((state) => state.filter);
  const applyInvert = (check) => {
    dispatch({
      type: INVERT,
      payload: check,
    });
    applyFilter(1, check && new fabric.Image.filters.Invert());
  };
  return (
    <div>
      <CustomCheckBox
        lable={t("InvertFilter.Invert")}
        checked={_filter.invert}
        onClick={(e) => applyInvert(e.target.checked)}
      />
    </div>
  );
};

export default InvertFilter;
