import React from "react";
import StatusInOrderDetail from "./components/statusInOrderDetail";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../../firebase";
import "./orderDetail.css";
import OrderId from "./orderId";
// import ShowAddressFrom from "./showAddressFrom";
// import ShowAddressTo from "./showAddressTo";
import ShowProducts from "./showProducts";
import ShowShipment from "./showShipment";
import { useDispatch, useSelector } from "react-redux";
import { orderConstants } from "../../../../redux/constants";
import OrderDetailTopBoxes from "../../../../components/orderDetailTopBoxes";
import OrderInformation from "../../../../components/orderInformation";
const { ORDERS } = orderConstants;

const OrderDetail = ({
  selectedOrderIndex,
  selectedOrder,
  setSelectedOrder,
  setShowOrderDetail,
}) => {
  // _order.orders
  const dispatch = useDispatch();
  const _order = useSelector((state) => state.order);
  const handleUpdateStatus = (status) => {
    let newArr = [..._order.orders];
    newArr[selectedOrderIndex] = {
      ...newArr[selectedOrderIndex],
      status: status,
    };
    setSelectedOrder({ ...selectedOrder, status: status });
    dispatch({
      type: ORDERS,
      payload: newArr,
    });
    try {
      const updateOrderStatus = httpsCallable(
        functions,
        "dbOrdersOnCall-updateOrderStatus"
      );
      updateOrderStatus({
        orderID: selectedOrder.orderID,
        status: status,
      }).then((result) => {});
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };
  return (
    <ul className="orderDetail">
      <OrderId
        setShowOrderDetail={setShowOrderDetail}
        orderID={selectedOrder.orderID}
      />
      <OrderDetailTopBoxes selectedOrder={selectedOrder} />
      <StatusInOrderDetail
        status={selectedOrder?.status}
        handleUpdateStatus={handleUpdateStatus}
      />
      <OrderInformation selectedOrder={selectedOrder} />
      {/* <ShowAddressFrom
        addressFrom={selectedOrder.addressFrom}
        sellerFirstName={selectedOrder.sellerFirstName}
        sellerLastName={selectedOrder.sellerLastName}
        sellerCompanyName={selectedOrder.sellerCompanyName}
      />
      <ShowAddressTo
        addressTo={selectedOrder.addressTo}
        buyerFirstName={selectedOrder.buyerFirstName}
        buyerLastName={selectedOrder.buyerLastName}
        buyerCompanyName={selectedOrder.buyerCompanyName}
      /> */}

      <ShowProducts products={selectedOrder.products} />
      <ShowShipment shipment={selectedOrder.shipment} />
    </ul>
  );
};

export default OrderDetail;
