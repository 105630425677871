/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import SimpleInput from "../simpleInput";
import ShipmentTable from "./components/shipmentTable";
import AddNewShipment from "./components/addNewShipment";
import { useAuth } from "../../../contexts/AuthContext";
import { useTranslation } from "react-i18next";
// import AddNewProduct from "./addNewProduct";

const Shipments = () => {
  const { t } = useTranslation();
  const { dbUserData } = useAuth();
  const [newShipment, setNewShipment] = useState(false);
  const [searchCountryText, setSearchCountryText] = useState("");
  const [editing, setEditing] = useState(false);
  const [shipmentsLoading, setShipmentsLoading] = useState(false);
  const [editingItem, setEditingItem] = useState(null);
  const [shipmentsGloble, setShipmentsGloble] = useState([]);
  const [shipments, setShipments] = useState([]);
  const [shipment, setShipment] = useState({
    country: "",
    price: 0,
    deliveryTime: "",
    currency: "EUR",
  });

  const handleAddNew = () => {
    setNewShipment(true);
  };

  useEffect(() => {
    if (dbUserData?.shipments) {
      setShipmentsGloble(dbUserData?.shipments);
      setShipments(dbUserData?.shipments);
    }
  }, [dbUserData]);

  const searchByProductId = (searchText) => {
    setSearchCountryText(searchText);
    if (searchText === "") {
      setShipments(shipmentsGloble);
    } else {
      setShipments(
        shipments.filter((x) =>
          x?.country?.toLowerCase().includes(searchText.toLowerCase())
        )
      );
    }
  };
  return (
    <div className="orders" style={{ position: "relative" }}>
      {newShipment || editing ? (
        <AddNewShipment
          editing={editing}
          setNewShipment={setNewShipment}
          setEditing={setEditing}
          shipmentsGloble={shipmentsGloble}
          shipment={shipment}
          setShipment={setShipment}
        />
      ) : (
        <>
          <h1>{t("Shipments.ShipmentsList")}</h1>
          <div className="filters-wrapper df jcsb aic mb-20">
            <div>
              <SimpleInput
                name="productId"
                value={searchCountryText}
                placeholder={t("Shipments.SearchByCountry")}
                onChange={(e) => searchByProductId(e.target.value)}
              />
            </div>
            &nbsp; &nbsp;
            <Button
              variant="contained"
              component="label"
              style={{
                borderColor: "#2e313f",
                color: "#fff",
                backgroundColor: "#1890FF",
                width: "150px",
              }}
              onClick={() => handleAddNew()}
            >
              {t("Shipments.AddNew")}
            </Button>
          </div>
          {/* table */}
          <ShipmentTable
            shipments={shipments}
            shipmentsLoading={shipmentsLoading}
            setEditing={setEditing}
            setEditingItem={setShipment}
          />
        </>
      )}
    </div>
  );
};

export default Shipments;
