import React from "react";
import "./productSearch.css";

const ProductSearch = ({
  name,
  value,
  placeholder,
  onChange,
  applyProductSearch,
}) => {
  return (
    <div className="productSearch-input">
      <input
        // className="custom-input"
        name={name}
        value={value}
        type="text"
        placeholder={placeholder}
        onChange={onChange}
      />
      <i className="ri-search-2-line" onClick={() => applyProductSearch()}></i>
    </div>
  );
};

export default ProductSearch;
