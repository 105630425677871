/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Checkbox, Typography } from "@material-ui/core";
import { fabric } from "fabric";
import CustomSelect from "../customSelect";
import CustomCheckBox from "../../../customCheckBox";
import CustomSlider from "../../../customSlider";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const BlendColorOptions = ({ save, saveBack }) => {
  const { t } = useTranslation();
  const _home = useSelector((state) => state.home);
  // filters start
  const [blendColor, setBlendColor] = useState(false);
  // filters end
  const [color, setColor] = useState("#000");
  const [mode, setMode] = useState("add");
  const [blendColorModes, setBlendColorModes] = useState([
    t("BlendColorOptions.Add"),
    t("BlendColorOptions.Diff"),
    t("BlendColorOptions.Subtract"),
    t("BlendColorOptions.Multiply"),
    t("BlendColorOptions.Screen"),
    t("BlendColorOptions.Lighten"),
    t("BlendColorOptions.Darken"),
    t("BlendColorOptions.Overlay"),
    t("BlendColorOptions.Exclusion"),
    t("BlendColorOptions.Tint"),
  ]);
  const [alpha, setAlpha] = useState(1);

  const applyFilter = (index, filter) => {
    var obj = _home.currentCanvas.getActiveObject();
    if (obj) {
      obj.filters[index] = filter;
      obj.applyFilters();
      _home.currentCanvas.requestRenderAll();
    }
    if (_home.face === "front") {
      save();
    } else {
      saveBack();
    }
  };

  const applyFilterValue = (index, prop, value) => {
    var obj = _home.currentCanvas.getActiveObject();
    if (obj.filters) {
      if (obj.filters[index]) {
        obj.filters[index][prop] = value;
        obj.applyFilters();
        _home.currentCanvas.requestRenderAll();
      }
    }
    if (_home.face === "front") {
      save();
    } else {
      saveBack();
    }
  };

  const applyBlendColor = (check) => {
    setBlendColor(check);
    applyFilter(
      16,
      check &&
        new fabric.Image.filters.BlendColor({
          color: color,
          mode: mode.toLowerCase(),
          alpha: alpha,
        })
    );
  };

  const handleValueChange = (event, newValue) => {
    setAlpha(newValue);
    applyFilterValue(16, "alpha", newValue);
  };

  const changeBlendColorMode = (mode) => {
    applyFilterValue(16, "mode", mode.toLowerCase());
  };

  const handleColorChange = (color) => {
    if (typeof color !== "undefined") {
      setColor(color);
      applyFilterValue(16, "color", color);
    }
  };

  useEffect(() => {
    if (_home.currentCanvas) {
      _home.currentCanvas.on({
        "selection:updated": HandleElement,
        "selection:created": HandleElement,
      });
    }
  }, [_home.currentCanvas]);
  const HandleElement = (obj) => {
    // BlendColor
    if (obj.target.filters) {
      if (obj.target.filters[16]) {
        setBlendColor(true);
      } else {
        setBlendColor(false);
      }
    } else {
      setBlendColor(false);
    }
  };

  return (
    <div className="edit-options">
      {t("BlendColorOptions.BlendColor")}
      <Checkbox
        style={{ color: "#1CCAFF" }}
        color="primary"
        checked={blendColor}
        onClick={(e) => applyBlendColor(e.target.checked)}
        inputProps={{ "aria-label": "secondary checkbox" }}
      />
      {/* <CustomCheckBox onClick={applyBlendColor} /> */}
      <Typography id="input-slider" gutterBottom>
        {t("BlendColorOptions.Mode")}
      </Typography>
      <CustomSelect options={blendColorModes} onChange={changeBlendColorMode} />
      <br />
      <div className="df aic mb-10">
        {t("BlendColorOptions.Color")}&nbsp;&nbsp;&nbsp;
        <input
          type="color"
          style={{
            width: "40px",
            cursor: "pointer",
            border: "none",
            background: "transparent",
          }}
          bind-value-to="fill"
          // onChange={(e) => handleColorChange(e.target.value)}
          onMouseLeave={(e) => handleColorChange(e.target.value)}
          className="btn-object-action"
        ></input>
      </div>
      <CustomSlider
        title={t("BlendColorOptions.Value")}
        value={alpha}
        min={0}
        max={1}
        step={0.01}
        handleChange={handleValueChange}
      />
    </div>
  );
};

export default BlendColorOptions;
