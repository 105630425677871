import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { filterConstants } from "../../../../../redux/constants";
import CustomCheckBox from "../../../../customCheckBox";
import { useTranslation } from "react-i18next";
const { EMBOSS } = filterConstants;

const EmbossFilter = ({ applyFilter, fabric }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const _filter = useSelector((state) => state.filter);
  const applyEmboss = (check) => {
    dispatch({
      type: EMBOSS,
      payload: check,
    });
    applyFilter(
      8,
      check &&
        new fabric.Image.filters.Convolute({
          //matrix: [1, 1, 1, 1, 0.7, -1, -1, -1, -1],
          matrix: [-2, -1, 0, -1, 1, 1, 0, 1, 2],
        })
    );
  };
  return (
    <div>
      <CustomCheckBox
        lable={t("EmbossFilter.Emboss")}
        checked={_filter.emboss}
        onClick={(e) => applyEmboss(e.target.checked)}
      />
    </div>
  );
};

export default EmbossFilter;
