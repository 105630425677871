import { Button } from "@material-ui/core";
import React, { useState } from "react";
import { functions } from "../../firebase";
import { httpsCallable } from "firebase/functions";
import CustomInput from "../../components/customInput";
import CheckBoxWithLabel from "./checkBoxWithLabel";

const UserManagement = () => {
  const [email, setEmail] = useState("");
  const [permissions, setPermissions] = useState({
    user: false,
    studio: false,
    designer: false,
    admin: false,
  });
  const handleField = (e) => {
    setEmail(e.target.value);
  };
  const handleUserPermisionChange = (permision) => {
    setPermissions({ ...permissions, user: permision });
  };
  const handleStudioPermisionChange = (permision) => {
    setPermissions({ ...permissions, studio: permision });
  };
  const handleDesignerPermisionChange = (permision) => {
    setPermissions({ ...permissions, designer: permision });
  };
  const handleAdminPermisionChange = (permision) => {
    setPermissions({ ...permissions, admin: permision });
  };
  const handleSubmit = () => {
    if (email !== "") {
      const changeClaim = httpsCallable(functions, "authOnCall-changeClaim");
      changeClaim({ email, permissions }).then((result) => {});
    } else {
    }
  };

  const handleGetCustomClaims = () => {
    if (email !== "") {
      const getClaim = httpsCallable(functions, "authOnCall-getClaim");
      getClaim({ email }).then((result) => {
        setPermissions(result.data);
      });
    } else {
    }
  };

  return (
    <>
      <div className="userManagement">
        <h1>Change User Permissions</h1>
        <br />
        <CustomInput
          label={"Email"}
          name="email"
          value={email}
          onChange={handleField}
          placeholder={"Enter email"}
        />
        <button
          className="claim-button"
          onClick={() => handleGetCustomClaims()}
        >
          Get custom claims
        </button>
        <CheckBoxWithLabel
          checked={permissions.user}
          onClick={handleUserPermisionChange}
          label={"User"}
        />
        <CheckBoxWithLabel
          checked={permissions.studio}
          onClick={handleStudioPermisionChange}
          label={"Studio"}
        />
        <CheckBoxWithLabel
          checked={permissions.designer}
          onClick={handleDesignerPermisionChange}
          label={"Designer"}
        />
        <CheckBoxWithLabel
          checked={permissions.admin}
          onClick={handleAdminPermisionChange}
          label={"Admin"}
        />
      </div>
      <div className="submit-button-wrapper">
        <Button
          variant="contained"
          component="label"
          style={{
            marginBottom: "10px",
            borderColor: "#2e313f",
            color: "#fff",
            backgroundColor: "#1890FF",
            width: "250px",
          }}
          onClick={() => handleSubmit()}
        >
          Submit
        </Button>
      </div>
    </>
  );
};

export default UserManagement;
