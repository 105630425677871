/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { fabric } from "fabric";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  makeStyles,
  Paper,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import CustomSlider from "../../../customSlider";
import { useSelector } from "react-redux";
// import CustomCheckBox from "../../../customCheckBox";

const useStyles = makeStyles({
  paper: {
    backgroundColor: "#212B36",
    padding: "10px",
    marginBottom: "10px",
    color: "white",
  },
});

const WhiteNBlack = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const _home = useSelector((state) => state.home);
  const [greyScale, setGreyScale] = useState(false);
  const [mode, setMode] = useState("average");
  const [blackWhite, setBlackWhite] = useState(false);
  const [contrastApl, setContrastApl] = useState(false);
  // filters end
  const [contrast, setContrast] = useState(0);
  const applyFilter = (index, filter) => {
    var obj = _home.currentCanvas.getActiveObject();
    if (obj) {
      obj.filters[index] = filter;
      obj.applyFilters();
      _home.currentCanvas.requestRenderAll();
    }
  };
  const applyFilterValue = (index, prop, value) => {
    var obj = _home.currentCanvas.getActiveObject();
    if (obj.filters[index]) {
      obj.filters[index][prop] = value;
      setMode(value);
      obj.applyFilters();
      _home.currentCanvas.requestRenderAll();
    }
  };
  const applyGreyScale = (check) => {
    setGreyScale(check);
    if (check) {
      setMode("average");
    }
    applyFilter(0, check && new fabric.Image.filters.Grayscale());
  };

  const applyContrast = (check) => {
    setContrastApl(check);
    applyFilter(
      6,
      check &&
        new fabric.Image.filters.Contrast({
          contrast: parseFloat(contrast),
        })
    );
  };

  const handleContrastChange = (event, newValue) => {
    setContrast(newValue);
    applyFilterValue(6, "contrast", parseFloat(newValue));
  };

  const applyBlackWhite = (check) => {
    setBlackWhite(check);
    applyFilter(19, check && new fabric.Image.filters.BlackWhite());
  };

  useEffect(() => {
    if (_home.currentCanvas) {
      _home.currentCanvas.on({
        "selection:updated": HandleElement,
        "selection:created": HandleElement,
      });
    }
  }, [_home.currentCanvas]);
  function HandleElement(obj) {
    // BlackWhite
    if (obj.target.filters) {
      if (obj.target.filters[19]) {
        setBlackWhite(true);
      } else {
        setBlackWhite(false);
      }
      // ContrastApl
      if (obj.target.filters[6]) {
        setContrastApl(true);
      } else {
        setContrastApl(false);
      }
      // GreyScale
      if (obj.target.filters[0]) {
        setGreyScale(true);
        setMode(obj.target.filters[0]["mode"]);
      } else {
        setGreyScale(false);
      }
    } else {
      setBlackWhite(false);
      setContrastApl(false);
      setGreyScale(false);
    }
  }
  return (
    <div className="p-10">
      <Paper className={classes.paper}>
        {t("BlackWhiteFilter.BlackWhite")}
        <Checkbox
          style={{ color: "#1CCAFF" }}
          color="primary"
          checked={blackWhite}
          onClick={(e) => applyBlackWhite(e.target.checked)}
          inputProps={{ "aria-label": "secondary checkbox" }}
        />
        {/* <CustomCheckBox onClick={applyBlackWhite} /> */}
      </Paper>
      <Paper className={classes.paper}>
        {t("Contrast.Contrast")}
        <Checkbox
          style={{ color: "#1CCAFF" }}
          color="primary"
          checked={contrastApl}
          onClick={(e) => applyContrast(e.target.checked)}
          inputProps={{ "aria-label": "secondary checkbox" }}
        />
        <CustomSlider
          title={t("Contrast.Value")}
          value={contrast}
          min={-1}
          max={1}
          step={0.003921}
          handleChange={handleContrastChange}
        />
      </Paper>
      <Paper className={classes.paper}>
        {t("GreyScaleFilter.Grayscale")}
        <Checkbox
          style={{ color: "#1CCAFF" }}
          color="primary"
          checked={greyScale}
          onClick={(e) => applyGreyScale(e.target.checked)}
          inputProps={{ "aria-label": "secondary checkbox" }}
        />
        {/* <CustomCheckBox onClick={applyGreyScale} /> */}
        <FormControl component="fieldset">
          <RadioGroup
            row
            aria-label="position"
            name="position"
            value={mode}
            onChange={(e) => applyFilterValue(0, "mode", e.target.value)}
          >
            <FormControlLabel
              value="average"
              control={<Radio style={{ color: "#1CCAFF" }} color="primary" />}
              label={t("GreyScaleFilter.Avg")}
              labelPlacement="start"
            />
            <FormControlLabel
              value="luminosity"
              control={<Radio style={{ color: "#1CCAFF" }} color="primary" />}
              label={t("GreyScaleFilter.Lum")}
              labelPlacement="start"
            />
            <FormControlLabel
              value="lightness"
              control={<Radio style={{ color: "#1CCAFF" }} color="primary" />}
              label={t("GreyScaleFilter.Light")}
              labelPlacement="start"
            />
          </RadioGroup>
        </FormControl>
      </Paper>
    </div>
  );
};

export default WhiteNBlack;
