/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fabric } from "fabric";
import FooterPanel from "../footerPanel";
import "./midlearea.css";
import { homeConstants } from "../../redux/constants";
import { colorConstants } from "../../colors";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
const {
  CURRENT_CANVAS,
  FRONT_CANVAS,
  BACK_CANVAS,
  FACE,
  CURRENT_ZOOM,
  DRAWING,
  MAX_ZOOM,
} = homeConstants;
const { MIDDLE_PANEL_BG_COLOR, ELEMENT_COLOR } = colorConstants;

const Midlearea = ({ copy, paste, clipboard }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [go, setGo] = useState(false);
  const _cart = useSelector((state) => state.cart);
  const _home = useSelector((state) => state.home);
  useEffect(() => {
    const fc = createFrontCanvas();
    const bc = createBackCanvas();
    // set current Canvas
    dispatch({
      type: CURRENT_CANVAS,
      payload: fc,
    });
    // set front Canvas
    dispatch({
      type: FRONT_CANVAS,
      payload: fc,
    });
    // set back Canvas
    dispatch({
      type: BACK_CANVAS,
      payload: bc,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const giveStaticScallingFactor = () => {
    return (
      _cart.selectedProduct.canvases[0].height / (window.screen.height * 0.65)
    );
  };

  useEffect(() => {
    dispatch({
      type: MAX_ZOOM,
      payload: giveStaticScallingFactor(),
    });
  }, [_cart.selectedProduct]);

  const createFrontCanvas = () =>
    new fabric.Canvas("fCanvas", {
      width:
        _cart.selectedProduct.canvases[0]?.width / giveStaticScallingFactor(),
      height:
        _cart.selectedProduct.canvases[0]?.height / giveStaticScallingFactor(),
      selection: true,
      backgroundColor: "white",
      preserveObjectStacking: true,
    });
  const createBackCanvas = () =>
    new fabric.Canvas("bCanvas", {
      width:
        _cart.selectedProduct.canvases[1]?.width / giveStaticScallingFactor(),
      height:
        _cart.selectedProduct.canvases[1]?.height / giveStaticScallingFactor(),
      selection: true,
      backgroundColor: "white",
      preserveObjectStacking: true,
    });
  useEffect(() => {
    // load zoom logic
    if (_home.currentCanvas) {
      _home.currentCanvas.on("mouse:wheel", function (opt) {
        var delta = opt.e.deltaY;
        var zoom = _home.currentCanvas.getZoom();
        zoom *= 0.999 ** delta;
        if (zoom > 20) zoom = 20;
        if (zoom < 1) zoom = 1;
        _home.currentCanvas.zoomToPoint(
          { x: opt.e.offsetX, y: opt.e.offsetY },
          zoom
        );
        opt.e.preventDefault();
        opt.e.stopPropagation();
      });
    }
  }, [_home.currentCanvas]);

  const handleHandleReloadCanvas = () => {
    var RFrontState = JSON.parse(localStorage.getItem("RFrontState"));
    var RBackState = JSON.parse(localStorage.getItem("RBackState"));
    if (RFrontState) {
      _home.frontCanvas?.clear();
      _home.frontCanvas?.loadFromJSON(RFrontState, function () {
        _home.frontCanvas?.renderAll();
      });
    }
    if (RBackState) {
      _home.backCanvas?.clear();
      _home.backCanvas?.loadFromJSON(RBackState, function () {
        _home.backCanvas?.renderAll();
      });
    }
  };

  useEffect(() => {
    if (_home.comeFromDashboard || go) {
      if (!_home.firstComeOnSite) {
        setGo(true);
        handleHandleReloadCanvas();
      }
    }
  }, [_home.comeFromDashboard, go]);

  const handleCanvousFaceTogle = (cf) => {
    _home.currentCanvas.isDrawingMode = false;
    _home.currentCanvas.discardActiveObject().renderAll();
    dispatch({
      type: DRAWING,
      payload: false,
    });
    if (cf === "front") {
      dispatch({
        type: CURRENT_CANVAS,
        payload: _home.frontCanvas,
      });
      _home.backCanvas.remove(_home.mousecursor1);
      dispatch({
        type: FACE,
        payload: "front",
      });
      _home.backCanvas.selection = false;
      _home.backCanvas.forEachObject(function (o) {
        o.selectable = false;
      });
      _home.frontCanvas.selection = true;
      _home.frontCanvas.forEachObject(function (o) {
        o.selectable = true;
      });
    } else {
      dispatch({
        type: CURRENT_CANVAS,
        payload: _home.backCanvas,
      });
      _home.frontCanvas.remove(_home.mousecursor);
      dispatch({
        type: FACE,
        payload: "back",
      });
      _home.backCanvas.selection = true;
      _home.backCanvas.forEachObject(function (o) {
        o.selectable = true;
      });
      _home.frontCanvas.selection = false;
      _home.frontCanvas.forEachObject(function (o) {
        o.selectable = false;
      });
    }
    dispatch({
      type: CURRENT_ZOOM,
      payload: 1,
    });
  };

  return (
    <div
      style={
        !_home.lP2 && !_home.lP3 && !_home.rpOpen
          ? {
              width: "calc(100vw - 58px - 58px)",
              background: MIDDLE_PANEL_BG_COLOR,
            }
          : _home.lP2 && !_home.lP3 && !_home.rpOpen
          ? {
              width: "calc(100vw - 15vw - 58px - 58px)",
              background: MIDDLE_PANEL_BG_COLOR,
            }
          : !_home.lP2 && _home.lP3 && !_home.rpOpen
          ? {
              width: "calc(100vw - 15vw - 58px - 58px)",
              background: MIDDLE_PANEL_BG_COLOR,
            }
          : !_home.lP2 && !_home.lP3 && _home.rpOpen
          ? {
              width: "calc(100vw - 15vw - 58px)",
              background: MIDDLE_PANEL_BG_COLOR,
            }
          : null
      }
    >
      <ul className="midlearea">
        <table className="canvas-table">
          <tbody>
            <tr>
              <td style={{ marginRight: "20px" }}>
                <Typography
                  style={
                    _home.face === "front"
                      ? {
                          fontWeight: "bold",
                          color: ELEMENT_COLOR,
                        }
                      : {
                          fontWeight: "normal",
                          cursor: "pointer",
                          color: "#4F667F", // #212B36 // #4F667F
                        }
                  }
                  variant="h5"
                  gutterBottom
                  onClick={() => handleCanvousFaceTogle("front")}
                >
                  {t("Midlearea.Front")}
                </Typography>
                <canvas id="fCanvas"></canvas>
              </td>
              <td
                style={
                  _cart.selectedProduct.canvases[1]
                    ? { marginLeft: "20px" }
                    : { display: "none" }
                }
              >
                <Typography
                  style={
                    _home.face === "back"
                      ? {
                          fontWeight: "bold",
                          color: ELEMENT_COLOR,
                        }
                      : {
                          fontWeight: "normal",
                          cursor: "pointer",
                          color: "#4F667F", // #212B36 // #4F667F
                        }
                  }
                  variant="h5"
                  gutterBottom
                  onClick={() => handleCanvousFaceTogle("back")}
                >
                  {t("Midlearea.Back")}
                </Typography>
                <canvas id="bCanvas"></canvas>
              </td>
            </tr>
          </tbody>
        </table>
      </ul>
      <FooterPanel copy={copy} paste={paste} clipboard={clipboard} />
    </div>
  );
};

export default Midlearea;
