import React, { useState } from "react";
import { Checkbox, Grid, Slider } from "@material-ui/core";
import { fabric } from "fabric";
import { useSelector } from "react-redux";

const VibranceOptions = ({ save, saveBack }) => {
  const _home = useSelector((state) => state.home);
  const [vibrance, setVibrance] = useState(0.5);

  const applyFilter = (index, filter) => {
    var obj = _home.currentCanvas.getActiveObject();
    if (obj) {
      obj.filters[index] = filter;
      obj.applyFilters();
      _home.currentCanvas.requestRenderAll();
    }
    if (_home.face === "front") {
      save();
    } else {
      saveBack();
    }
  };

  const applyFilterValue = (index, prop, value) => {
    var obj = _home.currentCanvas.getActiveObject();
    if (obj.filters[index]) {
      obj.filters[index][prop] = value;
      obj.applyFilters();
      _home.currentCanvas.requestRenderAll();
    }
    if (_home.face === "front") {
      save();
    } else {
      saveBack();
    }
  };

  const applyVibrance = (check) => {
    applyFilter(
      8,
      check &&
        new fabric.Image.filters.Vibrance({
          vibrance: parseFloat(vibrance),
        })
    );
  };

  const handleValueChange = (event, newValue) => {
    setVibrance(newValue);
    applyFilterValue(8, "vibrance", parseFloat(newValue));
  };
  return (
    <div>
      Vibrance:
      <Checkbox
        color="primary"
        onClick={(e) => applyVibrance(e.target.checked)}
        inputProps={{ "aria-label": "secondary checkbox" }}
      />
      <Grid container spacing={2} alignItems="center">
        <Grid item xs>
          <Slider
            step={0.01}
            min={0}
            max={1}
            value={typeof vibrance === "number" ? vibrance : 0}
            onChange={handleValueChange}
            aria-labelledby="input-slider"
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default VibranceOptions;
