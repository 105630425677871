/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import IconButton from "../../../../../components/iconButton";
import { colorConstants } from "../../../../../colors";
const { PRODUCT_DETAIL_GREEN_LABLES } = colorConstants;

const Product = ({ index, product }) => {
  return (
    <div key={index} className="dtal-prod">
      <h2 className="fw-300">Product # {index + 1}</h2>
      <p>
        <span
          className="adlable"
          style={{ color: PRODUCT_DETAIL_GREEN_LABLES }}
        >
          productID :
        </span>
        &nbsp;{product.productID}
      </p>
      <p>
        <span
          className="adlable"
          style={{ color: PRODUCT_DETAIL_GREEN_LABLES }}
        >
          name :
        </span>
        &nbsp;{product.name}
        &nbsp;&nbsp;
        <span
          className="adlable"
          style={{ color: PRODUCT_DETAIL_GREEN_LABLES }}
        >
          sku :
        </span>
        &nbsp;{product.sku}
        &nbsp;&nbsp;
        <span
          className="adlable"
          style={{ color: PRODUCT_DETAIL_GREEN_LABLES }}
        >
          childSKU :
        </span>
        &nbsp;{product.childSKU}
      </p>
      <div className="df aic">
        <span
          className="adlable"
          style={{ color: PRODUCT_DETAIL_GREEN_LABLES }}
        >
          color :
        </span>
        &nbsp;
        <span
          style={{
            width: "40px",
            height: "40px",
            display: "inline-block",
            backgroundColor: product.color,
          }}
        />
        &nbsp; &nbsp;
        <span
          className="adlable"
          style={{ color: PRODUCT_DETAIL_GREEN_LABLES }}
        >
          size :
        </span>
        &nbsp;{product.size}
        &nbsp; &nbsp;
        <span
          className="adlable"
          style={{ color: PRODUCT_DETAIL_GREEN_LABLES }}
        >
          quantity :
        </span>
        &nbsp;{product.quantity}
        &nbsp; &nbsp;
        <span
          className="adlable"
          style={{ color: PRODUCT_DETAIL_GREEN_LABLES }}
        >
          price :
        </span>
        &nbsp;{product.price}
        &nbsp; &nbsp;
        <a href={product.frontDownloadURL} target="_blank">
          <IconButton
            text="Front Preview"
            onClick={() => {}}
            active={false}
          ></IconButton>
        </a>
        &nbsp; &nbsp;
        <a href={product.backDownloadURL} target="_blank">
          <IconButton
            text="Back Preview"
            onClick={() => {}}
            active={false}
          ></IconButton>
        </a>
      </div>
      {/* <p>
        <span className="adlable">trackingID :</span>
        &nbsp;{product.trackingID}
      </p>
      <p>
        <span className="adlable">trackingURL :</span>
        &nbsp;{product.trackingURL}
      </p> */}
    </div>
  );
};

export default Product;
