/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import SimpleInput from "../simpleInput";
import ProductTable from "./productTable";
import AddNewProduct from "./addNewProduct";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import InnerLoader from "../../../components/innerLoader";

const Products = () => {
  const { t } = useTranslation();
  const _order = useSelector((state) => state.order);
  const [newProduct, setNewProduct] = useState(false);
  const [searchProdIdText, setSearchProdIdText] = useState("");
  const [editing, setEditing] = useState(false);
  const [productsLoading, setProductsLoading] = useState(false);
  const [editingItem, setEditingItem] = useState(null);
  const [productsGloble, setProductsGloble] = useState([]);
  const [products, setProducts] = useState([]);
  const [productFilter, setProductFilter] = useState({
    productId: "",
  });

  useEffect(() => {
    setProducts(_order.products);
    setProductsGloble(_order.products);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_order.products]);

  const handleField = (e) => {
    setProductFilter({ ...productFilter, [e.target.name]: e.target.value });
  };

  const handleAddNew = () => {
    setNewProduct(true);
  };

  const searchByProductId = (searchText) => {
    setSearchProdIdText(searchText);
    if (searchText === "") {
      setProducts(productsGloble);
    } else {
      setProducts(
        products.filter((x) =>
          x?.productID?.toLowerCase().includes(searchText.toLowerCase())
        )
      );
    }
  };
  return (
    <div className="orders" style={{ position: "relative" }}>
      {newProduct || editing ? (
        <AddNewProduct
          setNewProduct={setNewProduct}
          editing={editing}
          setEditing={setEditing}
          editingItem={editingItem}
        />
      ) : (
        <>
          <h1>{t("Products.ProductsList")}</h1>
          <div className="filters-wrapper df jcsb aic mb-20">
            <div>
              <SimpleInput
                name="productId"
                value={searchProdIdText}
                placeholder={t("Products.SearchByProductId")}
                onChange={(e) => searchByProductId(e.target.value)}
              />
            </div>
            &nbsp; &nbsp;
            <Button
              variant="contained"
              component="label"
              style={{
                borderColor: "#2e313f",
                color: "#fff",
                backgroundColor: "#1890FF",
                width: "150px",
              }}
              onClick={() => handleAddNew()}
            >
              {t("Products.AddNew")}
            </Button>
          </div>
          {/* table */}
          <ProductTable
            products={products}
            setProducts={setProducts}
            setEditing={setEditing}
            setEditingItem={setEditingItem}
          />
        </>
      )}
      {_order.productDeletLoading ? <InnerLoader /> : null}
    </div>
  );
};

export default Products;
