import React from "react";

const ThreeDModles = ({ threeDModles, product, setProduct }) => {
  const handleField = (e) => {
    setProduct({ ...product, [e.target.name]: e.target.value });
  };
  return (
    <div>
      <h3 className="fw-300 mt-10 mb-10">Select Model:</h3>
      <select
        style={{ width: "252px" }}
        className="studio-select"
        name="threeDModel"
        value={product.threeDModel}
        onChange={handleField}
      >
        <option disabled>--- Select Model ---</option>
        {threeDModles.map((item, i) => (
          <option key={i} value={item}>
            {item}
          </option>
        ))}
      </select>
    </div>
  );
};

export default ThreeDModles;
