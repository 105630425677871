/* eslint-disable no-unused-vars */
import React from "react";
import Profile from "./profile";
import "./dashboard.css";
import Pictures from "./pictures";
import MyOrders from "./myOrders";
import Orders from "./orders";
import AllOrders from "./allOrders";
import UserManagement from "./userManagement";
import Users from "./users";
import Products from "./products";
import Shipments from "./shipments";
import OrdersDashboard from "./ordersDashboard";
import MyOrdersDashboard from "./myOrdersDashboard";
import { Route, useParams } from "react-router-dom";
import LegalTexts from "./legalTexts";

const DashboardContentArea = ({ activeTab }) => {
  let { tab } = useParams();
  return (
    <div className="dashboard-content-area">
      {tab === "profile" ? <Profile /> : null}
      {tab === "financials" ? <OrdersDashboard /> : null}
      {tab === "pictures" ? <Pictures /> : null}
      {tab === "my-orders" ? <MyOrders /> : null}
      {tab === "products" ? <Products /> : null}
      {tab === "shipments" ? <Shipments /> : null}
      {tab === "legal-texts" ? <LegalTexts /> : null}
      {tab === "orders" ? <Orders /> : null}
      {tab === "all-orders" ? <AllOrders /> : null}
      {tab === "user-management" ? <UserManagement /> : null}
      {tab === "users" ? <Users /> : null}
      {/* <Route path="/dashboard/Profile" element={<Profile />} /> */}
      {/* {activeTab === "Profile" ? <Profile /> : null}
      {activeTab === "Pictures" ? <Pictures /> : null}
      {activeTab === "My Orders" ? <MyOrders /> : null}
      {activeTab === "Orders" ? <Orders /> : null}
      {activeTab === "Products" ? <Products /> : null}
      {activeTab === "Shipments" ? <Shipments /> : null}
      {activeTab === "Financials" ? <OrdersDashboard /> : null}
      {activeTab === "My Dashboard" ? <MyOrdersDashboard /> : null} */}
    </div>
  );
};

export default DashboardContentArea;
