/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import ProductDetail from "./components/productDetail";
import ProductsList from "./components/productsList";
import product1 from "../../assets/images/product1.jpg";
import product2 from "../../assets/images/product2.jpg";
import product3 from "../../assets/images/product3.jpg";
import product4 from "../../assets/images/product4.jpg";
import product5 from "../../assets/images/product5.jpg";
import product6 from "../../assets/images/product6.jpg";
import product7 from "../../assets/images/product7.jpg";
import product8 from "../../assets/images/product8.jpg";
import "./productsPopup.css";
import { colorConstants } from "../../colors";
const { PRODUCT_POPUP_BACKGROUND } = colorConstants;

const ProductsPopup = ({ setProductsPopupOpen }) => {
  const [showDetail, setShowDetail] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({
    image: product1,
    title: "Herren Basic T-Shirt",
    price: "7.56 €",
    size: "excl. VAT",
  });
  const [products, setProducts] = useState([
    {
      image: product1,
      title: "Herren Basic T-Shirt",
      price: "7.56 €",
      size: "excl. VAT",
    },
    {
      image: product2,
      title: "Unisex Hoodie",
      price: "15.55 €",
      size: "excl. VAT",
    },
    {
      image: product3,
      title: "Men's Tank Top",
      price: "8.82 €",
      size: "excl. VAT",
    },
    {
      image: product4,
      title: "Men's premium shirt",
      price: "9.24 €",
      size: "excl. VAT",
    },
    {
      image: product5,
      title: "Unisex Sweatshirt",
      price: "15.08 €",
      size: "excl. VAT",
    },
    {
      image: product6,
      title: "V-neck men's shirt",
      price: "8.82 €",
      size: "excl. VAT",
    },
    {
      image: product7,
      title: "Plus Size Unisex T-Shirt",
      price: "10.50 €",
      size: "excl. VAT",
    },
    {
      image: product8,
      title: "Zipper",
      price: "23.11 €",
      size: "excl. VAT",
    },
  ]);
  return (
    <div
      className="productsPopup"
      style={{ background: PRODUCT_POPUP_BACKGROUND }}
    >
      {!showDetail ? (
        <ProductsList
          products={products}
          setShowDetail={setShowDetail}
          setSelectedProduct={setSelectedProduct}
          setProductsPopupOpen={setProductsPopupOpen}
        />
      ) : (
        <ProductDetail
          setShowDetail={setShowDetail}
          selectedProduct={selectedProduct}
          setProductsPopupOpen={setProductsPopupOpen}
        />
      )}
    </div>
  );
};

export default ProductsPopup;
