/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import "./ordersDashboard.css";
import MyBarChart from "./components/barChart";
import MyLineChart from "./components/lineChart";
import moment from "moment";
import { useTranslation } from "react-i18next";
import momentTZ from "moment-timezone";
import InnerLoader from "../../../components/innerLoader";
import { useSelector } from "react-redux";

const OrdersDashboard = () => {
  const { t } = useTranslation();
  const _order = useSelector((state) => state.order);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [totalOrderedItems, setTotalOrderedItems] = useState(0);
  const [orders, setOrders] = useState([]);
  const [data, setData] = useState([
    {
      month: "Jan",
      Items: 0,
      Revenue: 0,
    },
    {
      month: "Feb",
      Items: 0,
      Revenue: 0,
    },
    {
      month: "Mar",
      Items: 0,
      Revenue: 0,
    },
    {
      month: "Apr",
      Items: 0,
      Revenue: 0,
    },
    {
      month: "May",
      Items: 0,
      Revenue: 0,
    },
    {
      month: "Jun",
      Items: 0,
      Revenue: 0,
    },
    {
      month: "Jul",
      Items: 0,
      Revenue: 0,
    },
    {
      month: "Aug",
      Items: 0,
      Revenue: 0,
    },
    {
      month: "Sep",
      Items: 0,
      Revenue: 0,
    },
    {
      month: "Oct",
      Items: 0,
      Revenue: 0,
    },
    {
      month: "Nov",
      Items: 0,
      Revenue: 0,
    },
    {
      month: "Dec",
      Items: 0,
      Revenue: 0,
    },
  ]);
  useEffect(() => {
    console.log("_order.studioOrders", _order.studioOrders);
    setOrders(_order.studioOrders);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_order.studioOrders]);

  useEffect(() => {
    if (orders.length > 0) {
      let tempTotalRevenue = 0;
      let tempTotalOrderedItems = 0;
      let janR = 0;
      let febR = 0;
      let marR = 0;
      let aprR = 0;
      let mayR = 0;
      let junR = 0;
      let julR = 0;
      let augR = 0;
      let sepR = 0;
      let octR = 0;
      let novR = 0;
      let decR = 0;
      let janI = 0;
      let febI = 0;
      let marI = 0;
      let aprI = 0;
      let mayI = 0;
      let junI = 0;
      let julI = 0;
      let augI = 0;
      let sepI = 0;
      let octI = 0;
      let novI = 0;
      let decI = 0;
      for (let i = 0; i < orders.length; i++) {
        for (let j = 0; j < orders[i].products.length; j++) {
          tempTotalRevenue =
            tempTotalRevenue +
            parseFloat(orders[i].products[j].unitPrice) *
              parseInt(orders[i].products[j].quantity);
          tempTotalOrderedItems =
            tempTotalOrderedItems + parseInt(orders[i].products[j].quantity);
          var timestamp = orders[i].createdAt;
          const d = new Date(timestamp);
          if (moment(momentTZ(d).tz("Europe/Berlin")).format("MMM") === "Jan") {
            janR =
              janR +
              parseFloat(orders[i].products[j].price) *
                parseInt(orders[i].products[j].quantity);
            janI++;
          } else if (
            moment(momentTZ(d).tz("Europe/Berlin")).format("MMM") === "Feb"
          ) {
            febR =
              febR +
              parseFloat(orders[i].products[j].price) *
                parseInt(orders[i].products[j].quantity);
            febI++;
          } else if (
            moment(momentTZ(d).tz("Europe/Berlin")).format("MMM") === "Mar"
          ) {
            marR =
              marR +
              parseFloat(orders[i].products[j].price) *
                parseInt(orders[i].products[j].quantity);
            marI++;
          } else if (
            moment(momentTZ(d).tz("Europe/Berlin")).format("MMM") === "Apr"
          ) {
            aprR =
              aprR +
              parseFloat(orders[i].products[j].price) *
                parseInt(orders[i].products[j].quantity);
            aprI++;
          } else if (
            moment(momentTZ(d).tz("Europe/Berlin")).format("MMM") === "May"
          ) {
            mayR =
              mayR +
              parseFloat(orders[i].products[j].price) *
                parseInt(orders[i].products[j].quantity);
            mayI++;
          } else if (
            moment(momentTZ(d).tz("Europe/Berlin")).format("MMM") === "Jun"
          ) {
            junR =
              junR +
              parseFloat(orders[i].products[j].price) *
                parseInt(orders[i].products[j].quantity);
            junI++;
          } else if (
            moment(momentTZ(d).tz("Europe/Berlin")).format("MMM") === "Jul"
          ) {
            julR =
              julR +
              parseFloat(orders[i].products[j].price) *
                parseInt(orders[i].products[j].quantity);
            julI++;
          } else if (
            moment(momentTZ(d).tz("Europe/Berlin")).format("MMM") === "Aug"
          ) {
            augR =
              augR +
              parseFloat(orders[i].products[j].price) *
                parseInt(orders[i].products[j].quantity);
            augI++;
          } else if (
            moment(momentTZ(d).tz("Europe/Berlin")).format("MMM") === "Sep"
          ) {
            sepR =
              sepR +
              parseFloat(orders[i].products[j].price) *
                parseInt(orders[i].products[j].quantity);
            sepI++;
          } else if (
            moment(momentTZ(d).tz("Europe/Berlin")).format("MMM") === "Oct"
          ) {
            octR =
              octR +
              parseFloat(orders[i].products[j].price) *
                parseInt(orders[i].products[j].quantity);
            octI++;
          } else if (
            moment(momentTZ(d).tz("Europe/Berlin")).format("MMM") === "Nov"
          ) {
            novR =
              novR +
              parseFloat(orders[i].products[j].price) *
                parseInt(orders[i].products[j].quantity);
            novI++;
          } else if (
            moment(momentTZ(d).tz("Europe/Berlin")).format("MMM") === "Dec"
          ) {
            decR =
              decR +
              parseFloat(orders[i].products[j].price) *
                parseInt(orders[i].products[j].quantity);
            decI++;
          }
        }
      }
      setTotalRevenue(tempTotalRevenue);
      setTotalOrderedItems(tempTotalOrderedItems);
      setData([
        {
          month: "Jan",
          Items: janI,
          Revenue: Math.round(janR * 100) / 100,
        },
        {
          month: "Feb",
          Items: febI,
          Revenue: Math.round(febR * 100) / 100,
        },
        {
          month: "Mar",
          Items: marI,
          Revenue: Math.round(marR * 100) / 100,
        },
        {
          month: "Apr",
          Items: aprI,
          Revenue: Math.round(aprR * 100) / 100,
        },
        {
          month: "May",
          Items: mayI,
          Revenue: Math.round(mayR * 100) / 100,
        },
        {
          month: "Jun",
          Items: junI,
          Revenue: Math.round(junR * 100) / 100,
        },
        {
          month: "Jul",
          Items: julI,
          Revenue: Math.round(julR * 100) / 100,
        },
        {
          month: "Aug",
          Items: augI,
          Revenue: Math.round(augR * 100) / 100,
        },
        {
          month: "Sep",
          Items: sepI,
          Revenue: Math.round(sepR * 100) / 100,
        },
        {
          month: "Oct",
          Items: octI,
          Revenue: Math.round(octR * 100) / 100,
        },
        {
          month: "Nov",
          Items: novI,
          Revenue: Math.round(novR * 100) / 100,
        },
        {
          month: "Dec",
          Items: decI,
          Revenue: Math.round(decR * 100) / 100,
        },
      ]);
    }
  }, [orders]);

  return (
    <ul className="ordersDashboard">
      <div className="df">
        <div className="totalRevenue-box">
          <h3 className="fw-400 title">{t("OrdersDashboard.Revenue")}</h3>
          <h3 className="fw-400 mt-15 value" style={{ color: "#1CCAFF" }}>
            €{totalRevenue.toFixed(2)}
          </h3>
        </div>
        <div className="totalRevenue-box ml20">
          <h3 className="fw-400 title">
            {t("OrdersDashboard.TotalOrderedItems")}
          </h3>
          <h3 className="fw-400 mt-15 value" style={{ color: "#1CCAFF" }}>
            {totalOrderedItems}
          </h3>
        </div>
      </div>
      {/* Number of ordered Items per month */}
      <h3 className="fw-300 mb-20 mt-20" style={{ textAlign: "center" }}>
        {t("OrdersDashboard.TotalRevenuePerMonth")}
      </h3>
      <MyLineChart data={data} XAxisDataKey="month" lineDataKey="Revenue" />
      <h3 className="fw-300 mb-20 mt-20" style={{ textAlign: "center" }}>
        {t("OrdersDashboard.TotalOrderedItemsPerMonth")}
      </h3>
      <MyBarChart data={data} dataKey="Items" />
      {_order.studioOrdersLoading ? <InnerLoader /> : null}
    </ul>
  );
};

export default OrdersDashboard;
