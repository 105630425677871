/* eslint-disable no-unused-vars */
import React, { Suspense, useEffect, useRef, useState } from "react";
import * as THREE from "three";
import { Canvas, useLoader } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
// import polyfill from "@juggle/resize-observer";
import { ResizeObserver } from "@juggle/resize-observer";
import { FBXLoader } from "three-stdlib";
import { useTranslation } from "react-i18next";
import "./threeDCanvous.css";
import femaleShirt from "../../assets/femaletshirt/femaleShirt.fbx";
import maleShirt from "../../assets/maleshirt/maleShirt.fbx";

const ThreeDCanvous = ({
  handleRefresh3DView,
  threeDView,
  hiddenCanvas,
  rpOpen,
  model,
  setThreeDView,
}) => {
  const [reload, setReload] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    setReload(true);
  }, [reload]);

  const handleReload = () => {
    setReload(false);
    handleRefresh3DView();
  };
  const Model = ({ url, ...props }) => {
    let scene = useLoader(FBXLoader, url);
    var map = new THREE.CanvasTexture(hiddenCanvas);
    scene.traverse((child) => {
      if (child.isMesh) {
        child.material.map = map;
      }
    });
    return <primitive object={scene} {...props} />;
  };
  return (
    <div
      className="three-d-canvas"
      style={
        !threeDView ? { display: "none" } : !rpOpen ? { right: "58px" } : null
      }
    >
      <i
        className="ri-refresh-line threeDRefresh"
        onClick={() => handleReload()}
      >
        {t("ThreeDCanvous.Refresh")}
      </i>
      <i
        className="ri-close-line product-popup-close-icon"
        style={{
          zIndex: 5,
          top: 20,
        }}
        onClick={() => setThreeDView(false)}
      ></i>
      {reload ? (
        <Canvas
          resize={{ polyfill: ResizeObserver }}
          style={{ width: 600, height: 800 }}
          pixelRatio={[1, 2]}
          camera={{ position: [0, 2, 5], fov: 50 }}
        >
          <directionalLight position={[20, 10, 5]} intensity={0.4} />
          <ambientLight position={[10, 10, -10]} intensity={0.3} />
          {/* <hemisphereLight position={[10, 10, -5]} intensity={0.5} /> */}
          {/* <spotLight position={[4, 7, 23]} intensity={0.1} /> */}
          {/* <spotLight position={[4, 7, 23]} intensity={0.1} /> */}
          <Suspense fallback={null}>
            <Model
              position-y={-0.5}
              scale={[0.1, 0.1, 0.1]}
              url={model === "Female Basic T-Shirt" ? femaleShirt : maleShirt}
              // url="https://firebasestorage.googleapis.com/v0/b/jandiprintdev.appspot.com/o/Jandiprint%2Fstatic%2Fshirt_FBX6.fbx?alt=media&token=aac91f43-c2e7-4327-8e3b-43c3ab8f4d8b"
            />
            {/* <Shirt url={shirt_FBX6}/> */}
          </Suspense>
          <OrbitControls />
        </Canvas>
      ) : null}
    </div>
  );
};

export default ThreeDCanvous;
