import React from "react";
import { Button } from "@material-ui/core";
import "./customButton.css";

const CustomButton = ({ text, onClick, bgColor }) => {
  return (
    <Button
      variant="contained"
      component="label"
      style={{
        marginBottom: "10px",
        borderColor: "#212B36",
        color: "#fff",
        backgroundColor: bgColor ? bgColor : "#212B36",
        width: "100%",
      }}
      onClick={() => onClick()}
    >
      {text}
    </Button>
  );
};

export default CustomButton;
