import React from "react";
import CanvasInput from "../addCanvas/canvasInput";

const Name = ({ product, setProduct }) => {
  const handleField = (e) => {
    setProduct({ ...product, [e.target.name]: e.target.value });
  };
  return (
    <>
      <h3 className="fw-300 mt-10 mb-10">Name:</h3>
      <CanvasInput
        name="name"
        type="text"
        value={product.name}
        onChange={handleField}
        placeholder="Product Name"
      />
    </>
  );
};

export default Name;
