/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from "react";
import { sendEmailVerification } from "firebase/auth";
import { Menu, ShoppingCart } from "@material-ui/icons";
import logo from "../../assets/images/jandilogo.png";
import "./header.css";
import { Link, useNavigate } from "react-router-dom";
import { makeStyles, Paper } from "@material-ui/core";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "../../contexts/AuthContext";
import { useDispatch, useSelector } from "react-redux";
import { colorConstants } from "../../colors";
import { cartConstants } from "../../redux/constants";
import IconButton from "../iconButton";
import ProductsPopup from "../productsPopup";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
const { HEADER_APP_BG_COLOR, ELEMENT_COLOR } = colorConstants;
const { SET_CART } = cartConstants;

toast.configure();
const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: "#212B36",
    position: "absolute",
    right: "10px",
    top: "50px",
    width: "150px",
    textAlign: "left",
    borderRadius: "5px",
  },
}));

const Header = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const _cart = useSelector((state) => state.cart);
  const { currentUser, logout } = useAuth();
  const [showloginDropdown, setShowloginDropdown] = useState(false);
  const [showlanguageDropdown, setShowlanguageDropdown] = useState(false);
  const [productsPopupOpen, setProductsPopupOpen] = useState(false);

  const handleLanguageChange = (lang) => {
    i18next.changeLanguage(lang);
  };

  const handleLogout = async () => {
    dispatch({
      type: SET_CART,
      payload: [],
    });
    try {
      await logout();
      setShowloginDropdown(false);
    } catch (error) {
      alert("Failed to Log out ");
    }
  };
  const handleCheckOut = async () => {
    if (_cart.cart?.length === 0) {
      toast(t("appHeader.CartEmpty"), {
        type: "error",
        position: toast.POSITION.BOTTOM_CENTER,
      });
      return;
    } else {
      navigate("/checkout");
    }
  };

  const handleHumburgerOpening = () => {
    setShowloginDropdown(!showloginDropdown);
  };
  const handleLanguageDropdownOpening = () => {
    setShowlanguageDropdown(!showlanguageDropdown);
  };

  const handleNavigate = (to) => {
    navigate(to);
    setShowloginDropdown(false);
  };

  const handleProductsPopupOpening = () => {
    setProductsPopupOpen((prev) => !prev);
  };

  const handleSendEmailVerification = () => {
    sendEmailVerification(currentUser).then(() => {
      // Email verification sent!
      toast(t("appHeader.CheckYourEmail"), {
        type: "success",
        position: toast.POSITION.BOTTOM_CENTER,
      });
    });
  };

  const emailNotVarifiedTost = () => {
    toast(t("appHeader.VerifyYourEmailFullFunctionality"), {
      type: "info",
      position: toast.POSITION.BOTTOM_CENTER,
    });
  };

  return (
    <div
      className="header-main-wrapper"
      style={{ background: HEADER_APP_BG_COLOR }}
    >
      <div className="left">
        {/* <button> */}
        <a
          className="df aic"
          style={{ color: "#fff" }}
          href="https://jandiprint.de"
          target="_blank"
        >
          {/* Jandiprint */}
          <img className="logo-img" src={logo} alt="jandiprint.de" />
        </a>
        {/* </button> */}
        &nbsp;&nbsp; &nbsp;&nbsp;
        {_cart.selectedProduct.name === "Dummy Product" ? (
          <div
            className="iconButton"
            onClick={() => handleProductsPopupOpening()}
            style={{ background: "#161c24", border: "1px solid yellow" }}
          >
            {/* <i className="ri-mark-pen-line iconButton-symbol"></i> */}
            <i className="ri-t-shirt-line"></i>&nbsp;&nbsp; Select Product
          </div>
        ) : (
          <IconButton
            text={t("appHeader.Products")}
            onClick={() => handleProductsPopupOpening()}
            active={productsPopupOpen}
          >
            <i className="ri-t-shirt-line"></i>
          </IconButton>
        )}
        {currentUser ? (
          !currentUser?.emailVerified ? (
            <>
              &nbsp; &nbsp;
              <IconButton
                text={t("appHeader.VerifyYourEmail") + currentUser.email}
                onClick={() => handleSendEmailVerification()}
                active={productsPopupOpen}
              >
                {/* <i className="ri-t-shirt-line"></i> */}
              </IconButton>
            </>
          ) : null
        ) : null}
      </div>
      <div className="center">
        {/* <button>Open</button>
        <button>Save</button>
        <button>Batch</button> */}
        <h4 className="product-name">
          <span style={{ color: ELEMENT_COLOR }}>
            {_cart.selectedProduct.name}
          </span>
          {_cart.selectedColor ? (
            <>
              &nbsp; &nbsp;
              <span
                className="h-selected-color"
                style={{ backgroundColor: _cart.selectedColor }}
              />
            </>
          ) : null}
          {_cart.selectedSize ? (
            <>
              &nbsp; &nbsp;
              <span className="h-selected-size">{_cart.selectedSize}</span>
            </>
          ) : null}
        </h4>
      </div>
      <div className="right">
        {/* <button className="header-upgrage-btn">Upgrade</button>
        <button className="header-icon-btn">
          <i className="fal fa-question-circle"></i>
        </button> */}
        <button
          onClick={() => handleCheckOut()}
          className="header-icon-btn"
          style={{ position: "relative" }}
        >
          <span className="cart-q">{_cart.cart?.length}</span>
          <ShoppingCart />
          {/* <i className="fas fa-cart-arrow-down"></i> */}
        </button>
        &nbsp;
        <button
          onClick={() => handleLanguageDropdownOpening()}
          className="header-icon-btn"
        >
          <i className="ri-translate"></i>
          {/* <i className="fas fa-bars"></i> */}
        </button>
        &nbsp;
        <button
          onClick={() => handleHumburgerOpening()}
          className="header-icon-btn"
        >
          <Menu />
          {/* <i className="fas fa-bars"></i> */}
        </button>
        {/* <div
          ref={innerRef}
          style={!showloginDropdown ? { display: "none" } : null}
          className="login-dropdown"
        > */}
        <div
          className="login-dropdown-wrap"
          style={!showlanguageDropdown ? { display: "none" } : null}
          onClick={() => setShowlanguageDropdown(false)}
        >
          <Paper
            className={classes.paper}
            style={!showlanguageDropdown ? { display: "none" } : null}
          >
            <ul className="login-dropdown color-white">
              <>
                <li onClick={() => handleLanguageChange("en")}>English</li>
                <li onClick={() => handleLanguageChange("de")}>Deutsch</li>
              </>
            </ul>
          </Paper>
        </div>
        <div
          className="login-dropdown-wrap"
          style={!showloginDropdown ? { display: "none" } : null}
          onClick={() => setShowloginDropdown(false)}
        >
          <Paper
            className={classes.paper}
            style={!showloginDropdown ? { display: "none" } : null}
          >
            <ul className="login-dropdown">
              {currentUser ? (
                <>
                  {!currentUser?.emailVerified ? (
                    <li onClick={() => emailNotVarifiedTost()}>
                      <i className="fal fa-home color-white"></i>
                      &nbsp;
                      <Link to="/">{t("appHeader.Dashboard")}</Link>
                    </li>
                  ) : (
                    <li onClick={() => handleNavigate("/dashboard")}>
                      <i className="fal fa-home color-white"></i>
                      &nbsp;
                      <Link to="/dashboard">{t("appHeader.Dashboard")}</Link>
                    </li>
                  )}
                  <li
                    onClick={() => handleLogout()}
                    style={{ border: "none" }}
                    className="logout-li"
                  >
                    <i className="fal fa-sign-out color-white"></i>
                    &nbsp;
                    <span className="underlineOnHover">
                      {t("appHeader.SignOut")}
                    </span>
                  </li>
                </>
              ) : (
                <>
                  <li onClick={() => handleNavigate("/signin")}>
                    <i
                      className="fal fa-sign-in"
                      style={{
                        color: "#fff",
                      }}
                    ></i>
                    &nbsp;
                    <Link to="/signin">{t("appHeader.SignIn")}</Link>
                  </li>
                  <li onClick={() => handleNavigate("/signup")}>
                    <i className="fal fa-user-plus color-white"></i>
                    &nbsp;
                    <Link to="/signup">{t("appHeader.Register")}</Link>
                  </li>
                </>
              )}
            </ul>
          </Paper>
        </div>
        {/* </div> */}
      </div>
      {productsPopupOpen ? (
        <div
          className="productsPopupWrapper"
          onClick={() => setProductsPopupOpen(false)}
        />
      ) : null}
      {productsPopupOpen ? (
        <ProductsPopup setProductsPopupOpen={setProductsPopupOpen} />
      ) : null}
    </div>
  );
};

export default Header;
