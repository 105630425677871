import React from "react";
import { fabric } from "fabric";
import "./header.css";
import { useDispatch, useSelector } from "react-redux";
import { homeConstants } from "../../../../redux/constants";
import { useTranslation } from "react-i18next";
const { DRAWING, LP2, LP3 } = homeConstants;

const Header = ({ lP2SelectedOption, mousecursor, mousecursor1 }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const _home = useSelector((state) => state.home);
  const handelBackArrowPress = () => {
    dispatch({
      type: LP3,
      payload: false,
    });
    dispatch({
      type: LP2,
      payload: true,
    });
    dispatch({
      type: DRAWING,
      payload: false,
    });
    _home.currentCanvas.remove(mousecursor);
    _home.currentCanvas.remove(mousecursor1);
    _home.currentCanvas.isDrawingMode = false;
    _home.currentCanvas.requestRenderAll();
    if (fabric.isWebglSupported()) {
      fabric.textureSize = 2048;
    }
  };
  return (
    <div className="p6-header">
      <div className="p6-header-left">
        <i
          className="backarrow fal fa-long-arrow-left"
          onClick={() => handelBackArrowPress()}
        ></i>
        &nbsp; &nbsp;
        <h4>{t(lP2SelectedOption)}</h4>
      </div>

      {/* <i className="minusicon leftIcon fas fa-layer-minus"></i> */}
    </div>
  );
};

export default Header;
