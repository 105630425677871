/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { Paper, Grid, makeStyles } from "@material-ui/core";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../firebase";
import { getStorage, ref, deleteObject } from "firebase/storage";
import { useDispatch, useSelector } from "react-redux";
import { homeConstants } from "../../redux/constants";
import InnerLoader from "../../components/innerLoader";
import Loader from "../../components/loader";
const { UPLOADED_IMAGES } = homeConstants;

const useStyles = makeStyles((theme) => ({
  paper: {
    height: 200,
    width: 200,
    display: "flex",
    background: "#212B36",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  control: {
    padding: theme.spacing(2),
  },
}));

const Pictures = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const _home = useSelector((state) => state.home);
  const [loading, setLoading] = useState(false);
  const { currentUser, dbUserData, userDataLoading } = useAuth();
  const [spacing, setSpacing] = React.useState(2);
  const [pictures, setPictures] = useState([]);
  const [picturesUpdated, setPicturesUpdated] = useState(false);

  useEffect(() => {
    if (dbUserData) {
      let tempArr = [];
      if (dbUserData.pictures) {
        setLoading(true);
        for (let i = 0; i < dbUserData.pictures.length; i++) {
          const w = parseInt(dbUserData.pictures[i].split(/[ ;]+/)[0]);
          const h = parseInt(dbUserData.pictures[i].split(/[ ;]+/)[1]);
          let cf = 0;
          if (w > h) {
            cf = w / 200;
          } else {
            cf = h / 200;
          }
          const nImg = {
            w: w,
            h: h,
            width: parseInt(w / cf),
            height: parseInt(h / cf),
            image: dbUserData.pictures[i].split(/[ ;]+/)[2],
          };
          tempArr.push(nImg);
        }
        setPictures(tempArr);
        setLoading(false);
      }
    }
  }, [dbUserData]);

  const removeImage = (item, i) => {
    let newArray = [];
    setPictures(pictures.filter((p, index) => index !== i));
    for (let i = 0; i < _home.uploadedImages.length; i++) {
      if (
        item.w === _home.uploadedImages[i].width &&
        item.h === _home.uploadedImages[i].height &&
        item.image === _home.uploadedImages[i].image
      ) {
      } else {
        newArray.push(_home.uploadedImages[i]);
      }
    }
    dispatch({
      type: UPLOADED_IMAGES,
      payload: newArray,
    });
    // item.image
    const storage = getStorage();
    // Create a reference to the file to delete
    const desertRef = ref(storage, item.image);

    // Delete the file
    deleteObject(desertRef)
      .then(() => {
        // File deleted successfully
      })
      .catch((error) => {
        // Deletion unsuccessful
        console.log("Image deletion unsuccessful");
      });
    // Remove from No sql
    try {
      const removePicture = httpsCallable(
        functions,
        "dbUsersOnCall-removePicture"
      );
      removePicture({
        width: item.w,
        height: item.h,
        image: item.image,
      }).then((result) => {
        // code here
      });
    } catch (e) {
      console.error("Error removing Image: ", e);
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <ul className="pictures">
        <Grid item xs={12}>
          <Grid container justifyContent="center" spacing={spacing}>
            {pictures.map((item, i) => (
              <Grid key={i} item>
                <Paper className={classes.paper}>
                  {/* <div
                className="totalRevenue-box"
                style={{
                  width: "auto",
                  height: "auto",
                  position: "relative",
                  borderRadius: "10px",
                }}
              > */}
                  <i
                    style={{
                      color: "#FF4842",
                      cursor: "default",
                      position: "absolute",
                      top: "5px",
                      right: "5px",
                    }}
                    className="fal fa-minus-circle"
                    onClick={() => removeImage(item, i)}
                  ></i>
                  <img
                    width={item.width}
                    height={item.height}
                    src={item.image}
                    alt="here"
                  />
                  {/* </div> */}
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </ul>
      {picturesUpdated ? <InnerLoader /> : null}
      {userDataLoading ? <InnerLoader /> : null}
    </div>
  );
};

export default Pictures;
