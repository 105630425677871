import { userCanvasesConstants } from "../constants";
const { USER_CANVASES, SELECTED_CANVAS } = userCanvasesConstants;
const initialState = {
  userCanvases: [],
  selectedCanvas: "",
};

export const userCanvasesReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_CANVASES:
      return {
        ...state,
        userCanvases: action.payload,
      };
    case SELECTED_CANVAS:
      return {
        ...state,
        selectedCanvas: action.payload,
      };
    default:
      return state;
  }
};
