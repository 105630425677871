import React from "react";
import moment from "moment";
import momentTZ from "moment-timezone";
import "./orderInformation.css";

const OrderInformation = ({ selectedOrder }) => {
  console.log("selectedOrder", selectedOrder);
  const totalPrice = () => {
    let total = 0;
    for (let i = 0; i < selectedOrder.products.length; i++) {
      total = total + selectedOrder.products[i].price;
    }
    return total;
  };
  const orderDate = () => {
    const d = new Date(selectedOrder.createdAt);
    const year = moment(momentTZ(d).tz("Europe/Berlin")).format("YYYY");
    const month = moment(momentTZ(d).tz("Europe/Berlin")).format("MM");
    const day = moment(momentTZ(d).tz("Europe/Berlin")).format("DD");
    return year + "-" + month + "-" + day;
  };
  return (
    <div className="orderInformation">
      <div className="orderInformation-left">
        <h3>Order Information</h3>
        <p>
          <span>Order ID</span>
          <span>{selectedOrder.orderID}</span>
        </p>
        <p>
          <span>Date</span>
          <span>{orderDate()}</span>
        </p>
        <p>
          <span>Price</span>
          <span>{totalPrice()}</span>
        </p>
        <p>
          <span>Shipment Cost</span>
          <span>{selectedOrder.shipment.price}</span>
        </p>
        <p>
          <span>Payment method</span>
        </p>
        <p>
          <span>Email</span>
          <span>{selectedOrder.buyerMail}</span>
        </p>
      </div>
      {/* right side */}
      {console.log("selectedOrder", selectedOrder)}
      <div className="orderInformation-right">
        <div className="billingAddress">
          <h3>Billing address</h3>
          <p>
            <span>First name</span>
            <span>{selectedOrder.sellerFirstName}</span>
          </p>
          <p>
            <span>Last name</span>
            <span>{selectedOrder.sellerLastName}</span>
          </p>
          <p>
            <span>Company</span>
            <span>{selectedOrder.sellerCompanyName}</span>
          </p>
          <p>
            <span>Country</span>
            <span>{selectedOrder.buyerBillingAddress.country}</span>
          </p>
          <p>
            <span>Street</span>
            <span>{selectedOrder.buyerBillingAddress.street}</span>
          </p>
          <p>
            <span>HouseNo</span>
            <span>{selectedOrder.buyerBillingAddress.houseNo}</span>
          </p>
          <p>
            <span>City</span>
            <span>{selectedOrder.buyerBillingAddress.city}</span>
          </p>
          <p>
            <span>ZipCode</span>
            <span>{selectedOrder.buyerBillingAddress.zipCode}</span>
          </p>
        </div>
        <div className="shipingAddress">
          <h3>Shipping address</h3>
          <p>
            <span>First name</span>
            <span>{selectedOrder.buyerFirstName}</span>
          </p>
          <p>
            <span>Last name</span>
            <span>{selectedOrder.buyerLastName}</span>
          </p>
          <p>
            <span>Company</span>
            <span>{selectedOrder.buyerCompanyName}</span>
          </p>
          <p>
            <span>Country</span>
            <span>{selectedOrder.buyerShippingAddress.country}</span>
          </p>
          <p>
            <span>Street</span>
            <span>{selectedOrder.buyerShippingAddress.street}</span>
          </p>
          <p>
            <span>HouseNo</span>
            <span>{selectedOrder.buyerShippingAddress.houseNo}</span>
          </p>
          <p>
            <span>City</span>
            <span>{selectedOrder.buyerShippingAddress.city}</span>
          </p>
          <p>
            <span>ZipCode</span>
            <span>{selectedOrder.buyerShippingAddress.zipCode}</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default OrderInformation;
