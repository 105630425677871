import React from "react";

const CanvasInput = ({
  width,
  index,
  name,
  type,
  value,
  onChange,
  placeholder,
  readOnly,
}) => {
  return (
    <input
      key={index}
      name={name}
      className="custom-input"
      style={width ? { width: width } : null}
      type={type}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      readOnly={readOnly}
    />
  );
};

export default CanvasInput;
