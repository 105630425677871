// helper to get dimensions of an image
const imageDimensions = (file) =>
  new Promise((resolve, reject) => {
    const img = new Image();

    // the following handler will fire after the successful loading of the image
    img.onload = () => {
      const { naturalWidth: width, naturalHeight: height } = img;
      resolve({ width, height });
    };

    // and this handler will fire if there was an error with the image (like if it's not really an image or a corrupted one)
    img.onerror = () => {
      reject("There was some problem with the image.");
    };

    img.src = URL.createObjectURL(file);
  });

// here's how to use the helper
const getInfo = async (file) => {
  try {
    const dimensions = await imageDimensions(file);
    return dimensions;
  } catch (error) {
    console.error(error);
  }
};

const calcCf = (w, h, n) => {
  let cf = 0;
  if (w > h) {
    cf = w / n;
  } else {
    cf = h / n;
  }
  return cf;
};
export { getInfo, calcCf };
