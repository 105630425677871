import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
import { Typography } from "@material-ui/core";

const PrettoSlider = withStyles({
  root: {
    color: "#B78103",
    height: 8,
    width: "90%",
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#7A4F01",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

const CustomSlider = ({
  title,
  value,
  min,
  max,
  step,
  handleChange,
  disable,
}) => {
  return (
    <div>
      <Typography gutterBottom>{title}</Typography>
      <PrettoSlider
        value={value}
        min={min}
        max={max}
        step={step}
        onChange={disable ? null : handleChange}
        defaultValue={value}
        valueLabelDisplay="auto"
        aria-label="pretto slider"
      />
    </div>
  );
};

export default CustomSlider;
