import { Select, MenuItem, makeStyles } from "@material-ui/core";
import countries from "i18n-iso-countries";
// Import the languages you want to use
import enLocale from "i18n-iso-countries/langs/en.json";
import itLocale from "i18n-iso-countries/langs/it.json";

const useStyles = makeStyles({
  select: {
    border: "1px solid #1CCAFF !important",
  },
  icon: {
    fill: "#1CCAFF !important",
  },
  root: {
    color: "white",
  },
});

const CountrySelector = ({ value, onChange }) => {
  const classes = useStyles();

  // Have to register the languages you want to use
  countries.registerLocale(enLocale);
  countries.registerLocale(itLocale);

  // Returns an object not a list
  // const countryObj = countries.getNames("en", { select: "official" });

  // const countryArr = Object.entries(countryObj).map(([key, value]) => {
  //   return {
  //     label: value,
  //     value: key,
  //   };
  // });

  return (
    <div>
      <Select
        className={classes.select}
        name="country"
        inputProps={{
          classes: {
            icon: classes.icon,
            root: classes.root,
          },
        }}
        placeholder="Select..."
        style={{
          width: "100%",
          height: "40px",
          fontSize: "14px",
          color: "white",
        }}
        value={value}
        onChange={onChange}
        variant="outlined"
      >
        {/* {!!countryArr?.length &&
          countryArr.map(({ label, value }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))} */}
        <MenuItem value="DE">Germany</MenuItem>
        <MenuItem value="PK">Pakistan</MenuItem>
      </Select>
    </div>
  );
};
export default CountrySelector;
