import React from "react";
import "./customInput.css";

const CustomInput = ({
  type,
  name,
  label,
  value,
  onChange,
  placeholder,
  column,
}) => {
  return (
    <div style={column ? { display: "block" } : null} className="input-fileld">
      <div
        style={column ? { width: "100%", marginBottom: "10px" } : null}
        className="left"
      >
        {label}:&nbsp;&nbsp;
      </div>
      <div className="right">
        <input
          className="custom-input"
          name={name}
          value={value}
          type={type ? type : "text"}
          placeholder={placeholder}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default CustomInput;
