/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { functions } from "../../../firebase";
import { httpsCallable } from "firebase/functions";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useTranslation } from "react-i18next";
import "./legalTexts.css";
import BigEditor from "../../../components/bigEditor";
import { Button } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useAuth } from "../../../contexts/AuthContext";
import InnerLoader from "../../../components/innerLoader";

const LegalTexts = () => {
  const { t } = useTranslation();
  const { dbUserData } = useAuth();
  const [loading, setLoading] = useState(false);
  const _productOptions = useSelector((state) => state.productOptions);
  const [legal, setLegal] = useState({
    companyDetails: dbUserData?.legal?.companyDetails
      ? dbUserData?.legal.companyDetails
      : "",
    termsConditions: dbUserData?.legal?.termsConditions
      ? dbUserData.legal.termsConditions
      : "",
    dataProtection: dbUserData?.legal?.dataProtection
      ? dbUserData.legal.dataProtection
      : "",
    revocation: dbUserData?.legal?.revocation
      ? dbUserData?.legal?.revocation
      : "",
  });

  // {
  //   studioId: 'lskjlskd',
  //   legal: legal
  // }

  const handleCompanyDetailsChange = (e, editor) => {
    setLegal({ ...legal, companyDetails: editor.getData() });
  };
  const handleTermsConditionsChange = (e, editor) => {
    setLegal({ ...legal, termsConditions: editor.getData() });
  };
  const handleDataProtectionChange = (e, editor) => {
    setLegal({ ...legal, dataProtection: editor.getData() });
  };
  const handleRevocationChange = (e, editor) => {
    setLegal({ ...legal, revocation: editor.getData() });
  };

  const handleSubmit = () => {
    // setLegal
    setLoading(true);
    const setLegal = httpsCallable(functions, "dbUsersOnCall-setLegal");
    setLegal({
      studioId: _productOptions.selectedStudio.userID,
      legal: legal,
    }).then((result) => {
      console.log("setLegal: ", result);
      setLoading(false);
    });
  };

  return (
    <ul className="legalTexts" style={{ position: "relative" }}>
      <h2 className="fw-300">{t("LegalTexts.LegalTexts")}</h2>
      {/* Company Details */}
      <BigEditor
        lable={t("LegalTexts.CompanyDetails")}
        data={legal.companyDetails}
        onChange={handleCompanyDetailsChange}
      />
      {/* Terms Conditions */}
      <BigEditor
        lable={t("LegalTexts.TermsConditions")}
        data={legal.termsConditions}
        onChange={handleTermsConditionsChange}
      />
      {/* Data Protection */}
      <BigEditor
        lable={t("LegalTexts.DataProtection")}
        data={legal.dataProtection}
        onChange={handleDataProtectionChange}
      />
      {/* Revocation */}
      <BigEditor
        lable={t("LegalTexts.Revocation")}
        data={legal.revocation}
        onChange={handleRevocationChange}
      />
      <div className="submit-btn-wrapper">
        <Button
          variant="contained"
          component="label"
          style={{
            borderColor: "#2e313f",
            color: "#fff",
            backgroundColor: "#1890FF",
            width: "250px",
          }}
          onClick={() => handleSubmit()}
        >
          Submit
        </Button>
      </div>
      {loading ? <InnerLoader /> : null}
    </ul>
  );
};

export default LegalTexts;
