/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Checkbox, Typography } from "@material-ui/core";
import { fabric } from "fabric";
import CustomSelect from "../customSelect";
import roboimg from "../../../../assets/images/robot.png";
import CustomCheckBox from "../../../customCheckBox";
import { useSelector } from "react-redux";

const BlendImageOptions = () => {
  const _home = useSelector((state) => state.home);
  const [blendImageModes, setBlendImageModes] = useState(["Multiply", "Mask"]);
  // const [alpha, setAlpha] = useState(1);

  const [blendColor, setBlendColor] = useState(false);

  var imageElement = document.createElement("img");
  imageElement.src = roboimg;
  var fImage = new fabric.Image(imageElement);
  fImage.scaleX = 1;
  fImage.scaleY = 1;
  fImage.top = 15;
  fImage.left = 15;

  const applyFilter = (index, filter) => {
    var obj = _home.currentCanvas.getActiveObject();
    if (obj) {
      obj.filters[index] = filter;
      obj.applyFilters();
      _home.currentCanvas.requestRenderAll();
    }
  };

  const getFilter = (index) => {
    var obj = _home.currentCanvas.getActiveObject();
    return obj.filters[index];
  };

  const applyFilterValue = (index, prop, value) => {
    var obj = _home.currentCanvas.getActiveObject();
    if (obj.filters[index]) {
      obj.filters[index][prop] = value;
      obj.applyFilters();
      _home.currentCanvas.requestRenderAll();
    }
  };

  const applyBlendColor = (check) => {
    setBlendColor(check);
    applyFilter(
      20,
      check &&
        new fabric.Image.filters.BlendImage({
          image: fImage,
        })
    );
  };

  const changeBlendImageMode = (mode) => {
    applyFilterValue(20, "mode", mode.toLowerCase());
  };

  useEffect(() => {
    if (_home.currentCanvas) {
      _home.currentCanvas.on({
        "selection:updated": HandleElement,
        "selection:created": HandleElement,
      });
    }
  }, [_home.currentCanvas]);
  function HandleElement(obj) {
    // BlendColor
    if (obj.target.filters[20]) {
      setBlendColor(true);
    } else {
      setBlendColor(false);
    }
  }

  return (
    <div>
      Blend Image:
      <Checkbox
        style={{ color: "#1077FA" }}
        color="primary"
        checked={blendColor}
        onClick={(e) => applyBlendColor(e.target.checked)}
        inputProps={{ "aria-label": "secondary checkbox" }}
      />
      {/* <CustomCheckBox onClick={applyBlendColor} /> */}
      <Typography id="input-slider" gutterBottom>
        Mode:
      </Typography>
      <CustomSelect options={blendImageModes} onChange={changeBlendImageMode} />
      <br />
    </div>
  );
};

export default BlendImageOptions;
