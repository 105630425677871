import React from "react";
import IconButton from "../../components/iconButton";
// import CustomButton from "../../components/leftPanel2/components/customButton";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./dashboard.css";

const DashboardSidebar = ({ activeTab, setActiveTab }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userClaims } = useAuth();
  const handleNavigate = (to, tab) => {
    navigate(`/dashboard/${to}`);
    setActiveTab(tab);
  };
  return (
    <div className="dashboard-sidebar">
      <div className="m-10">
        {userClaims?.studio ? (
          <>
            <IconButton
              text={t("DashboardSidebar.Financials")}
              onClick={() =>
                handleNavigate("financials", "DashboardSidebar.Financials")
              }
              active={activeTab === "DashboardSidebar.Financials"}
            >
              <i className="ri-bank-fill"></i>
            </IconButton>
            <IconButton
              text={t("DashboardSidebar.Orders")}
              onClick={() =>
                handleNavigate("orders", "DashboardSidebar.Orders")
              }
              active={activeTab === "DashboardSidebar.Orders"}
            >
              <i className="ri-list-unordered"></i>
            </IconButton>
            <IconButton
              text={t("DashboardSidebar.Products")}
              onClick={() =>
                handleNavigate("products", "DashboardSidebar.Products")
              }
              active={activeTab === "DashboardSidebar.Products"}
            >
              <i className="ri-t-shirt-line"></i>
            </IconButton>
            <IconButton
              text={t("DashboardSidebar.Shipments")}
              onClick={() =>
                handleNavigate("shipments", "DashboardSidebar.Shipments")
              }
              active={activeTab === "DashboardSidebar.Shipments"}
            >
              <i className="ri-ship-2-line"></i>
            </IconButton>
            <IconButton
              text={t("LegalTexts.LegalTexts")}
              onClick={() =>
                handleNavigate("legal-texts", "LegalTexts.LegalTexts")
              }
              active={activeTab === "LegalTexts.LegalTexts"}
            >
              <i className="ri-file-text-line"></i>
            </IconButton>
          </>
        ) : null}
        {userClaims?.user ? (
          <>
            <IconButton
              text={t("DashboardSidebar.Profile")}
              onClick={() =>
                handleNavigate("profile", "DashboardSidebar.Profile")
              }
              active={activeTab === "DashboardSidebar.Profile"}
            >
              <i className="ri-profile-line"></i>
            </IconButton>
            <IconButton
              text={t("DashboardSidebar.Pictures")}
              onClick={() =>
                handleNavigate("pictures", "DashboardSidebar.Pictures")
              }
              active={activeTab === "DashboardSidebar.Pictures"}
            >
              <i className="ri-image-line"></i>
            </IconButton>
            <IconButton
              text={t("DashboardSidebar.MyOrders")}
              onClick={() =>
                handleNavigate("my-orders", "DashboardSidebar.MyOrders")
              }
              active={activeTab === "DashboardSidebar.MyOrders"}
            >
              <i className="ri-list-unordered"></i>
            </IconButton>
          </>
        ) : null}
        {userClaims?.admin ? (
          <>
            <IconButton
              text={t("DashboardSidebar.UserManagement")}
              onClick={() =>
                handleNavigate(
                  "user-management",
                  "DashboardSidebar.UserManagement"
                )
              }
              active={activeTab === "DashboardSidebar.UserManagement"}
            >
              <i className="ri-folder-user-line"></i>
            </IconButton>
            <IconButton
              text={t("DashboardSidebar.Users")}
              onClick={() => handleNavigate("users", "DashboardSidebar.Users")}
              active={activeTab === "DashboardSidebar.Users"}
            >
              <i className="ri-user-3-line"></i>
            </IconButton>
            <IconButton
              text={t("DashboardSidebar.AllOrders")}
              onClick={() =>
                handleNavigate("all-orders", "DashboardSidebar.AllOrders")
              }
              active={activeTab === "DashboardSidebar.AllOrders"}
            >
              <i className="ri-list-unordered"></i>
            </IconButton>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default DashboardSidebar;
