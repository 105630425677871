/* eslint-disable no-sequences */
// /* eslint-disable no-unused-vars */
import React from "react";
import ImageManager from "./components/imageManager";
import Panel3Header from "./components/panel3Header";
import "./leftPanel2.css";
import TextOptions from "./components/textOptions";
import { useDispatch, useSelector } from "react-redux";
import { homeConstants } from "../../redux/constants";
import YourCanvases from "./components/yourCanvases";
import { colorConstants } from "../../colors";
import IconButton from "../iconButton";
import { useTranslation } from "react-i18next";
import ProductColors from "../leftPanel1/components/productColors";
import DrawOptions from "../leftPanel3/components/drawOptions";
const { RP_OPEN, LP2_SELECTED_OPTION, LP2, LP3 } = homeConstants;
const { LEFT_PANEL2_BG_COLOR } = colorConstants;

const LeftPanel2 = ({ save, saveBack }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const _home = useSelector((state) => state.home);

  const handlelP2OptionSelect = (option) => {
    dispatch({
      type: LP2,
      payload: false,
    });
    dispatch({
      type: RP_OPEN,
      payload: false,
    });
    dispatch({
      type: LP2_SELECTED_OPTION,
      payload: option,
    });
    dispatch({
      type: LP3,
      payload: true,
    });
  };

  return (
    <div
      style={
        !_home.lP2
          ? { display: "none", background: LEFT_PANEL2_BG_COLOR }
          : { background: LEFT_PANEL2_BG_COLOR }
      }
      className="leftPanel2-main-wrapper"
    >
      <Panel3Header lP1SelectedOption={_home.lP1SelectedOption} />
      {_home.lP1SelectedOption === "leftPanel1.Colors" ? (
        <ProductColors />
      ) : null}
      {_home.lP1SelectedOption === "leftPanel1.Draw" ? (
        <DrawOptions
          mousecursor={_home.mousecursor}
          mousecursor1={_home.mousecursor1}
        />
      ) : null}
      {_home.lP1SelectedOption === "leftPanel1.ImageManager" ? (
        <ImageManager save={save} saveBack={saveBack} />
      ) : null}
      {_home.lP1SelectedOption === "leftPanel1.Edit" ? (
        <div className="edit-options">
          <IconButton
            text={t("leftPanel2.CropandErase")}
            onClick={() => handlelP2OptionSelect("leftPanel2.CropandErase")}
          >
            <i className="ri-crop-line"></i>
          </IconButton>
          <IconButton
            text={t("leftPanel2.Filters")}
            onClick={() => handlelP2OptionSelect("leftPanel2.Filters")}
          >
            <i className="ri-equalizer-line"></i>
          </IconButton>
          <IconButton
            text={t("leftPanel2.Black&White")}
            onClick={() => handlelP2OptionSelect("leftPanel2.Black&White")}
          >
            <i className="ri-contrast-line"></i>
          </IconButton>
          <IconButton
            text={t("leftPanel2.Colorize")}
            onClick={() => handlelP2OptionSelect("leftPanel2.Colorize")}
          >
            <i className="ri-drop-line"></i>
          </IconButton>
          <IconButton
            text={t("leftPanel2.Alternate")}
            onClick={() => handlelP2OptionSelect("leftPanel2.Alternate")}
          >
            <i className="ri-sound-module-line"></i>
          </IconButton>
          <IconButton
            text={t("leftPanel2.Brightness")}
            onClick={() => handlelP2OptionSelect("leftPanel2.Brightness")}
          >
            <i className="ri-sun-line"></i>
          </IconButton>
          <IconButton
            text={t("leftPanel2.BlendColor")}
            onClick={() => handlelP2OptionSelect("leftPanel2.BlendColor")}
          >
            <i className="ri-printer-line"></i>
          </IconButton>
        </div>
      ) : null}
      {_home.lP1SelectedOption === "leftPanel1.Text" ? (
        <TextOptions
          handlelP2OptionSelect={handlelP2OptionSelect}
          save={save}
          saveBack={saveBack}
        />
      ) : null}
      {_home.lP1SelectedOption === "leftPanel1.YourCanvases" ? (
        <YourCanvases />
      ) : null}
    </div>
  );
};

export default LeftPanel2;
