import React, { useState } from "react";
import { fabric } from "fabric";
import CreateIcon from "@material-ui/icons/Create";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { Checkbox, makeStyles, Paper, Typography } from "@material-ui/core";
import "./drawOptions.css";
import CustomSlider from "../../../customSlider";
import { useDispatch, useSelector } from "react-redux";
import { homeConstants } from "../../../../redux/constants";
import { useTranslation } from "react-i18next";
const { BRUSH_WIDTH, DRAWING } = homeConstants;

const useStyles = makeStyles({
  paper: {
    backgroundColor: "#212B36",
    padding: "10px",
    marginBottom: "10px",
    color: "white",
  },
});

const DrawOptions = ({ mousecursor, mousecursor1 }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const _home = useSelector((state) => state.home);
  // eslint-disable-next-line no-unused-vars
  const [cursorOpacity, setCursorOpacity] = useState(0.5);
  const [brushShadowWidth, setBrushShadowWidth] = useState(20);
  const [brushShadowOfset, setBrushShadowOfset] = useState(20);
  const [brushShadowColor, setBrushShadowColor] = useState("#208");
  const [color, setColor] = useState("#000");
  const handleSliderChange = (event, newValue) => {
    dispatch({
      type: BRUSH_WIDTH,
      payload: newValue,
    });
    var size = parseInt(newValue, 10);
    _home.currentCanvas.freeDrawingBrush.width = size;
    if (_home.face === "") {
      mousecursor
        .center()
        .set({
          radius: size / 2,
        })
        .setCoords()
        .canvas.renderAll();
    } else if (_home.face === "front") {
      mousecursor
        .center()
        .set({
          radius: size / 2,
        })
        .setCoords()
        .canvas.renderAll();
    } else {
      mousecursor1
        .center()
        .set({
          radius: size / 2,
        })
        .setCoords()
        .canvas.renderAll();
    }
    // canvas.requestRenderAll();
  };

  const handleShadowWidthChange = (event, newValue) => {
    setBrushShadowWidth(newValue);
    _home.currentCanvas.freeDrawingBrush.shadow.blur =
      parseInt(newValue, 10) || 0;
    _home.currentCanvas.requestRenderAll();
  };

  const handleShadowOfsetChange = (event, newValue) => {
    setBrushShadowOfset(newValue);
    _home.currentCanvas.freeDrawingBrush.shadow.offsetX =
      parseInt(newValue, 10) || 0;
    _home.currentCanvas.freeDrawingBrush.shadow.offsetY =
      parseInt(newValue, 10) || 0;
    _home.currentCanvas.requestRenderAll();
  };

  const changeDrawingMode = (mode) => {
    // "Pencil", "Circle", "Spray"
    if (mode === "Pencil") {
      _home.currentCanvas.freeDrawingBrush = new fabric.PencilBrush(
        _home.currentCanvas
      );
      _home.currentCanvas.freeDrawingBrush.width = _home.brushWidth;
      _home.currentCanvas.freeDrawingBrush.color = color;
      _home.currentCanvas.freeDrawingBrush.shadow = new fabric.Shadow({
        blur: parseInt(brushShadowWidth.value, 10) || 0,
        offsetX: 0,
        offsetY: 0,
        affectStroke: true,
        color: brushShadowColor,
      });
      _home.currentCanvas.requestRenderAll();
    }
    if (mode === "Circle") {
      _home.currentCanvas.freeDrawingBrush = new fabric.CircleBrush(
        _home.currentCanvas
      );
      _home.currentCanvas.freeDrawingBrush.width = _home.brushWidth;
      _home.currentCanvas.freeDrawingBrush.color = color;
      _home.currentCanvas.freeDrawingBrush.shadow = new fabric.Shadow({
        blur: parseInt(brushShadowWidth.value, 10) || 0,
        offsetX: 0,
        offsetY: 0,
        affectStroke: true,
        color: brushShadowColor,
      });
      _home.currentCanvas.requestRenderAll();
    } else if (mode === "Spray") {
      _home.currentCanvas.freeDrawingBrush = new fabric.SprayBrush(
        _home.currentCanvas
      );
      _home.currentCanvas.freeDrawingBrush.width = _home.brushWidth;
      _home.currentCanvas.freeDrawingBrush.color = color;
      _home.currentCanvas.freeDrawingBrush.shadow = new fabric.Shadow({
        blur: parseInt(brushShadowWidth.value, 10) || 0,
        offsetX: 0,
        offsetY: 0,
        affectStroke: true,
        color: brushShadowColor,
      });
      _home.currentCanvas.requestRenderAll();
    }
  };

  const handleColorChange = (color) => {
    setColor(color);
    _home.currentCanvas.freeDrawingBrush.color = color;
    var bigint = parseInt(color.replace("#", ""), 16);
    var r = (bigint >> 16) & 255;
    var g = (bigint >> 8) & 255;
    var b = bigint & 255;
    if (_home.face === "front") {
      mousecursor
        .center()
        .set({
          fill: "rgba(" + [r, g, b, cursorOpacity].join(",") + ")",
        })
        .setCoords()
        .canvas.renderAll();
    } else {
      mousecursor1
        .center()
        .set({
          fill: "rgba(" + [r, g, b, cursorOpacity].join(",") + ")",
        })
        .setCoords()
        .canvas.renderAll();
    }
  };

  const handleShadowColorChange = (color) => {
    setBrushShadowColor(color);
    _home.currentCanvas.freeDrawingBrush.shadow.color = brushShadowColor;
    _home.currentCanvas.requestRenderAll();
  };

  const handleDrawing = (checked) => {
    var bigint = parseInt(color.replace("#", ""), 16);
    var r = (bigint >> 16) & 255;
    var g = (bigint >> 8) & 255;
    var b = bigint & 255;
    if (checked) {
      _home.currentCanvas.freeDrawingBrush = new fabric.PencilBrush(
        _home.currentCanvas
      );
      _home.currentCanvas.freeDrawingBrush.width = _home.brushWidth;
      _home.currentCanvas.freeDrawingCursor = "none";
      if (_home.face === "front") {
        _home.frontCanvas.add(mousecursor);
        mousecursor
          .center()
          .set({
            radius: _home.brushWidth / 2,
            fill: "rgba(" + [r, g, b, cursorOpacity].join(",") + ")",
          })
          .setCoords()
          .canvas.renderAll();
      } else {
        _home.backCanvas.add(mousecursor1);
        mousecursor1
          .center()
          .set({
            radius: _home.brushWidth / 2,
            fill: "rgba(" + [r, g, b, cursorOpacity].join(",") + ")",
          })
          .setCoords()
          .canvas.renderAll();
      }

      _home.frontCanvas.on("mouse:move", function (evt) {
        var mouse = this.getPointer(evt.e);
        mousecursor
          ?.set({
            top: mouse.y,
            left: mouse.x,
          })
          ?.setCoords()
          ?.canvas?.renderAll();
        //---
        mousecursor1
          ?.set({
            top: -100,
            left: -100,
          })
          ?.setCoords()
          ?.canvas?.renderAll();
      });
      _home.backCanvas.on("mouse:move", function (evt) {
        var mouse = this.getPointer(evt.e);
        mousecursor1
          ?.set({
            top: mouse.y,
            left: mouse.x,
          })
          ?.setCoords()
          ?.canvas?.renderAll();
        // ---
        mousecursor
          ?.set({
            top: -100,
            left: -100,
          })
          ?.setCoords()
          ?.canvas?.renderAll();
      });
      _home.frontCanvas.on("mouse:out", function () {
        // put circle off screen
        mousecursor
          ?.set({
            top: -100,
            right: -100,
          })
          ?.setCoords()
          ?.canvas?.renderAll();
      });
      _home.backCanvas.on("mouse:out", function () {
        // put circle off screen
        mousecursor1
          ?.set({
            top: -100,
            left: -100,
          })
          ?.setCoords()
          ?.canvas?.renderAll();
      });
      // currentMode = modes.drawing;
      _home.currentCanvas.freeDrawingBrush.color = color;
      _home.currentCanvas.freeDrawingBrush.shadow = new fabric.Shadow({
        blur: parseInt(brushShadowWidth.value, 10) || 0,
        offsetX: 0,
        offsetY: 0,
        affectStroke: true,
        color: brushShadowColor,
      });
    } else {
      if (_home.face === "front") {
        _home.currentCanvas.remove(mousecursor);
      } else {
        _home.currentCanvas.remove(mousecursor1);
      }
    }
    _home.currentCanvas.isDrawingMode = checked;
    dispatch({
      type: DRAWING,
      payload: checked,
    });
    _home.currentCanvas.requestRenderAll();
  };

  return (
    <div className="edit-options">
      {t("DrawOptions.Drawing")}:
      <Checkbox
        style={{ color: "#1CCAFF" }}
        color="primary"
        checked={_home.drawing}
        onClick={(e) => handleDrawing(e.target.checked)}
        inputProps={{ "aria-label": "secondary checkbox" }}
      />
      {/* style={!drawing ? { display: "none" } : null} */}
      <div>
        <Paper className={classes.paper}>
          <Typography
            style={{ textAlign: "center" }}
            id="input-slider"
            gutterBottom
          >
            {t("DrawOptions.Modes")}
          </Typography>
          <div className="draw-modes">
            <CreateIcon
              className="cursor-pointer"
              onClick={() => changeDrawingMode("Pencil")}
            />
            <FiberManualRecordIcon
              className="cursor-pointer"
              onClick={() => changeDrawingMode("Circle")}
            />
            <i
              className="fas fa-spray-can cursor-pointer"
              onClick={() => changeDrawingMode("Spray")}
            ></i>
          </div>
        </Paper>
        <Paper className={classes.paper}>
          <CustomSlider
            title={t("DrawOptions.LineWidth")}
            value={_home.brushWidth}
            min={1}
            max={150}
            step={1}
            handleChange={handleSliderChange}
            disable={!_home.drawing}
          />
          <div className="df aic mb-10">
            <span>{t("DrawOptions.LineColor")}:</span>&nbsp;&nbsp;&nbsp;
            <input
              type="color"
              style={{
                width: "40px",
                background: "transparent",
                border: "none",
                cursor: "pointer",
              }}
              onChange={(e) => handleColorChange(e.target.value)}
              className="btn-object-action"
              disabled={!_home.drawing}
            ></input>
          </div>
        </Paper>
        <Paper className={classes.paper}>
          <div className="df aic mb-10">
            <span>{t("DrawOptions.ShadowColor")}:</span>&nbsp;&nbsp;&nbsp;
            <input
              type="color"
              style={{
                width: "40px",
                background: "transparent",
                border: "none",
                cursor: "pointer",
              }}
              bind-value-to="fill"
              onChange={(e) => handleShadowColorChange(e.target.value)}
              className="btn-object-action"
              disabled={!_home.drawing}
            ></input>
          </div>
          <CustomSlider
            title={t("DrawOptions.ShadowWidth")}
            value={brushShadowWidth}
            min={1}
            max={50}
            step={1}
            handleChange={handleShadowWidthChange}
            disable={!_home.drawing}
          />
          <CustomSlider
            title={t("DrawOptions.Shadowoffset")}
            value={brushShadowOfset}
            min={1}
            max={50}
            step={1}
            handleChange={handleShadowOfsetChange}
            disable={!_home.drawing}
          />
        </Paper>
      </div>
    </div>
  );
};

export default DrawOptions;
