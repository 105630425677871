import React, { useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useTranslation } from "react-i18next";

const BigEditor = ({ lable, data, onChange }) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  return (
    <>
      <h3 className="fw-300 mt-10" style={{ color: "#fff" }}>
        {lable}:{" "}
        <button className="showhide" onClick={() => setShow((prev) => !prev)}>
          {show ? t("PopupButton.Hide") : t("PopupButton.Show")}
        </button>
      </h3>
      {show ? (
        <div className="cl-black mt-10 mr-10">
          <CKEditor editor={ClassicEditor} data={data} onChange={onChange} />
        </div>
      ) : null}
    </>
  );
};

export default BigEditor;
