/* eslint-disable no-unused-vars */
import { Grid, makeStyles, Paper } from "@material-ui/core";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import React, { useState } from "react";
import { toast } from "react-toastify";
import UploadImage from "../../uploadImage";
import AddVariant from "../../addVariant";
import { calcCf, getInfo } from "../../../../../util";
import { storage } from "../../../../../firebase";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { useAuth } from "../../../../../contexts/AuthContext";
import CanvasInput from "../../addCanvas/canvasInput";
import { useTranslation } from "react-i18next";
import "./selectedVarient.css";

const useStyles = makeStyles((theme) => ({
  paper: {
    height: 200,
    width: 200,
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#212B36",
    alignItems: "center",
  },
  control: {
    padding: theme.spacing(2),
  },
}));

toast.configure();
const SelectedVarient = ({ product, setProduct, setSelectedVarient, i }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { currentUser } = useAuth();
  const imageInputRef = React.useRef();
  const [progress, setProgress] = useState(0);
  const handleOnDragEnd = (result) => {
    const items = Array.from(product.variants[i].subVariants);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    let newArray2 = [...product.variants];
    newArray2[i] = { ...newArray2[i], subVariants: items };
    setProduct({ ...product, variants: newArray2 });
  };
  const handleVarientUploadClick = async (e, i) => {
    if (e.target.files.length < 1) return;
    const file = e.target.files[0];
    let dimensions = await getInfo(file);
    // change file name
    const myNewFile = new File([file], new Date().getTime() + file.name, {
      type: file.type,
    });
    const sotrageRef = ref(
      storage,
      `images/${currentUser.uid}/products/${myNewFile.name}`
    );
    const uploadTask = uploadBytesResumable(sotrageRef, myNewFile);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(prog);
      },
      (error) => console.log(error),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
          const nImg = {
            width: dimensions.width,
            height: dimensions.height,
            url: downloadURL,
          };
          handleVarientImageURL(nImg, i);
        });
      }
    );
  };
  const handleVarientImageURL = (productImage, i) => {
    let newArray = [...product.variants];
    newArray[i] = { ...newArray[i], imageURL: productImage };
    setProduct({ ...product, variants: newArray });
  };
  const handleVariantChange = (e, i) => {
    let newArray = [...product.variants];
    newArray[i] = { ...newArray[i], [e.target.name]: e.target.value };
    setProduct({ ...product, variants: newArray });
  };
  const handleSubVariantChange = (e, i, j) => {
    if (e.target.name === "quantityInStock") {
      if (e.target.value >= 0) {
        if (e.target.value === "0") {
          // sub varient update
          let newArray1 = [...product.variants[i].subVariants];
          newArray1[j] = {
            ...newArray1[j],
            quantityInStock: e.target.value,
            inStock: false,
          };
          // main varient update
          let newArray2 = [...product.variants];
          newArray2[i] = { ...newArray2[i], subVariants: newArray1 };
          setProduct({ ...product, variants: newArray2 });
        } else {
          // sub varient update
          let newArray1 = [...product.variants[i].subVariants];
          newArray1[j] = { ...newArray1[j], [e.target.name]: e.target.value };
          // main varient update
          let newArray2 = [...product.variants];
          newArray2[i] = { ...newArray2[i], subVariants: newArray1 };
          setProduct({ ...product, variants: newArray2 });
        }
      }
    } else if (e.target.name === "price") {
      // sub varient update
      let newArray1 = [...product.variants[i].subVariants];
      if (e.target.value === "") {
        newArray1[j] = {
          ...newArray1[j],
          [e.target.name]: 0,
        };
      } else if (parseFloat(e.target.value) < 0) {
        newArray1[j] = {
          ...newArray1[j],
          [e.target.name]: 0,
        };
      } else {
        newArray1[j] = {
          ...newArray1[j],
          [e.target.name]: parseFloat(e.target.value),
        };
      }
      // main varient update
      let newArray2 = [...product.variants];
      newArray2[i] = { ...newArray2[i], subVariants: newArray1 };
      setProduct({ ...product, variants: newArray2 });
    } else {
      // sub varient update
      let newArray1 = [...product.variants[i].subVariants];
      newArray1[j] = { ...newArray1[j], [e.target.name]: e.target.value };
      // main varient update
      let newArray2 = [...product.variants];
      newArray2[i] = { ...newArray2[i], subVariants: newArray1 };
      setProduct({ ...product, variants: newArray2 });
    }
  };
  const handleSetStockQuantityInfinity = (i, j) => {
    // sub varient update
    let newArray1 = [...product.variants[i].subVariants];
    newArray1[j] = { ...newArray1[j], quantityInStock: "" };
    // main varient update
    let newArray2 = [...product.variants];
    newArray2[i] = { ...newArray2[i], subVariants: newArray1 };
    setProduct({ ...product, variants: newArray2 });
  };
  const handleVariantInStockChange = (v, i, j) => {
    // sub varient update
    let newArray1 = [...product.variants[i].subVariants];
    if (product.variants[i].name === "") {
      toast(t("AddNewProduct.MissingProductVariantName"), {
        type: "error",
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else if (!product.variants[i].imageURL) {
      toast(t("AddNewProduct.MissingProductVariantImage"), {
        type: "error",
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else if (product.variants[i].subVariants[j].childSKU === "") {
      toast(
        `${t("AddNewProduct.SubVariant")} ${j + 1} ${t(
          "AddNewProduct.MissingProductSubVariantChildSKU"
        )}`,
        {
          type: "error",
          position: toast.POSITION.BOTTOM_CENTER,
        }
      );
    } else if (product.variants[i].subVariants[j].name === "") {
      toast(
        `${t("AddNewProduct.SubVariant")} ${j + 1} ${t(
          "AddNewProduct.MissingProductSubVariantName"
        )}`,
        {
          type: "error",
          position: toast.POSITION.BOTTOM_CENTER,
        }
      );
    } else if (product.variants[i].subVariants[j].price === 0) {
      toast(
        `${t("AddNewProduct.SubVariant")} ${
          j + 1
        } t("AddNewProduct.MissingProductSubVariantPriceNotZero")}`,
        {
          type: "error",
          position: toast.POSITION.BOTTOM_CENTER,
        }
      );
    } else if (product.variants[i].subVariants[j].size === "") {
      toast(
        `${t("AddNewProduct.SubVariant")} ${j + 1} ${t(
          "AddNewProduct.MissingProductSubVariantSize"
        )}`,
        {
          type: "error",
          position: toast.POSITION.BOTTOM_CENTER,
        }
      );
    } else {
      newArray1[j] = { ...newArray1[j], inStock: v };
    }
    // main varient update
    let newArray2 = [...product.variants];
    newArray2[i] = { ...newArray2[i], subVariants: newArray1 };
    setProduct({ ...product, variants: newArray2 });
  };
  const handleVarientPlusButton = (i, j) => {
    // sub varient update
    let newArray1 = [...product.variants[i].subVariants];
    newArray1.splice(i + 1, 0, {
      id: new Date().getTime() + 1,
      childSKU: "",
      currency: "Eur",
      inStock: false,
      name: "",
      price: "",
      size: "",
      quantityInStock: "",
    });
    // main varient update
    let newArray2 = [...product.variants];
    newArray2[i] = { ...newArray2[i], subVariants: newArray1 };
    setProduct({ ...product, variants: newArray2 });
  };
  const handleVarientMinusButton = (i, ind) => {
    if (product.variants[i].subVariants.length > 1) {
      // sub varient update
      let newArray1 = product.variants[i].subVariants.filter(
        (c, indx) => indx !== ind
      );
      // main varient update
      let newArray2 = [...product.variants];
      newArray2[i] = { ...newArray2[i], subVariants: newArray1 };
      setProduct({ ...product, variants: newArray2 });
    }
  };
  const handleVarientCloneButton = (i, j) => {
    // sub varient update
    var newArray1 = [...product.variants[i].subVariants];
    let n = { ...newArray1[j], id: new Date().getTime() + 1 };
    newArray1.splice(j + 1, 0, n);
    // main varient update
    let newArray2 = [...product.variants];
    newArray2[i] = { ...newArray2[i], subVariants: newArray1 };
    setProduct({ ...product, variants: newArray2 });
  };
  return (
    <div>
      <h1>
        <i
          className="backarrow fal fa-long-arrow-left"
          onClick={() => setSelectedVarient(false)}
        ></i>
        &nbsp;&nbsp;{" "}
        {product.variants[i].name ? product.variants[i].name : "Name"}
      </h1>
      <Grid container>
        <Grid item xs={12} sm={3} className="p-10">
          <Grid item xs={12} sm={12} className="p-10">
            <CanvasInput
              type="text"
              value={product.variants[i].name}
              name="name"
              onChange={(e) => handleVariantChange(e, i)}
              placeholder="Name"
              width="80%"
            />
          </Grid>
          <Grid item xs={12} sm={12} className="p-10">
            <div className="image-wrap">
              {!product.variants[i].imageURL ? (
                <h1 className="warning-upload-img">Upload Image</h1>
              ) : null}
              <Paper className={classes.paper}>
                <img
                  src={
                    product.variants[i].imageURL
                      ? product.variants[i].imageURL.url
                      : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRXLUSQTsvEWvFsaGTxqLq9s8CwrzjJHOtUkA&usqp=CAU"
                  }
                  width={
                    product.variants[i].imageURL
                      ? product.variants[i].imageURL.width /
                        calcCf(
                          product.variants[i].imageURL.width,
                          product.variants[i].imageURL.height,
                          200
                        )
                      : "100%"
                  }
                  height={
                    product.variants[i].imageURL
                      ? product.variants[i].imageURL.height /
                        calcCf(
                          product.variants[i].imageURL.width,
                          product.variants[i].imageURL.height,
                          200
                        )
                      : "100%"
                  }
                  alt="varimg"
                />
              </Paper>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} className="p-10">
            <UploadImage
              handleUploadClick={(e) => handleVarientUploadClick(e, i)}
              imageInputRef={imageInputRef}
            />
          </Grid>
          <Grid item xs={12} sm={12} className="p-10 df aic">
            Color:
            <input
              type="color"
              style={{ width: "40px" }}
              value={product.variants[i].color}
              name="color"
              onChange={(e) => handleVariantChange(e, i)}
              className="btn-object-action color"
            ></input>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={9}>
          {product.variants[i].subVariants?.length > 0 ? (
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="characters">
                {(provided) => (
                  <ul
                    className="characters"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {product.variants[i].subVariants?.map((sv, j) => (
                      <Draggable
                        key={sv.id}
                        draggableId={sv.id.toString()}
                        index={j}
                      >
                        {(provided) => (
                          <li
                            key={j}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            id={sv.id}
                          >
                            <AddVariant
                              index={j}
                              childSKU={sv.childSKU}
                              currency={sv.currency}
                              inStock={sv.inStock}
                              name={sv.name}
                              price={sv.price}
                              size={sv.size}
                              type={sv.type}
                              quantityInStock={sv.quantityInStock}
                              handleSubVariantChange={(e) =>
                                handleSubVariantChange(e, i, j)
                              }
                              //   handleVarientUploadClick={(e) =>
                              //     handleVarientUploadClick(e, i)
                              //   }
                              //   handleVariantChange={(e) => handleVariantChange(e, i)}
                              handleVariantInStockChange={(v) =>
                                handleVariantInStockChange(v, i, j)
                              }
                              handleVarientPlusButton={() =>
                                handleVarientPlusButton(i, j)
                              }
                              handleVarientCloneButton={() =>
                                handleVarientCloneButton(i, j)
                              }
                              handleVarientMinusButton={() =>
                                handleVarientMinusButton(i, j)
                              }
                              handleSetStockQuantityInfinity={() =>
                                handleSetStockQuantityInfinity(i, j)
                              }
                            />
                          </li>
                        )}
                      </Draggable>
                    ))}
                  </ul>
                )}
              </Droppable>
            </DragDropContext>
          ) : null}
        </Grid>
      </Grid>
    </div>
  );
};

export default SelectedVarient;
