/* eslint-disable no-unused-vars */
import React from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  // Legend,
  ResponsiveContainer,
} from "recharts";
import { colorConstants } from "../../../../../colors";
const { BARCHART_BAR_FILL_COLOR } = colorConstants;

const MyBarChart = ({ data, dataKey }) => {
  return (
    <div className="mb-20">
      <ResponsiveContainer width="100%" aspect={4}>
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 10,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" tick={{ stroke: "white", strokeWidth: 1 }} />
          <YAxis tick={{ stroke: "white", strokeWidth: 1 }} />
          <Tooltip />
          {/* <Legend /> */}
          <Bar dataKey={dataKey} fill={BARCHART_BAR_FILL_COLOR} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default MyBarChart;
