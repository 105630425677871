/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { functions } from "../../firebase";
import { httpsCallable } from "firebase/functions";
import { sendEmailVerification } from "firebase/auth";
import { useAuth } from "../../contexts/AuthContext";
import "./dashboard.css";
import { toast } from "react-toastify";
import CheckBoxWithLabel from "./checkBoxWithLabel";
import { Button, Checkbox } from "@material-ui/core";
import CustomInput from "../../components/customInput";
import CountrySelector from "../../components/countrySelector";
import { useTranslation } from "react-i18next";
import Loader from "../../components/loader";
import { getAuth, updateEmail } from "firebase/auth";
import IconButton from "../../components/iconButton";
import InnerLoader from "../../components/innerLoader";

const Profile = () => {
  const { t } = useTranslation();
  const {
    currentUser,
    dbUserData,
    setDbUserData,
    userDataLoading,
    userClaims,
  } = useAuth();
  const [profileUpdated, setProfileUpdated] = useState(false);
  const [updateEmail1, setUpdateEmail1] = useState(false);
  const [updatedEmail, setUpdatedEmail] = useState(currentUser.email);
  const [reEnteredEmail, setReEnteredEmail] = useState("");

  const [userData, setUserData] = useState({
    userID: currentUser.uid,
    firstName: "",
    lastName: "",
    companyName: "",
    phone: "",
    inSale: false,
    billingAddress: {
      country: "",
      street: "",
      houseNo: "",
      adrLine1: "",
      city: "",
      zipCode: "",
    },
    shippingAddressFlag: false,
    shippingAddress: {
      country: "",
      street: "",
      houseNo: "",
      adrLine1: "",
      city: "",
      zipCode: "",
    },
    pictures: [],
    legal: null,
  });

  useEffect(() => {
    if (dbUserData) {
      setUserData({
        userID: currentUser.uid,
        firstName: dbUserData.firstName ? dbUserData.firstName : "",
        lastName: dbUserData.lastName ? dbUserData.lastName : "",
        companyName: dbUserData.companyName ? dbUserData.companyName : "",
        phone: dbUserData.phone ? dbUserData.phone : "",
        inSale: dbUserData.inSale ? dbUserData.inSale : false,
        billingAddress: {
          country: dbUserData.billingAddress.country
            ? dbUserData.billingAddress.country
            : "",
          street: dbUserData.billingAddress.street
            ? dbUserData.billingAddress.street
            : "",
          houseNo: dbUserData.billingAddress.houseNo
            ? dbUserData.billingAddress.houseNo
            : "",
          adrLine1: dbUserData.billingAddress.adrLine1
            ? dbUserData.billingAddress.adrLine1
            : "",
          city: dbUserData.billingAddress.city
            ? dbUserData.billingAddress.city
            : "",
          zipCode: dbUserData.billingAddress.zipCode
            ? dbUserData.billingAddress.zipCode
            : "",
        },
        shippingAddressFlag: dbUserData.shippingAddressFlag,
        shippingAddress: {
          country: dbUserData.shippingAddress.country
            ? dbUserData.shippingAddress.country
            : "",
          street: dbUserData.shippingAddress.street
            ? dbUserData.shippingAddress.street
            : "",
          houseNo: dbUserData.shippingAddress.houseNo
            ? dbUserData.shippingAddress.houseNo
            : "",
          adrLine1: dbUserData.shippingAddress.adrLine1
            ? dbUserData.shippingAddress.adrLine1
            : "",
          city: dbUserData.shippingAddress.city
            ? dbUserData.shippingAddress.city
            : "",
          zipCode: dbUserData.shippingAddress.zipCode
            ? dbUserData.shippingAddress.zipCode
            : "",
        },
        pictures: dbUserData.pictures,
        legal: dbUserData.legal,
      });
    }
  }, [dbUserData]);

  const handleSendEmailVerification = () => {
    sendEmailVerification(currentUser).then(() => {
      // Email verification sent!
      toast(t("Profile.EmailUpdatePleasecheckyouremail"), {
        type: "success",
        position: toast.POSITION.BOTTOM_CENTER,
      });
    });
  };

  const handleField = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const handleAddress = (e) => {
    setUserData({
      ...userData,
      billingAddress: {
        ...userData.billingAddress,
        [e.target.name]: e.target.value,
      },
    });
  };
  const handleShippingAddressFlag = (checked) => {
    setUserData({
      ...userData,
      shippingAddressFlag: checked,
    });
  };
  const handleinSale = (checked) => {
    setUserData({
      ...userData,
      inSale: checked,
    });
  };
  const handleShippingAddress = (e) => {
    setUserData({
      ...userData,
      shippingAddress: {
        ...userData.shippingAddress,
        [e.target.name]: e.target.value,
      },
    });
  };

  const handleSubmit = () => {
    setProfileUpdated(true);
    const updateUserData = httpsCallable(
      functions,
      "dbUsersOnCall-updateUserData"
    );
    updateUserData(userData).then((result) => {
      setDbUserData(userData);
      setProfileUpdated(false);
    });
  };
  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };
  const handleEmailUpdate = () => {
    if (!validateEmail(updatedEmail)) {
      toast(t("Profile.Pleaseenteravalidemailaddress"), {
        type: "error",
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else {
      if (updatedEmail !== reEnteredEmail) {
        toast(t("Profile.Emaildoesnotmatch"), {
          type: "error",
          position: toast.POSITION.BOTTOM_CENTER,
        });
      } else {
        const auth = getAuth();
        updateEmail(currentUser, updatedEmail)
          .then((result) => {
            // Email updated!
            const setEmail = httpsCallable(functions, "dbUsersOnCall-setEmail");
            setEmail({ userId: currentUser.uid, newEmail: updatedEmail }).then(
              (result) => {
                console.log("setEmail: ", result);
              }
            );
            handleSendEmailVerification();
            setUpdateEmail1(false);
          })
          .catch((error) => {
            // An error occurred
            toast(error.message, {
              type: "error",
              position: toast.POSITION.BOTTOM_CENTER,
            });
            console.log("Error", error.message);
          });
      }
    }
  };
  return (
    <div style={{ position: "relative" }}>
      <div className="m-20">
        <p>
          <strong>{t("Profile.Email")}: </strong>
          {currentUser.email}
        </p>
        <br />
        <div className="content-wrapper">
          <div className="contarea-left">
            <CustomInput
              label={t("Profile.FirstName")}
              name="firstName"
              value={userData.firstName}
              onChange={handleField}
              placeholder={t("Profile.FirstName")}
            />
            <CustomInput
              label={t("Profile.LastName")}
              name="lastName"
              value={userData.lastName}
              onChange={handleField}
              placeholder={t("Profile.LastName")}
            />
            <CustomInput
              label={t("Profile.CompanyName")}
              name="companyName"
              value={userData.companyName}
              onChange={handleField}
              placeholder={t("Profile.CompanyName")}
            />
            <CustomInput
              label={t("Profile.Phone")}
              name="phone"
              type="tel"
              value={userData.phone}
              onChange={handleField}
              placeholder={t("Profile.Phone")}
            />
            <p className="mb-10">
              <strong>{t("Profile.Address")}:</strong>
            </p>
            <div className="df aic jcsb mb-10">
              <span style={{ width: "120px" }}>{t("Profile.Country")}:</span>
              <div style={{ width: "255px" }}>
                <CountrySelector
                  value={userData.billingAddress.country}
                  onChange={handleAddress}
                />
              </div>
            </div>
            <CustomInput
              label={t("Profile.Street")}
              name="street"
              value={userData.billingAddress.street}
              onChange={handleAddress}
              placeholder={t("Profile.Street")}
            />
            <CustomInput
              label={t("Profile.HouseNO")}
              name="houseNo"
              value={userData.billingAddress.houseNo}
              onChange={handleAddress}
              placeholder={t("Profile.HouseNO")}
            />
            <CustomInput
              label={t("Profile.AddressLine1")}
              name="adrLine1"
              value={userData.billingAddress.adrLine1}
              onChange={handleAddress}
              placeholder={t("Profile.AddressLine1")}
            />
            <CustomInput
              label={t("Profile.City")}
              name="city"
              value={userData.billingAddress.city}
              onChange={handleAddress}
              placeholder={t("Profile.City")}
            />
            <CustomInput
              label={t("Profile.ZipCode")}
              name="zipCode"
              value={userData.billingAddress.zipCode}
              onChange={handleAddress}
              placeholder={t("Profile.ZipCode")}
            />
            <CheckBoxWithLabel
              checked={userData.shippingAddressFlag}
              onClick={handleShippingAddressFlag}
              label={t("Profile.if_different_shipming_address")}
            />
          </div>
          <div className="contarea-right">
            {userClaims?.studio ? (
              <div className="seller-functionalities">
                <div className="mb-10">
                  {t("Profile.SellerFunctionalities")}
                </div>
                <CheckBoxWithLabel
                  checked={userData.inSale}
                  onClick={handleinSale}
                  label={t("Profile.InSale")}
                />
              </div>
            ) : null}
            <div
              style={!userData.shippingAddressFlag ? { display: "none" } : null}
            >
              <p className="mb-10">
                <strong>{t("Profile.Shippingaddress")}:</strong>
              </p>
              <div className="df aic jcsb mb-10">
                <span style={{ width: "120px" }}>{t("Profile.Country")}:</span>
                <div style={{ width: "255px" }}>
                  <CountrySelector
                    value={userData.shippingAddress.country}
                    onChange={handleShippingAddress}
                  />
                </div>
              </div>
              <CustomInput
                label={t("Profile.Street")}
                name="street"
                value={userData.shippingAddress.street}
                onChange={handleShippingAddress}
                placeholder={t("Profile.Street")}
              />
              <CustomInput
                label={t("Profile.HouseNO")}
                name="houseNo"
                value={userData.shippingAddress.houseNo}
                onChange={handleShippingAddress}
                placeholder={t("Profile.HouseNO")}
              />
              <CustomInput
                label={t("Profile.AddressLine1")}
                name="adrLine1"
                value={userData.shippingAddress.adrLine1}
                onChange={handleShippingAddress}
                placeholder={t("Profile.AddressLine1")}
              />
              <CustomInput
                label={t("Profile.City")}
                name="city"
                value={userData.shippingAddress.city}
                onChange={handleShippingAddress}
                placeholder={t("Profile.City")}
              />
              <CustomInput
                label={t("Profile.ZipCode")}
                name="zipCode"
                value={userData.shippingAddress.zipCode}
                onChange={handleShippingAddress}
                placeholder={t("Profile.ZipCode")}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="submit-button-wrapper">
        <Button
          variant="contained"
          component="label"
          style={{
            borderColor: "#2e313f",
            color: "#fff",
            backgroundColor: "#1890FF",
            width: "250px",
          }}
          onClick={() => handleSubmit()}
        >
          {t("Profile.Submit")}
        </Button>
        {!updateEmail1 ? (
          <>
            &nbsp; &nbsp; &nbsp;
            <Button
              variant="contained"
              component="label"
              style={{
                borderColor: "#2e313f",
                color: "#fff",
                backgroundColor: "#1890FF",
                width: "250px",
              }}
              onClick={() => setUpdateEmail1(true)}
            >
              {t("Profile.UpdateEmail")}
            </Button>
          </>
        ) : (
          <>
            &nbsp; &nbsp; &nbsp;
            <input
              className="custom-input"
              name="updatedEmail"
              value={updatedEmail}
              type={"email"}
              placeholder={t("Profile.EnterNewEmail")}
              onChange={(e) => setUpdatedEmail(e.target.value)}
            />
            &nbsp; &nbsp; &nbsp;
            <input
              className="custom-input"
              name="reEnteredEmail"
              value={reEnteredEmail}
              type={"email"}
              placeholder={t("Profile.Reenteremail")}
              onChange={(e) => setReEnteredEmail(e.target.value)}
              onPaste={(e) => e.preventDefault()}
            />
            &nbsp; &nbsp;
            <span
              style={{
                display: "inline-block",
                lineHeight: "35px",
                height: "35px",
              }}
              className="iconButton"
              onClick={() => handleEmailUpdate()}
            >
              {/* <i className="ri-mark-pen-line iconButton-symbol"></i> */}
              <>
                <i className="ri-check-double-line"></i>&nbsp;&nbsp;
              </>
              {t("Profile.Update")}
            </span>
          </>
        )}
      </div>
      {profileUpdated ? <Loader /> : null}
      {userDataLoading ? <InnerLoader /> : null}
    </div>
  );
};

export default Profile;
