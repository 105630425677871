import React from "react";

const OrderId = ({ setShowOrderDetail, orderID }) => {
  return (
    <div>
      <h1 className="df aic">
        <i
          className="backarrow fal fa-long-arrow-left"
          onClick={() => setShowOrderDetail(false)}
        ></i>
        {/* &nbsp;&nbsp;Order ID #&nbsp;
        <span className="adlable fw-300">{orderID}</span> */}
      </h1>
    </div>
  );
};

export default OrderId;
