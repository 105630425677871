/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { makeStyles, Paper, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
import React, { useEffect, useState } from "react";
import CustomSlider from "../../../customSlider";
import CustomSelect from "../customSelect";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
  paper: {
    backgroundColor: "#212B36",
    padding: "10px",
    marginBottom: "10px",
    color: "white",
    textAlign: "left",
  },
});

const PrettoSlider = withStyles({
  root: {
    color: "#52af77",
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

const FontsOptions = ({ save, saveBack }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const _home = useSelector((state) => state.home);
  const [fontSize, setFontSize] = useState(38);
  const [fontFamilys, setFontFamilys] = useState([
    "Arial",
    "Myriad Pro",
    "Verdana",
    "Georgia",
    "Courier",
    "Comic Sans MS",
    "Impact",
    "Monaco",
  ]);
  const [customeFontTypes, setCustomeFontTypes] = useState([
    "Normal Fonts",
    "Anime Fonts",
    "Children Fonts",
  ]);
  const [customFontType, setCustomFontType] = useState("");
  const [animeFonts, setAnimeFonts] = useState([
    "AChildishWonders",
    "AttackOnCourier",
    "AvantstileRegular",
    "Chirurgeon",
    "Doreking",
    "EllymonyBlueMsmm",
    "Futurepolis3000",
    "GbshintoRegular",
    "GirlOnAnAlleyChmc",
    "Hangyaku",
    "HirakatanadpJX",
    "KidpixiesNew",
    "Lollipoptron",
    "Monopixies2w43",
    "MsmmBetSeteWDO",
    "NightmareOrphanage",
    "NinjaNaruto",
    "OnePiecezAqL",
    "PokemonSolidNormal",
    "PolandCannedIntoFuture",
    "RaspberryMiltownRegular",
    "RavenSansNbp",
    "RezaZulmiAlfaizi",
    "RobopixiesNew",
    "SheStoleThePleasure",
    "SkyrimouskiRegular",
    "Sofiebrushzt7ZLR",
    "StorkTrekRegular",
    "Tokyosofty1qZ",
    "VerminorikoAki",
  ]);
  const [childrenFonts, setChildrenFonts] = useState([
    "AnakCute513pv",
    "ArbeiBerry",
    "AckiPreschool",
    "AmaliqZ3WV",
    "Artooh",
    "BlubariRegular",
    "BodrumJzRm",
    "CamdenRegular",
    "CandysRGrozzRegular",
    "ClaudaergAEO",
    "ChildrenOnceWhere",
    "Clemira",
    "Cof",
    "CuteAuroraDisplay",
    "CuteAurora",
    "Elisfirstfont",
    "Elissecondfont",
    "FairyPlayground",
    "FilthySunshine",
    "Gosrokanexxl",
    "HennyPenny",
    "JeanSunHoVEol",
    "JlrBaby8adZ",
    "Joker3zl9X",
    "JsKang0GRG",
    "KidsZoneBold",
    "Kidzone",
    "Kintergarden",
    "LegoBlocks",
    "MbakMbik1a00m",
    "Mclaren99XL",
    "MelchiorHandwrittenMedium",
    "MessyHandwrittenRegular",
    "Mory0W14o",
    "MyFirstFontEver",
    "MyLeftFont71vR",
    "PaintLettersGreekExtended",
    "Pequenita",
    "PupilCaligraphic",
    "ReallyFreeALwl7",
    "RestaDisplayFont",
    "Rudiment0gwP",
    "Schoolbell",
    "SiKancilWZdn",
    "SusahuGOYnD",
    "SyifanaK70Ky",
    "TommysFirstAlphabet",
    "TwoTurtleDoves",
    "WatatsukiTechSans",
    "YakumoPreschoolHand",
  ]);
  const [normalFonts, setNormalFonts] = useState([
    "Arial",
    "Myriad Pro",
    "Verdana",
    "Georgia",
    "Courier",
    "Comic Sans MS",
    "Impact",
    "Monaco",
    "Abstractus",
    "AmadeusRegular",
    "ArcaneNine",
    "Arrosex3JPK",
    "BinjaynR5D0",
    "BlazmaX3eVP",
    "BodoAmatBWnd8",
    "ConsulateConsulateRegular",
    "DatcubeZO2g",
    "DatdotK7prl",
    "DonikaYzBGo",
    "EasyPrintRegular",
    "Elizabeth8Mqy0",
    "GihunDORdR",
    "HelpMeowODB",
    "HeyHatersGOY5g",
    "Jengkolz8e74",
    "Justink0Wql4",
    "KurlandOVKaA",
    "LikeSnow6YG8A",
    "LineramaPKL3x",
    "MbkaosEaBP8",
    "NeonSansL3GXE",
    "NightHalloween",
    "OmniMasthead",
    "PartyConfettiRegular",
    "PinzelanvmGwD",
    "PixeloidMono",
    "Quinquefive",
    "RosetypeaxOM5",
    "RushBerryJRvVn",
    "SangGuruOVmgP",
    "SmallburglgO05",
    "SolidSoldier1G87M",
    "SusahuGOYnD",
    "ThinPrintRegular",
    "WartegZV8qz",
    "XTypewriter",
    "Zantung2OYg3",
    "Zector3zxAZ",
  ]);
  const [lineHeight, setLineHeight] = useState(1.5);
  const [charSpacing, setCharSpacing] = useState(200);

  const setActiveStyle = (styleName, value, object) => {
    object = object || _home.currentCanvas.getActiveObject();
    if (!object) return;

    if (object.setSelectionStyles && object.isEditing) {
      var style = {};
      style[styleName] = value;
      object.setSelectionStyles(style);
      object.setCoords();
    } else {
      object.set(styleName, value);
    }

    object.setCoords();
    _home.currentCanvas.requestRenderAll();
    if (_home.face === "front") {
      save();
    } else {
      saveBack();
    }
  };

  const setActiveProp = (name, value) => {
    var object = _home.currentCanvas.getActiveObject();
    if (!object) return;
    object.set(name, value).setCoords();
    _home.currentCanvas.renderAll();
    if (_home.face === "front") {
      save();
    } else {
      saveBack();
    }
  };

  const changeFontFamily = (ff) => {
    setActiveProp("fontFamily", ff.toLowerCase());
  };

  const handleFontSize = (event, newValue) => {
    setFontSize(newValue);
    setActiveStyle("fontSize", parseInt(newValue, 10));
  };

  useEffect(() => {
    if (_home.currentCanvas) {
      _home.currentCanvas.on({
        "selection:updated": HandleElement,
        "selection:created": HandleElement,
      });
    }
  }, [_home.currentCanvas]);
  function HandleElement(obj) {
    // fill
    setFontSize(obj.target["fontSize"]);
    // console.log(obj.target["fontFamily"]);
  }

  const handleLineHeight = (event, newValue) => {
    setLineHeight(newValue);
    setActiveStyle("lineHeight", parseFloat(newValue, 10));
  };

  const handleCharSpacing = (event, newValue) => {
    setCharSpacing(newValue);
    setActiveStyle("charSpacing", newValue);
  };

  return (
    <div className="p-10" style={{ paddingLeft: 5 }}>
      {/* <Typography id="input-slider" gutterBottom>
        Font family:
      </Typography>
      <CustomSelect options={fontFamilys} onChange={changeFontFamily} /> */}
      {/* <br /> */}
      <Typography id="input-slider" gutterBottom>
        {t("FontsOptions.SelectfontCategory")}:
      </Typography>
      <CustomSelect options={customeFontTypes} onChange={setCustomFontType} />
      {customFontType === "Anime Fonts" ? (
        <>
          <br />
          <Typography id="input-slider" gutterBottom>
            Anime Fonts:
          </Typography>
          <CustomSelect options={animeFonts} onChange={changeFontFamily} />
        </>
      ) : null}
      {customFontType === "Children Fonts" ? (
        <>
          <br />
          <Typography id="input-slider" gutterBottom>
            Children Fonts:
          </Typography>
          <CustomSelect options={childrenFonts} onChange={changeFontFamily} />
        </>
      ) : null}
      {customFontType === "Normal Fonts" ? (
        <>
          <br />
          <Typography id="input-slider" gutterBottom>
            Normal Fonts:
          </Typography>
          <CustomSelect options={normalFonts} onChange={changeFontFamily} />
        </>
      ) : null}
      <br />
      <Paper className={classes.paper}>
        <div>
          <CustomSlider
            title={t("FontsOptions.FontSize")}
            value={fontSize}
            min={0}
            max={120}
            step={1}
            handleChange={handleFontSize}
          />
        </div>
      </Paper>
      <Paper className={classes.paper}>
        <CustomSlider
          title={t("FontsOptions.LineHeight")}
          value={lineHeight}
          min={0}
          max={10}
          step={0.1}
          handleChange={handleLineHeight}
        />
      </Paper>
      <Paper className={classes.paper}>
        <CustomSlider
          title={t("FontsOptions.CharSpacing")}
          value={charSpacing}
          min={-200}
          max={800}
          step={10}
          handleChange={handleCharSpacing}
        />
      </Paper>
    </div>
  );
};

export default FontsOptions;
