/* eslint-disable no-unused-vars */
import Home from "./pages/home";
import Signup from "./pages/signup";
import Signin from "./pages/signin";
import Dashboard from "./pages/dashboard";
import "./App.css";
import { AuthProvider } from "./contexts/AuthContext";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import CheckOut from "./pages/checkOut";
import "remixicon/fonts/remixicon.css";
import ForgetPassword from "./pages/forgetPassword";

function App() {
  return (
    <div>
      {/* Remove this later */}
      <span style={{ position: "fixed", left: "-500px" }}>Photo Editor</span>
      <Router>
        <AuthProvider>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/checkout" element={<CheckOut />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/signin" element={<Signin />} />
            <Route path="/forget-password" element={<ForgetPassword />} />
            {/* <Route path="/dashboard" element={<Dashboard />} /> */}
            <Route path="/dashboard" element={<Dashboard />}>
              <Route path="/dashboard/:tab" element={<Dashboard />} />
            </Route>
          </Routes>
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
