/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { calcCf } from "../../../../../../util";
import { toast } from "react-toastify";
import product1 from "../../../../../../assets/images/product1.jpg";
import "./productDetailUpper.css";
import { useDispatch, useSelector } from "react-redux";
import ProdDetailColors from "./components/prodDetailColors";
import { cartConstants } from "../../../../../../redux/constants";
import { useTranslation } from "react-i18next";
const { SELECT_PRODUCT, SELECT_SIZE } = cartConstants;
toast.configure();

const ProductDetailUpper = ({
  setShowDetail,
  selectedProduct,
  setProductsPopupOpen,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const _cart = useSelector((state) => state.cart);
  const [sizes, setSizes] = useState([]);
  const handleProductSelect = () => {
    if (_cart.selectedProduct.name === "Dummy Product") {
      setProductsPopupOpen(false);
      return;
    }
    if (_cart.selectedColor === "") {
      toast(t("ProductDetailUpper.PleaseSelectedColor"), {
        type: "error",
        position: toast.POSITION.BOTTOM_CENTER,
      });
      return;
    }
    if (_cart.selectedSize === "") {
      toast(t("ProductDetailUpper.PleaseSelectedSize"), {
        type: "error",
        position: toast.POSITION.BOTTOM_CENTER,
      });
      return;
    } else {
      setProductsPopupOpen(false);
    }
  };
  const handleBackPress = () => {
    dispatch({
      type: SELECT_PRODUCT,
      payload: {
        name: "Dummy Product",
        sku: "",
        productImage: product1,
        description: "",
        canvases: [
          {
            width: "3484",
            height: "4327",
          },
          {
            width: "3484",
            height: "4327",
          },
        ],
        shipments: [],
        variants: [],
      },
    });
    setShowDetail(false);
  };
  const getSizesOfSelectedColors = () => {
    let newArray = [];
    for (let i = 0; i < _cart.selectedProduct.variants.length; i++) {
      if (_cart.selectedColor === _cart.selectedProduct.variants[i].color)
        newArray.push(_cart.selectedProduct.variants[i].size);
    }
    setSizes(newArray);
  };
  useEffect(() => {
    getSizesOfSelectedColors();
    dispatch({
      type: SELECT_SIZE,
      payload: "",
    });
  }, [_cart.selectedColor]);
  const handleSizeSelect = (s) => {
    dispatch({
      type: SELECT_SIZE,
      payload: s,
    });
  };

  const calculateMinMaxPrice = () => {
    let min = parseFloat(selectedProduct.variants[0].price);
    let max = parseFloat(selectedProduct.variants[0].price);
    for (let i = 0; i < selectedProduct.variants.length; i++) {
      if (parseFloat(selectedProduct.variants[i].price) < min) {
        min = parseFloat(selectedProduct.variants[i].price);
      }
      if (parseFloat(selectedProduct.variants[i].price) > max) {
        max = parseFloat(selectedProduct.variants[i].price);
      }
    }
    if (min === max) {
      return min;
    } else {
      return min + " - " + max;
    }
  };

  return (
    <div className="productDetailUpper">
      {/* Main Image */}
      <div className="mainImage">
        <img
          src={selectedProduct?.productImage?.url}
          width={
            selectedProduct?.productImage?.width /
            calcCf(
              selectedProduct?.productImage?.width,
              selectedProduct?.productImage?.height,
              250
            )
          }
          height={
            selectedProduct?.productImage?.height /
            calcCf(
              selectedProduct?.productImage?.width,
              selectedProduct?.productImage?.height,
              250
            )
          }
          alt="product"
        />
      </div>
      {/* detailInfo */}
      <div className="detailInfo">
        {/* mainTitle */}
        <div className="mainTitle">
          <i
            style={{ color: "white" }}
            className="detaiProdBack fal fa-long-arrow-left"
            onClick={() => handleBackPress()}
          ></i>
          {_cart.selectedProduct?.name}
        </div>
        <div className="colorSizeWrapper">
          {/* prodDetailColors */}
          <ProdDetailColors />
          {/* prodDetailSize */}
          <div className="prodDetailSize">
            <div className="sizeTitle">Sizes</div>
            <ul className="sizes">
              {sizes.length > 0 ? (
                sizes.map((s, i) => (
                  <div
                    key={i}
                    className="size cud"
                    onClick={() => handleSizeSelect(s)}
                  >
                    {s}
                  </div>
                ))
              ) : (
                <p className="mt-15 color-white">
                  {_cart.selectedProduct.name === "Dummy Product"
                    ? "Dummy Product Don't have Sizes"
                    : "Select Color First"}
                </p>
              )}
            </ul>
          </div>
        </div>
        {/* prodDetailPriceSelect */}
        <div className="prodDetailPriceSelect">
          <div className="priceBox">
            <h4>Price</h4>
            <h5>{calculateMinMaxPrice()}</h5>
          </div>
          <div className="selectButtonBox">
            <div
              className="productSelectButton"
              onClick={() => handleProductSelect()}
            >
              <span>Select</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetailUpper;
