import React from "react";
import { colorConstants } from "../../../../colors";
const { PRODUCT_DETAIL_GREEN_LABLES } = colorConstants;

const ShowShipment = ({ shipment }) => {
  return (
    <div className="showaddress">
      <h2 className="fw-300">Shipment</h2>
      <p>
        <span
          className="adlable"
          style={{ color: PRODUCT_DETAIL_GREEN_LABLES }}
        >
          shippingCompany :
        </span>
        &nbsp;{shipment.shippingCompany}
        &nbsp; &nbsp;
        <span
          className="adlable"
          style={{ color: PRODUCT_DETAIL_GREEN_LABLES }}
        >
          status :
        </span>
        &nbsp;{shipment.status}
        &nbsp; &nbsp;
        <span
          className="adlable"
          style={{ color: PRODUCT_DETAIL_GREEN_LABLES }}
        >
          trackingID :
        </span>
        &nbsp;{shipment.trackingID}
        &nbsp; &nbsp;
        <span
          className="adlable"
          style={{ color: PRODUCT_DETAIL_GREEN_LABLES }}
        >
          trackingURL :
        </span>
        &nbsp;{shipment.trackingURL}
      </p>
    </div>
  );
};

export default ShowShipment;
