/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useTranslation } from "react-i18next";
import {
  Paper,
  makeStyles,
  TableBody,
  TableRow,
  TableCell,
  CircularProgress,
} from "@material-ui/core";
import moment from "moment";
import momentTZ from "moment-timezone";
import useTable from "./components/useTable";
import "./orders.css";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    backgroundColor: "#161C24",
  },
});

const MyOrdersTable = ({
  orders,
  setSelectedOrderIndex,
  setShowOrderDetail,
  setSelectedOrder,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const _order = useSelector((state) => state.order);
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  let headCells = [
    { id: "orderID", label: t("MyOrdersTable.OrderID"), disableSorting: false },
    { id: "createdAt", label: t("MyOrdersTable.Date"), disableSorting: false },
    { id: "seller", label: t("MyOrdersTable.Seller"), disableSorting: true },
    {
      id: "products",
      label: t("MyOrdersTable.Products"),
      disableSorting: true,
    },
    { id: "status", label: t("MyOrdersTable.Status"), disableSorting: false },
    { id: "amount", label: t("MyOrdersTable.Amount"), disableSorting: true },
    { id: "actions", label: t("MyOrdersTable.Actions"), disableSorting: true },
  ];

  const handleOrderSelect = (index, order) => {
    setShowOrderDetail(true);
    setSelectedOrder(order);
    setSelectedOrderIndex(index);
  };

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(orders, headCells, filterFn);

  const getTotalPrice = (products) => {
    let totalPrice = 0;
    for (let i = 0; i < products.length; i++) {
      totalPrice = totalPrice + products[i].price;
    }
    return totalPrice;
  };

  const getStatusColor = (status) => {
    if (status === "Initial") {
      return "#1890FF";
    } else if (status === "Printing") {
      return "#FFC107";
    } else if (status === "Delivery") {
      return "#54D62C";
    } else if (status === "Cancel") {
      return "#FF4842";
    }
  };

  return (
    <ul className="table-container">
      <Paper className={classes.pageContent} style={{ background: "#161C24" }}>
        <TblContainer>
          <TblHead />
          <TableBody>
            {recordsAfterPagingAndSorting().map((item, i) => (
              <TableRow key={i}>
                <TableCell style={{ color: "#fff" }}>{item.orderID}</TableCell>
                <TableCell style={{ color: "#fff" }}>
                  {moment(momentTZ(item.createdAt).tz("Europe/Berlin")).format(
                    "MMMM Do YYYY, HH:mm:ss"
                  )}
                </TableCell>
                <TableCell style={{ color: "#fff" }}>
                  {item.sellerCompanyName}
                </TableCell>
                <TableCell style={{ color: "#fff" }}>
                  {item.products.length === 1
                    ? item.products.map((p, i) => p.subVariantName)
                    : null}
                  {item.products.length === 2
                    ? item.products[0].subVariantName +
                      ", " +
                      item.products[1].subVariantName
                    : null}
                  {item.products.length > 2
                    ? item.products[0].subVariantName +
                      ", " +
                      item.products[1].subVariantName +
                      "..."
                    : null}
                </TableCell>
                <TableCell style={{ color: getStatusColor(item.status) }}>
                  {item.status}
                </TableCell>
                <TableCell style={{ color: "#fff" }}>
                  {getTotalPrice(item.products)}
                </TableCell>
                <TableCell align="center">
                  {/* <VisibilityIcon
                    style={{ color: "#328cc1", cursor: "pointer" }}
                    onClick={() => handleOrderSelect(i, item)}
                  /> */}
                  <i
                    className="ri-edit-box-line"
                    style={{
                      color: "#328cc1",
                      cursor: "pointer",
                      fontSize: "24px",
                    }}
                    onClick={() => handleOrderSelect(i, item)}
                  ></i>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TblContainer>
        {_order.ordersLoading ? (
          <div style={{ textAlign: "center", margin: "20px" }}>
            <CircularProgress style={{ color: "#1CCAFF" }} />
          </div>
        ) : null}
        <TblPagination />
      </Paper>
    </ul>
  );
};

export default MyOrdersTable;
