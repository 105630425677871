import React, { useState } from "react";
import { Grid, Slider, Typography } from "@material-ui/core";

const CharSpacingOptions = ({ setActiveStyle }) => {
  const [charSpacing, setCharSpacing] = useState(200);

  const handleCharSpacing = (event, newValue) => {
    setCharSpacing(newValue);
    setActiveStyle("charSpacing", newValue);
  };
  return (
    <div>
      <Typography id="input-slider" gutterBottom>
        Char Spacing:
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs>
          <Slider
            min={-200}
            max={800}
            step={10}
            value={typeof charSpacing === "number" ? charSpacing : 0}
            onChange={handleCharSpacing}
            aria-labelledby="input-slider"
            style={{ width: "90%" }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default CharSpacingOptions;
