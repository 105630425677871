/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import Recaptcha from "react-google-invisible-recaptcha";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
import { useAuth } from "../../contexts/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../firebase";
//import axios from "axios";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(7),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  notchedOutline: {
    borderWidth: "1px",
    borderColor: "#1CCAFF !important",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  varify: {
    margin: theme.spacing(0, 0, 0),
  },
  floatingLabelFocusStyle: {
    color: "#1CCAFF !important",
  },
  multilineColor: {
    color: "#F9FAFB",
  },
}));

const SignUp = () => {
  const { t } = useTranslation();
  // const recaptch = useRef();
  const navigate = useNavigate();
  const { currentUser, authError, signup } = useAuth();
  const classes = useStyles();
  // const [email, setEmail] = useState("");
  // const [password, setPassword] = useState("");
  // const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [isHuman, setIsHuman] = useState(false);
  const refRecaptcha = useRef(null);

  useEffect(() => {
    if (currentUser) {
      navigate("/");
    }
  }, [currentUser]);

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleEmail = (email) => {
    localStorage.setItem("email", JSON.stringify(email));
  };
  const handlePassword = (password) => {
    localStorage.setItem("password", JSON.stringify(password));
  };
  const handleConfirmPassword = (confirmPassword) => {
    localStorage.setItem("confirmPassword", JSON.stringify(confirmPassword));
  };

  const handleSubmit = () => {
    let email = JSON.parse(localStorage.getItem("email"));
    let password = JSON.parse(localStorage.getItem("password"));
    let confirmPassword = JSON.parse(localStorage.getItem("confirmPassword"));
    if (email.length === 0) {
      setError("* Please, enter email address.");
    } else if (!validateEmail(email)) {
      setError("* Please, enter a valid email address.");
    } else if (password.length < 6) {
      setError("* Password must be at least 6 characters");
    } else if (password !== confirmPassword) {
      setError("Passwords do not match");
    } else {
      try {
        setError("");
        setLoading(true);
        signup(email, password);
        localStorage.removeItem("email");
        localStorage.removeItem("password");
        localStorage.removeItem("confirmPassword");
      } catch (error) {
        setError("Failed to create an account");
      }
    }
    setLoading(false);
  };

  const backToHome = () => {
    navigate("/");
  };

  const sendMessage = (e) => {
    console.log("e.getModifierState sendMessage", e.getModifierState());
    console.log("e sendMessage", e);
    console.log("refRecaptcha sendMessage", refRecaptcha);

    e.preventDefault();
    //refRecaptcha.current.callbacks.reset();
    let email = JSON.parse(localStorage.getItem("email"));
    refRecaptcha.current.callbacks.execute();
    console.log("email in sendMessage", email);
  };

  const onResolved = async (response) => {
    console.log(
      "Recaptcha resolved with response: ",
      refRecaptcha.current.callbacks.getResponse()
    );
    console.log("email", JSON.parse(localStorage.getItem("email")));
    console.log("password", JSON.parse(localStorage.getItem("password")));
    console.log(
      "confirmPassword",
      JSON.parse(localStorage.getItem("confirmPassword"))
    );
    // setEmail(JSON.parse(localStorage.getItem("email")));
    // setPassword(JSON.parse(localStorage.getItem("password")));
    // setConfirmPassword(JSON.parse(localStorage.getItem("confirmPassword")));
    // send token to backend here
    // TODO Cloud Function
    // const secretKey = "6LecwJ4eAAAAAFk3S7PGq27lKfsllIWcns4StFXS";
    // let token = refRecaptcha.current.callbacks.getResponse();
    // var res = await axios.get(
    //   `https://www.google.com/recaptcha/api/siteverify?secret=${secretKey}&response=${token}`
    // );
    // console.log("kh res", res);

    console.log("running cloud function");
    try {
      const recapchaHumanCheck = httpsCallable(
        functions,
        "recapchaOnCall-recapchaHumanCheck"
      );
      await recapchaHumanCheck({ response }).then((result) => {
        console.log(result);
        console.log("result.score", result.score);
        if (result.data.score >= 0.5) {
          handleSubmit();
        } else {
          setError("Very you are a human");
        }
      });
    } catch (e) {
      console.error("Error adding document: ", e);
      setError("Very you are a human");
    }
  };

  const redoCaptcha = () => {
    refRecaptcha.current.callbacks.reset();
    refRecaptcha.current.callbacks.execute();
  };

  const handleChange = (e) => {
    console.log("e handleChange", e);
  };

  return (
    <div>
      <Container component="main" maxWidth="xs">
        <div className={classes.paper}>
          <Typography
            style={{ color: "#F9FAFB", display: "flex", alignItems: "center" }}
            variant="h4"
            gutterBottom
          >
            <i
              className="backarrow fal fa-long-arrow-left"
              onClick={() => backToHome()}
            ></i>
            &nbsp;{t("SignUp.SignUptoJandiprint")}
          </Typography>
          {authError || error ? (
            <>
              <Alert
                style={{ width: "100%", marginTop: "10px" }}
                severity="error"
              >
                {authError}
                {error}
              </Alert>
            </>
          ) : null}
          {/* <form className={classes.form} onSubmit={handleSubmit} noValidate> */}
          <form className={classes.form}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  InputProps={{
                    classes: {
                      notchedOutline: classes.notchedOutline,
                    },
                    className: classes.multilineColor,
                  }}
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                  id="email"
                  label={t("SignUp.EmailAddress")}
                  name="email"
                  onChange={(e) => handleEmail(e.target.value)}
                  autoComplete="email"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  onChange={(e) => handlePassword(e.target.value)}
                  required
                  fullWidth
                  InputProps={{
                    classes: {
                      notchedOutline: classes.notchedOutline,
                    },
                    className: classes.multilineColor,
                  }}
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                  name="password"
                  label={t("SignUp.Password")}
                  type="password"
                  id="password"
                  autoComplete="current-password"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  onChange={(e) => handleConfirmPassword(e.target.value)}
                  required
                  fullWidth
                  InputProps={{
                    classes: {
                      notchedOutline: classes.notchedOutline,
                    },
                    className: classes.multilineColor,
                  }}
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                  name="confirmPassword"
                  label={t("SignUp.ConfirmPassword")}
                  type="password"
                  id="confirmPassword"
                  autoComplete="current-password"
                />
              </Grid>
            </Grid>
            <div
              style={{
                marginTop: "20px",
              }}
              className="df jcc"
            ></div>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <p className="color-white" style={{ color: "#F9FAFB" }}>
                  {t("LegalTexts.ConcentText1")}
                  &nbsp;&nbsp;
                  <a
                    target="_blank"
                    href="https://jandiprint.de/allgemeine-geschaeftsbedingungen/"
                    title="example"
                  >
                    {t("LegalTexts.ConcentText2")}
                  </a>
                </p>
              </Grid>
            </Grid>
            <Button
              type="button"
              fullWidth
              variant="contained"
              style={{ background: "#1890FF", color: "#fff" }}
              className={classes.submit}
              disabled={loading}
              onClick={(e) => sendMessage(e)}
            >
              {t("SignUp.SignUp")}
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <p className="color-white" style={{ color: "#F9FAFB" }}>
                  {t("SignUp.Alreadyhaveanaccount")}&nbsp;&nbsp;
                  <Link to="/signin" style={{ color: "#1CCAFF" }}>
                    {t("SignUp.Signin")}
                  </Link>
                </p>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
      <Recaptcha
        ref={refRecaptcha}
        sitekey="6LecwJ4eAAAAAHCSgeWEvitos5bBfxw0O7jdgs0o"
        onResolved={onResolved}
        onExpired={redoCaptcha}
        onChange={handleChange}
      />
    </div>
  );
};

export default SignUp;
