import { fabric } from "fabric";
import { homeConstants } from "../constants";
const {
  FACE,
  CURRENT_CANVAS,
  FRONT_CANVAS,
  BACK_CANVAS,
  CURRENT_ZOOM,
  MAX_ZOOM,
  BRUSH_WIDTH,
  RP_OPEN,
  DRAWING,
  LP1_SELECTED_OPTION,
  LP2_SELECTED_OPTION,
  LP2,
  LP3,
  UPLOADED_IMAGES,
  R,
  G,
  B,
  COME_FROM_DASHBOARD,
  FIRST_COME_ONE_SITE,
} = homeConstants;
const initialState = {
  // add
  face: "front",
  currentCanvas: null,
  frontCanvas: null,
  backCanvas: null,
  maxZoom: 0,
  currentZoom: 1,
  brushWidth: 20,
  rpOpen: false,
  drawing: false,
  lP1SelectedOption: "",
  lP2SelectedOption: "",
  lP2: false,
  lP3: false,
  uploadedImages: [],
  r: 255,
  g: 255,
  b: 255,
  mousecursor: new fabric.Circle({
    left: -100,
    top: -100,
    radius: 20 / 2,
    fill: "rgba(0,0,0," + 0.5 + ")",
    stroke: "black",
    originX: "center",
    originY: "center",
  }),
  mousecursor1: new fabric.Circle({
    left: -100,
    top: -100,
    radius: 20 / 2,
    fill: "rgba(0,0,0," + 0.5 + ")",
    stroke: "black",
    originX: "center",
    originY: "center",
  }),
  comeFromDashboard: false,
  firstComeOnSite: true,
};

export const homeReducer = (state = initialState, action) => {
  switch (action.type) {
    // firstComeOnSite
    case FIRST_COME_ONE_SITE:
      return {
        ...state,
        firstComeOnSite: action.payload,
      };
    // face
    case FACE:
      return {
        ...state,
        face: action.payload,
      };
    // b
    case B:
      return {
        ...state,
        b: action.payload,
      };
    // g
    case G:
      return {
        ...state,
        g: action.payload,
      };
    // r
    case R:
      return {
        ...state,
        r: action.payload,
      };
    // uploadedImages
    case UPLOADED_IMAGES:
      return {
        ...state,
        uploadedImages: action.payload,
      };
    // lP3
    case LP3:
      return {
        ...state,
        lP3: action.payload,
      };
    // lP2
    case LP2:
      return {
        ...state,
        lP2: action.payload,
      };
    // lP2SelectedOption
    case LP2_SELECTED_OPTION:
      return {
        ...state,
        lP2SelectedOption: action.payload,
      };
    // lP1SelectedOption
    case LP1_SELECTED_OPTION:
      return {
        ...state,
        lP1SelectedOption: action.payload,
      };
    // drawing
    case DRAWING:
      return {
        ...state,
        drawing: action.payload,
      };
    // rp open
    case RP_OPEN:
      return {
        ...state,
        rpOpen: action.payload,
      };
    // brush width
    case BRUSH_WIDTH:
      return {
        ...state,
        brushWidth: action.payload,
      };
    // current zoom
    case CURRENT_ZOOM:
      return {
        ...state,
        currentZoom: action.payload,
      };
    // currentCanvas
    case CURRENT_CANVAS:
      return {
        ...state,
        currentCanvas: action.payload,
      };
    // frontCanvas
    case FRONT_CANVAS:
      return {
        ...state,
        frontCanvas: action.payload,
      };
    // backCanvas
    case BACK_CANVAS:
      return {
        ...state,
        backCanvas: action.payload,
      };
    case MAX_ZOOM:
      return {
        ...state,
        maxZoom: action.payload,
      };
    case COME_FROM_DASHBOARD:
      return {
        ...state,
        comeFromDashboard: action.payload,
      };
    default:
      return state;
  }
};
