import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { filterConstants } from "../../../../../redux/constants";
import { useTranslation } from "react-i18next";
import CustomCheckBox from "../../../../customCheckBox";
const { KODACHROME } = filterConstants;

const KodachromeFilter = ({ applyFilter, fabric }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const _filter = useSelector((state) => state.filter);
  const applyKodachrome = (check) => {
    dispatch({
      type: KODACHROME,
      payload: check,
    });
    applyFilter(18, check && new fabric.Image.filters.Kodachrome());
  };
  return (
    <div>
      <CustomCheckBox
        lable={t("KodachromeFilter.Kodachrome")}
        checked={_filter.kodachrome}
        onClick={(e) => applyKodachrome(e.target.checked)}
      />
    </div>
  );
};

export default KodachromeFilter;
