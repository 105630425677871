import React from "react";
import StatusInOrderDetail from "./components/statusInOrderDetail";
import "./orderDetail.css";
import OrderId from "./orderId";
import OrderDetailTopBoxes from "../../../../components/orderDetailTopBoxes";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../../firebase";
// import ShowAddressFrom from "./showAddressFrom";
// import ShowAddressTo from "./showAddressTo";
import ShowProducts from "./showProducts";
import ShowShipment from "./showShipment";
import OrderInformation from "../../../../components/orderInformation";
import { orderConstants } from "../../../../redux/constants";
import { useDispatch, useSelector } from "react-redux";
const { MY_ORDERS } = orderConstants;

const OrderDetail = ({
  selectedOrder,
  setShowOrderDetail,
  selectedOrderIndex,
  setSelectedOrder,
}) => {
  const dispatch = useDispatch();
  const _order = useSelector((state) => state.order);
  const handleUpdateStatus = (status) => {
    let newArr = [..._order.myOrders];
    newArr[selectedOrderIndex] = {
      ...newArr[selectedOrderIndex],
      status: status,
    };
    setSelectedOrder(newArr[selectedOrderIndex]);
    dispatch({
      type: MY_ORDERS,
      payload: newArr,
    });
    try {
      const updateOrderStatus = httpsCallable(
        functions,
        "dbOrdersOnCall-updateOrderStatus"
      );
      updateOrderStatus({
        orderID: selectedOrder.orderID,
        status: status,
      }).then((result) => {
        // refresh the data on front side
      });
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  return (
    <div className="orderDetail">
      <OrderId
        setShowOrderDetail={setShowOrderDetail}
        orderID={selectedOrder.orderID}
      />
      <OrderDetailTopBoxes selectedOrder={selectedOrder} />
      <StatusInOrderDetail
        status={selectedOrder?.status}
        handleUpdateStatus={handleUpdateStatus}
      />
      <OrderInformation selectedOrder={selectedOrder} />
      <ShowProducts products={selectedOrder.products} />
      <ShowShipment shipment={selectedOrder.shipment} />
    </div>
  );
};

export default OrderDetail;
