import React from "react";
import { Button } from "@material-ui/core";

const UploadImage = ({ handleUploadClick, imageInputRef }) => {
  return (
    <div>
      <Button
        variant="contained"
        component="label"
        style={{
          marginTop: "10px",
          borderColor: "#2e313f",
          color: "#fff",
          backgroundColor: "#212B36",
        }}
      >
        Upload Image
        <input
          accept="image/*"
          name="img"
          type="file"
          // multiple
          onChange={handleUploadClick}
          ref={imageInputRef}
          hidden
        />
      </Button>
    </div>
  );
};

export default UploadImage;
