/* eslint-disable no-loop-func */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import * as THREE from "three";
import CustomButton from "../leftPanel2/components/customButton";
import femaleTexture from "../../assets/femaletshirt/femaleTexture.png";
import maleTexture from "../../assets/maleshirt/maleTexture.png";
import ThreeDCanvous from "../threeDCanvous";
import "./rightPanel.css";
import {
  Button,
  makeStyles,
  Modal,
  Tooltip,
  withStyles,
} from "@material-ui/core";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../firebase";
import ColorButton from "../colorButton";
import SizeButton from "../sizeButton";
import SizeClarificationPopup from "../sizeClarificationPopup";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../firebase";
import { cartConstants, homeConstants } from "../../redux/constants";
import { useAuth } from "../../contexts/AuthContext";
import Loader from "../loader";
import { useTranslation } from "react-i18next";
import { colorConstants } from "../../colors";
import { useNavigate } from "react-router-dom";
import IconButton from "../iconButton";
import LegalTextsPopup from "../legalTextsPopup";
const { SELECT_COLOR, SELECT_SIZE, ADD_TO_CART, ADD_TO_CART_LOADING } =
  cartConstants;
const { CURRENT_ZOOM, RP_OPEN, LP2, LP3, R, G, B } = homeConstants;
const { RIGHT_PANEL_BG_COLOR } = colorConstants;

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    background: "#637381",
  };
}

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

toast.configure();
const RightPanel = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { dbUserData, currentUser } = useAuth();
  const [open, setOpen] = useState(false);
  const _home = useSelector((state) => state.home);
  const [go, setGo] = useState(false);
  const _cart = useSelector((state) => state.cart);
  const _productOptions = useSelector((state) => state.productOptions);
  const [modalStyle] = useState(getModalStyle);
  const [width, setWidth] = useState(2048);
  const [height, setHeight] = useState(2048);
  const [hCanvous, setHCanvous] = useState(null);
  const [canvasBgColorOpen, setCanvasBgColorOpen] = useState(false);
  const [sizesOpen, setSizesOpen] = useState(false);
  const [sizeClarificationOpen, setSizeClarificationOpen] = useState(false);
  const [legalTextsOpen, setLegalTextsOpen] = useState(false);
  const [colors, setColors] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [profileMissing, setProfileMissing] = useState([]);
  const [mainVariant, setMainVariant] = useState(null);
  const [subVariant, setSubVariant] = useState(null);

  const [threeDView, setThreeDView] = useState(false);
  //modify picture, add canvas image to front
  const createCanvas = (width, height) => {
    var c = document.createElement("canvas");
    c.setAttribute("width", width);
    c.setAttribute("height", height);
    c.setAttribute("border", "2px solid #d3d3d3");
    return c;
  };

  const placeImage = (canvas, img) => {
    var ctx = canvas.getContext("2d");
    // https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/drawImage
    //void ctx.drawImage(image, dx, dy, dWidth, dHeight);
    // from top left corner: dx = destinaxion x-axis; dy = destinaxion y-axis; dWidth/dHeight = destination width/height
    //e.g ctx.drawImage(imageObj1, 0, 0, 328, 526);
    ctx.drawImage(img, 0, 0, 2048, 2048);
  };

  const calculateScallingForPlacingImage = (
    canvas,
    img,
    dx,
    dy,
    dWidth,
    dHeight,
    cWidth,
    cHeight
  ) => {
    let factor = 1;
    let calculatedWidth = dWidth;
    let calculatedHeight = dHeight;
    let calculatedDx = dx;
    let calculatedDy = dy;

    const factorWidth = cWidth / dWidth;
    const factorHeight = cHeight / dHeight;
    if (factorWidth > factorHeight) {
      factor = factorWidth;
    } else {
      factor = factorHeight;
    }
    calculatedWidth = cWidth / factor;
    calculatedHeight = cHeight / factor;

    calculatedDx = dx + (dWidth - calculatedWidth) / 2;

    var ctx = canvas.getContext("2d");
    ctx.drawImage(
      img,
      calculatedDx,
      calculatedDy,
      calculatedWidth,
      calculatedHeight
    );
    // console.log("calculatedDx", calculatedDx);
    // console.log("calculatedDy", calculatedDy);
    // console.log("calculatedWidth", calculatedWidth);
    // console.log("calculatedHeight", calculatedHeight);
  };

  const placeImage_canvas = (canvas, img, dx, dy, dWidth, dHeight) => {
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, dx, dy, dWidth, dHeight);
  };

  const replaceColor = (
    canvas,
    img,
    oldRed,
    oldGreen,
    oldBlue,
    newRed,
    newGreen,
    newBlue
  ) => {
    var ctx = canvas.getContext("2d");
    var w = img.width;
    var h = img.height;

    // pull the entire image into an array of pixel data
    var imageData = ctx.getImageData(0, 0, w, h);

    // examine every pixel,
    // change any old rgb to the new-rgb
    for (var i = 0; i < imageData.data.length; i += 4) {
      // is this pixel the old rgb?
      // implement +-5 for range
      if (
        imageData.data[i] >= oldRed - 5 &&
        imageData.data[i] <= oldRed + 5 &&
        imageData.data[i + 1] >= oldGreen - 5 &&
        imageData.data[i + 1] <= oldGreen + 5 &&
        imageData.data[i + 2] >= oldBlue - 5 &&
        imageData.data[i + 2] <= oldBlue + 5
      ) {
        // change to your new rgb
        imageData.data[i] = newRed;
        imageData.data[i + 1] = newGreen;
        imageData.data[i + 2] = newBlue;
      }
    }
    // put the altered data back on the canvas
    ctx.putImageData(imageData, 0, 0);
  };

  var hiddenCanvas = createCanvas(width, height);

  // add base t-shirt image
  var image_obj = new Image();
  image_obj.onload = function () {
    placeImage(hiddenCanvas, image_obj);
    // replace rgb (yellow = 200 168 43) (red=255, 0,0)
    // 10 range is implemented
    if (_home.r !== null) {
      if (_cart.selectedProduct.threeDModel === "Female Basic T-Shirt") {
        replaceColor(
          hiddenCanvas,
          image_obj,
          200,
          170,
          40,
          _home.r,
          _home.g,
          _home.b
        );
      } else {
        replaceColor(
          hiddenCanvas,
          image_obj,
          215,
          101,
          52,
          _home.r,
          _home.g,
          _home.b
        );
      }
    } else {
      replaceColor(hiddenCanvas, image_obj, 200, 170, 40, 255, 255, 255);
    }
  };

  if (_cart.selectedProduct.threeDModel === "Female Basic T-Shirt") {
    image_obj.src = femaleTexture;
  } else if (_cart.selectedProduct.threeDModel === "Male Basic T-Shirt") {
    image_obj.src = maleTexture;
  } else {
    image_obj.src = femaleTexture;
  }

  //add canvas image to texture
  var frontImage_canvas = new Image();
  var backImage_canvas = new Image();

  const getUniqueColors = () => {
    let newArray = [];
    for (let i = 0; i < _cart.selectedProduct.variants.length; i++) {
      if (newArray.indexOf(_cart.selectedProduct.variants[i].color) === -1)
        newArray.push(_cart.selectedProduct.variants[i].color);
    }
    setColors(newArray);
  };

  // useEffect(() => {
  //   getUniqueColors();
  //   if (_home.frontCanvas && _home.backCanvas) {
  //     _home.frontCanvas.setDimensions({
  //       width: _cart.selectedProduct.canvases[0]?.width / _home.maxZoom,
  //       height: _cart.selectedProduct.canvases[0]?.height / _home.maxZoom,
  //     });
  //     _home.frontCanvas.requestRenderAll();
  //     _home.backCanvas.setDimensions({
  //       width: _cart.selectedProduct.canvases[1]?.width / _home.maxZoom,
  //       height: _cart.selectedProduct.canvases[1]?.height / _home.maxZoom,
  //     });
  //     _home.backCanvas.requestRenderAll();
  //   }
  // }, [_cart.selectedProduct]);

  const getSizesOfSelectedColors = () => {
    let newArray = [];
    for (let i = 0; i < _cart.selectedProduct.variants.length; i++) {
      if (_cart.selectedColor === _cart.selectedProduct.variants[i].color) {
        for (
          let j = 0;
          j < _cart.selectedProduct.variants[i].subVariants.length;
          j++
        ) {
          newArray.push(_cart.selectedProduct.variants[i].subVariants[j].size);
        }
      }
    }

    setSizes(newArray);
  };
  useEffect(() => {
    getSizesOfSelectedColors();
    dispatch({
      type: SELECT_SIZE,
      payload: "",
    });
  }, [_cart.selectedColor]);

  // set fabric image, asynchron does not work needs to be with a button
  const test3DTexture = () => {
    frontImage_canvas.src = _home.frontCanvas.toDataURL("image/png", 1.0);
    backImage_canvas.src = _home.backCanvas.toDataURL("image/png", 1.0);

    frontImage_canvas.onload = function () {
      const w = _cart.selectedProduct.canvases[0].width / _home.maxZoom;
      // front
      if (_cart.selectedProduct.threeDModel === "Female Basic T-Shirt") {
        calculateScallingForPlacingImage(
          hiddenCanvas,
          frontImage_canvas,
          570,
          1400,
          490,
          615,
          _cart.selectedProduct.canvases[0].width,
          _cart.selectedProduct.canvases[0].height
        );
      } else if (_cart.selectedProduct.threeDModel === "Male Basic T-Shirt") {
        calculateScallingForPlacingImage(
          hiddenCanvas,
          frontImage_canvas,
          38,
          134,
          512,
          652,
          _cart.selectedProduct.canvases[0].width,
          _cart.selectedProduct.canvases[0].height
        );
      }
    };

    backImage_canvas.onload = function () {
      const w = _cart.selectedProduct.canvases[1].width / _home.maxZoom;
      // back
      if (_cart.selectedProduct.threeDModel === "Female Basic T-Shirt") {
        calculateScallingForPlacingImage(
          hiddenCanvas,
          backImage_canvas,
          50,
          1400,
          490,
          615,
          _cart.selectedProduct.canvases[1].width,
          _cart.selectedProduct.canvases[1].height
        );
      } else if (_cart.selectedProduct.threeDModel === "Male Basic T-Shirt") {
        calculateScallingForPlacingImage(
          hiddenCanvas,
          backImage_canvas,
          572,
          142,
          510,
          657,
          _cart.selectedProduct.canvases[1].width,
          _cart.selectedProduct.canvases[1].height
        );
      }
    };
    setHCanvous(hiddenCanvas);
  };

  const handleShow3DView = () => {
    setThreeDView((prev) => !prev);
    _home.currentCanvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
    if (!threeDView) {
      test3DTexture();
    }
    dispatch({
      type: CURRENT_ZOOM,
      payload: 1,
    });
  };
  const handleRefresh3DView = () => {
    _home.currentCanvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
    test3DTexture();
    dispatch({
      type: CURRENT_ZOOM,
      payload: 1,
    });
  };
  const handleCanvasColorsOpen = () => {
    setCanvasBgColorOpen((prev) => !prev);
  };
  const handleSizePenelOpen = () => {
    setSizesOpen((prev) => !prev);
  };
  const handleSizeClaraficationPenelOpen = () => {
    setSizeClarificationOpen((prev) => !prev);
  };
  const handleLegalTextsOpen = () => {
    setLegalTextsOpen((prev) => !prev);
  };
  const handleCanvasBgColorsChange = (r, g, b) => {
    _home.currentCanvas.setBackgroundColor(
      `rgb(${r},${g},${b})`,
      _home.currentCanvas.renderAll.bind(_home.currentCanvas)
    );
    _home.frontCanvas.setBackgroundColor(
      `rgb(${r},${g},${b})`,
      _home.frontCanvas.renderAll.bind(_home.frontCanvas)
    );
    _home.backCanvas.setBackgroundColor(
      `rgb(${r},${g},${b})`,
      _home.backCanvas.renderAll.bind(_home.backCanvas)
    );
    dispatch({
      type: R,
      payload: r,
    });
    dispatch({
      type: G,
      payload: g,
    });
    dispatch({
      type: B,
      payload: b,
    });
  };
  const handleRpToggle = () => {
    if (!_home.rpOpen) {
      dispatch({
        type: LP3,
        payload: false,
      });
      dispatch({
        type: LP2,
        payload: false,
      });
    }
    dispatch({
      type: RP_OPEN,
      payload: !_home.rpOpen,
    });
  };
  const getRfromHex = (color) => {
    var bigint = parseInt(color.replace("#", ""), 16);
    var r = (bigint >> 16) & 255;
    return r;
  };
  const getGfromHex = (color) => {
    var bigint = parseInt(color.replace("#", ""), 16);
    var g = (bigint >> 8) & 255;
    return g;
  };
  const getBfromHex = (color) => {
    var bigint = parseInt(color.replace("#", ""), 16);
    var b = bigint & 255;
    return b;
  };
  var dataURLtoBlob = function (dataurl) {
    var parts = dataurl.split(","),
      mime = parts[0].match(/:(.*?);/)[1];
    if (parts[0].indexOf("base64") !== -1) {
      var bstr = atob(parts[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new Blob([u8arr], { type: mime });
    } else {
      var raw = decodeURIComponent(parts[1]);
      return new Blob([raw], { type: mime });
    }
  };
  const getCanvasAtResoution = (canvas, newWidth) => {
    if (canvas.width !== newWidth) {
      var scaleMultiplier = newWidth / canvas.width;
      var objects = canvas.getObjects();
      for (var i in objects) {
        objects[i].scaleX = objects[i].scaleX * scaleMultiplier;
        objects[i].scaleY = objects[i].scaleY * scaleMultiplier;
        objects[i].left = objects[i].left * scaleMultiplier;
        objects[i].top = objects[i].top * scaleMultiplier;
        objects[i].setCoords();
      }
      // setBrushWidth(brushWidth * scaleMultiplier);
      // canvas.freeDrawingBrush.width = brushWidth * scaleMultiplier;
      canvas.setWidth(canvas.getWidth() * scaleMultiplier);
      canvas.setHeight(canvas.getHeight() * scaleMultiplier);
      canvas.renderAll();
      canvas.calcOffset();
    }
  };
  const handleHandleReloadCanvas = () => {
    var RFrontState = JSON.parse(localStorage.getItem("RFrontState"));
    var RBackState = JSON.parse(localStorage.getItem("RBackState"));
    if (RFrontState) {
      _home.frontCanvas?.clear();
      _home.frontCanvas?.loadFromJSON(RFrontState, function () {
        _home.frontCanvas?.renderAll();
      });
    }
    if (RBackState) {
      _home.backCanvas?.clear();
      _home.backCanvas?.loadFromJSON(RBackState, function () {
        _home.backCanvas?.renderAll();
      });
    }
  };

  const getSelectedVarient = () => {
    for (let i = 0; i < _cart.selectedProduct.variants.length; i++) {
      for (
        let j = 0;
        j < _cart.selectedProduct.variants[i].subVariants.length;
        j++
      ) {
        if (
          _cart.selectedProduct.variants[i].color === _cart.selectedColor &&
          _cart.selectedProduct.variants[i].subVariants[j].size ===
            _cart.selectedSize
        ) {
          setMainVariant(_cart.selectedProduct.variants[i]);
          setSubVariant(_cart.selectedProduct.variants[i].subVariants[j]);
        }
      }
    }
  };

  const isProfileCompleted = () => {
    let newArray = [];
    let flag = false;
    if (dbUserData.firstName && dbUserData.lastName) {
      if (dbUserData.shippingAddressFlag) {
        if (
          dbUserData.shippingAddress.country &&
          dbUserData.shippingAddress.street &&
          dbUserData.shippingAddress.houseNo &&
          dbUserData.shippingAddress.city &&
          dbUserData.shippingAddress.zipCode
        ) {
          flag = true;
        }
      } else {
        if (
          dbUserData.billingAddress.country &&
          dbUserData.billingAddress.street &&
          dbUserData.billingAddress.houseNo &&
          dbUserData.billingAddress.city &&
          dbUserData.billingAddress.zipCode
        ) {
          flag = true;
        }
      }
    }
    if (!dbUserData.firstName) {
      newArray.push("First Name");
    }
    if (!dbUserData.lastName && dbUserData.country) {
      newArray.push("Last Name");
    }
    if (dbUserData.shippingAddressFlag) {
      if (!dbUserData.shippingAddress.country) {
        newArray.push("country");
      }
      if (!dbUserData.shippingAddress.street) {
        newArray.push("Street");
      }
      if (!dbUserData.shippingAddress.houseNo) {
        newArray.push("House No");
      }
      if (!dbUserData.shippingAddress.city) {
        newArray.push("City");
      }
      if (!dbUserData.shippingAddress.zipCode) {
        newArray.push("zipCode");
      }
    } else {
      if (!dbUserData.billingAddress.country) {
        newArray.push("country");
      }
      if (!dbUserData.billingAddress.street) {
        newArray.push("Street");
      }
      if (!dbUserData.billingAddress.houseNo) {
        newArray.push("House No");
      }
      if (!dbUserData.billingAddress.city) {
        newArray.push("City");
      }
      if (!dbUserData.billingAddress.zipCode) {
        newArray.push("zipCode");
      }
    }
    setProfileMissing(newArray);
    return flag;
  };

  useEffect(() => {
    getSelectedVarient();
  }, [_cart.selectedSize]);

  const handleAddToCart = () => {
    if (currentUser) {
      if (_cart.selectedProduct.name === "Dummy Product") {
        toast(t("rightPanel.SelectProduct"), {
          type: "error",
          position: toast.POSITION.BOTTOM_CENTER,
        });
        return;
      }
      if (isProfileCompleted()) {
        if (_cart.selectedColor === "") {
          toast(t("rightPanel.SelectColor"), {
            type: "error",
            position: toast.POSITION.BOTTOM_CENTER,
          });
          return;
        }
        if (_cart.selectedSize === "") {
          toast(t("rightPanel.SelectSize"), {
            type: "error",
            position: toast.POSITION.BOTTOM_CENTER,
          });
          return;
        }
        dispatch({
          type: ADD_TO_CART_LOADING,
          payload: true,
        });
        // downalod start
        let fOriginal_width = parseInt(_cart.selectedProduct.canvases[0].width);
        let bOriginal_width = _cart.selectedProduct.canvases[1]
          ? parseInt(_cart.selectedProduct.canvases[1].width)
          : null;
        getCanvasAtResoution(_home.frontCanvas, fOriginal_width);
        getCanvasAtResoution(_home.backCanvas, bOriginal_width);
        _home.frontCanvas.backgroundColor = "";
        _home.frontCanvas.requestRenderAll();
        var frontDownloadURL = _home.frontCanvas.toDataURL({
          format: "png",
          multiplier: 0,
        });
        // front start
        var frontBlob = dataURLtoBlob(frontDownloadURL);
        _home.backCanvas.backgroundColor = "";
        _home.backCanvas.requestRenderAll();
        var backDownloadURL = _home.backCanvas.toDataURL({
          format: "png",
          multiplier: 0,
        });
        var backBlob = _cart.selectedProduct.canvases[1]
          ? dataURLtoBlob(backDownloadURL)
          : null;
        let fc;
        let bc;
        _home.frontCanvas.backgroundColor = _cart.selectedColor;
        _home.frontCanvas.requestRenderAll();
        _home.backCanvas.backgroundColor = _cart.selectedColor;
        _home.backCanvas.requestRenderAll();
        let reversfOriginal_width =
          parseInt(_cart.selectedProduct.canvases[0].width) / _home.maxZoom;
        getCanvasAtResoution(_home.frontCanvas, reversfOriginal_width);
        let reversbOriginal_width =
          parseInt(_cart.selectedProduct.canvases[1]?.width) / _home.maxZoom;
        getCanvasAtResoution(_home.backCanvas, reversbOriginal_width);
        const sotrageRef = ref(
          storage,
          `orders/${currentUser.uid}/${new Date().getTime()}.png`
        );
        const uploadTask = uploadBytesResumable(sotrageRef, frontBlob);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const prog = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            // setProgress(prog);
          },
          (error) => console.log(error),
          async () => {
            fc = await getDownloadURL(uploadTask.snapshot.ref);
            const sotrageRef1 = ref(
              storage,
              `orders/${currentUser.uid}/${new Date().getTime()}.png`
            );
            if (_cart.selectedProduct.canvases[1]) {
              const uploadTask1 = uploadBytesResumable(sotrageRef1, backBlob);
              uploadTask1.on(
                "state_changed",
                (snapshot) => {
                  const prog = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                  );
                },
                (error) => console.log(error),
                async () => {
                  bc = await getDownloadURL(uploadTask1.snapshot.ref);
                  let newCartItem = {
                    studioID: _productOptions.selectedStudio.userID,
                    studio: _productOptions.selectedStudio.companyName,
                    productID: _cart.selectedProduct.productID,
                    addressFrom: _productOptions.selectedStudio
                      .shippingAddressFlag
                      ? _productOptions.selectedStudio.shippingAddress
                      : _productOptions.selectedStudio.billingAddress,
                    billingAddress: dbUserData.shippingAddressFlag
                      ? dbUserData.shippingAddress
                      : dbUserData.billingAddress,
                    shippingAddress: dbUserData.shippingAddressFlag
                      ? dbUserData.shippingAddress
                      : dbUserData.billingAddress,
                    mainVariantID: mainVariant?.id,
                    mainVariantName: mainVariant?.name,
                    subVariantID: subVariant?.id,
                    subVariantName: subVariant?.name,
                    productName: _cart.selectedProduct.name,
                    sku: _cart.selectedProduct.sku,
                    color: _cart.selectedColor,
                    size: _cart.selectedSize,
                    childSKU: subVariant?.childSKU,
                    quantity: 1,
                    price: subVariant?.price,
                    unitPrice: subVariant?.price,
                    frontDownloadURL: fc,
                    backDownloadURL: bc,
                  };
                  try {
                    const updateUserCart = httpsCallable(
                      functions,
                      "dbUsersOnCall-updateUserCart"
                    );
                    console.log("newCartItem", newCartItem);
                    updateUserCart({ cart: [..._cart.cart, newCartItem] }).then(
                      (result) => {
                        console.log("cartItems", result);
                        dispatch({
                          type: ADD_TO_CART,
                          payload: newCartItem,
                        });
                        dispatch({
                          type: ADD_TO_CART_LOADING,
                          payload: false,
                        });
                      }
                    );
                  } catch (e) {
                    console.error("Error adding document: ", e);
                  }
                }
              );
            } else {
              let newCartItem = {
                studioID: _productOptions.selectedStudio.userID,
                studio: _productOptions.selectedStudio.companyName,
                productID: _cart.selectedProduct.productID,
                addressFrom: _productOptions.selectedStudio.shippingAddressFlag
                  ? _productOptions.selectedStudio.shippingAddress
                  : _productOptions.selectedStudio.billingAddress,
                billingAddress: dbUserData.shippingAddressFlag
                  ? dbUserData.shippingAddress
                  : dbUserData.billingAddress,
                shippingAddress: dbUserData.shippingAddressFlag
                  ? dbUserData.shippingAddress
                  : dbUserData.billingAddress,
                mainVariantID: mainVariant?.id,
                mainVariantName: mainVariant?.name,
                subVariantID: subVariant?.id,
                subVariantName: subVariant?.name,
                productName: _cart.selectedProduct.name,
                sku: _cart.selectedProduct.sku,
                color: _cart.selectedColor,
                size: _cart.selectedSize,
                childSKU: subVariant?.childSKU,
                quantity: 1,
                price: subVariant?.price,
                unitPrice: subVariant?.price,
                frontDownloadURL: fc,
                backDownloadURL: bc,
              };
              try {
                const updateUserCart = httpsCallable(
                  functions,
                  "dbUsersOnCall-updateUserCart"
                );
                console.log("newCartItem", newCartItem);
                updateUserCart({ cart: [..._cart.cart, newCartItem] }).then(
                  (result) => {
                    console.log("cartItems", result);
                    dispatch({
                      type: ADD_TO_CART,
                      payload: newCartItem,
                    });
                    dispatch({
                      type: ADD_TO_CART_LOADING,
                      payload: false,
                    });
                  }
                );
              } catch (e) {
                console.error("Error adding document: ", e);
              }
            }
          }
        );
        // downalod end
      } else {
        setOpen(true);
      }
    } else {
      toast(t("rightPanel.addToCartLoginMsg"), {
        type: "info",
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };
  const handleColorSelect = (c) => {
    handleCanvasBgColorsChange(getRfromHex(c), getGfromHex(c), getBfromHex(c));
    dispatch({
      type: SELECT_COLOR,
      payload: c,
    });
  };
  const handleSizeSelect = (s) => {
    dispatch({
      type: SELECT_SIZE,
      payload: s,
    });
  };
  const navigateToProfile = () => {
    navigate(`/dashboard/profile`);
    setOpen(false);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div
        style={
          !_home.rpOpen
            ? { width: "58px", background: RIGHT_PANEL_BG_COLOR }
            : { background: RIGHT_PANEL_BG_COLOR }
        }
        className="rightPanel-main-wrapper"
      >
        <div style={{ textAlign: "left" }} className="p-10">
          <i
            style={{ color: "white" }}
            className={
              _home.rpOpen
                ? "backarrow fal fa-long-arrow-right"
                : "backarrow fal fa-long-arrow-left"
            }
            onClick={() => handleRpToggle()}
          ></i>
          {!_home.rpOpen &&
          _cart.selectedProduct.threeDModel !== "No 3D Model" ? (
            <>
              <br />
              <br />
              <i
                style={{ color: "white" }}
                className={"backarrow"}
                onClick={() => handleShow3DView()}
              >
                3D
              </i>
            </>
          ) : null}
          {!_home.rpOpen && _cart.selectedProduct.name !== "Dummy Product" ? (
            <>
              <br />
              <br />
              <i
                style={{ color: "white" }}
                className="backarrow fad fa-cart-plus"
                onClick={() => handleRpToggle()}
              ></i>
            </>
          ) : null}
        </div>
        <div
          style={
            !_home.rpOpen
              ? { display: "none" }
              : { position: "relative", paddingTop: 0 }
          }
          className="p-10"
        >
          {_cart.selectedProduct.threeDModel !== "No 3D Model" ? (
            <IconButton
              text={
                threeDView
                  ? t("rightPanel.Hide3DView")
                  : t("rightPanel.ShowIn3D")
              }
              onClick={() => handleShow3DView()}
              active={threeDView}
            />
          ) : null}
          {/* <IconButton
            text={
              canvasBgColorOpen
                ? t("rightPanel.HideColors")
                : t("rightPanel.ShowColors")
            }
            onClick={() => handleCanvasColorsOpen()}
            active={canvasBgColorOpen}
          /> */}
          {/* <div
            style={
              !canvasBgColorOpen
                ? {
                    display: "none",
                  }
                : null
            }
          >
            {colors.map((c, i) => (
              <ColorButton
                index={i}
                background={c}
                onClick={() => handleColorSelect(c)}
              />
            ))}
            {_cart.selectedProduct.name === "Dummy Product"
              ? _cart.dumyColors.map((c, i) => (
                  <ColorButton
                    index={i}
                    background={c}
                    onClick={() => handleColorSelect(c)}
                  />
                ))
              : null}
          </div> */}
          {sizes.length > 0 ? (
            // <IconButton
            //   text={t("rightPanel.SelectSizeBelow")}
            //   onClick={() => {}}
            //   active={sizesOpen}
            // />
            <p style={{ marginTop: "10px", marginBottom: "10px" }}>
              {t("rightPanel.SelectSizeBelow")}
            </p>
          ) : null}
          <div
          // style={
          //   !sizesOpen
          //     ? {
          //         display: "none",
          //       }
          //     : null
          // }
          >
            {sizes.map((s, i) => (
              <span key={i}>
                <SizeButton
                  index={i}
                  text={s}
                  onClick={() => handleSizeSelect(s)}
                />
              </span>
            ))}
          </div>
          {_cart.selectedProduct.name !== "Dummy Product" ? (
            <>
              <IconButton
                text={
                  sizeClarificationOpen
                    ? t("rightPanel.HideClarification")
                    : t("rightPanel.ShowClarification")
                }
                onClick={() => handleSizeClaraficationPenelOpen()}
                active={sizeClarificationOpen}
              />
              <SizeClarificationPopup
                selectedProduct={_cart.selectedProduct}
                open={sizeClarificationOpen}
              />
            </>
          ) : null}
          {/* reload state is happenign on refresh the button is not needed */}
          <IconButton
            text={t("rightPanel.ReloadState")}
            onClick={() => handleHandleReloadCanvas()}
            active={false}
          />
          {true ? (
            currentUser ? (
              <Button
                variant="contained"
                component="label"
                style={{
                  marginBottom: "10px",
                  borderColor: "#2e313f",
                  color: "#161C24",
                  backgroundColor: "#FFC107",
                  width: "100%",
                }}
                onClick={() => handleAddToCart()}
                disabled={_cart.addToCartLoading}
              >
                {t("rightPanel.AddtoCart")}
              </Button>
            ) : (
              <LightTooltip
                title={t("rightPanel.addToCartLoginMsg")}
                placement="left"
              >
                <Button
                  variant="contained"
                  component="label"
                  style={{
                    marginBottom: "10px",
                    borderColor: "#2e313f",
                    color: "#161C24",
                    backgroundColor: "#FFC107",
                    width: "100%",
                  }}
                  onClick={() => handleAddToCart()}
                  disabled={_cart.addToCartLoading}
                >
                  {t("rightPanel.AddtoCart")}
                </Button>
              </LightTooltip>
            )
          ) : null}
          {_productOptions.selectedStudio &&
          _cart.selectedProduct.name !== "Dummy Product" ? (
            <>
              <p className="mb-10">
                {_productOptions.selectedStudio.companyName}
              </p>
              <IconButton
                text={t("LegalTexts.LegalTexts")}
                onClick={() => handleLegalTextsOpen()}
                active={legalTextsOpen}
              />
              <LegalTextsPopup
                open={legalTextsOpen}
                setLegalTextsOpen={setLegalTextsOpen}
              />
            </>
          ) : null}
        </div>

        {/* 3D Canvous start */}
        <ThreeDCanvous
          handleRefresh3DView={handleRefresh3DView}
          threeDView={threeDView}
          hiddenCanvas={hCanvous}
          rpOpen={_home.rpOpen}
          model={_cart.selectedProduct.threeDModel}
          setThreeDView={setThreeDView}
        />
        {/* 3D Canvous end */}
      </div>
      {_cart.addToCartLoading ? <Loader /> : null}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <h3 className="fw-300 mt-10 mb-10 canv-lable">
            Your profile is not completed.
          </h3>
          <div>
            <p className="mb-10">
              Missing:{" "}
              {profileMissing.map((m, i) => (
                <span>{m},&nbsp;</span>
              ))}
            </p>
            <p>
              Click{" "}
              <span
                style={{ color: "#1CCAFF", cursor: "pointer" }}
                onClick={() => navigateToProfile()}
              >
                here
              </span>{" "}
              to complete your profile.
            </p>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default RightPanel;
