import { Tooltip, withStyles } from "@material-ui/core";
import React from "react";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const VariantPlusButton = ({ product, setProduct }) => {
  const handleAddVarient = () => {
    setProduct({
      ...product,
      variants: [
        {
          id: new Date().getTime(),
          imageURL: null,
          color: "#000000",
          name: "",
          subVariants: [
            {
              id: new Date().getTime() + 1,
              childSKU: "",
              currency: "Eur",
              inStock: false,
              name: "",
              price: 0,
              size: "",
              quantityInStock: "",
            },
          ],
        },
      ],
    });
  };
  return (
    <h3 className="fw-300 mt-10">
      Variants &nbsp;&nbsp;
      {product.variants?.length === 0 ? (
        <LightTooltip title="Add New Variant" placement="right">
          <i
            className="backarrow fal fa-plus"
            onClick={() => handleAddVarient()}
          ></i>
        </LightTooltip>
      ) : null}
    </h3>
  );
};

export default VariantPlusButton;
