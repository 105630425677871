/* eslint-disable no-unused-vars */
import { Button, makeStyles, Modal } from "@material-ui/core";
import React, { useState } from "react";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../../firebase";
import { useDispatch, useSelector } from "react-redux";
import CanvasInput from "../../../leftPanel1/components/canvasInput";
import { useAuth } from "../../../../contexts/AuthContext";
import CanvasButton from "./components/canvasButton";
import { toast } from "react-toastify";
import "./yourCanvases.css";
import { userCanvasesConstants } from "../../../../redux/constants";
import Loader from "../../../loader";
import IconButton from "../../../iconButton";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
const { USER_CANVASES } = userCanvasesConstants;

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    background: "#637381",
  };
}
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const YourCanvases = () => {
  const { t } = useTranslation();
  const { currentUser } = useAuth();
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const _home = useSelector((state) => state.home);
  const _userCanvases = useSelector((state) => state.userCanvases);
  const [modalStyle] = useState(getModalStyle);
  const [open, setOpen] = useState(false);
  const [editCanvasName, setEditCanvasName] = useState(false);
  const [editIndex, setEditIndex] = useState(0);
  const [canvasName, setCanvasName] = useState("");
  const [eCanvasName, setECanvasName] = useState("");
  const [canvasNameErr, setCanvasNameErr] = useState(false);
  const [canvasSaveErrText, setCanvasSaveErrText] = useState("");
  const [updateCanvacesLoading, setUpdateCanvacesLoading] = useState(false);

  const handleOpen = () => {
    if (currentUser) {
      setOpen(true);
    } else {
      toast(t("YourCanvases.Logintosavecanvasesinyourcustomeraccount"), {
        type: "info",
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };
  const handleLogin = () => {
    navigate("/signin");
  };
  const handleClose = () => {
    setCanvasName("");
    setCanvasSaveErrText("");
    setOpen(false);
  };
  const handleCloseEdit = () => {
    setECanvasName("");
    setCanvasSaveErrText("");
    setEditCanvasName(false);
  };
  const handleCanvasNameChange = (v) => {
    setCanvasName(v);
    if (v !== "") {
      setCanvasSaveErrText("");
    }
  };
  const handleECanvasNameChange = (v) => {
    setECanvasName(v);
    if (v !== "") {
      setCanvasSaveErrText("");
    }
  };
  const handleSaveCanvas = () => {
    if (canvasName === "") {
      setCanvasNameErr(true);
      setCanvasSaveErrText("Please Enter Canvas Name");
    } else {
      let c = JSON.stringify(_home.currentCanvas);
      if (_userCanvases.userCanvases?.length > 0) {
        dispatch({
          type: USER_CANVASES,
          payload: [
            {
              canvasName: canvasName,
              canvas: c,
            },
            ..._userCanvases.userCanvases,
          ],
        });
      } else {
        dispatch({
          type: USER_CANVASES,
          payload: [
            {
              canvasName: canvasName,
              canvas: c,
            },
          ],
        });
      }
      setCanvasName("");
      setCanvasSaveErrText("");
      setOpen(false);
    }
  };
  const handleUpdateCurrentCanvas = (index) => {
    let c = JSON.stringify(_home.currentCanvas);
    let newArr = [..._userCanvases.userCanvases];
    newArr[index] = { ...newArr[index], canvas: c };
    dispatch({
      type: USER_CANVASES,
      payload: newArr,
    });
  };
  const handleEditCanvasName = () => {
    if (eCanvasName === "") {
      setCanvasNameErr(true);
      setCanvasSaveErrText("Please Enter Canvas Name");
    } else {
      let newArr = [..._userCanvases.userCanvases];
      newArr[editIndex] = { ...newArr[editIndex], canvasName: eCanvasName };
      dispatch({
        type: USER_CANVASES,
        payload: newArr,
      });
      setECanvasName("");
      setCanvasSaveErrText("");
      setEditCanvasName(false);
    }
  };
  const handleUpdateAllCanvases = () => {
    if (currentUser) {
      if (_userCanvases.userCanvases !== null) {
        try {
          setUpdateCanvacesLoading(true);
          const updateCanvases = httpsCallable(
            functions,
            "dbCanvasesOnCall-updateCanvases"
          );
          updateCanvases({ canvases: _userCanvases.userCanvases }).then(
            (result) => {
              setUpdateCanvacesLoading(false);
            }
          );
        } catch (e) {
          console.error("Error adding document: ", e);
        }
      }
    } else {
      toast(t("YourCanvases.Logintosavecanvasesinyourcustomeraccount"), {
        type: "info",
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };
  const handleRemoveCanvaus = (index) => {
    dispatch({
      type: USER_CANVASES,
      payload: [..._userCanvases.userCanvases.filter((f, i) => index !== i)],
    });
  };
  const handleEditCanvausName = (i) => {
    setEditIndex(i);
    setECanvasName(_userCanvases.userCanvases[i].canvasName);
    setEditCanvasName(true);
  };
  const showUserCanvases = () =>
    _userCanvases.userCanvases?.length > 0
      ? _userCanvases.userCanvases.map((c, i) => (
          <span key={i}>
            <CanvasButton
              index={i}
              canvas={c}
              handleRemoveCanvaus={() => handleRemoveCanvaus(i)}
              handleEditCanvausName={() => handleEditCanvausName(i)}
              handleUpdateCurrentCanvas={() => handleUpdateCurrentCanvas(i)}
            />
          </span>
        ))
      : null;
  return (
    <div>
      <div className="p-10">
        <IconButton
          text={t("YourCanvases.AddCanvas")}
          onClick={() => handleOpen()}
        >
          <i className="ri-add-box-line"></i>
        </IconButton>
      </div>
      <div
        className="your-canv-wrap"
        style={
          _userCanvases.userCanvases?.length > 9
            ? { overflowY: "scroll" }
            : null
        }
      >
        {currentUser ? (
          showUserCanvases()
        ) : (
          <div className="m-10">
            <p
              style={{ color: "red", marginBottom: "10px", textAlign: "left" }}
            >
              <span
                style={{
                  border: "1px solid red",
                  width: "20px",
                  display: "inline-block",
                  textAlign: "center",
                }}
              >
                !
              </span>{" "}
              {t("YourCanvases.Logintosavecanvasesinyourcustomeraccount")}
            </p>
            <IconButton
              text={t("YourCanvases.Login")}
              onClick={() => handleLogin()}
            >
              <i className="ri-user-line"></i>
            </IconButton>
          </div>
        )}
      </div>
      <div className="p-10">
        <IconButton
          text={t("YourCanvases.SaveAllCanvases")}
          onClick={() => handleUpdateAllCanvases()}
        >
          <i className="ri-save-2-line"></i>
        </IconButton>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <h3 className="fw-300 mt-10 mb-10 canv-lable">Canvas Name:</h3>
          <CanvasInput
            name="canvasName"
            type="text"
            value={canvasName}
            onChange={(e) => handleCanvasNameChange(e.target.value)}
            placeholder="Canvas Name"
          />
          {canvasNameErr ? (
            <div className="mt-10">
              <p style={{ color: "red" }}>{canvasSaveErrText}</p>
            </div>
          ) : null}
          <div className="mt-20">
            <Button
              variant="contained"
              component="label"
              style={{
                marginBottom: "10px",
                borderColor: "#2e313f",
                color: "#fff",
                backgroundColor: "#1890FF",
                width: "100px",
              }}
              onClick={() => handleSaveCanvas()}
            >
              Save
            </Button>
            &nbsp; &nbsp; &nbsp;
            <Button
              variant="contained"
              component="label"
              style={{
                marginBottom: "10px",
                borderColor: "#2e313f",
                color: "#161C24",
                backgroundColor: "#FFC107",
                width: "100px",
              }}
              onClick={() => handleClose()}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        open={editCanvasName}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <h3 className="fw-300 mt-10 mb-10 canv-lable">Canvas Name:</h3>
          <CanvasInput
            name="canvasName"
            type="text"
            value={eCanvasName}
            onChange={(e) => handleECanvasNameChange(e.target.value)}
            placeholder="Canvas Name"
          />
          {canvasNameErr ? (
            <div className="mt-10">
              <p style={{ color: "red" }}>{canvasSaveErrText}</p>
            </div>
          ) : null}
          <div className="mt-20">
            <Button
              variant="contained"
              component="label"
              style={{
                marginBottom: "10px",
                borderColor: "#2e313f",
                color: "#fff",
                backgroundColor: "#1890FF",
                width: "100px",
              }}
              onClick={() => handleEditCanvasName()}
            >
              Save
            </Button>
            &nbsp; &nbsp; &nbsp;
            <Button
              variant="contained"
              component="label"
              style={{
                marginBottom: "10px",
                borderColor: "#2e313f",
                color: "#161C24",
                backgroundColor: "#FFC107",
                width: "100px",
              }}
              onClick={() => handleCloseEdit()}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
      {updateCanvacesLoading ? <Loader /> : null}
    </div>
  );
};

export default YourCanvases;
