import React from "react";
import Product from "./product";

const ShowProducts = ({ products }) => {
  const handleShowProducts = () => (
    <>
      {products.map((p, i) => (
        <span key={i}>
          <Product index={i} product={p} />
        </span>
      ))}
    </>
  );
  return <div>{handleShowProducts()}</div>;
};

export default ShowProducts;
