import React from "react";

const ColorButton = ({ index, background, onClick }) => {
  return (
    <div
      key={index}
      style={{
        background: background,
        width: "40px",
        height: "40px",
        marginRight: "5px",
        marginBottom: "5px",
        borderRadius: "5px",
        display: "inline-block",
        cursor: "pointer",
      }}
      onClick={onClick}
    />
  );
};

export default ColorButton;
