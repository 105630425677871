import { Tooltip, withStyles } from "@material-ui/core";
import React from "react";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const CanvasPlusButton = ({ product, setProduct }) => {
  const handleAddCanvas = () => {
    setProduct({
      ...product,
      canvases: [
        {
          width: "",
          height: "",
        },
      ],
    });
  };
  return (
    <h3 className="fw-300 mt-10">
      Canvases &nbsp;&nbsp;
      {product.canvases?.length === 0 ? (
        <LightTooltip title="Add New Canvas" placement="right">
          <i
            className="backarrow fal fa-plus"
            onClick={() => handleAddCanvas()}
          ></i>
        </LightTooltip>
      ) : null}
    </h3>
  );
};

export default CanvasPlusButton;
