import React from "react";

const Header = ({ setNewProduct, editing, setEditing }) => {
  const handleBackPress = () => {
    setNewProduct(false);
    setEditing(false);
  };
  return (
    <h1>
      <i
        className="backarrow fal fa-long-arrow-left"
        onClick={() => handleBackPress()}
      ></i>
      &nbsp;&nbsp;
      {editing ? "Edit Product" : "New Product"}
    </h1>
  );
};

export default Header;
