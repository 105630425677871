import React from "react";

const CanvasInput = ({
  index,
  name,
  type,
  value,
  onChange,
  placeholder,
  borderColor,
}) => {
  return (
    <input
      key={index}
      name={name}
      className="custom-input"
      style={{ color: "#000" }}
      type={type}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
    />
  );
};

export default CanvasInput;
