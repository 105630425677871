/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { functions } from "../../../firebase";
import { httpsCallable } from "firebase/functions";
import { useAuth } from "../../../contexts/AuthContext";
import { toast } from "react-toastify";
import "../dashboard.css";
import CheckBoxWithLabel from "../checkBoxWithLabel";
import { Button, Checkbox } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import CustomInput from "../../../components/customInput";
import CountrySelector from "../../../components/countrySelector";
import Loader from "../../../components/loader";
import InnerLoader from "../../../components/innerLoader";

const UpdateUser = ({
  userData,
  setUserData,
  setDetail,
  usersGloble,
  setUsers,
  setUsersGloble,
}) => {
  const { t } = useTranslation();
  const { currentUser, dbUserData, setDbUserData, userDataLoading } = useAuth();
  const [profileUpdated, setProfileUpdated] = useState(false);
  const [updateEmail1, setUpdateEmail1] = useState(false);
  const [updatedEmail, setUpdatedEmail] = useState("");
  const [reEnteredEmail, setReEnteredEmail] = useState("");

  const handleField = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const handleinSale = (checked) => {
    setUserData({
      ...userData,
      inSale: checked,
    });
  };

  const handleAddress = (e) => {
    setUserData({
      ...userData,
      billingAddress: {
        ...userData.billingAddress,
        [e.target.name]: e.target.value,
      },
    });
  };
  const handleShippingAddressFlag = (checked) => {
    setUserData({
      ...userData,
      shippingAddressFlag: checked,
    });
  };
  const handleShippingAddress = (e) => {
    setUserData({
      ...userData,
      shippingAddress: {
        ...userData.shippingAddress,
        [e.target.name]: e.target.value,
      },
    });
  };

  const handleSubmit = () => {
    console.log("userData", userData);
    setProfileUpdated(true);
    const updateUserData = httpsCallable(
      functions,
      "dbUsersOnCall-updateUserData"
    );
    updateUserData(userData).then((result) => {
      console.log("Updated Sussccess", result.data);
      // setDbUserData(userData);
      let newA = [];
      for (let i = 0; i < usersGloble.length; i++) {
        if (usersGloble[i].email !== userData.email) {
          newA.push(usersGloble[i]);
        } else {
          newA.push(userData);
        }
      }
      setUsers(newA);
      setUsersGloble(newA);
      setProfileUpdated(false);
    });
  };
  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };
  const handleEmailUpdate = () => {
    if (!validateEmail(updatedEmail)) {
      toast(t("Profile.Pleaseenteravalidemailaddress"), {
        type: "error",
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else {
      if (updatedEmail !== reEnteredEmail) {
        toast(t("Profile.Emaildoesnotmatch"), {
          type: "error",
          position: toast.POSITION.BOTTOM_CENTER,
        });
      } else {
        const setEmail = httpsCallable(functions, "dbUsersOnCall-setEmail");
        setEmail({
          userId: userData.userID,
          newEmail: updatedEmail,
        }).then((result) => {});
      }
    }
  };
  return userData ? (
    <div style={{ position: "relative" }}>
      <div className="m-20">
        <div className="mb-10">
          <i
            className="backarrow fal fa-long-arrow-left"
            onClick={() => setDetail(false)}
          ></i>
        </div>
        <p>
          <strong>Email: </strong>
          {currentUser?.email}
        </p>
        <br />
        <div className="content-wrapper">
          <div className="contarea-left">
            <CustomInput
              label={"First Name"}
              name="firstName"
              value={userData.firstName}
              onChange={handleField}
              placeholder={"First Name"}
            />
            <CustomInput
              label={"Last Name"}
              name="lastName"
              value={userData.lastName}
              onChange={handleField}
              placeholder={"Last Name"}
            />
            <CustomInput
              label={t("Profile.CompanyName")}
              name="companyName"
              value={userData.companyName}
              onChange={handleField}
              placeholder={t("Profile.CompanyName")}
            />
            <CustomInput
              label={t("Profile.Phone")}
              name="phone"
              type="tel"
              value={userData.phone}
              onChange={handleField}
              placeholder={t("Profile.Phone")}
            />
            <p className="mb-10">
              <strong>Address:</strong>
            </p>
            <div className="df aic jcsb mb-10">
              <span style={{ width: "120px" }}>{t("Profile.Country")}:</span>
              <div style={{ width: "255px" }}>
                <CountrySelector
                  value={userData.billingAddress?.country}
                  onChange={handleAddress}
                />
              </div>
            </div>
            <CustomInput
              label={"Street"}
              name="street"
              value={userData.billingAddress.street}
              onChange={handleAddress}
              placeholder={"Street"}
            />
            <CustomInput
              label={"House NO#"}
              name="houseNo"
              value={userData.billingAddress.houseNo}
              onChange={handleAddress}
              placeholder={"House Number"}
            />
            <CustomInput
              label={t("Profile.AddressLine1")}
              name="adrLine1"
              value={userData.billingAddress.adrLine1}
              onChange={handleAddress}
              placeholder={t("Profile.AddressLine1")}
            />
            <CustomInput
              label={"City"}
              name="city"
              value={userData.billingAddress.city}
              onChange={handleAddress}
              placeholder={"City"}
            />
            <CustomInput
              label={"Zip Code"}
              name="zipCode"
              value={userData.billingAddress.zipCode}
              onChange={handleAddress}
              placeholder={"Zip Code"}
            />
            <CheckBoxWithLabel
              checked={userData.shippingAddressFlag}
              onClick={handleShippingAddressFlag}
              label={"Shipping address is different from address"}
            />
          </div>
          <div className="contarea-right">
            <div className="seller-functionalities">
              <div className="mb-10">{t("Profile.SellerFunctionalities")}</div>
              <CheckBoxWithLabel
                checked={userData.inSale}
                onClick={handleinSale}
                label={t("Profile.InSale")}
              />
            </div>
            <div
              style={!userData.shippingAddressFlag ? { display: "none" } : null}
            >
              <p className="mb-10">
                <strong>Shipping address:</strong>
              </p>
              <div className="df aic jcsb mb-10">
                <span style={{ width: "120px" }}>{t("Profile.Country")}:</span>
                <div style={{ width: "255px" }}>
                  <CountrySelector
                    value={userData.shippingAddress?.country}
                    onChange={handleShippingAddress}
                  />
                </div>
              </div>
              <CustomInput
                label={"Street"}
                name="street"
                value={userData.shippingAddress.street}
                onChange={handleShippingAddress}
                placeholder={"Street"}
              />
              <CustomInput
                label={"House NO#"}
                name="houseNo"
                value={userData.shippingAddress.houseNo}
                onChange={handleShippingAddress}
                placeholder={"House Number"}
              />
              <CustomInput
                label={t("Profile.AddressLine1")}
                name="adrLine1"
                value={userData.shippingAddress.adrLine1}
                onChange={handleShippingAddress}
                placeholder={t("Profile.AddressLine1")}
              />
              <CustomInput
                label={"City"}
                name="city"
                value={userData.shippingAddress.city}
                onChange={handleShippingAddress}
                placeholder={"City"}
              />
              <CustomInput
                label={"Zip Code"}
                name="zipCode"
                value={userData.shippingAddress.zipCode}
                onChange={handleShippingAddress}
                placeholder={"Zip Code"}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="submit-button-wrapper">
        <Button
          variant="contained"
          component="label"
          style={{
            marginBottom: "10px",
            borderColor: "#2e313f",
            color: "#fff",
            backgroundColor: "#00A86B",
            width: "250px",
          }}
          onClick={() => handleSubmit()}
        >
          Update
        </Button>
        {!updateEmail1 ? (
          <>
            &nbsp; &nbsp; &nbsp;
            <Button
              variant="contained"
              component="label"
              style={{
                borderColor: "#2e313f",
                color: "#fff",
                backgroundColor: "#1890FF",
                width: "250px",
              }}
              onClick={() => setUpdateEmail1(true)}
            >
              {t("Profile.UpdateEmail")}
            </Button>
          </>
        ) : (
          <>
            &nbsp; &nbsp; &nbsp;
            <input
              className="custom-input"
              name="updatedEmail"
              value={updatedEmail}
              type={"email"}
              placeholder={t("Profile.EnterNewEmail")}
              onChange={(e) => setUpdatedEmail(e.target.value)}
            />
            &nbsp; &nbsp; &nbsp;
            <input
              className="custom-input"
              name="reEnteredEmail"
              value={reEnteredEmail}
              type={"email"}
              placeholder={t("Profile.Reenteremail")}
              onChange={(e) => setReEnteredEmail(e.target.value)}
              onPaste={(e) => e.preventDefault()}
            />
            &nbsp; &nbsp;
            <span
              style={{
                display: "inline-block",
                lineHeight: "35px",
                height: "35px",
              }}
              className="iconButton"
              onClick={() => handleEmailUpdate()}
            >
              {/* <i className="ri-mark-pen-line iconButton-symbol"></i> */}
              <>
                <i className="ri-check-double-line"></i>&nbsp;&nbsp;
              </>
              {t("Profile.Update")}
            </span>
          </>
        )}
      </div>
      {/* {profileUpdated ? <Loader /> : null} */}
      {profileUpdated ? <InnerLoader /> : null}
    </div>
  ) : null;
};

export default UpdateUser;
