import React from "react";
import CustomButton from "../../../leftPanel2/components/customButton";

const TextStylesOptions = ({ setActiveStyle, getActiveStyle }) => {
  const toggleBold = () => {
    setActiveStyle(
      "fontWeight",
      getActiveStyle("fontWeight") === "bold" ? "" : "bold"
    );
  };

  const toggleItalic = () => {
    setActiveStyle(
      "fontStyle",
      getActiveStyle("fontStyle") === "italic" ? "" : "italic"
    );
  };

  const isUnderline = () => {
    return (
      getActiveStyle("textDecoration").indexOf("underline") > -1 ||
      getActiveStyle("underline")
    );
  };

  const toggleUnderline = () => {
    var value = isUnderline()
      ? getActiveStyle("textDecoration").replace("underline", "")
      : getActiveStyle("textDecoration") + " underline";

    setActiveStyle("textDecoration", value);
    setActiveStyle("underline", !getActiveStyle("underline"));
  };

  const isLinethrough = () => {
    return (
      getActiveStyle("textDecoration").indexOf("line-through") > -1 ||
      getActiveStyle("linethrough")
    );
  };

  const toggleLinethrough = () => {
    var value = isLinethrough()
      ? getActiveStyle("textDecoration").replace("line-through", "")
      : getActiveStyle("textDecoration") + " line-through";

    setActiveStyle("textDecoration", value);
    setActiveStyle("linethrough", !getActiveStyle("linethrough"));
  };

  const isOverline = () => {
    return (
      getActiveStyle("textDecoration").indexOf("overline") > -1 ||
      getActiveStyle("overline")
    );
  };
  const toggleOverline = () => {
    var value = isOverline()
      ? getActiveStyle("textDecoration").replace("overline", "")
      : getActiveStyle("textDecoration") + " overline";

    setActiveStyle("textDecoration", value);
    setActiveStyle("overline", !getActiveStyle("overline"));
  };
  return (
    <div>
      <CustomButton text="Bold" onClick={() => toggleBold()} />
      <CustomButton text="Italic" onClick={() => toggleItalic()} />
      <CustomButton text="Underline" onClick={() => toggleUnderline()} />
      <CustomButton text="Linethrough" onClick={() => toggleLinethrough()} />
      <CustomButton text="Overline" onClick={() => toggleOverline()} />
    </div>
  );
};

export default TextStylesOptions;
