import React from "react";

const SizeButton = ({ index, text, onClick }) => {
  return (
    <button
      key={index}
      style={{
        background: "#fff",
        height: "40px",
        width: "40px",
        marginRight: "5px",
        marginBottom: "5px",
        fontWeight: "bold",
        border: "none",
        borderRadius: "5px",
      }}
      onClick={onClick}
    >
      {text}
    </button>
  );
};

export default SizeButton;
