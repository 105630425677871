import { filterConstants } from "../constants";
const {
  GREY_SCALE,
  MODE,
  INVERT,
  SEPIA,
  SHARPEN,
  EMBOSS,
  BLACK_WHITE,
  BROWNIE,
  VINTAGE,
  KODACHROME,
  TECHNICOLOR,
  POLAROID,
} = filterConstants;
const initialState = {
  greyScale: false,
  mode: "average",
  invert: false,
  sepia: false,
  sharpen: false,
  emboss: false,
  blackWhite: false,
  brownie: false,
  vintage: false,
  kodachrome: false,
  technicolor: false,
  polaroid: false,
};

export const filterReducer = (state = initialState, action) => {
  switch (action.type) {
    // face
    case GREY_SCALE:
      return {
        ...state,
        greyScale: action.payload,
      };
    case MODE:
      return {
        ...state,
        mode: action.payload,
      };
    case INVERT:
      return {
        ...state,
        invert: action.payload,
      };
    case SEPIA:
      return {
        ...state,
        sepia: action.payload,
      };
    case SHARPEN:
      return {
        ...state,
        sharpen: action.payload,
      };
    case EMBOSS:
      return {
        ...state,
        emboss: action.payload,
      };
    case BLACK_WHITE:
      return {
        ...state,
        blackWhite: action.payload,
      };
    case BROWNIE:
      return {
        ...state,
        brownie: action.payload,
      };
    case VINTAGE:
      return {
        ...state,
        vintage: action.payload,
      };
    case KODACHROME:
      return {
        ...state,
        kodachrome: action.payload,
      };
    case TECHNICOLOR:
      return {
        ...state,
        technicolor: action.payload,
      };
    case POLAROID:
      return {
        ...state,
        polaroid: action.payload,
      };
    default:
      return state;
  }
};
