import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Paper from "@material-ui/core/Paper";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../firebase";
import { getStorage, ref, deleteObject } from "firebase/storage";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { CircularProgress, withStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { orderConstants } from "../../../redux/constants";
const { PRODUCTS, PRODUCT_DELETE_LOADING } = orderConstants;

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
    backgroundColor: "#161C24",
  },
});

const ProductTable = ({ products, setEditing, setEditingItem }) => {
  console.log("products", products);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const _order = useSelector((state) => state.order);
  const [page, setPage] = React.useState(0);
  const [pictures, setPictures] = useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const columns = [
    { id: "productId", label: t("ProductTable.PRODUCTID"), minWidth: 170 },
    { id: "name", label: t("ProductTable.Name"), minWidth: 100 },
    { id: "picture", label: t("ProductTable.PICTURE"), minWidth: 100 },
    { id: "sku", label: t("ProductTable.SKU"), minWidth: 100 },
    { id: "inSale", label: t("ProductTable.InSale"), minWidth: 100 },
    {
      id: "details",
      label: "",
      minWidth: 170,
      align: "right",
    },
  ];

  const handleDetailClick = (item) => {
    setEditing(true);
    setEditingItem(item);
  };

  useEffect(() => {
    let tempArr = [];
    if (products.length > 0) {
      for (let i = 0; i < products.length; i++) {
        const w = parseInt(products[i].productImage.split(/[ ;]+/)[0]);
        const h = parseInt(products[i].productImage.split(/[ ;]+/)[1]);
        const nImg = {
          width: w,
          height: h,
          url: products[i].productImage.split(/[ ;]+/)[2],
        };
        tempArr.push(nImg);
      }
      setPictures(tempArr);
    }
  }, [products]);

  const calcCf = (w, h, n) => {
    let cf = 0;
    if (w > h) {
      cf = w / n;
    } else {
      cf = h / n;
    }
    return cf;
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleRemoveProduct = (docID) => {
    dispatch({
      type: PRODUCT_DELETE_LOADING,
      payload: true,
    });
    const storage = getStorage();
    try {
      console.log("getProductImagePaths in productTable");
      const getProductImagePaths = httpsCallable(
        functions,
        "dbProductsOnCall-getProductImagePaths"
      );
      getProductImagePaths({ docID }).then((result) => {
        //remove pictures
        // URL with token to path
        result.data.forEach(function (arrayItem) {
          // transform URL to path
          var url_token = arrayItem.split("?");
          var url = url_token[0].split("/");
          var filePath = url[url.length - 1].replaceAll("%2F", "/");

          // deletion of the path file
          const desertRef = ref(storage, filePath);
          deleteObject(desertRef)
            .then(() => {
              // File deleted successfully
            })
            .catch((error) => {
              console.log(error);
            });
        });
      });
    } catch (e) {
      console.error("Error retreiving Image path from product: ", e);
    }

    // removeProduct
    try {
      // setProductsLoading(true);
      const removeProduct = httpsCallable(
        functions,
        "dbProductsOnCall-removeProduct"
      );
      removeProduct({ docID }).then((result) => {
        // code here
        // setProducts(result.data);
        // setProductsLoading(false);
        dispatch({
          type: PRODUCTS,
          payload: products.filter((item, i) => item.productID !== docID),
        });
        dispatch({
          type: PRODUCT_DELETE_LOADING,
          payload: false,
        });
      });
    } catch (e) {
      console.error("Error removing Image: ", e);
      // setProductsLoading(false);
    }
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column, i) => (
                <TableCell
                  key={i}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    backgroundColor: "#212B36",
                    color: "#F9FAFB",
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {products
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, i) => {
                return (
                  <TableRow key={i} hover role="checkbox" tabIndex={-1}>
                    <TableCell style={{ color: "#fff" }}>
                      {row.productID}
                    </TableCell>
                    <TableCell style={{ color: "#fff" }}>{row.name}</TableCell>
                    <TableCell style={{ color: "#fff" }}>
                      <img
                        src={pictures[i]?.url}
                        width={(
                          pictures[i]?.width /
                          calcCf(pictures[i]?.width, pictures[i]?.height, 50)
                        ).toString()}
                        height={(
                          pictures[i]?.height /
                          calcCf(pictures[i]?.width, pictures[i]?.height, 50)
                        ).toString()}
                        alt="productImage"
                      />
                    </TableCell>
                    <TableCell style={{ color: "#fff" }}>{row.sku}</TableCell>
                    <TableCell style={{ color: "#fff" }}>
                      {row.inSale
                        ? t("ProductTable.Yes")
                        : t("ProductTable.No")}
                    </TableCell>
                    <TableCell align="right">
                      <LightTooltip title="Edit" placement="top">
                        <i
                          style={{ color: "#B78103", fontSize: "20px" }}
                          className="cup fal fa-edit"
                          onClick={() => handleDetailClick(row)}
                        ></i>
                      </LightTooltip>
                      &nbsp; &nbsp;
                      <LightTooltip title="Delete" placement="top">
                        <i
                          style={{ color: "#FF4842", fontSize: "20px" }}
                          className="cup fal fa-trash-alt"
                          onClick={() => handleRemoveProduct(row.productID)}
                        ></i>
                      </LightTooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        {_order.productsLoading ? (
          <div style={{ textAlign: "center", margin: "20px" }}>
            <CircularProgress style={{ color: "#1CCAFF" }} />
          </div>
        ) : null}
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={products.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        style={{ backgroundColor: "#212B36", color: "#F9FAFB" }}
      />
    </Paper>
  );
};

export default ProductTable;
