import React from "react";
import "./iconBox.css";

const IconBox = ({ bgColor, icon }) => {
  return (
    <div className="iconBox" style={{ background: bgColor }}>
      <i className={icon}></i>
    </div>
  );
};

export default IconBox;
