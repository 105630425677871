import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { filterConstants } from "../../../../../redux/constants";
import { useTranslation } from "react-i18next";
import CustomCheckBox from "../../../../customCheckBox";
const { SHARPEN } = filterConstants;

const SharpenFilter = ({ applyFilter, fabric }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const _filter = useSelector((state) => state.filter);
  const applySharpen = (check) => {
    dispatch({
      type: SHARPEN,
      payload: check,
    });
    applyFilter(
      12,
      check &&
        new fabric.Image.filters.Convolute({
          matrix: [0, -1, 0, -1, 5, -1, 0, -1, 0],
        })
    );
  };
  return (
    <div>
      <CustomCheckBox
        lable={t("SharpenFilter.Sharpen")}
        checked={_filter.sharpen}
        onClick={(e) => applySharpen(e.target.checked)}
      />
    </div>
  );
};

export default SharpenFilter;
