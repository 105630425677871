import React from "react";
import { useDispatch, useSelector } from "react-redux";
import CategoriesSidebar from "./components/categoriesSidebar";
// import product1 from "../../../../assets/images/product1.jpg";
import ProductItem from "./components/productItem";
import StudioDropdown from "./components/studioDropdown";
import "./productsList.css";
import { cartConstants } from "../../../../redux/constants";
import InnerLoader from "../../../innerLoader";
import { colorConstants } from "../../../../colors";
import { useTranslation } from "react-i18next";
const { PRODUCT_POPUP_Items_BG } = colorConstants;
const { SELECT_PRODUCT } = cartConstants;

const ProductsList = ({
  setProductsPopupOpen,
  products,
  setShowDetail,
  setSelectedProduct,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const _productOptions = useSelector((state) => state.productOptions);

  const handleProductSelect = () => {
    dispatch({
      type: SELECT_PRODUCT,
      payload: {
        name: "Dummy Product",
        sku: "",
        productImage: null,
        description: "",
        threeDModel: "Female Basic T-Shirt",
        canvases: [
          {
            width: "3484",
            height: "4327",
          },
          {
            width: "3484",
            height: "4327",
          },
        ],
        shipments: [],
        variants: [],
      },
    });
    setProductsPopupOpen(false);
  };
  return (
    <div className="productsList">
      {/* Dummy Button */}
      <div className="dummy-wrapper">
        <div
          className="dummy-btn cud"
          style={{ background: PRODUCT_POPUP_Items_BG }}
          onClick={() => handleProductSelect()}
        >
          <span>Dummy</span>
        </div>
        <StudioDropdown />
        <i
          className="ri-close-line product-popup-close-icon"
          onClick={() => setProductsPopupOpen(false)}
        ></i>
      </div>
      {/* Dropdown */}
      {/* Popup Body */}
      <div className="popup-body">
        {/* Categories Side Bar */}
        <CategoriesSidebar />
        <ul
          className="products-area"
          style={{ background: PRODUCT_POPUP_Items_BG }}
        >
          {/* {products.length > 0 && products
            ? products.map((product, i) => (
                <ProductItem
                  product={product}
                  setSelectedProduct={setSelectedProduct}
                  setShowDetail={setShowDetail}
                />
              ))
            : null} */}
          {_productOptions.products.map((p, i) =>
            p.inSale ? (
              <span key={i}>
                <ProductItem
                  product={p}
                  index={i}
                  setSelectedProduct={setSelectedProduct}
                  // setShowDetail={setShowDetail}
                  setProductsPopupOpen={setProductsPopupOpen}
                />
              </span>
            ) : null
          )}
          {!_productOptions.selectedStudio ? (
            // {t("StudioDropdown.SelectStudio")}
            <h1 style={{ color: "#fff" }}>
              {t("ProductsList.PleaseSelectTheStudio")}
            </h1>
          ) : null}
          {_productOptions.productsLoading && _productOptions.selectedStudio ? (
            <InnerLoader />
          ) : null}
        </ul>
      </div>
    </div>
  );
};

export default ProductsList;
